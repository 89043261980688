import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';
import { colors, elevation } from '@skyslope/mache';

export const StyledMenu = withStyles({
  list: {
    padding: 0,
  },
  paper: {
    boxShadow: elevation.active[12],
  },
})(Menu);

export const StyledMenuItem = withStyles({
  root: {
    padding: '8px 16px',
    colors: colors.grey[800],
    '&:hover': {
      backgroundColor: colors.blue[50],
      color: colors.blue[800],
    },
  },
})(MenuItem);
