import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { colors, typography } from '@skyslope/mache';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  input: {
    fontFamily: 'proxima-nova,Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,Roboto,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    flexGrow: 1,
    borderRadius: '26px',
    border: `1px solid ${colors.grey[400]}`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: colors.grey[500],
    },
    ...typography.body1,
  },
  icon: {
    color: colors.grey[500],
    position: 'absolute',
    right: theme.spacing(3),
    pointerEvents: 'none',
  },
  clearIcon: {
    color: colors.grey[800],
    pointerEvents: 'initial',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  title?: string;
}

const Search = (props: IProps) => {
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      <input
        title={props.title}
        disabled={props.disabled}
        placeholder="Search..."
        className={classes.input}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        data-spec="search-input"
      />

      {props.value ? (
        <ClearIcon
          className={`${classes.icon} ${classes.clearIcon}`}
          onClick={props.disabled ? undefined : () => props.onChange('')}
        />
      ) : (
        <SearchIcon className={classes.icon} />
      )}
    </div>
  );
};

export default Search;
