import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { colors } from '@skyslope/mache';
import { isSmallScreenMediaQuery } from '../../lib/isSmallScreen';

const useStyles = makeStyles(() => ({
  modal: {
    height: '230px',
    width: '600px',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '40px',
  },
  icon: {
    color: colors.grey[500],
    position: 'absolute',
    top: '6px',
    right: '6px',
  },
  modalTitle: {
    display: 'flex',
    justifyCntent: 'flex-start',
    color: colors.grey[900],
    padding: '0px 0px 24px 0px',
  },
  body: {
    padding: '0px 40px 0px 40px',
  },
  subject: {
    fontSize: '22px',
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    padding: '15px',
    borderTop: `1px solid ${colors.grey[400]}`,
    width: '100%',
  },
  stayButton: {
    marginRight: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    '&:hover': {
      backgroundColor: colors.blue[50],
    },
  },
  leaveButton: {
    '&:hover': {
      backgroundColor: colors.blue[600],
    },
  },
}));

const modalTitle = 'Are you sure you want to leave?';
const modalBody = 'Changes you have entered will not be saved.';

interface IProps {
  closeModal: () => void;
  confirmBack: () => void;
}

const ActionButtons = ({ closeModal, confirmBack }: { closeModal: () => void; confirmBack: () => void }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonWrapper}>
      <Button data-spec="stayButton" variant="text" color="primary" className={classes.stayButton} onClick={closeModal}>
        Stay
      </Button>
      <Button
        data-spec="leaveButton"
        variant="contained"
        color="primary"
        className={classes.leaveButton}
        onClick={confirmBack}
      >
        Leave
      </Button>
    </div>
  );
};

export const WarningFormsModal = (props: IProps) => {
  const classes = useStyles();
  const isSmallScreen = isSmallScreenMediaQuery();

  return (
    <Dialog
      open
      onClose={props.closeModal}
      classes={{ paper: isSmallScreen ? '' : classes.modal }}
      maxWidth="lg"
      fullWidth
      fullScreen={isSmallScreen}
      BackdropProps={{ style: { backgroundColor: colors.grey[800], opacity: 0.6 } }}
    >
      <div className={classes.closeButton}>
        <IconButton data-spec="closeFromsLeaveWarning" classes={{ root: classes.icon }} onClick={props.closeModal}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.body}>
        <div className={classes.modalTitle}>
          <Typography variant="h3">{modalTitle}</Typography>
        </div>
        <Typography>{modalBody}</Typography>
      </div>
      <ActionButtons closeModal={props.closeModal} confirmBack={props.confirmBack} />
    </Dialog>
  );
};

export default WarningFormsModal;
