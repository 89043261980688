import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { colors } from '@skyslope/mache';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderRadius: '16px',
    backgroundColor: colors.grey[200],
    border: `1px solid ${colors.grey[500]}`,
    color: colors.grey[800],
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    height: 32,
    minWidth: 120,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:focus': {
      border: `1px solid ${colors.blue[100]}`,
    },
  },
  icon: {
    display: 'flex',
    color: colors.grey[500],
    marginRight: theme.spacing(1),
  },
  text: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  removeIcon: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btn: {
    display: 'contents',
  },
}));

interface IProps {
  icon?: React.ReactNode;
  text: string;
  onDelete?: Function;
  key?: string;
  pillDetail?: string;
}

const Pill = (props: IProps) => {
  const { icon, text, onDelete, key, pillDetail } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      tabIndex={0}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onDelete!();
        }
      }}
      aria-label={`${text} ${pillDetail || ''} - Press Enter to delete`}
    >
      {icon ? <div className={classes.icon}>{icon}</div> : ''}
      <div data-spec={`pill-${key}`} className={classes.text}>
        {text}
      </div>
      {onDelete ? (
        <button type="button" role="button" className={classes.btn} onClick={() => onDelete!()}>
          <CancelIcon className={classes.removeIcon} />
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default Pill;
