import { combineReducers } from 'redux';
import { fork } from 'redux-saga/effects';

// Documents
import documentsReducers from './documents/reducers';
import { IDocumentsState } from './documents/types';
import documentsSagas from './documents/sagas';

// Recipients
import { IRecipientsState } from './recipients/types';
import recipientReducers from './recipients/reducers';
import recipientsSagas from './recipients/sagas';

// Properties
import propertiesReducers from './properties/reducer';
import { IPropertiesState } from './properties/types';
import propertiesSaga from './properties/saga';

export interface IDTMState {
  documents: IDocumentsState;
  recipients: IRecipientsState;
  properties: IPropertiesState;
}

export const dtmReducers = combineReducers({
  documents: documentsReducers,
  recipients: recipientReducers,
  properties: propertiesReducers,
});

export const dtmSagas = [fork(documentsSagas), fork(recipientsSagas), fork(propertiesSaga)];
