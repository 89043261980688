import { usePrimeAuthSession } from '@skyslope/auth-react';
import useChameleon from '../hooks/useChameleon';
import useHeap from '../hooks/useHeap';
import useClarity from '../hooks/useClarity';

export default function SecurityEffects() {
  usePrimeAuthSession();
  useHeap();
  useChameleon();
  useClarity();

  return null;
}
