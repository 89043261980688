import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';

const useStyles = makeStyles(() => ({
  footer: (props: IProps) => ({
    position: 'fixed',
    bottom: 0,
    padding: '15px',
    justifyContent: 'flex-end',
    display: 'flex',
    right: 0,
    left: props.customLeft ? props.customLeft : 0,
    width: props.customWidth ? props.customWidth : '',
    border: `1px solid ${colors.grey[400]}`,
    zIndex: 100,
    backgroundColor: colors.grey[100],
    '& > *': {
      marginLeft: '15px',
    },
  }),
}));

interface IProps {
  children: React.ReactNode;
  customLeft?: any;
  customWidth?: any;
}

const Footer = (props: IProps) => {
  const { children } = props;
  const classes = useStyles(props);
  return <div className={classes.footer}>{children}</div>;
};

export default Footer;
