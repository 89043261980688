import React from 'react';
import { useHistory } from 'react-router';
import { StyledMenu, StyledMenuItem } from '../common/StyledMenu';
import { EnvelopeStatus, IEnvelopeMetadata } from '../../store/envelopeManagement/types';
import * as actions from '../../store/envelopeHistory/actions';
import * as senderActions from '../../store/senderBuild/actions';
import { useDispatch } from '../../lib/reduxHooks';
import DeleteModal from './DeleteModal';
import CancelModal from './CancelModal';
import ResendModal from './ResendModal';
import CorrectModal from './CorrectModal';
import RenameModal from './RenameModal';
import { generateFileName } from '../../lib/utils';
import { hideAlert } from '../../store/pageFrame/actions';
import { IRecipient } from '../../common/recipients/types';
import { LaunchDarklyFlags } from '../../common/launchDarkly';
import { getEnvelopeDocuments } from '../../lib/api/envelope/envelope';
import { checkIfRecipientIsSender } from '../../common/utils';

interface IProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: (e: any) => void;
  envelope: IEnvelopeMetadata;
  sender?: { firstName: string, lastName: string, email: string };
  recipients?: IRecipient[];
  flags?: LaunchDarklyFlags;
}

// This tracks all of the possible menu options
const menuItemsMap = {
  downloadEnvelope: {
    key: 'downloadEnvelope',
    label: 'Download Envelope',
  },
  downloadCertificate: {
    key: 'downloadCertificate',
    label: 'Download Certificate',
  },
  viewHistory: {
    key: 'viewHistory',
    label: 'View History',
  },
  editEnvelope: {
    key: 'editEnvelope',
    label: 'Edit Envelope',
  },
  resendEnvelope: {
    key: 'resendEnvelope',
    label: 'Send Reminder',
  },
  cancelEnvelope: {
    key: 'cancelEnvelope',
    label: 'Cancel Envelope',
  },
  deleteDraft: {
    key: 'deleteDraft',
    label: 'Delete Draft',
  },
  correctEnvelope: {
    key: 'correctEnvelope',
    label: 'Correct Envelope',
  },
  signNow: {
    key: 'signNow',
    label: 'Sign Envelope'
  },
  renameEnvelope: {
    key: 'renameEnvelope',
    label: 'Edit Envelope Name'
  }
};

// This tracks what options we want for each envelope status
// This is the order actions should be in if they are being included:
// signEnvelope
// renameEnvelope
// editEnvelope
// resendEnvelope
// downloadEnvelope
// downloadCertificate
// viewHistory
// cancelEnvelope
export const envelopeStatusActionsMap = {
  [EnvelopeStatus.DRAFT]: [
    menuItemsMap.deleteDraft,
  ],
  [EnvelopeStatus.COMPLETED]: [
    menuItemsMap.downloadEnvelope,
    menuItemsMap.downloadCertificate,
    menuItemsMap.viewHistory,
  ],
  [EnvelopeStatus.SENT]: [
    menuItemsMap.correctEnvelope,
    menuItemsMap.resendEnvelope,
    menuItemsMap.downloadEnvelope,
    menuItemsMap.downloadCertificate,
    menuItemsMap.viewHistory,
    menuItemsMap.cancelEnvelope,
  ],
  [EnvelopeStatus.INPROGRESS]: [
    menuItemsMap.correctEnvelope,
    menuItemsMap.resendEnvelope,
    menuItemsMap.downloadEnvelope,
    menuItemsMap.downloadCertificate,
    menuItemsMap.viewHistory,
    menuItemsMap.cancelEnvelope,
  ],
  [EnvelopeStatus.CANCELED]: [
    menuItemsMap.downloadEnvelope,
    menuItemsMap.viewHistory,
  ],
  [EnvelopeStatus.CORRECTING]: [
    menuItemsMap.downloadEnvelope,
    menuItemsMap.viewHistory,
    menuItemsMap.cancelEnvelope,
  ],
};

const ActionsMenu = (props: IProps) => {
  const { open, anchorEl, onClose, envelope, sender, recipients } = props;
  const showEditEnvelopeName = props.flags && props.flags['show-edit-envelope-name'];
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showResendModal, setShowResendModal] = React.useState(false);
  const [showCorrectModal, setShowCorrectModal] = React.useState(false);
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const senderRecipient = recipients?.find(r => checkIfRecipientIsSender(r) && r.email === sender?.email);
  const openResendModal = () => {
    setShowResendModal(true);
    dispatch(actions.resend(envelope.id));
  };

  // This tracks what actions we want to occur on the click of an option
  const menuItemsActionsMap = {
    viewHistory: () => history.push(`/envelopes/${envelope.id}/history`),
    editEnvelope: () => history.push(`/envelopes/${envelope.id}/documents`),
    cancelEnvelope: () => setShowCancelModal(true),
    resendEnvelope: () => openResendModal(),
    downloadEnvelope: async () => {
      const docs = await getEnvelopeDocuments(envelope.id);
      const envelopeFileName = generateFileName(docs);
      dispatch(actions.downloadEnvelope(envelope.id, envelopeFileName, false));
    },
    downloadCertificate: () => {
      const certificateFileName = 'DigiSignAuditCertificate.pdf';
      dispatch(actions.downloadCertificate(envelope.id, certificateFileName, false));
    },
    deleteDraft: () => setShowDeleteModal(true),
    correctEnvelope: () => setShowCorrectModal(true),
    signNow: () => dispatch(senderActions.fetchSignerToken({ envelopeId: envelope.id, signerId: senderRecipient?.id, signerEmail: sender?.email })),
    renameEnvelope: () => setShowRenameModal(true),
  };

  const clickMenuItem = async (event: any, itemKey?: string) => {
    event.stopPropagation()
    await menuItemsActionsMap[itemKey || '']();
    onClose(event);
  };

  const clickHandler = () => {
    setShowResendModal(false);
    dispatch(hideAlert());
  }

  const getActionItems = () => {
    const addSignNow = envelope.senderCanSign && (envelope.status === "Sent" || envelope.status === "InProgress");
    if (showEditEnvelopeName) {
      let editNameOptions = [menuItemsMap.renameEnvelope];
      if (addSignNow) {
        editNameOptions.unshift(menuItemsMap.signNow);
      }
      return editNameOptions.concat(envelopeStatusActionsMap[envelope.status.toLowerCase()]);
    }
    else if (addSignNow) {
      const signNow = [menuItemsMap.signNow];
      return signNow.concat(envelopeStatusActionsMap[envelope.status.toLowerCase()]);
    }
    else return envelopeStatusActionsMap[envelope.status.toLowerCase()];
  };

  return (
    <>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        data-spec="actions-menu"
      >
        {getActionItems().map(
          (item: { key: string; label: string; action?: () => void }) => (
            <StyledMenuItem
              data-spec={`actions-menu-item-${item.key}`}
              onClick={async e => clickMenuItem(e, item.key)}
              key={item.key}
            >
              {item.label}
            </StyledMenuItem>
          )
        )}
      </StyledMenu>
      {showDeleteModal && <DeleteModal envelopeId={envelope.id} close={() => setShowDeleteModal(false)} />}
      {showCancelModal && <CancelModal envelopeId={envelope.id} close={() => setShowCancelModal(false)} />}
      {showResendModal && <ResendModal close={clickHandler} flags={props.flags!} />}
      {showCorrectModal && <CorrectModal envelopeId={envelope.id} close={() => setShowCorrectModal(false)} />}
      {showRenameModal && <RenameModal envelopeId={envelope.id} envelopeName={envelope.name} close={() => setShowRenameModal(false)} />}
    </>
  );
};

export default ActionsMenu;
