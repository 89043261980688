import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BLOCK_TYPE_KEYS } from '../../lib/constants';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/applyTemplate/actions';
import { IRootState } from '../../store';
import { IBlockWithSignerNumber, ITemplateMetadata } from '../../store/applyTemplate/types';
import { IDocument, IPageDimensions } from '../../store/senderBuild/types';
import { Document, Page } from 'react-pdf';
import pdfCache from '../../lib/pdfCache';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { colors } from '@skyslope/mache';
import Pagination from '@material-ui/lab/Pagination';
import CheckIcon from '@material-ui/icons/CheckCircle';
import BlockPreview from './BlockPreview';
import { ReactComponent as Tab } from '../../images/tab.svg';
import { getRecipientColor } from '../../lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: -24,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: 'grid',
  },
  document: {
    '@global canvas': {
      width: '100% !important',
      height: 'auto !important',
    },
    position: 'relative',
  },
  page: {
    outline: `1px solid ${colors.grey[500]}`,
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
    height: '74px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  paginationUl: {
    margin: 'revert',
    '& button': {
      color: colors.grey[800],
      '&.Mui-selected': {
        backgroundColor: colors.blue[100],
        color: 'inherit',
        '&:hover': {
          backgroundColor: colors.blue[100],
        },
      },
      '&.Mui-disabled': {
        opacity: 1,
        color: colors.grey[500],
      },
    },
  },
  plusIcon: {
    margin: '-2px 0px -2px -6px',
    height: 18,
  },
  appliedTemplate: {
    color: colors.grey[500],
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  check: {
    color: '#02AF90',
    fontSize: 18,
    marginRight: theme.spacing(1),
  },
  spinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.33)',
  },
  signerTags: {
    position: 'absolute',
    top: 16,
    left: -20,
    display: 'flex',
    flexDirection: 'column',
  },
  tag: {
    marginTop: 16,
    width: 28,
  },
  pageWrapper: {
    position: 'relative',
    margin: '0px 28px',
  },
}));

interface IState {
  document: IDocument;
  numberOfSigners: number;
  selectedPageIndex: number;
  showTemplatesSection: boolean;
  appliedTemplate?: { metadata: ITemplateMetadata; blocks?: [] };
  fetchedAppliedTemplates: boolean;
  templatePageDimensions: IPageDimensions;
}

const selector = (state: IRootState) => ({
  document: state.applyTemplate.envelope!.documents.find(
    (d) => d.documentId === state.applyTemplate.selectedDocumentId!
  ),
  numberOfSigners: state.applyTemplate.envelope!.signers.length,
  selectedPageIndex: state.applyTemplate.selectedPageIndex,
  showTemplatesSection: state.applyTemplate.showTemplatesSection,
  appliedTemplate: state.applyTemplate.appliedTemplates[state.applyTemplate.selectedDocumentId!],
  fetchedAppliedTemplates: state.applyTemplate.fetchedAppliedTemplates,
  templatePageDimensions: state.applyTemplate.pageDimensions,
});

const DocumentPreview = () => {
  const {
    document,
    selectedPageIndex,
    showTemplatesSection,
    appliedTemplate,
    fetchedAppliedTemplates,
    templatePageDimensions,
  }: IState = useShallowSelector(selector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(1.5);

  const selectPage = (pageIndex: number) => {
    if (pageIndex === selectedPageIndex) {
      return;
    }
    dispatch(actions.selectPage(pageIndex));
  };

  useEffect(() => {
    if (!showTemplatesSection) dispatch(actions.showTemplatesSection());
  });

  const [pageDimensions, setPageDimensions] = React.useState({ x: NaN, y: NaN });

  const handlePageLoaded = (page: any) => {
    setPageDimensions({ x: page.width / zoom, y: page.height / zoom });
  };

  const blockFilter = (block: IBlockWithSignerNumber) => {
    if (block.pageNumber !== selectedPageIndex) {
      return false;
    }
    if (block.x! + block.width! > pageDimensions.x || block.y! + block.height! > pageDimensions.y) {
      return false;
    }
    return true;
  };

  const getBlockColor = (block: IBlockWithSignerNumber) => {
    if (block.blockType === BLOCK_TYPE_KEYS.STRIKE) {
      return '#000';
    } else if (block.blockType === BLOCK_TYPE_KEYS.RECTANGLE) {
      return block.bgColor;
    } else if (block.signerNumber > -1) {
      return getRecipientColor(block.signerNumber + 1);
    } else {
      return colors.grey[500];
    }
  };

  const height = templatePageDimensions[document.documentId][selectedPageIndex].height;
  const width = templatePageDimensions[document.documentId][selectedPageIndex].width;

  return (
    <div className={classes.wrapper}>
      <div
        style={{
          padding: '0px 28px',
          display: 'flex',
          flexDirection: 'column',
          width: width * zoom,
          justifySelf: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '766px' }}>
          <Typography
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginRight: '60px' }}
            variant="h5"
          >
            {document.fileName}
          </Typography>
        </div>
        <div className={classes.subheader}>
          {appliedTemplate && (
            <Typography variant="overline" className={classes.appliedTemplate}>
              <CheckIcon className={classes.check} />
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '580px' }}>
                {appliedTemplate.metadata.name}
              </span>
            </Typography>
          )}
        </div>
      </div>
      <span
        className={classes.pageWrapper}
        style={{
          height: height * zoom,
          width: width * zoom,
          justifySelf: 'center',
        }}
      >
        <Document
          className={classes.document}
          key={document.documentId}
          file={pdfCache[document.documentId]}
          loading=""
        >
          <Page
            scale={zoom}
            pageNumber={selectedPageIndex + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            className={classes.page}
            onLoadSuccess={handlePageLoaded}
            loading=""
          />
          {fetchedAppliedTemplates && appliedTemplate && !appliedTemplate?.blocks ? (
            <div className={classes.spinner}>
              <CircularProgress id="apply-template-spinner" />
            </div>
          ) : (
            ''
          )}
          {appliedTemplate?.blocks && (
            <div className={classes.signerTags}>
              {Array.from({ length: appliedTemplate?.metadata.numberOfSigners }).map((_, i) => (
                <Tab key={i} className={classes.tag} style={{ color: getRecipientColor(i + 1) }} />
              ))}
            </div>
          )}
          {appliedTemplate?.blocks &&
            appliedTemplate.blocks
              .filter(blockFilter)
              .map((block) => (
                <BlockPreview
                  key={block.blockId}
                  block={block}
                  color={getBlockColor(block)}
                  pageDimensions={pageDimensions}
                />
              ))}
        </Document>
      </span>
      <Pagination
        className={classes.pagination}
        classes={{ root: classes.pagination, ul: classes.paginationUl }}
        count={document.numberOfPages}
        page={selectedPageIndex + 1}
        onChange={(_: any, page: number) => selectPage(page - 1)}
        color="primary"
        size="small"
      />
    </div>
  );
};

export default DocumentPreview;
