import update from 'immutability-helper';
import { ActionTypes } from './actions';
import { IEnvelopeHistoryState, IAuditHistoryBody, IRecipientBody } from './types';
import { EnvelopeStatuses, eventNames } from '../../lib/constants';
import produce from 'immer';
import dayjs from 'dayjs';

export const initialState: IEnvelopeHistoryState = {
  isFetching: false,
  envelope: {
    id: '',
    status: '',
    recipients: [],
    documents: [],
    sentDate: '',
    signerDetails: {
      details: [],
      activeGroup: '',
    },
  },
  recipientsWhoHaveNotViewed: [],
  auditHistory: [],
  envelopeId: '',
  lastRecipient: '',
  updateRecipientsError: null,
};

export default (state: IEnvelopeHistoryState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_DONE: {
      const { envelopeHistory, auditHistory } = action.payload;

      auditHistory.forEach((historyEvent: IAuditHistoryBody) => {
        historyEvent.date = historyEvent.timeStamp;
        historyEvent.time = historyEvent.timeStamp;
      });

      auditHistory.reverse();

      const allSentEvents: IAuditHistoryBody[] = auditHistory.filter(
        (e: IAuditHistoryBody) => e.eventName === eventNames.EnvelopeSent
      );
      const firstSentEvent = allSentEvents.reduce((a: IAuditHistoryBody, b: IAuditHistoryBody) =>
        new Date(a.timeStamp) > new Date(b.timeStamp) ? b : a
      );

      const filteredAuditHistory: IAuditHistoryBody[] = auditHistory.filter((e: IAuditHistoryBody) =>
        (e.eventName === eventNames.SignerUpdated && dayjs(e.timeStamp).isBefore(dayjs(firstSentEvent.timeStamp))) ||
        (e.eventName === eventNames.CarbonCopyUpdated && dayjs(e.timeStamp).isBefore(dayjs(firstSentEvent.timeStamp)))
          ? false
          : true
      );

      const waitingFor: IAuditHistoryBody = {
        eventName: eventNames.WaitingFor,
        timeStamp: '',
        ipAddress: '',
        userId: '',
        userType: '',
        userEmail: '',
        eventData: {},
      };

      filteredAuditHistory.unshift(waitingFor);

      const getViewedEventUserIds = (): string[] => {
        const allCorrectingEvents: IAuditHistoryBody[] = filteredAuditHistory.filter(
          (e: IAuditHistoryBody) => e.eventName === eventNames.EnvelopeCorrecting
        );
        if (allCorrectingEvents.length >= 1) {
          const latestCorrectingEvent: IAuditHistoryBody = allCorrectingEvents.reduce(
            (a: IAuditHistoryBody, b: IAuditHistoryBody) => (new Date(a.timeStamp) > new Date(b.timeStamp) ? a : b)
          );
          return filteredAuditHistory
            .filter(
              (e: IAuditHistoryBody) =>
                e.eventName == eventNames.EnvelopeViewed &&
                dayjs(e.timeStamp).isAfter(dayjs(latestCorrectingEvent.timeStamp))
            )
            .map((e: IAuditHistoryBody) => e.userId);
        } else {
          return filteredAuditHistory
            .filter((e: IAuditHistoryBody) => e.eventName == eventNames.EnvelopeViewed)
            .map((e: IAuditHistoryBody) => e.userId);
        }
      };

      const recipientsNotViewed: IRecipientBody[] = envelopeHistory.data.envelope.recipients.filter(
        (r: IRecipientBody) => r.role === 'Signer' && !getViewedEventUserIds().includes(r.id)
      );

      const waitingIndex = filteredAuditHistory.map((e) => e.eventName).indexOf(eventNames.WaitingFor);

      const canceledEvent = filteredAuditHistory.find(
        (e: IAuditHistoryBody) => e.eventName === eventNames.EnvelopeCanceled
      );

      if (
        canceledEvent ||
        envelopeHistory.data.envelope.status === EnvelopeStatuses.CORRECTING ||
        (recipientsNotViewed.length < 1 && waitingIndex !== -1)
      ) {
        filteredAuditHistory.splice(waitingIndex, 1);
      }

      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.envelope = envelopeHistory.data.envelope;
        draftState.recipientsWhoHaveNotViewed = recipientsNotViewed;
        draftState.auditHistory = filteredAuditHistory.filter((e: IAuditHistoryBody) =>
          Object.values(eventNames).includes(e.eventName)
        );
        return draftState;
      });
    }
    case ActionTypes.RESEND: {
      return update(state, {
        isFetching: { $set: true },
      });
    }
    case ActionTypes.RESEND_DONE: {
      return update(state, {
        isFetching: { $set: false },
        envelope: { $set: action.payload.envelopeHistory.data.envelope },
        envelopeId: { $set: action.payload.envelopeId },
      });
    }
    case ActionTypes.SEND_EMAIL: {
      return update(state, {
        lastRecipient: { $set: action.payload.createEmailConfig.recipient.recipientId },
      });
    }
    case ActionTypes.UPDATE_RECIPIENTS_DONE: {
      return update(state, {
        envelope: {
          recipients: { $set: action.payload },
        },
      });
    }
    case ActionTypes.UPDATE_RECIPIENTS_ERROR: {
      return update(state, { updateRecipientsError: { $set: action.payload } });
    }
    case ActionTypes.FETCH_CONTACTS_DONE: {
      return update(state, {
        contacts: {
          $set: action.payload,
        },
      });
    }
    default: {
      return state;
    }
  }
};
