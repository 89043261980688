import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import NewEnvelopeButton from './NewEnvelopeButton';
import { EnvelopeStatuses } from '../../lib/constants';
import BlankSlate from '../../images/envelope_blank_state_illustration.png';
import noCompletedEnvelopes from '../../images/no_completed_envelopes.svg';
import noSentEnvelopes from '../../images/no_sent_envelopes.svg';
import noDraftEnvelopes from '../../images/no_draft_envelopes.svg';

interface IProps {
  selectedStatus: EnvelopeStatuses;
}

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    elevation: 0,
  },
  flexItem: {
    width: '40%',
  },
  blankAll: {
    maxHeight: '280px',
    maxWidth: '405px',
  },
  blankSlate: {
    maxHeight: '390px',
    maxWidth: '405px',
  },
  paper: {
    height: '460px',
    width: '1200px',
    border: `1px solid ${colors.grey[400]}`,
    boxShadow: 'none',
  },
  title: {
    fontSize: '40px',
    lineHeight: '48px',
  },
  typeBody: {
    fontSize: '19px',
    padding: '16px 0',
  },
}));

const stateMap = {
  [EnvelopeStatuses.ALL]: {
    titleText: 'Create your first envelope to get started.',
    bodyText: 'Eliminate email clutter by packaging your documents into one neat envelope for your client to sign.',
    showNewEnvelopeButton: true,
    imgSrc: BlankSlate,
  },
  [EnvelopeStatuses.COMPLETED]: {
    titleText: 'No completed envelopes yet!',
    bodyText: `Finish up any drafts or see what's outstanding in your sent envelopes to get those wrapped up.`,
    showNewEnvelopeButton: false,
    imgSrc: noCompletedEnvelopes,
  },
  [EnvelopeStatuses.SENT]: {
    titleText: `It looks like you don’t have any sent envelopes`,
    bodyText: 'Wrap-up any drafts or get started with a new envelope.',
    showNewEnvelopeButton: true,
    imgSrc: noSentEnvelopes,
  },
  [EnvelopeStatuses.DRAFT]: {
    titleText: `It seems you don’t currently have any drafts`,
    bodyText: 'Need to get documents signed? Get started on a new envelope.',
    showNewEnvelopeButton: true,
    imgSrc: noDraftEnvelopes,
  },
};

const EmptyState = (props: IProps) => {
  const classes = useStyles();
  const { selectedStatus } = props;

  let stateToShow = stateMap[EnvelopeStatuses.ALL];
  let styleToShow = classes.blankAll;
  if (selectedStatus && stateMap[selectedStatus] && selectedStatus !== EnvelopeStatuses.ALL) {
    stateToShow = stateMap[selectedStatus];
    styleToShow = classes.blankSlate;
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.flexItem} style={{ textAlign: 'center' }}>
          <img src={stateToShow.imgSrc} alt="No Envelopes" className={styleToShow} />
        </div>
        <div className={classes.flexItem}>
          <Typography variant="h1" className={classes.title}>
            {stateToShow.titleText}
          </Typography>
          <Typography variant="body1" className={classes.typeBody}>
            {stateToShow.bodyText}
          </Typography>
          {stateToShow.showNewEnvelopeButton ? <NewEnvelopeButton /> : null}
        </div>
      </div>
    </Paper>
  );
};

export default EmptyState;
