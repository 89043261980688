import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { IBlock, ISigner } from '../../store/senderBuild/types';
import BlockInner from './BlockInner';
import { hex2rgba } from '../../lib/utils';
import { colors } from '@skyslope/mache';
import { BLOCK_TYPE_KEYS } from '../../lib/constants';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    color: colors.grey[900],
    position: 'absolute',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
    zIndex: 3,
    userSelect: 'none',
  },
  text: {
    fontSize: 22,
    whiteSpace: 'nowrap',
  },
  signature: {
    fontFamily: 'shelby',
    fontSize: 32,
  },
  innerBlock: {
    margin: '0 auto',
  },
  textValue: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    whiteSpace: 'normal',
    padding: theme.spacing(0.5),
  },
}));

interface IProps {
  blockType: any;
  signer?: ISigner;
  block: IBlock;
  startEditing: (multiSelect?: boolean, blockIndex?: number, selectThroughGroup?: boolean) => void;
  zoom: number;
  staticMouseDownStateToggle?: Function;
  mouseDownCoordinatesHandler?: Function;
  blockIndex?: number;
  doubleClickSelect?: (index: number) => void | null;
  currentSelectedBlockIndices?: number[];
  blocks?: IBlock[];
  onMouseUp?: (block: IBlock, x: number, y: number, shiftKey: boolean) => void | null;
  pageDimensions: { height: number; width: number };
}

const BlockStatic = (props: IProps) => {
  const classes = useStyles();
  const { block, signer, blockType, currentSelectedBlockIndices, blockIndex, pageDimensions } = props;
  const currentSelectedBlockIds = props.currentSelectedBlockIndices!.map((i) => props.blocks![i].blockId);
  const currentBlockSelected = currentSelectedBlockIds.includes(block.blockId);

  const onMouseDownHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (block.isCreating) {
      return;
    }
    const hasBlockGroup = !!block.groupId;
    if (currentSelectedBlockIndices) {
      const selectedBlocks = props
        .currentSelectedBlockIndices!.map((i) => props.blocks![i])
        .every((block) => !!block.groupId && block.groupId === props.block.groupId);
      if (!selectedBlocks) {
        handleStaticMouseDownStateToggle(e);
        return props.startEditing(e.shiftKey, blockIndex, hasBlockGroup);
      }
    }
    props.startEditing(e.shiftKey, blockIndex, hasBlockGroup);
    handleStaticMouseDownStateToggle(e);
  };

  const handleStaticMouseDownStateToggle = (e: any) => {
    if (props.staticMouseDownStateToggle) {
      props.staticMouseDownStateToggle!(e);
      props.mouseDownCoordinatesHandler!(e);
    }
  };

  const onDoubleClick = () => {
    if (props.doubleClickSelect) {
      props.doubleClickSelect(props.blockIndex!);
    }
  };

  function getBackgroundColor() {
    if (block.blockType === BLOCK_TYPE_KEYS.STRIKE) {
      return 'none';
    }
    if (block.blockType === BLOCK_TYPE_KEYS.RECTANGLE) {
      return block.bgColor;
    }
    return hex2rgba(signer ? signer.color! : colors.grey[500]);
  }

  function getOpacity() {
    if (block.blockType === BLOCK_TYPE_KEYS.RECTANGLE) {
      return block.opacity || 1;
    }
    return block.isCreating ? 0.75 : 1;
  }

  const blockLeft =
    props.currentSelectedBlockIndices?.length > 1 && currentBlockSelected
      ? block.x * props.zoom
      : `${(block.x! / pageDimensions.width!) * 100}%`;
  const blockTop =
    props.currentSelectedBlockIndices?.length > 1 && currentBlockSelected
      ? block.y * props.zoom
      : `${(block.y! / pageDimensions.height!) * 100}%`;
  const blockWidth =
    props.currentSelectedBlockIndices?.length > 1 && currentBlockSelected
      ? block.width * props.zoom
      : `${(block.width! / pageDimensions.width!) * 100}%`;
  const blockHeight =
    props.currentSelectedBlockIndices?.length > 1 && currentBlockSelected
      ? block.height * props.zoom
      : `${(block.height! / pageDimensions.height!) * 100}%`;
  return (
    <div
      {...(block.blockId ? { id: `${block.blockType}_${block.blockId}` } : {})}
      className={`${classes.block} ${block.blockType}`}
      onMouseDown={(e) => onMouseDownHandler(e)}
      onMouseUp={(e: any) => {
        props.onMouseUp ? props.onMouseUp(block, block.x, block.y, e.shiftKey) : '';
      }}
      onDoubleClick={onDoubleClick}
      style={{
        left: blockLeft,
        top: blockTop,
        width: blockWidth,
        height: blockHeight,
        opacity: getOpacity(),
        zIndex: 5,
        backgroundColor: getBackgroundColor(),
      }}
    >
      <BlockInner
        block={block}
        blockIndex={props.blockIndex}
        blockType={blockType}
        zoom={props.zoom}
        color={signer ? signer.color! : colors.grey[500]}
        signer={signer}
      />
    </div>
  );
};

export default BlockStatic;
