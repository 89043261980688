import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import * as actions from './actions';
import { ITemplateResponse } from '../../lib/api/template';
import { ISettings } from './types';
import { errorToast, successToast } from './actions';
import * as templateApi from '../../lib/api/template';
import * as accountApi from '../../lib/api/account/accountApi';
import * as userServiceApi from '../../lib/api/userService/userService';
import { getGlobalToken } from '../../auth/oktaConfig';
import { formatDateToYYYYMMDD } from '../../common/utils';

const { ActionTypes } = actions;

export function* handleGetSettings(action: ReturnType<typeof actions.getSettings>) {
  try {
    const { digisignAccountId, senderId } = action.payload;
    const res: ITemplateResponse<ISettings> = yield call(templateApi.getSettings, digisignAccountId, senderId);

    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Account Settings GET: ${res.status}`);
    }
    yield put(actions.setSettings(res.result!));
  } catch (err) {
    console.error('Error getting settings', err);
    yield put(
      actions.errorToast(
        `Oops! Looks like some wires got crossed. Please logout and login again. If this persists, send a note to support@skyslope.com and they'll help you out`
      )
    );
  }
}

export function* handleUpdateUserSettings(action: ReturnType<typeof actions.updateUserSettings>) {
  try {
    const res = yield call(templateApi.updateUserSettings, action.payload);
    yield put(successToast('Your preferences have been successfully updated!'));
    yield put(actions.updateUserSettingsDone(res));
  } catch (err) {
    yield put(errorToast('We were unable to update your preferences at this time, please try again'));
  }
}

export function* handleUpdateUserSubscription(action: ReturnType<typeof actions.updateUserSubscription>) {
  const showAlert = action.payload === undefined ? true : action.payload;
  try {
    yield put(actions.setLoading(true));
    const res = yield call(accountApi.updateUserSubscription);
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of CREATE or UPDATE Subscription POST: ${res.status}`);
    }
    if (showAlert) {
      yield put(successToast('Your subscription has been successfully updated!'));
    }
    res.result.validUntil = formatDateToYYYYMMDD(res.result.validUntil);
    yield put(actions.updateUserSubscriptionDone(res.result));
  } catch (err) {
    if (showAlert) {
      yield put(errorToast('We were unable to validate your subscription, please try again or reach out to support'));
    }
    yield put(actions.updateUserSubscriptionDone());
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* handleFetchUserInfo(action: ReturnType<typeof actions.fetchUserInfo>) {
  try {
    let res = yield call(userServiceApi.getUserInfo);
    // This will happen if the user is impersonated but has not been provisioned
    if (res.status === 404) {
      // We can only provision if we have an account id
      if (action.payload) {
        yield call(userServiceApi.provisionUser, action.payload);
        // After provisioning try to get the user info again
        res = yield call(userServiceApi.getUserInfo);
      }
    }

    // if both calls fail, we will throw an error
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of GET User Info: ${res.status}`);
    }
    sessionStorage.setItem('userInfo', JSON.stringify(res.data));
    yield put(actions.updateisFetchingUserInfo(false));
    yield put(actions.updateIsUserInfoFetched(true));
  } catch (err) {
    console.error('Error getting user info', err);
  }
}

export function* handleGetGlobalToken() {
  try {
    const res = yield getGlobalToken();
    if (res.length) {
      yield put(actions.fetchGlobalTokenDone());
    } else {
      console.error('Failed to fetch a global token.');
    }
  } catch (err) {
    console.error('Failed to fetch a global token.');
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.GET_ACCOUNT_SETTINGS, handleGetSettings),
    takeEvery(ActionTypes.UPDATE_USER_SETTINGS, handleUpdateUserSettings),
    takeEvery(ActionTypes.UPDATE_USER_SUBSCRIPTION, handleUpdateUserSubscription),
    takeLeading(ActionTypes.FETCH_USER_INFO, handleFetchUserInfo),
    takeLeading(ActionTypes.GET_GLOBAL_TOKEN, handleGetGlobalToken),
  ]);
}
