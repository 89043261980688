import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@skyslope/mache';
import React from 'react';
import ReorderIcon from '@material-ui/icons/Reorder';
import { IRecipient } from '../types';
import Draggable from '../../drag/Draggable';

const useStyles = makeStyles((theme: Theme) => ({
  recipient: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${colors.grey[400]}`,
    padding: '0 20px',
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    '&:hover': {
      cursor: 'move',
    },
  },
  recipientWhenDragging: {
    border: `1px solid ${colors.grey[200]}`,
    backgroundColor: colors.grey[200],
    width: '100%',
    height: 64,
    marginTop: 16,
  },
  drag: {
    color: colors.grey[500],
    marginRight: theme.spacing(3),
  },
  text: {
    wordBreak: 'break-all',
    paddingRight: theme.spacing(1),
    lineHeight: '16px',
  },
}));

interface IProps {
  recipient: IRecipient;
  modal: boolean;
  isMobile?: boolean;
}

const Recipient = (props: IProps) => {
  const classes = useStyles();
  const { recipient, modal, isMobile } = props;

  return (
    <Draggable id={props.recipient.id}>
      <div data-spec="group-recipient">
        <div
          className={classes.recipient}
          style={{ borderLeft: `4px solid ${recipient.color}`, minHeight: isMobile ? '84px' : '64px' }}
        >
          <ReorderIcon className={classes.drag} />
          <Grid
            container
            alignItems={isMobile ? 'flex-start' : 'center'}
            style={{ flexDirection: isMobile ? 'column' : 'row' }}
          >
            <Grid item xs={6} style={{ maxWidth: isMobile ? '100%' : '50%' }}>
              <Typography
                component="span"
                variant="body1"
                className={classes.text}
                style={{ fontWeight: isMobile ? '700' : 'normal' }}
              >
                {recipient.firstName} {recipient.lastName}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ maxWidth: isMobile ? '100%' : '50%' }}>
              <Typography component="span" variant="body1" className={classes.text}>
                {recipient.email}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Draggable>
  );
};

export default Recipient;
