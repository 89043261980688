import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '@material-ui/lab/Skeleton';
import { IRootState } from '../../../store';
import { IDocument } from '../../../store/dtm/documents/types';
import SectionHeader from './SectionHeader';
import Section from './Section';
import Documents from '../../../common/documents';
import { IDoc } from '../../../common/documents/types';
import * as actions from '../../../store/dtm/documents/actions';
import { errorToast } from '../../../store/pageFrame/actions';
import { progressPercentageForDocuments } from '../../../lib/api/common/progressPercentage';
import { chameleonElId } from '../../../lib/constants';
import { hex2rgba } from '../../../lib/utils';
import { useShallowSelector, useDispatch } from '../../../lib/reduxHooks';

const useStyles = makeStyles((theme: Theme) => ({
  focusButton: {
    '&:focus': {
      backgroundColor: colors.blue[50],
    },
  },
  descriptionIcon: {
    color: colors.grey[500],
    marginRight: theme.spacing(1),
  },
  documentAddIcon: {
    height: 20,
    width: 20,
    margin: '-4px 4px -4px -4px',
  },
  placeholder: {
    padding: '32px 32px 24px',
    border: `2px dashed ${colors.grey[400]}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginBottom: 24,
    position: 'relative',
  },
  placeholderOverlay: {
    position: 'absolute',
    backgroundColor: hex2rgba(colors.blue[50], 0.9),
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    fontSize: 160,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    userSelect: 'none',
  },
  placeholderText: {
    textAlign: 'center',
    color: '#6788A2',
    width: '100%',
  },
  envelopeSection: {
    padding: '32px 24px',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    overflowY: 'auto',
  },
}));

interface IState {
  envelopeDocuments: IDocument[];
  isFetching: boolean;
  envelopeDocumentsFetchComplete: boolean;
}

const selector = (state: IRootState) => ({
  envelopeDocuments: state.dtm.documents.envelopeDocuments,
  isFetching: state.dtm.documents.isFetching,
  envelopeDocumentsFetchComplete: state.dtm.documents.envelopeDocumentsFetchComplete,
});

const EnvelopeSection = () => {
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleUploadDocument = (tempId: string, file: File) => {
    dispatch(actions.uploadDocument(tempId, file));
  };

  const handleReorderDocuments = (documents: IDocument[]) => {
    dispatch(actions.reorderDocuments(documents));
  };

  const handleDeleteDocument = (documentId: string) => {
    dispatch(actions.deleteDocument(documentId));
  };

  const handleUpdateDocuments = (documents: IDocument[]) => {
    dispatch(actions.updateDocuments(documents));
  };

  const handleError = (errStr: string) => {
    dispatch(errorToast(errStr));
  };

  const documentsHeader = (isDragActive: boolean, open: Function) => (
    <div className={classes.placeholder} id={chameleonElId(10)}>
      {isDragActive && <div className={classes.placeholderOverlay}>+</div>}
      <Typography variant="body1" className={classes.placeholderText}>
        Drag and drop .pdf, .doc, .docx, or .docm documents here
        <br />
        (Maximum size 25MB) to upload or
      </Typography>
      <Button color="primary" className={classes.focusButton} onClick={() => open()}>
        <AddIcon className={classes.documentAddIcon} />
        Upload Document
      </Button>
    </div>
  );

  const documentsStr =
    store.isFetching && !store.envelopeDocumentsFetchComplete ? (
      <Skeleton animation="wave" />
    ) : (
      `${store.envelopeDocuments.length} DOCUMENT${store.envelopeDocuments.length === 1 ? '' : 'S'} ADDED`
    );

  return (
    <Section id={chameleonElId(16)}>
      <SectionHeader>
        <DescriptionIcon className={classes.descriptionIcon} />
        <Typography variant="subtitle1" style={{ width: store.isFetching ? '100%' : '' }}>
          {documentsStr}
        </Typography>
      </SectionHeader>
      <div className={classes.envelopeSection}>
        <Documents
          documents={store.envelopeDocuments as IDoc[]}
          handleUploadDocument={handleUploadDocument}
          handleReorderDocuments={handleReorderDocuments}
          handleDeleteDocument={handleDeleteDocument}
          handleUpdateDocuments={handleUpdateDocuments}
          handleError={handleError}
          header={({ isDragActive, open }) => documentsHeader(isDragActive, open)}
          footer={() => ''}
          progressPercentageForDocuments={progressPercentageForDocuments}
          inlineRemoveButton
          smallerPadding
          isFetching={store.isFetching}
          envelopeDocumentsFetchComplete={store.envelopeDocumentsFetchComplete}
        />
      </div>
    </Section>
  );
};

export default EnvelopeSection;
