import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import Button from '@material-ui/core/Button';
import * as actions from '../../store/templateManagement/actions';
import { useDispatch } from '../../lib/reduxHooks';
import templateEmptyState from '../../images/template_empty_state.svg';

const useStyles = makeStyles(() => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    elevation: 0,
  },
  image: {
    textAlign: 'center'
  },
  text: {
    maxWidth: 590,
    marginLeft: 100,
  },
  paper: {
    height: '460px',
    width: '1200px',
    border: `1px solid ${colors.grey[400]}`,
    boxShadow: 'none',
  },
  title: {
    fontSize: '40px',
    lineHeight: '48px',
  },
  typeBody: {
    fontSize: '19px',
    padding: '16px 0',
  },
}));

const EmptyState = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const createTemplate = () => {
    dispatch(actions.createTemplate());
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexContainer}>
        <div className={classes.image}>
          <img src={templateEmptyState} alt="No Templates"/>
        </div>
        <div className={classes.text}>
          <Typography variant="h1" className={classes.title} aria-label="Title">
            Streamline your <br /> workflow with templates
          </Typography>
          <Typography variant="body1" className={classes.typeBody} aria-label="Body">
            Skip the repetition by creating templates of your most frequently <br /> used envelopes.
            Add your documents, recipients, blocks, and even <br /> email subject and message to save time.
          </Typography>
          <Button onClick={createTemplate} color="primary" variant="contained"
            data-spec="create-template" aria-label="Create">
            New Template
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default EmptyState;
