import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import { SHELF_WIDTH, chameleonElId } from '../../lib/constants';
import { ITemplateRecipient, ITemplate } from '../../store/templateCreate/types';
import Recipients from './Recipients';

const useStyles = makeStyles((theme: Theme) => ({
  shelf: {
    position: 'fixed',
    left: 0,
    top: '110px',
    bottom: 0,
    width: SHELF_WIDTH,
    textAlign: 'left',
    borderRight: `1px solid ${colors.grey[400]}`,
    color: colors.blue[900],
    display: 'flex',
    zIndex: 0,
    flexDirection: 'column',
    overflowY: 'auto',
    height: 'calc(100% - 182px)',
  },
  header: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
  },
  recipientSection: {
    padding: '24px 6px 24px 22px',
    borderTop: `1px solid ${colors.grey[400]}`,
    display: 'flex',
    flexDirection: 'column',
  },
  templateNameSection: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginTop: 16,
  },
  inputRoot: {
    color: colors.grey[800],
  },
  inputFocused: {
    color: colors.grey[900],
  },
  inputLabel: {
    marginLeft: '5px',
  },
  addButton: {
    padding: '8px',
  },
  recipientWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}));

interface IProps {
  template: ITemplate;
  updateTemplate: (patchBody: Partial<ITemplate>) => void;
  addRecipient: () => void;
  updateRecipient: (recipientId: string, update: Partial<ITemplateRecipient>) => void;
  changeRecipientTitle: (recipientId: string, title: string) => void;
  recipientTitles: string[];
  deleteDisabled: boolean;
  templateId: string;
  deleteRecipient: (templateId: string, recipient: ITemplateRecipient) => void;
  hideAction: boolean;
  userOrigin: string;
}

const Shelf = (props: IProps) => {
  const classes = useStyles();
  const { template, recipientTitles, deleteDisabled, templateId  } = props;
  const [name, setName] = useState(template.name);
  const meRecipientExists = template.recipients.some(recipient => recipient.recipientTitle === 'Me');
  const filteredRecipientTitles = meRecipientExists ? recipientTitles.filter(title => title !== 'Me') : recipientTitles

    React.useEffect(() => {
    if (!props.template.recipients?.length) {
      props.addRecipient();
    }
  }, []);

  const onBlur = () => {
    if (!name.length) {
      return;
    }
    props.updateTemplate({ name });
  };

  return (
    <div className={classes.shelf}>
      <div>
        <div className={ classes.templateNameSection } id={chameleonElId(17)}>
          <Typography variant="overline">NAME</Typography>
          <TextField
            variant="filled"
            InputLabelProps={{ classes: { root: classes.inputLabel } }}
            placeholder="Template name"
            onChange={e => setName(e.target.value)}
            onBlur={onBlur}
            className={classes.input}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                focused: classes.inputFocused,
              },
            }}
            error={!name}
            inputProps={{ maxLength: 100 }}
            value={name}
            autoComplete="skyslope-prevent-autocomplete"
            name="name"
          />
        </div>
      </div>
      <div className={classes.recipientSection} id={chameleonElId(18)}>
        <span className={classes.recipientWrapper}>
          <Typography variant="overline">RECIPIENTS</Typography>
          <IconButton color="primary" onClick={props.addRecipient} classes={{root: classes.addButton}}>
            <AddCircleOutlineIcon style={{height: '20px', width: '20px', }} />
          </IconButton>
        </span>
        <Recipients
          recipients={template.recipients}
          updateRecipient={props.updateRecipient}
          changeRecipientTitle={props.changeRecipientTitle}
          addRecipient={props.addRecipient}
          recipientTitles={filteredRecipientTitles}
          deleteDisabled={deleteDisabled}
          templateId={templateId}
          deleteRecipient={props.deleteRecipient}
          hideAction={props.hideAction}
          userOrigin={props.userOrigin}
        />
      </div>
    </div>
  );
};

export default Shelf;
