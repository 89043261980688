import update from 'immutability-helper';
import { ActionTypes } from './actions';
import { IPageFrameState } from './types';

export const initialState: IPageFrameState = {
  loading: false,
  errorShow: false,
  msg: '',
  settings: undefined,
  settingsFetched: false,
  successShow: false,
  showAlert: false,
  message: '',
  alert: {
    type: 'ERROR',
    message: '',
    visible: false,
  },
  fetchedUserSubscription: false,

  isFetchingUserInfo: false,
  isUserInfoFetched: false,
  fetchedGlobalToken: false,
};

export default (state: IPageFrameState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING: {
      return update(state, {
        loading: { $set: action.payload },
      });
    }
    case ActionTypes.ERROR_TOAST: {
      return update(state, {
        errorShow: { $set: true },
        msg: { $set: action.payload },
      });
    }
    case ActionTypes.SUCCESS_TOAST: {
      return update(state, {
        successShow: { $set: true },
        msg: { $set: action.payload },
      });
    }
    case ActionTypes.HIDE_ERROR_TOAST: {
      return update(state, {
        errorShow: { $set: false },
        successShow: { $set: false },
      });
    }
    case ActionTypes.SET_ACCOUNT_SETTINGS: {
      const qs = window.location.search;
      const isCarUser = qs.includes('car');
      isCarUser ? action.payload.account.envelope.blockTypes.disabled.push('Strike') : '';
      if (action.payload.user.dateFormat?.preference) {
        // API returns the dateFormat as lowercase for the ISO format so we capitalize it for the UI
        action.payload.user.dateFormat.preference = action.payload.user.dateFormat.preference.toUpperCase();
      }
      return update(state, {
        settings: { $set: action.payload },
        settingsFetched: { $set: true },
      });
    }
    case ActionTypes.SHOW_ALERT: {
      const { type, message } = action.payload;
      return update(state, {
        alert: {
          type: { $set: type },
          message: { $set: message },
          visible: { $set: true },
        },
      });
    }
    case ActionTypes.HIDE_ALERT: {
      return update(state, {
        alert: {
          visible: { $set: false },
        },
      });
    }
    case ActionTypes.UPDATE_USER_SETTINGS_DONE: {
      return update(state, {
        settings: {
          user: { $set: action.payload.result },
        },
      });
    }
    case ActionTypes.UPDATE_RECIPIENT_COLOR_COUNTER: {
      return update(state, {
        recipientColorCounter: { $set: action.payload },
      });
    }
    case ActionTypes.UPDATE_USER_SUBSCRIPTION_DONE: {
      const subscription = action.payload;
      return update(state, {
        settings: {
          user: {
            subscription: { $set: subscription ?? state.settings?.user.subscription },
          },
        },
        fetchedUserSubscription: { $set: true },
      });
    }
    case ActionTypes.UPDATE_IS_FETCHING_USER_INFO: {
      return update(state, {
        isFetchingUserInfo: { $set: action.payload },
      });
    }
    case ActionTypes.UPDATE_IS_USER_INFO_FETCHED: {
      return update(state, {
        isUserInfoFetched: { $set: action.payload },
      });
    }
    case ActionTypes.GET_GLOBAL_TOKEN_DONE: {
      return update(state, {
        fetchedGlobalToken: { $set: true },
      });
    }
    default: {
      return state;
    }
  }
};
