import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Description from '@material-ui/icons/Description';
import { colors } from '@skyslope/mache';
import { useHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import { HeaderContext } from '../context/header-context';
import Table from '../components/common/Table';
import RecipientsCell from '../components/envelopeManagement/RecipientsCell';
import EmptyState from '../components/envelopeManagement/EmptyState';
import accessibleEnter from '../lib/accessibility/enter';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import { IRootState } from '../store';
import * as actions from '../store/envelopeManagement/actions';
import { IEnvelopeMetadata, EnvelopeStatus } from '../store/envelopeManagement/types';
import { setLoading } from '../store/pageFrame/actions';
import NewEnvelopeButton from '../components/envelopeManagement/NewEnvelopeButton';
import Search from '../components/common/Search';
import useDebounce from '../lib/useDebounce';
import StatusProgress from '../components/envelopeManagement/StatusProgress';
import StatusActionCell from '../components/envelopeManagement/StatusActionCell';
import FilterStatus from '../components/envelopeManagement/FilterStatus';
import PdfPreview from '../components/envelopeManagement/PdfPreview';
import NoResultsState from '../components/common/NoResultsState';
import { ResourceNames, EnvelopeStatuses, signerUiUrl } from '../lib/constants';
import ManagementHeader from '../components/common/ManagementHeader';
import FooterLinks from '../components/common/FooterLinks';
import { ISettings } from '../store/pageFrame/types';
import SkeletonTable from '../common/loading/SkeletonTable';
import useBottomScrollListener from '../hooks/useBottomScrollListener';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';
import { UserOrigins } from '../lib/types';
import { IRecipient } from '../common/recipients/types';
import useUserInfo from '../auth/useUserInfo';
import { STATUSES } from '../components/envelopeManagement/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 1200,
    width: '100%',
    margin: `${theme.spacing(8)}px ${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
  },
  tableControls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    alignItems: 'center',
  },
  templateCount: {
    display: 'flex',
    alignItems: 'center',
  },
  templateCountIcon: {
    marginRight: theme.spacing(1),
    color: colors.grey[500],
  },
  plusIcon: {
    margin: '-2px 0px -2px -6px',
    height: 18,
  },
  statusActionButton: {
    padding: 6,
    margin: -7,
  },
  statusLinks: {
    display: 'flex',
    marginTop: 8,
  },
  statusLink: {
    padding: '6px 15px 4px 15px',
    margin: '4px 2px 1px 0',
    position: 'relative',
    '&:hover,&:focus': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
      borderTopRightRadius: '15px',
      borderTopLeftRadius: '15px',
      outline: 'none',
    },
    userSelect: 'none',
  },
  statusLinkSelected: {
    backgroundColor: colors.blue[800],
    height: '3px',
    position: 'absolute',
    left: '0',
    bottom: '0',
    right: '0',
    margin: '0 14px',
    borderRadius: '2px',
  },
  divider: {
    height: 1,
    width: '100%',
    marginTop: 40,
    marginBottom: 32,
    backgroundColor: colors.grey[400],
  },
  legacySpinner: {
    flexGrow: 1,
    marginLeft: theme.spacing(15),
  },
  foundSkeleton: {
    width: 200,
  },
}));

interface IState {
  init: boolean;
  envelopes: IEnvelopeMetadata[];
  totalEnvelopeCount: number;
  isLoading: boolean;
  envelopeRedirect?: IEnvelopeMetadata;
  endReached: boolean;
  pdfPreview?: IEnvelopeMetadata;
  orderBy: string;
  orderByDesc: boolean;
  selectedStatus: string;
  settings: ISettings;
  settingsFetched: boolean;
  signerToken: string;
  recipients?: IRecipient[];
}

const selector = (state: IRootState) => ({
  init: state.envelopeManagement.init,
  envelopes: state.envelopeManagement.envelopes,
  totalEnvelopeCount: state.envelopeManagement.totalEnvelopeCount,
  isLoading: state.pageFrame.loading,
  envelopeRedirect: state.envelopeManagement.envelopeRedirect,
  endReached: state.envelopeManagement.endReached,
  pdfPreview: state.envelopeManagement.pdfPreview,
  orderBy: state.envelopeManagement.orderBy,
  orderByDesc: state.envelopeManagement.orderByDesc,
  selectedStatus: state.envelopeManagement.status,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  signerToken: state.senderBuild.signerToken,
  recipients: state.senderBuild.recipientsModal.recipients,
});

export const EnvelopeManagement = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const { senderFirstName, senderLastName, senderEmail, isPrimeAuthenticated, provisioned } = useUserInfo() ?? {};
  const headerContext = useContext(HeaderContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);
  const ignoreFirstLoad = React.useRef(true);
  const [showBottomLoader, setShowBottomLoader] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [orderByState, setOrderBy] = useState('DATE_UPDATED');
  const [orderByDescState, setOrderByDesc] = useState(true);
  const [statusFilter, setStatusFilter] = useState('');
  const [resetTableScroll, setResetTableScroll] = useState(false);
  const [hasEnvelopes, setHasEnvelopes] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  // This hook prevents the rendering of an empty state when the user backspaces the search input until it is empty
  const [alreadySearched, setAlreadySearched] = useState(false);
  const [primeUser, setPrimeUser] = useState(false);
  const showEditEnvelopeName = flags && flags['show-edit-envelope-name'];
  const sender = {
    firstName: senderFirstName,
    lastName: senderLastName,
    email: senderEmail,
  };

  const filteredEnvelopes = statusFilter
    ? store.envelopes.filter(envelope => STATUSES[statusFilter].statuses.includes(envelope.status))
    : store.envelopes;

  useEffect(() => {
    if (store.signerToken.length) {
      window.location.href = `${signerUiUrl}?signNow=true#t=${store.signerToken}`;
    }
  }, [store.signerToken]);

  useEffect(() => {
    if (provisioned) {
      fetchMoreEnvelopes();
    }
  }, [provisioned]);

  const statusTabs = hasEnvelopes ? (
    <div className={classes.statusLinks}>
      {Object.keys(STATUSES).map((status, index) =>
        STATUSES[status].dateColumn ? (
          <Typography
            component="span"
            key={status}
            tabIndex={index + 1}
            className={`${classes.statusLink} `}
            onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
              handleSetSelectedStatus(status);
              e.currentTarget.blur();
            }}
            onKeyDown={(e: React.KeyboardEvent) => accessibleEnter(e, () => handleSetSelectedStatus(status))}
            variant={selectedStatus === status ? 'body2' : 'body1'}
            data-spec={`status-button-${status}`}
          >
            {status}
            {selectedStatus === status ? <div className={classes.statusLinkSelected} /> : null}
          </Typography>
        ) : null
      )}
    </div>
  ) : null;

  useEffect(() => {
    if (store.settingsFetched) {
      const isPrimeUser = store.settings.account.userOrigin === UserOrigins.Prime;
      if (isPrimeUser && isPrimeAuthenticated) {
        setPrimeUser(true);
      }
    }
  }, [store.settingsFetched, isPrimeAuthenticated]);

  useEffect(() => {
    headerContext.setContent(
      <ManagementHeader resource={ResourceNames.ENVELOPE} flags={flags} userOrigin={store.settings?.account.userOrigin}>
        {statusTabs}
      </ManagementHeader>
    );
    return () => {
      headerContext.setContent(null);
    };
  }, [hasEnvelopes, selectedStatus, store.settingsFetched]);

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, []);

  useEffect(() => {
    if (ignoreFirstLoad.current) {
      ignoreFirstLoad.current = false;
    } else {
      setResetTableScroll(true);
      dispatch(setLoading(true));
      let { orderBy, orderByDesc } = store;
      if (selectedStatus !== store.selectedStatus) {
        orderBy = STATUSES[selectedStatus]?.dateColumn?.sortKey || 'DATE_UPDATED';
        orderByDesc = true;
        setOrderBy(orderBy);
        setOrderByDesc(orderByDesc);
      }
      dispatch(actions.fetchEnvelopes(debouncedSearch, selectedStatus, orderBy, orderByDesc));
      setResetTableScroll(false);
    }
  }, [debouncedSearch, selectedStatus]);

  useEffect(() => {
    if (store.envelopes.length > 0 && !hasEnvelopes) {
      setHasEnvelopes(true);
    }
    if (showBottomLoader) {
      setShowBottomLoader(false);
    }
  }, [store.envelopes.length, store.endReached]);

  useEffect(() => {
    if (statusFilter) {
      setStatusFilter('');
    }
    if (alreadySearched) {
      setAlreadySearched(false);
    }
    if (!showSearch) {
      setShowSearch(true);
    }
  }, [selectedStatus]);

  const fetchMoreEnvelopes = React.useCallback(() => {
    setShowBottomLoader(true);
    dispatch(actions.fetchEnvelopes(debouncedSearch, selectedStatus, orderByState, orderByDescState));
  }, [debouncedSearch, selectedStatus, orderByState, orderByDescState]);

  const changeSort = (orderBy: string) => {
    let orderByDesc = true;

    if (store.orderBy === orderBy) {
      orderByDesc = !store.orderByDesc;
    }
    setResetTableScroll(true);
    dispatch(setLoading(true));
    setOrderBy(orderBy);
    setOrderByDesc(orderByDesc);
    dispatch(actions.fetchEnvelopes(debouncedSearch, selectedStatus, orderBy, orderByDesc));
  };

  const tableColumns = [
    { key: showEditEnvelopeName ? 'name' : 'subject', label: 'Envelope Name', boldText: true, sortKey: 'NAME' },
    {
      key: 'recipientsCount',
      label: 'Recipients',
      cell: (envelope: IEnvelopeMetadata) => <RecipientsCell envelope={envelope} />,
    },
    {
      key: 'status',
      label: 'Status',
      sortKey: 'STATUS',
      cell: (envelope: IEnvelopeMetadata) => (
        <StatusProgress
          status={envelope.status}
          signerCount={envelope.signerCount}
          completedSignerCount={envelope.completedSignerCount}
          sentDate={envelope.sentDate}
        />
      ),
    },
    STATUSES[selectedStatus].dateColumn,
    {
      key: 'statusAction',
      align: 'right',
      label: '',
      cell: (envelope: IEnvelopeMetadata) => (
        <StatusActionCell sender={sender} envelope={envelope} recipients={store.recipients} flags={flags} />
      ),
    },
  ];

  const rowClickHandler = (envelope: IEnvelopeMetadata) => {
    const status = envelope.status.toLowerCase();
    if (status === EnvelopeStatus.DRAFT || status === EnvelopeStatus.CORRECTING) {
      const pathPrefix = envelope.config?.senderUi?.envelopePathPrefix;
      if (pathPrefix) {
        history.push(`/${pathPrefix}envelopes/${envelope.id}/documents`);
      } else {
        history.push(`/envelopes/${envelope.id}/documents`);
      }
    } else {
      dispatch(actions.openPdfPreview(envelope));
    }
  };

  const closePdfPreview = () => {
    dispatch(actions.closePdfPreview());
  };

  if (store.envelopeRedirect) {
    const pathPrefix = store.envelopeRedirect!.config?.senderUi?.envelopePathPrefix;
    if (pathPrefix) {
      window.location.pathname = `/${pathPrefix}envelopes/${store.envelopeRedirect!.id}/documents`;
    } else {
      history.push(`/envelopes/${store.envelopeRedirect!.id}/documents`);
    }
  }

  useBottomScrollListener(() => {
    if (!store.endReached) {
      fetchMoreEnvelopes();
    }
  });

  const searchContent = () => {
    return (
      <>
        {STATUSES[selectedStatus].showFilter ? (
          <FilterStatus
            statuses={STATUSES[selectedStatus].statuses}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            selectedStatus={selectedStatus}
          />
        ) : null}
        <div className={classes.divider} />
        <div className={classes.tableControls}>
          <Typography variant="overline" className={classes.templateCount} data-spec="template-count">
            <Description className={classes.templateCountIcon} />
            {statusFilter
              ? `${filteredEnvelopes.length} ENVELOPE${filteredEnvelopes.length !== 1 ? 'S' : ''} FOUND (FILTERED)`
              : `${store.totalEnvelopeCount.toLocaleString()} ENVELOPE${
                  store.totalEnvelopeCount !== 1 ? 'S' : ''
                } FOUND`}
          </Typography>
          <NewEnvelopeButton isPrimeAuthenticated={isPrimeAuthenticated} isPrimeUser={primeUser} />
        </div>
      </>
    );
  };

  const resultsContent = () => {
    if (filteredEnvelopes.length > 0 || statusFilter) {
      if (!showSearch) {
        setShowSearch(true);
      }
      return (
        <Table
          columns={tableColumns}
          rows={filteredEnvelopes}
          idKey="id"
          showBottomLoader={showBottomLoader}
          resetScroll={resetTableScroll}
          onClickRow={rowClickHandler}
          onClickHeader={changeSort}
          currentSortKey={store.orderBy}
          currentSortDesc={store.orderByDesc}
          managementTable
        />
      );
    }
    if (search) {
      return <NoResultsState resource={ResourceNames.ENVELOPE} />;
    }
    return null;
  };

  const contentToDisplay = () => {
    if (!store.isLoading && store.init && store.settingsFetched) {
      if (filteredEnvelopes.length || search || statusFilter) {
        return (
          <>
            {searchContent()}
            {resultsContent()}

            {store.pdfPreview ? (
              <PdfPreview envelopeId={store.pdfPreview.id} subject={store.pdfPreview.subject} close={closePdfPreview} />
            ) : (
              ''
            )}
            <FooterLinks showSocialIcons />
          </>
        );
      }
      if (filteredEnvelopes.length || alreadySearched) {
        return null;
      }

      if (showSearch) {
        setShowSearch(false);
      }
      return (
        <>
          <EmptyState selectedStatus={EnvelopeStatuses[selectedStatus.toUpperCase()]} data-spec="createFirstEnvelope" />
          <FooterLinks showSocialIcons />
        </>
      );
    }
    return (
      <>
        <div data-spec="skeleton-loading-table">
          <div className={classes.divider} />
          <div className={classes.tableControls}>
            <Typography variant="overline" className={classes.templateCount} data-spec="template-count">
              <Description className={classes.templateCountIcon} />
              <span className={classes.foundSkeleton}>
                <Skeleton animation="wave" />
              </span>
            </Typography>
            <NewEnvelopeButton disabled />
          </div>
          <SkeletonTable columns={tableColumns} />
        </div>
      </>
    );
  };

  const handleSearchChange = (value: string) => {
    if (!alreadySearched && value !== '') {
      setAlreadySearched(true);
    }
    setSearch(value);
  };

  const handleSetSelectedStatus = (status: string) => {
    setAlreadySearched(false);
    setSelectedStatus(status);
  };

  return (
    <div className={classes.root}>
      {showSearch ? <Search value={search} onChange={handleSearchChange} /> : null}
      {contentToDisplay()}
    </div>
  );
};

export default withLaunchDarkly(EnvelopeManagement);
