import React from 'react';
import { colors } from '@skyslope/mache';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: (props: IProps) => ({
    display: 'flex',
    marginTop: props.customMarginTop ?? '32px',
    width: props.customWidth ?? '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 13,
  }),
  legalLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: colors.grey[800],
    alignItems: 'center',
    display: 'flex',
  },
  favorite: {
    margin: theme.spacing(0.5),
    color: colors.grey[600],
  },
  anchor: {
    color: colors.grey[800],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  spacer: {
    padding: theme.spacing(1),
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  socialIcons: {
    color: colors.grey[800],
    fontSize: 28,
    marginLeft: theme.spacing(1),
  },
  button: {
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
}));

interface IProps {
  showSocialIcons?: boolean;
  customWidth?: string;
  customMarginTop?: string;
}

const FooterLinks = (props: IProps) => {
  const classes = useStyles(props);
  const { showSocialIcons } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classes.legalLinks}>
        <span className={classes.text}>
          Made with <FavoriteIcon style={{ fontSize: 14 }} classes={{ root: classes.favorite }} /> in Sacramento by
          SkySlope
        </span>
        <span className={classes.spacer}>|</span>
        <a className={classes.anchor} href="https://skyslope.com/terms-conditions/" target="blank">
          Terms of Use
        </a>
        <span className={classes.spacer}>|</span>
        <a className={classes.anchor} href="https://skyslope.com/privacy-policy/" target="blank">
          Privacy Policy
        </a>
      </div>
      {showSocialIcons ? (
        <div className={classes.iconWrapper}>
          <IconButton
            classes={{ root: classes.button }}
            onClick={() => window.open('https://www.facebook.com/skyslope/')}
          >
            <FacebookIcon className={classes.socialIcons} />
          </IconButton>
          <IconButton
            classes={{ root: classes.button }}
            onClick={() => window.open('https://www.instagram.com/skyslope')}
          >
            <InstagramIcon className={classes.socialIcons} />
          </IconButton>
          <IconButton classes={{ root: classes.button }} onClick={() => window.open('https://twitter.com/skyslope')}>
            <TwitterIcon className={classes.socialIcons} />
          </IconButton>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FooterLinks;
