import React from 'react';

export interface MobileApp {
  nextButtonTap: () => void;
  backButtonTap: () => void;
}

const MobileAppFunctions: React.FC = () => {
  const defaultFunctions: MobileApp = {
    nextButtonTap: () => {},
    backButtonTap: () => {},
  };
  window.mobileApp = window.mobileApp || defaultFunctions;
  return null;
};

export default MobileAppFunctions;
