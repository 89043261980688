import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { ITemplateRecipient } from '../../store/templateCreate/types';
import { RecipientRole } from '../../common/recipients/types';
import { UserOrigins } from '../../lib/types';
import { ReactComponent as NeedsToSignIcon } from '../../images/need_to_sign.svg';
import { ReactComponent as ReceiveCopyIcon } from '../../images/receive_copy.svg';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    justifyContent: 'space-between',
  },
  recipient: (props: IProps) => ({
    display: 'flex',
    border: `1px solid ${colors.grey[500]}`,
    backgroundColor: '#FFFFFF',
    padding: '16px',
    marginRight: props.hideAction ? '8px' : 'none',
    width: '100%',
    justifyContent: 'space-between'
  }),
  exampleText: {
    color: colors.grey[700],
    textTransform: 'initial',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    display: 'block',
    marginTop: 2,
  },
  title: (props: IProps) => ({
    width: props.hideAction ? '222px' : '170px',
  }),
  icon: {
    height: 20,
    width: 24,
  },
  iconMenu: {
    maxHeight: 24,
  },
  roleSelect: {
    marginRight: '10px'
  },
  item: { 
    colors: colors.blue[800],
    '&:hover': {
      backgroundColor: colors.blue[50],
      color: colors.blue[800],
    },
  },
  roleItem: { 
    colors: colors.blue[800],
    '&:active':{
      color: 'white',
      backgroundColor: colors.blue[800],
    },
    '&:hover': {
      backgroundColor: colors.blue[50],
      color: colors.blue[800],
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: colors.blue[800],
      '&:hover': {
        backgroundColor: colors.blue[800],
      }
    },
  },
  deleteButton: {
    padding: '8px',
    color: colors.grey[500],
    '&:hover': {
      backgroundColor: colors.blue[50],
      color: colors.blue[800],
    },
  },
  hideButtons: {
    display: 'none',
  },
  input: {
    padding: '10px !important',
    width: '100% !important',
  },
  inputRoot: {
    padding: '0 !important',
  },
  inputLabel: {
    marginLeft: '5px',
    marginBottom: '5px'
  },
}));

interface IProps {
  recipient: ITemplateRecipient;
  updateRecipient: (recipientId: string, update: Partial<ITemplateRecipient>) => void;
  changeRecipientTitle: (recipientId: string, title: string) => void;
  recipientTitles: string[];
  disabled: boolean;
  templateId: string;
  deleteRecipient: (templateId: string, recipient: ITemplateRecipient) => void;
  index?: number;
  hideAction: boolean;
  userOrigin: string;
}

const Recipient = (props: IProps) => {
  const classes = useStyles(props);
  const { recipient, disabled } = props;
  const [role, setRole] = React.useState<RecipientRole>(recipient.role || RecipientRole.Signer);
  const [title, setTitle] = React.useState<string>('');
  const [oldTitle, setOldTitle] = React.useState<string>('');

  React.useEffect(() => {
    setTitle(recipient.recipientTitle || '');
    setOldTitle(recipient.recipientTitle || '');
  }, [recipient.recipientTitle ]);

  React.useEffect(() => {
    setRole(recipient.role as RecipientRole);
  }, [recipient.role ]);

  const updateTitle = (e: React.ChangeEvent<HTMLSelectElement>, value: string) => {
    if (!value) return;
    setTitle(value);
    props.updateRecipient(recipient.id, { role, recipientTitle: value });
  };

  const updateRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(e.target.value as RecipientRole);
    if (title.length) {
      props.updateRecipient(recipient.id, { 
        role: e.target.value as RecipientRole, 
        recipientTitle: title 
      });
    }
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLTextAreaElement>, value: string) => {
    if (e) {
      setTitle(value);
      props.changeRecipientTitle(recipient.id, value);
    }
  };

  const handleOnBlurChange = async (value: string) => {
    if (!value.length || value===oldTitle) return;
     setOldTitle(value);
    props.updateRecipient(recipient.id, { role, recipientTitle: value });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.recipient}>
        <div className={classes.roleSelect}>
          <InputLabel classes={{root: classes.inputLabel}}>
            Role
          </InputLabel>
          {(props.userOrigin === UserOrigins.Prime || props.userOrigin === UserOrigins.DOP) ? (
          <Autocomplete
            id='templateRecipientTitle'       
            autoSelect
            classes={{
              popupIndicator: classes.hideButtons, endAdornment: classes.hideButtons,
              input: classes.input, inputRoot: classes.inputRoot
            }}
            options={props.recipientTitles}

            onChange={updateTitle}
            onInputChange={handleInputChange}
            inputValue={title}
            renderInput={params => (
              <TextField
                {...params}
                variant="filled"
                placeholder="(Seller, Buyer, etc..)"
              />
            )}
            />) :
            (<TextField
              variant="filled"
              InputLabelProps={{ classes: { root: classes.inputLabel } }}
              onChange={e => setTitle(e.target.value)}
              onBlur={e => handleOnBlurChange(e.target.value)}
              value={title}
              name="role"
              placeholder="(Seller, Buyer, etc..)"
            />)}
        </div>
        {props.hideAction ? '' :
          <div>
            <InputLabel classes={{root: classes.inputLabel}}>
              Action
            </InputLabel>
            <Select
              variant="filled"
              onChange={updateRole}
              value={role}
              required
              classes={{selectMenu: classes.iconMenu}}
            >
              <MenuItem value={RecipientRole.Signer} >
                <NeedsToSignIcon className={classes.icon} />
              </MenuItem>
              <MenuItem value={RecipientRole.CarbonCopy} classes={{ root: classes.item }}>
                <ReceiveCopyIcon className={classes.icon} />
              </MenuItem>
            </Select>  
          </div>
        }
      </div>
      <IconButton data-spec={`delete-recipient-${props.index}`} disabled={disabled}
        onClick={() => props.deleteRecipient(props.templateId, recipient)} classes={{ root: classes.deleteButton }}>
        <CloseIcon style={{height: '20px', width: '20px', }} />
      </IconButton>
    </div>  
  );
};

export default Recipient;
