import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import RecipientManagement from "../../containers/RecipientManagement";
import { useDispatch, useShallowSelector } from "../../lib/reduxHooks";
import { IRootState } from '../../store';
import { IRecipients } from '../../store/senderBuild/types';
import * as actions from "../../store/senderBuild/actions";
import { RecipientRole } from '../../common/recipients/types';
import useUserInfo from '../../auth/useUserInfo';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0',
    '&:first-child': {
      paddingTop: '0',
    },
  },
  paper: {
    maxWidth: '880px',
    maxHeight: '600px',
    height: '100%',
    width: '100%',
  },
  dialogActionsRoot: {
    borderTop: '1px solid #CAD5E0',
    padding: '14px 24px',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IState {
  recipientsModal: IRecipients;
  loading: boolean;
  successShow: boolean;
  errorShow: boolean;
  id: string;
}

const selector = (state: IRootState) => ({
  recipientsModal: state.senderBuild.recipientsModal,
  id: state.senderBuild.id,
  errorShow: state.pageFrame.errorShow,
  successShow: state.pageFrame.successShow,
});

interface IProps {
  open: boolean;
  toggleModal: () => void;
}
export const RecipientsModal = (props: IProps) => {
  const { recipientsModal, id }: IState = useShallowSelector(selector);
  const userInfo = useUserInfo();
  const { open, toggleModal } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { recipients, loading } = recipientsModal;
  const handleSave = () => {
    dispatch(actions.updateRecipients(recipients, id, userInfo!));
  };
  const saveDisabled = loading || !recipients.length || !recipients.some((r) => r.role === RecipientRole.Signer);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} onClose={toggleModal}>
      <DialogContent classes={{ root: classes.root }}>
        <RecipientManagement modal />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button color="primary" disabled={loading} onClick={toggleModal} style={{ marginRight: '12px' }}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={saveDisabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RecipientsModal;
