import React from 'react';
import { MenuItem } from '@material-ui/core';
import { SHELF_WIDTH } from '../../lib/constants';
import { makeStyles } from '@material-ui/styles';
import { useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import { IDocument, IPageDimensions } from '../../store/senderBuild/types';
import StyledSelect from './StyledSelect';
import ZoomButtons from '../common/ZoomButtons';
import { colors } from '@skyslope/mache';
import AutoSaveIndicator from './AutoSaveIndicator';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    left: SHELF_WIDTH + 1,
    height: '60px',
    right: 0,
    borderBottom: `1px solid ${colors.grey[400]}`,
    zIndex: 99999,
    backgroundColor: colors.grey[100],
  },
  selectionWrapper: {
    whiteSpace: 'nowrap',
  },
  selectWrapper: {
    marginLeft: '16px',
  },
  zoomPercent: {
    width: '58px',
    color: colors.grey[800],
    textAlign: 'center',
  },
  zoomWrapper: {
    marginLeft: '10px',
    marginRight: '40px',
  },
});

interface IProps {
  currentDocumentId: string | null;
  currentPageIndex: number;
  zoom: (zoomIn: boolean) => void;
}

const selector = (state: IRootState) => ({
  zoom: state.senderBuild.zoom,
  documents: state.senderBuild.documents,
  pageDimensions: state.senderBuild.pageDimensions,
});

interface IState {
  zoom: number;
  documents: IDocument[];
  pageDimensions: IPageDimensions;
}

const Subheader = (props: IProps) => {
  const store: IState = useShallowSelector(selector);
  const { currentDocumentId, currentPageIndex } = props;
  const classes = useStyles();

  const scrollToPage = (pageNum: number) => {
    const e = document.getElementById(`doc-${currentDocumentId}-page-${pageNum}`);
    if (e) {
      e!.scrollIntoView();
      const pdfViewer = document.querySelector('.PdfViewer');
      pdfViewer!.scrollTop -= 90;
    }
  };

  const currentDocument = store.documents.find((d) => d.documentId === currentDocumentId);
  const pages = Array.from({ length: currentDocument?.numberOfPages || 0 }, (_, i) => i);
  return (
    <div className={`${classes.wrapper} pdfViewerHeader`}>
      <div className={classes.selectionWrapper}>
        <span className={classes.selectWrapper} id="selectPage">
          <StyledSelect onChangeCallback={scrollToPage} value={currentPageIndex} disabled={pages.length < 2}>
            {pages.map((page) => (
              <MenuItem key={page} value={page}>
                Page {page + 1}
              </MenuItem>
            ))}
          </StyledSelect>
        </span>
      </div>
      <AutoSaveIndicator />
      <div className={classes.zoomWrapper}>
        <ZoomButtons zoom={store.zoom} zoomIn={() => props.zoom(true)} zoomOut={() => props.zoom(false)} />
      </div>
    </div>
  );
};

export default Subheader;
