import { BLOCK_TYPE_KEYS } from '../../../lib/constants';
import pdfCache from '../../../lib/pdfCache';
import { envelopeApi } from '../../../lib/api/envelope/';
import {
  IResponse,
  addBlock,
  createNewGroup,
  deleteBlock,
  deleteBlockGroup,
  editBlockGroup,
  editBlock,
} from '../../../lib/api/envelope/envelope';
import { calculateFontSize } from '../../../lib/useAutomaticFontSizing';
import { IRootState } from '../../index';
import { IGroupState } from '../reducers';
import { IBlock, IBlocks, IEnvelope, IGroup, IPageDimensions, ISigningGroup } from '../types';
import { call, all, select, put, CallEffect } from 'redux-saga/effects';
import { setAutoSaving } from '../actions';
import { ISigner } from '../../../common/recipients/types';
import { cleanUpInvalidBlockValuesAndLogToSentry, getRecipientColor } from '../../../lib/utils';

const getId = (state: IRootState) => state.senderBuild.id;
const getGroups = (state: IRootState) => state.senderBuild.groups;
const getBlocks = (state: IRootState) => state.senderBuild.blocks;
const getZoom = (state: IRootState) => state.senderBuild.zoom;

export function* envelopeFetch(id: string) {
  const zoom = yield getZoom;
  const res: IResponse<IEnvelope> = yield call(envelopeApi.getEnvelopeById, id);
  const envelope = res.result!;
  const signers = envelope.signers;
  const carbonCopy = envelope.carbonCopy.map((recipient, i: number) => ({
    ...recipient,
    color: getRecipientColor(recipient.recipientColorCounter),
  }));
  let signingGroups: ISigningGroup[] = [];
  if (envelope.signingGroups) {
    signingGroups = envelope.signingGroups
      .map((sg) => ({ name: sg.name, id: sg.id, order: sg.order }))
      .sort((a, b) => a.order - b.order);
  }
  const pageDimensions: IPageDimensions = {};
  envelope.documents.map((document) => {
    pageDimensions[document.documentId] = document.pages;
    document.isDownloaded = false;
  });
  const blocks: IBlocks = {};
  const groups: IGroupState = {};
  envelope.documents.forEach((document) => {
    blocks[document.documentId] = {};
    pageDimensions[document.documentId] = document.pages;
    const currentGroups = document.blockGroups;
    if (currentGroups) {
      currentGroups.forEach((group) => {
        if (!groups[group.documentId]) {
          groups[group.documentId] = {};
        }
        if (!groups[group.documentId][group.pageNumber]) {
          groups[group.documentId][group.pageNumber] = {};
        }
        groups[group.documentId][group.pageNumber][group.id] = group;
      });
    }
    for (let i = 0; i < document.numberOfPages!; i++) {
      blocks[document.documentId][i] = [];
    }
    document.blocks?.forEach((block) => {
      if (block.blockType === BLOCK_TYPE_KEYS.TEXT && block.value && !block.fontSize) {
        block.fontSize = calculateFontSize(block.value as string, 22, block.height!, block.width!, zoom);
      }
      blocks[document.documentId][block.pageNumber].push(block);
    });
  });

  return {
    blocks,
    groups,
    documents: envelope.documents,
    carbonCopy,
    signers: signers,
    signingGroups: signingGroups,
    emailConfig: envelope.emailConfig,
    config: envelope.config,
    pageDimensions,
    status: envelope.status,
    recipientColorCounter: envelope.recipientColorCounter,
  };
}

export function* senderCreateBlock(documentId: string, pageIndex: number, block: IBlock) {
  const envelopeId = yield select(getId);
  const { result } = yield call(addBlock, envelopeId, documentId, block);
  return result.id;
}

export function* senderCreateBlocksThenGroup(newBlocks: IBlock[], newGroup: IGroup) {
  const apiCalls: Generator[] = [];
  newBlocks.forEach((newBlock) => {
    delete newBlock.blockId;
    delete newBlock.groupId;
    apiCalls.push(senderCreateBlock(newGroup.documentId, newGroup.pageNumber, newBlock));
  });
  const blockIds = yield all(apiCalls);
  newBlocks.forEach((newBlock, i) => {
    newBlock.blockId = blockIds[i];
  });
  newGroup.blockIds.push(...blockIds);
  const groupId = yield senderCreateGroup(newGroup);
  newGroup.id = groupId;
  for (let i = 0; i < newBlocks.length; i++) {
    newBlocks[i].groupId = groupId;
  }
}

export function* senderCreateGroup(group: IGroup) {
  const envelopeId = yield select(getId);
  const { result } = yield call(createNewGroup, envelopeId, group);
  return result;
}

export function* senderEditBlock(documentId: string, pageIndex: number, blockIndex: number) {
  const originalBlock = yield select((state: IRootState) => {
    return state.senderBuild.blocks[documentId][pageIndex][blockIndex];
  });
  yield put(setAutoSaving(true));
  const envelopeId = yield select(getId);
  const cleanBlock = yield cleanUpInvalidBlockValuesAndLogToSentry(
    originalBlock,
    envelopeId,
    'senderEditBlock: Block had invalid values'
  );
  try {
    yield call(editBlock, envelopeId, documentId, cleanBlock);
  } catch (e) {
    if (!e.message.includes('404')) {
      throw new Error(e);
    }
  }
}

export function* senderGroupEdit(documentId: string, pageIndex: number, groupId: string, updateObj: any) {
  const originalGroup = yield select((state: IRootState) => {
    return state.senderBuild.groups[documentId][pageIndex][groupId];
  });
  let newGroup: IGroup = { ...originalGroup, ...updateObj };
  if (newGroup.blockIds.length <= newGroup.validation.value) {
    newGroup.validation.value = newGroup.blockIds.length;
  }
  const envelopeId = yield select(getId);
  yield call(editBlockGroup, envelopeId, newGroup);
}

export function* senderDeleteBlock(documentId: string, pageIndex: number, blockIds: string[]) {
  yield put(setAutoSaving(true));
  const envelopeId = yield select(getId);
  let pageGroups = yield select(getGroups);
  if (pageGroups[documentId]) {
    pageGroups = yield select((state: IRootState) => state.senderBuild.groups[documentId][pageIndex]);
  }
  let remainingBlockIds = [...blockIds];
  const apiCalls: CallEffect[] = [];
  for (const groupId in pageGroups) {
    const groupBlockIds = pageGroups[groupId].blockIds;
    if (groupBlockIds && groupBlockIds.every((blockId: string) => remainingBlockIds.includes(blockId))) {
      apiCalls.push(call(deleteBlockGroup, envelopeId, documentId, groupId));
      remainingBlockIds = remainingBlockIds.filter((blockId) => !groupBlockIds.includes(blockId));
    }
  }
  if (remainingBlockIds.length) {
    const blocks: IBlocks[] = yield select(getBlocks);
    remainingBlockIds.forEach((blockId) => {
      const block = blocks[documentId][pageIndex].find((b: IBlock) => b.blockId === blockId);
      // PATCH blockGroups endpoint will delete the block for us if it is removed from the blockIds array
      // If blockGroupId still exist in a sigle block - DIGI-2016
      if (!block?.groupId || !pageGroups?.[block.groupId]) {
        apiCalls.push(call(deleteBlock, envelopeId, documentId, blockId));
      }
    });
  }
  try {
    const res: number[] = yield all(apiCalls);
    if (res.every((status) => status !== 204)) {
      throw new Error('404');
    }
  } catch (e) {
    throw new Error(e);
  }
}
