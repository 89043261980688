export enum FileStatus {
  Incomplete = 'Incomplete',
  Expired = 'Expired',
  Active = 'Active',
  Pending = 'Pending',
}

export interface IProperty {
  streetNumber?: string;
  streetAddress?: string;
  city?: string;
  direction?: string;
  state?: string;
  unit?: string;
  zip?: string;
}

export interface IFile {
  id: string;
  propertyStr: string; // computed by us
  listingGuid?: string;
  createdOn: string;
  expirationDate: string;
  modifiedOn: string;
  objectType: 'listing' | 'sale';
  property: IProperty;
  status: FileStatus;
  agent: IAgent;
  cursor?: string;
}

export interface IFileResponse {
  totalCount: number;
  properties: IFile[];
  lastListingCursor: string,
  lastSalesCursor: string
}

export interface IAgent {
  id: string;
}
