import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import { v4 as uuid } from 'uuid';
import Skeleton from '@material-ui/lab/Skeleton';
import { IRootState } from '../../../store';
import { IFileDocument } from '../../../store/dtm/documents/types';
import SectionHeader from './SectionHeader';
import Section from './Section';
import DocumentEmptyState from './DocumentEmptyState';
import { IFile } from '../../types';
import { uploadPrimeDocument } from '../../../store/dtm/documents/actions';
import { chameleonElId } from '../../../lib/constants';
import { useShallowSelector, useDispatch } from '../../../lib/reduxHooks';

const useStyles = makeStyles((theme: Theme) => ({
  focusButton: {
    '&:focus': {
      backgroundColor: colors.blue[50],
    },
  },
  primeSection: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    overflowX: 'hidden',
  },
  fileDoc: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${colors.grey[400]}`,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(3)}px`,
    minHeight: 38,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  fileName: {
    flexGrow: 1,
    wordBreak: 'break-word',
  },
  addedFile: {
    backgroundColor: colors.grey[100],
    borderColor: colors.grey[100],
  },
  addedText: {
    display: 'flex',
    color: `${colors.grey[500]} !important`,
  },
  primeAddIcon: {
    marginRight: theme.spacing(1),
  },
  descriptionIcon: {
    color: colors.grey[500],
    marginRight: theme.spacing(1),
  },
  propertyStr: {
    marginLeft: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

interface IState {
  envelopeDocumentNames: string[];
  fileDocuments: IFileDocument[];
  file: IFile;
  isLoading: boolean;
}

const selector = (state: IRootState) => ({
  envelopeDocumentNames: state.dtm.documents.envelopeDocuments.map((d) => d.fileName),
  fileDocuments: state.dtm.documents.fileDocuments,
  file: state.dtm.documents.file,
  isLoading: state.dtm.documents.isFetching,
});

const PrimeSection = () => {
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const addPrimeDocument = (fileName: string, url: string) => {
    const tempId = uuid();
    dispatch(uploadPrimeDocument(tempId, fileName, url));
  };

  const truncateLongFileName = (name: string) => (name.length > 55 ? `${name.substring(0, 55)}...` : name);
  const documentsIn = store.isLoading ? (
    <Skeleton animation="wave" />
  ) : (
    `${store.fileDocuments.length} DOCUMENT${store.fileDocuments.length === 1 ? '' : 'S'} IN:`
  );
  const propertyStr = store.isLoading ? '' : store.file.propertyStr;

  const documentSection = () => {
    if (store.isLoading) {
      const skeletonArray = new Array(3).fill(<Skeleton animation="wave" style={{height: '100%', width: '100%'}}/>);
      return skeletonArray.map((skeleton) => {
        return <div className={classes.fileDoc}>{skeleton}</div>;
      });
    }

    if (store.fileDocuments.length > 0) {
      return store.fileDocuments.map((doc, i) => {
        const alreadyAdded = store.envelopeDocumentNames.includes(doc.fileName);
        const propsToAdd = i === 0 ? { id: chameleonElId(9) } : null;
        return (
          <div
            key={i}
            className={`${classes.fileDoc} ${alreadyAdded ? classes.addedFile : ''}`}
            data-spec="file-document"
          >
            <div className={classes.fileName}>
              <Typography variant="body2" className={alreadyAdded ? classes.addedText : ''}>
                {truncateLongFileName(doc.fileName)}
              </Typography>
            </div>
            {alreadyAdded ? (
              <Typography variant="body2" className={classes.addedText}>
                <CheckCircleIcon className={classes.primeAddIcon} />
                Added
              </Typography>
            ) : (
              <Button
                color="primary"
                className={classes.focusButton}
                onClick={() => addPrimeDocument(doc.fileName, doc.url)}
                {...propsToAdd}
              >
                <AddCircleIcon className={classes.primeAddIcon} />
                Add
              </Button>
            )}
          </div>
        );
      });
    }
    return <DocumentEmptyState />;
  };
  return (
    <Section id={chameleonElId(8)}>
      <SectionHeader>
        <DescriptionIcon className={classes.descriptionIcon} />
        <Typography variant="subtitle1" style={{ width: store.isLoading ? '100%' : '' }}>
          {documentsIn}
        </Typography>
        <Typography variant="body1" className={classes.propertyStr}>
          {propertyStr}
        </Typography>
      </SectionHeader>
      <div className={classes.primeSection}>{documentSection()}</div>
    </Section>
  );
};

export default PrimeSection;
