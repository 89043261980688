import React from 'react';
import BlockShelf from './BlockShelf';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { SHELF_WIDTH } from '../../lib/constants';
import { ISelectedBlocks } from '../../store/senderBuild/types';
import { IRootState } from '../../store';
import { useShallowSelector } from '../../lib/reduxHooks';

const useStyles = makeStyles({
  shelf: {
    backgroundColor: 'white',
    position: 'fixed',
    transform: `translateX(-${SHELF_WIDTH / 4}px)`,
    top: '156px',
    bottom: 0,
    width: SHELF_WIDTH,
    textAlign: 'left',
    color: colors.grey[800],
    display: 'flex',
    zIndex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    transition: 'all 0.25s',
    opacity: 0,
    pointerEvents: 'none',
  },
  in: {
    transform: 'translateX(0)',
    opacity: 1,
    pointerEvents: 'initial',
  },
});
interface IState {
  selectedBlocks: ISelectedBlocks;
}

const blockShelfWrapperSelector = (state: IRootState) => ({
  selectedBlocks: state.senderBuild.selectedBlocks,
});

const BlockShelfWrapper = () => {
  const { selectedBlocks }: IState = useShallowSelector(blockShelfWrapperSelector);

  const show = selectedBlocks.blockIndices.length > 0;
  const classes = useStyles();
  return <div className={`${classes.shelf} ${show && classes.in}`}>{show && <BlockShelf />}</div>;
};

export default BlockShelfWrapper;
