import React from 'react';

// Hidden div used to check if the text fits in the box
const hiddenDiv = document.createElement('div');
const style = {
  fontFamily: 'courier',
  overflow: 'hidden',
  boxSizing: 'content-box',
  wordBreak: 'break-word',
  textAlign: 'left',
  lineHeight: 1,
  position: 'fixed',
  whiteSpace: 'break-spaces',
  visibility: 'hidden',
};
for (const key in style) {
  hiddenDiv.style[key] = style[key];
}
document.body.appendChild(hiddenDiv);

// Functions for calculating font sizes
const shrink = (fontSize: number, height: number, zoom: number): number => {
  let newFontSize = Math.min(fontSize, height) - 1;
  while (newFontSize > 0) {
    hiddenDiv.style.fontSize = `${newFontSize * zoom}px`;
    if (hiddenDiv.clientHeight < height * zoom) {
      return newFontSize;
    }
    newFontSize--;
  }
  return 0;
};

const grow = (fontSize: number, height: number, zoom: number): number => {
  const maxFontSize = Math.min(height - 1, 25);
  let newFontSize = Math.min(fontSize + 1, maxFontSize);
  while (newFontSize <= maxFontSize) {
    hiddenDiv.style.fontSize = `${newFontSize * zoom}px`;
    if (hiddenDiv.clientHeight < height * zoom) {
      newFontSize++;
    } else {
      return newFontSize - 1;
    }
  }
  return newFontSize - 1;
};

const calculateFontSize = (value: string, fontSize: number, height: number, width: number, zoom: number): number => {
  hiddenDiv.style.width = `${width * zoom - 8}px`;
  hiddenDiv.textContent = value;
  hiddenDiv.style.fontSize = `${fontSize * zoom}px`;
  if (hiddenDiv.clientHeight >= height * zoom || fontSize >= height) {
    return shrink(fontSize, height, zoom);
  }
  return grow(fontSize, height, zoom);
};

// Hook
interface IHookProps {
  height: number;
  width: number;
  zoom: number;
  initialFontSize?: number;
  initialValue?: string;
}

const useAutomaticFontSizing = ({ height, width, zoom, initialFontSize, initialValue }: IHookProps) => {
  const defaultFontSize = Math.min(height - 1, initialFontSize || 24, 24);
  const [fontSize, setFontSize] = React.useState(defaultFontSize);
  const [value, setValue] = React.useState(initialValue || '');

  React.useEffect(() => {
    const newFontSize = calculateFontSize(value, fontSize, height, width, zoom);
    setFontSize(newFontSize);
  }, [height, width, value]);

  return { fontSize, value, setValue };
};

export { calculateFontSize, useAutomaticFontSizing };
