import React from 'react';
import { ReactComponent as CopyIcon } from '../../../images/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../images/delete.svg';
import { ReactComponent as DisabledDeleteIcon } from '../../../images/disabledDelete.svg';
import { ReactComponent as PasteIcon } from '../../../images/paste.svg';
import { ReactComponent as DisabledPasteIcon } from '../../../images/disabledPaste.svg';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useShallowSelector, useDispatch } from '../../../lib/reduxHooks';
import { IRootState } from '../../../store';
import { ISelectedBlocks, IBlocks, ICopyCache, IPageDimensions, IGroups } from '../../../store/senderBuild/types';
import * as actions from '../../../store/senderBuild/actions';
import { pasteCacheBlocks } from '../../../lib/utils';
import { BLOCK_TYPE_KEYS } from '../../../lib/constants';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginLeft: -1 * theme.spacing(1),
  },
  actionIcon: {
    height: 18,
    width: 18,
    marginRight: theme.spacing(1),
  },
  actionSection: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  toolbarActionSection: {
    padding: theme.spacing(1),
  },
}));

interface IProps {
  isButtonDisabled: boolean;
  isBlockToolbar?: boolean;
  blockType?: string;
}

const blockActionsSelector = (state: IRootState) => {
  const {
    currentPage,
    currentDocument,
    blocks,
    copyCache,
    selectedBlocks,
    pageDimensions,
    id,
    groups,
    isDeletingBlocks,
    isCreatingBlock,
  } = state.senderBuild;
  return {
    currentPage,
    currentDocument,
    blocks,
    copyCache,
    selectedBlocks,
    pageDimensions,
    id,
    groups,
    isDeletingBlocks,
    isCreatingBlock,
  };
};

interface IState {
  id: string;
  selectedBlocks: ISelectedBlocks;
  blocks: IBlocks;
  copyCache: ICopyCache;
  currentPage: number;
  currentDocument: string;
  pageDimensions: IPageDimensions;
  groups: IGroups;
  isDeletingBlocks: boolean;
  isCreatingBlock: boolean;
}

const BlockActions = (props: IProps) => {
  const {
    selectedBlocks,
    blocks,
    copyCache,
    currentPage,
    currentDocument,
    pageDimensions,
    groups,
    id,
    isDeletingBlocks,
    isCreatingBlock,
  }: IState = useShallowSelector(blockActionsSelector);
  const selectedPageBlocks = blocks[selectedBlocks.documentId!][selectedBlocks.pageIndex!];
  const isStrikeBlock = props.blockType === BLOCK_TYPE_KEYS.STRIKE;

  const copyBlocks = () => {
    const copiedBlocks = selectedBlocks.blockIndices.map((i) => selectedPageBlocks[i]);
    dispatch(actions.copyBlocksToCache(copiedBlocks));
  };

  const pasteBlocks = () => {
    const { pasteCounter } = copyCache;
    const pasteBlocksObj = pasteCacheBlocks({
      pasteCounter: pasteCounter,
      copyCache: copyCache,
      blocks: blocks,
      pageHeight: pageDimensions[currentDocument][currentPage].height,
      pageWidth: pageDimensions[currentDocument][currentPage].width,
      selectedBlocks: selectedBlocks,
      currentDocument: currentDocument,
      currentPage: currentPage,
      envelopeId: id,
      blockGroups: groups,
    });
    if (pasteBlocksObj.updatedGroup!.newBlock!.length > 0) {
      dispatch(
        actions.addNewBlocksToGroup(pasteBlocksObj.updatedGroup!.currentGroup, pasteBlocksObj.updatedGroup!.newBlock)
      );
    } else {
      dispatch(
        actions.pasteBlocks(currentDocument, currentPage, pasteBlocksObj.blocksWithNewIds, pasteBlocksObj.newGroups)
      );
    }
  };

  const deleteBlock = () => {
    const blockIds = selectedBlocks.blockIndices.map(
      (blockIndex) => blocks[selectedBlocks.documentId!][selectedBlocks.pageIndex!][blockIndex].blockId!
    );
    dispatch(actions.deleteBlocks(selectedBlocks.documentId!, selectedBlocks.pageIndex!, blockIds));
  };

  const dispatch = useDispatch();
  const classes = useStyles(props);
  return (
    <section className={props.isBlockToolbar ? classes.toolbarActionSection : classes.actionSection}>
      {!props.isBlockToolbar && <Typography variant="overline">Block Actions:</Typography>}
      <div
        className={
          props.isBlockToolbar && isStrikeBlock
            ? 'flex justify-around py-2 px-0'
            : props.isBlockToolbar
            ? 'flex justify-around p-2'
            : classes.actions
        }
      >
        <Button variant="text" color="primary" onClick={copyBlocks} data-spec="copy">
          <CopyIcon className={classes.actionIcon} />
          Copy
        </Button>
        <Button
          disabled={props.isButtonDisabled}
          variant="text"
          color="primary"
          className={props.isBlockToolbar && isStrikeBlock ? 'ml-1' : 'ml-4'}
          data-spec="paste"
          onClick={pasteBlocks}
        >
          {props.isButtonDisabled ? (
            <>
              <DisabledPasteIcon className={classes.actionIcon} />
              Paste
            </>
          ) : (
            <>
              <PasteIcon className={classes.actionIcon} />
              Paste
            </>
          )}
        </Button>
        <Button
          disabled={isDeletingBlocks || isCreatingBlock}
          variant="text"
          color="primary"
          className={props.isBlockToolbar && isStrikeBlock ? 'ml-1' : 'ml-4'}
          onClick={deleteBlock}
          data-spec="delete"
        >
          {isDeletingBlocks || isCreatingBlock ? (
            <DisabledDeleteIcon className={classes.actionIcon} />
          ) : (
            <DeleteIcon className={classes.actionIcon} />
          )}
          Delete
        </Button>
      </div>
    </section>
  );
};

export default BlockActions;
