import React from 'react';
import { makeStyles } from '@material-ui/core';
import { colors } from '@skyslope/mache';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';

interface IProps {
  columns?: any[];
  numberOfColumns?: number;
}

const useStyles = makeStyles(() => ({
  tableRow: {
    backgroundColor: '#FFFFFF',
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
    height: 64,
  },
  tableCell: {
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
  },
}));

const SkeletonRow = (props: IProps) => {
  const { columns, numberOfColumns } = props;
  const classes = useStyles(props);

  const cellToRender = (i: number) => (
    <TableCell key={i} className={classes.tableCell}>
      <Skeleton animation="wave" />
    </TableCell>
  );

  return (
    <TableRow className={classes.tableRow}>
      {columns ? columns.map((_, i) => cellToRender(i)) : [...Array(numberOfColumns || 3)].map(i => cellToRender(i))}
    </TableRow>
  );
};

export default SkeletonRow;
