import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import { useStyles } from './Signers/index';
import { RecipientsHeader } from '../common/RecipientsHeader';
import { IRecipient } from '../../common/recipients/types';

const carbonCopyRecipientsSelector = (state: IRootState) => ({
  recipients: state.senderBuild.recipientsModal.recipients,
});

interface IState {
  recipients: IRecipient[];
}

export const CarbonCopyRecipients = () => {
  const classes = useStyles();
  const { recipients }: IState = useShallowSelector(carbonCopyRecipientsSelector);
  const carbonCopyRecipients = recipients.filter((r) => r.role === 'CarbonCopy' && r.isActive);
  if (!carbonCopyRecipients.length) return null;
  const renderCarbonCopyRecipient = (ccRecipient: IRecipient) => (
    <div key={ccRecipient.id} className={classes.row} tabIndex={0}>
      <div className={classes.icon} style={{ marginRight: '14px', marginLeft: '2px' }}>
        Cc:
      </div>
      <div className={classes.overflowContainer}>
        <Typography variant="body2">
          {ccRecipient.firstName} {ccRecipient.lastName}
        </Typography>
        <Typography variant="body1" className={`${classes.entry} ${classes.email}`}>
          {ccRecipient.email}
        </Typography>
      </div>
    </div>
  );

  return (
    <div>
      <div className={classes.signingGroup}>
        <RecipientsHeader />
        {carbonCopyRecipients.map((ccRecipient) => renderCarbonCopyRecipient(ccRecipient))}
      </div>
    </div>
  );
};
