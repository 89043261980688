import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import documentEmptyState from '../../../images/document_empty_state.svg';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconWrapper: {
    width: '280px',
    height: '280px',
  },
  documentEmptyStateImg: {
    width: '100%',
    height: '100%',
  },
}));

const DocumentEmptyState = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} data-spec="document-empty-state">
      <div className={classes.iconWrapper}>
        <img src={documentEmptyState} alt="no documents found" className={classes.documentEmptyStateImg} />
      </div>
      <div className={classes.textWrapper}>
        <Typography variant="h6">No documents found</Typography>
        <Typography variant="body1">There are no documents associated with this property.</Typography>
      </div>
    </div>
  );
};

export default DocumentEmptyState;
