import React, { useEffect, useState } from 'react';
import { colors } from '@skyslope/mache';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import * as actions from './../../store/senderBuild/actions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Description } from '@material-ui/icons';
import { IDocument } from '../../store/senderBuild/types';
import { IRootState } from '../../store';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const documentListSelector = (state: IRootState) => ({
  documents: state.senderBuild.documents,
  currentDocument: state.senderBuild.currentDocument,
});

interface IState {
  documents: IDocument[];
  currentDocument: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    marginRight: -24,
  },
  row: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EBF1F6',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: '#EBF1F6',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
    color: colors.grey[500],
    width: 28,
    height: 28,
  },
  overflowContainer: {
    overflow: 'hidden',
  },
  entry: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowContainer: {
    height: '40px',
    overflow: 'hidden',
    display: 'flex',
    wordBreak: 'break-all',
    whiteSpace: 'pre',
    alignItems: 'center',
  },
  selected: {
    '&.Mui-selected': {
      backgroundColor: colors.blue[50],
      '&::after': {
        content: "''",
        position: 'absolute',
        height: '40px',
        width: '4px',
        background: colors.blue[800],
        left: '0px',
        top: '0px',
      },
    },
  },
  activeHover: {
    backgroundColor: colors.blue[50],
  },
}));

export const DocumentList = () => {
  const { documents, currentDocument }: IState = useShallowSelector(documentListSelector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentActiveDocument, setCurrentActiveDocument] = useState('');
  useEffect(() => {
    setCurrentActiveDocument(currentDocument);
  }, [currentDocument]);

  const handleDocumentClick = (e: any) => {
    dispatch(actions.setCurrentDocument(e));
    const doc = document.getElementById(`doc-${e}-page-0`);
    if (doc) {
      doc!.scrollIntoView();
      const pdfViewer = document.querySelector('.PdfViewer');
      pdfViewer!.scrollTop -= 90;
    }
  };

  const handleKeyDown = (e: any, documentId: string) => {
    if (e.key === 'Enter') {
      handleDocumentClick(documentId);
    }
  };

  return (
    <List className={classes.list}>
      {documents.map((document, i) => (
        <ListItem
          id={`Document_${document.fileName}`}
          key={document.documentId}
          selected={currentActiveDocument === document.documentId}
          className={classes.row}
          onClick={() => handleDocumentClick(document.documentId)}
          classes={{
            selected: classes.selected,
          }}
          style={{ backgroundColor: currentActiveDocument === document.documentId ? colors.blue[50] : '' }}
          data-spec={`doc-${document.documentId}`}
          tabIndex={0}
          onKeyDown={(e: any) => handleKeyDown(e, document.documentId)}
        >
          <div key={document.documentId} className={classes.rowContainer}>
            <Description className={classes.icon} />
            <div title={document.fileName} className={classes.overflowContainer}>
              <Typography variant="body1" className={classes.entry}>
                {document.fileName}
              </Typography>
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

export default DocumentList;
