import React from 'react';
import { RecipientsHeader } from './../../common/RecipientsHeader';
import { useStyles, renderSigner } from './index';
import { ISigner, ISigningGroup } from '../../../store/senderBuild/types';

interface IProps {
  signingGroup: ISigningGroup;
  signers: ISigner[];
  index: number;
  moveSigner: (signerId: string, newGroupId: string) => void;
  totalSigningGroups: number;
}

export default (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={`${classes.signingGroup}`}>
      {props.totalSigningGroups > 1 ? <RecipientsHeader index={props.index} /> : ''}
      {props.signers.map((s, i) => (
        <div key={s.signerId}>{renderSigner(classes, s, false, i)}</div>
      ))}
    </div>
  );
};
