import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableMui from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import { colors } from '@skyslope/mache';
import cx from 'classnames';
import SkeletonRow from '../../../common/loading/SkeletonRow';

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    backgroundColor: '#FFFFFF',
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
    height: 64,
  },
  wrapper: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${colors.grey[400]}`,
    borderBottomWidth: 2,
  },
  tableCell: {
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
  },
  backgroundOverride: {
    backgroundColor: 'inherit',
  },
  clickableRow: {
    '&:hover,&:focus': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
    },
  },
  selectedRow: {
    backgroundColor: `${colors.blue[100]} !important`
  },
  clickableHeader: {
    userSelect: 'none',
    '&:hover': {
      backgroundColor: colors.blue[50],
      cursor: 'pointer',
    },
  },
  buttonDropDown: {
    color: colors.grey[800],
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover, &:focus': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
    }
  },
  tableHeader: {
    display: 'table',
    marginLeft: -16,
    padding: '8px 16px',
    borderRadius: '50px',
  },
  headerLabel: {
    display: 'table-cell',
  },
  sortIcons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sortIcon: {
    margin: '-7.5px 0',
  },
}));

interface IProps {
  columns: IColumn[];
  rows?: any[];
  idKey?: string;
  isLoading?: boolean;
  showBottomLoader?: boolean;
  resetScroll?: boolean;
  onClickRow?: Function;
  className?: string;
  rowClassName?: string;
  cellClassName?: string;
  onClickHeader?: (sortKey: string) => void;
  currentSortKey?: string;
  currentSortDesc?: boolean;
  activityLog?: boolean;
  rowIsSelected?: Function;
}

export interface IColumn {
  key: string;
  label: string;
  align?: 'left' | 'center' | 'right';
  boldText?: boolean;
  cell?: (row: any) => React.ReactNode;
  sortKey?: string;
}


let cachedScrollTop = 0;
const Table = (props: IProps) => {
  const classes = useStyles(props);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const numberOfRowsOnLoad = 3;

  React.useEffect(() => {
    if (cachedScrollTop && !props.resetScroll) {
      wrapperRef.current!.scrollTop = cachedScrollTop;
    }
    return () => {
      cachedScrollTop = wrapperRef.current!.scrollTop;
    };
  }, [props.rows?.length]);

  const onRowKeyUp = (e: KeyboardEvent, row: any) => {    
    if (e.key === 'Enter' && props.onClickRow) {    
      props.onClickRow(row);
    }       
  }; 

  return (
    <div
      ref={wrapperRef}
      className={cx(classes.wrapper, props.className)}
      style={{ maxHeight: props.activityLog ? 'none' : '' }}
    >
      <TableMui stickyHeader>
        <TableHead>
          <TableRow className={cx(classes.tableRow, props.rowClassName)}>
            {props.columns.map((col) => (
              <TableCell
                classes={{
                  root: cx(classes.tableCell, props.cellClassName),
                  stickyHeader: classes.backgroundOverride,
                }}
                key={col.key}
                align={col.align || 'left'}
              >
                {
                  col.sortKey ? 
                  <Button
                    data-spec={`header-button-${col.sortKey}`}
                    classes={{root: classes.buttonDropDown}}
                    onClick={() => props.onClickHeader ? props.onClickHeader(col.sortKey!) : false}
                    tabIndex={0}
                    endIcon={
                      <div className={classes.sortIcons}>
                        <ArrowUpIcon
                          className={classes.sortIcon}
                          style={{
                            color:
                              col.sortKey === props.currentSortKey && !props.currentSortDesc
                                ? colors.grey[800]
                                : colors.grey[500],
                          }}
                        />
                        <ArrowDownIcon
                          className={classes.sortIcon}
                          style={{
                            color:
                              col.sortKey === props.currentSortKey && props.currentSortDesc
                                ? colors.grey[800]
                                : colors.grey[500],
                          }}
                        />
                      </div>
                    }
                  >
                    {col.label}
                  </Button> :  
                  <div
                    className={classes.tableHeader}
                  >
                    <Typography className={classes.headerLabel} variant="body2">
                      {col.label}
                    </Typography>
                  </div>
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.isLoading ? (
            <TableRow>
              <TableCell>Fetching...</TableCell>
            </TableRow>
          ) : (
            props.rows!.map((row, i) => (
              <Row
                key={i}
                index={i}
                row={row}
                idKey={props.idKey}
                columns={props.columns}
                onClickRow={props.onClickRow}
                onRowKeyUp={onRowKeyUp}
                rowClassName={props.rowClassName}
                cellClassName={props.cellClassName}
                rowIsSelected={props.rowIsSelected}
              />
            ))
          )}
          {props.showBottomLoader && (
            <>
              {[...Array(numberOfRowsOnLoad)].map(i => (
                <SkeletonRow key={i} columns={props.columns} />
              ))}
            </>
          )}
        </TableBody>
      </TableMui>
    </div>
  );
};

interface IRowProps {
  index: number;
  row: any;
  idKey?: string;
  columns: IColumn[];
  rowClassName?: string;
  cellClassName?: string;
  onClickRow?: Function;
  onRowKeyUp?: Function;
  rowIsSelected?: Function;
}

const Row = (props: IRowProps) => {
  const { index, row, idKey, columns, onClickRow, onRowKeyUp } = props;
  const classes = useStyles(props);
  const clickableRow = Boolean(onClickRow);
  const isSelected = props.rowIsSelected ? props.rowIsSelected(row) : false;
  return (
    // @ts-ignore
    <TableRow
      key={row[idKey!] || index}
      className={cx(classes.tableRow, props.rowClassName, { [classes.clickableRow]: clickableRow, [classes.selectedRow]: isSelected })}
      onClick={clickableRow ? () => onClickRow!(row) : undefined}
      onKeyUp={clickableRow && onRowKeyUp ? (e: KeyboardEvent) => onRowKeyUp(e, row): undefined}
      tabIndex={0}
      data-spec="file-row"
    >
      {columns.map((col, index) => {
        return (
          <TableCell className={cx(classes.tableCell, props.cellClassName)} key={col.key} align={col.align || 'left'}>
            {col.cell ? (
              col.cell(row)
            ) : (
              <Typography variant={col.boldText ? 'body2' : 'body1'}>{row[col.key]}</Typography>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default Table;
