import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { hex2rgba } from '../../lib/utils';
import { IBlockWithSignerNumber } from '../../store/applyTemplate/types';

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    position: 'absolute',
    zIndex: 4,
  },
}));

interface IProps {
  block: IBlockWithSignerNumber;
  color: string;
  pageDimensions: { x: number; y: number };
}

const BlockPreview = (props: IProps) => {
  const { block, color, pageDimensions } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.block}
      style={{
        left: `${(block.x! / pageDimensions.x) * 100}%`,
        height: `${(block.height! / pageDimensions.y) * 100}%`,
        width: `${(block.width! / pageDimensions.x) * 100}%`,
        top: `${(block.y! / pageDimensions.y) * 100}%`,
        backgroundColor: hex2rgba(color),
        outline: `1px solid ${color}`,
      }}
    />
  );
};

export default BlockPreview;
