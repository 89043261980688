import React from 'react';
import { v4 as uuid } from 'uuid';
import RecipientGroups from './recipientGroups';
import RecipientList from './recipientList';
import { IRecipient, IGroup } from './types';

interface IProps {
  recipients: IRecipient[];
  optionalRecipients?: IRecipient[];
  incompletePlaceholders?: IRecipient[];
  onChange: (recipients: IRecipient[], groups: IGroup[]) => void;
  createPrimeContact?: (contact: { firstName: string; lastName: string; email: string }) => void;
  isPrimeUser?: boolean;
  contacts: any[];
  modal?: boolean;
  disableRecipientList?: boolean;
  colorCounter?: number;
}

const RecipientManagement = (props: IProps) => {
  const { recipients, onChange, isPrimeUser, modal } = props;
  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [updatedRecipients, setUpdatedRecipients] = React.useState<IRecipient[]>(recipients);

  React.useEffect(() => {
    setUpdatedRecipients(recipients);
  }, [recipients]);

  const buildSigningGroupsFromRecipients = (newRecipients: IRecipient[]) => {
    const activeGroupIds = newRecipients.map((r) => r.group?.id).filter((i) => i);
    const sGroups = groups.filter((sg) => activeGroupIds.includes(sg.id));
    newRecipients.forEach((r, i) => {
      if (r.group && !sGroups.find((sg) => sg.id === r.group!.id)) {
        sGroups.push(r.group);
      }
    });
    sGroups.sort((a, b) => a.order - b.order);
    sGroups.forEach((sg, i) => {
      sg.order = i;
    });
    if (!sGroups.length) {
      sGroups.push({ id: 'default', name: 'default', order: 0 });
    }
    return sGroups;
  };

  const setRecipients = (newRecipients: IRecipient[]) => {
    onChange(newRecipients, groups);
    setUpdatedRecipients(newRecipients);
  };

  const setSigningGroups = (newGroups: IGroup[]) => {
    setGroups(newGroups);
    onChange(recipients, newGroups);
  };

  const createNewGroup = () => {
    const newGroup = {
      id: uuid(),
      name: 'Signing Group',
      order: groups.length,
    };
    setSigningGroups([...groups, newGroup]);
  };

  React.useEffect(() => {
    const newGroups = buildSigningGroupsFromRecipients(recipients);
    setGroups(newGroups);
    onChange(recipients, newGroups);
  }, [recipients]);

  const lastGroupEmpty =
    recipients.length === 0 ||
    groups.length === 0 ||
    recipients.filter((r) => r.group?.id === groups[groups.length - 1]?.id).length === 0;

  const filteredRecipients = recipients.filter((r) => (r.firstName || r.email) && r.isActive);

  return (
    <>
      <RecipientList
        recipients={updatedRecipients}
        incompletePlaceholders={props.incompletePlaceholders}
        optionalRecipients={props.optionalRecipients}
        signingGroups={groups}
        setRecipients={setRecipients}
        lastGroupEmpty={lastGroupEmpty}
        data-spec="recipientList"
        createPrimeContact={props.createPrimeContact}
        isPrimeUser={isPrimeUser}
        contacts={props.contacts}
        modal={modal}
        disableRecipientList={!!props.disableRecipientList}
        colorCounter={props.colorCounter}
      />
      <RecipientGroups
        recipients={filteredRecipients}
        signingGroups={groups}
        createNewGroup={createNewGroup}
        setRecipients={setRecipients}
        modal={modal}
        disableRecipientList={!!props.disableRecipientList}
      />
    </>
  );
};

export default RecipientManagement;
