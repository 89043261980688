import axios from 'axios';
import { API_URL } from '../../constants';
import { headers } from '../common/helper';
import { getAccessToken } from '../../../auth/oktaConfig';
import { IRecipient } from '../../../common/recipients/types';

export interface IResponse<T> {
  result?: T;
  success?: boolean;
  statusCode: number;
  errors?: any[];
}

export const getRecipients = async (envelopeId: string, allRecipients: boolean = false): Promise<IRecipient[]> => {
  const accessToken = await getAccessToken();
  const getAllRecipients = allRecipients ? '&allRecipients' : '';
  const { data } = await axios.get(
    `${API_URL}/envelopes/${envelopeId}/recipients?api-version=2.0${getAllRecipients}`,
    headers(accessToken)
  );
  return data;
};

export const updateRecipients = async (recipients: IRecipient[], envelopeId: string): Promise<IRecipient[]> => {
  const accessToken = await getAccessToken();
  const newRecipients = recipients.map((r) => {
    return {
      ...r,
      ...(r.group && { group: r.group!.order }),
      envelopeId: envelopeId,
    };
  });
  try {
    return await axios.put(
      `${API_URL}/envelopes/${envelopeId}/recipients?api-version=2.0`,
      newRecipients,
      headers(accessToken)
    );
  } catch ({ response }) {
    return response;
  }
};

export const updateRecipient = async (recipient: IRecipient, envelopeId: string): Promise<IRecipient> => {
  const accessToken = await getAccessToken();
  const updatedRecipient = {
    firstName: recipient.firstName,
    lastName: recipient.lastName,
    email: recipient.email,
    role: recipient.role,
    isActive: recipient.isActive,
    isPlaceHolder: recipient.isPlaceholder,
  };
  try {
    return await axios.patch(
      `${API_URL}/envelopes/${envelopeId}/recipients/${recipient.id}?api-version=2.0`,
      updatedRecipient,
      headers(accessToken)
    );
  } catch ({ response }) {
    return response;
  }
};
