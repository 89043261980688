import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Collapse from '@material-ui/core/Collapse';

interface IProps {
  children: any;
  disableCollapse?: boolean;
}

const CollapseOnScroll = (props: IProps) => {
  const trigger = useScrollTrigger({ threshold: 50 });
  if (props.disableCollapse) {
    return <>{props.children}</>;
  }
  return <Collapse in={!trigger}>{props.children}</Collapse>;
};

export default CollapseOnScroll;
