import React, { useRef, useState } from 'react';
import cx from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { colors } from '@skyslope/mache';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Label from '@material-ui/icons/Label';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { BLOCK_TYPE_KEYS, BLOCK_TYPES, ResourceNames, SHELF_WIDTH, chameleonElId } from '../../lib/constants';
import { IBlock, IBlocks, ISelectedBlocks, ISigner } from '../../store/senderBuild/types';
import { IRootState } from '../../store';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { DateFormatType, IDateFormat, ISettings } from '../../store/pageFrame/types';
import { hex2rgba } from '../../lib/utils';
import { isMediumScreenMediaQuery } from '../../lib/isSmallScreen';
import { withLaunchDarkly, LaunchDarklyFlags } from '../../common/launchDarkly';
import TimeStampPreferenceModal from './TimeStampPreferenceModal';
import * as pageFrameActions from '../../store/pageFrame/actions';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    top: '73px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${colors.grey[400]}`,
    borderTop: 'none',
    zIndex: 1000,
  },
  tab: {
    color: colors.grey[800],
    fontSize: 16,
    opacity: 1,
    minWidth: 120,
    borderRadius: 0,
    margin: 0,
    padding: '16px 12px 20px',
    textTransform: 'initial',
    fontWeight: 'normal',
    borderRight: `1px solid ${colors.grey[400]}`,
    '&:first-child': {
      borderLeft: `1px solid ${colors.grey[400]}`,
    },
    '&:hover:not($selected)': {
      backgroundColor: `${colors.grey[200]} !important`,
    },
  },
  tabs: {
    width: 'calc(100% - 24px)',
  },
  tabsIndicator: {
    display: 'none',
  },
  selected: {
    color: `${colors.grey[800]} !important`,
  },
  signer: {
    padding: '5px 25px 15px 25px',
    minWidth: SHELF_WIDTH - 51,
    maxWidth: SHELF_WIDTH - 51,
  },
  signerLabel: {
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  select: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  selectRoot: {
    paddingRight: 32,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: colors.grey[500],
    width: 28,
    height: 28,
  },
  menuInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  moreMenuItems: {
    '&:hover:not($selected)': {
      color: `${colors.blue[800]}`,
      backgroundColor: `${colors.blue[50]}`,
    },
  },
  collapsedMoreButton: {
    minWidth: '60px',
    maxWidth: '60px',
  },
  collapsedMenuIcon: {
    marginRight: theme.spacing(1),
    fontSize: '16px',
  },
  signerText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

interface IState {
  activeBlockType: string;
  activeSigner: string;
  signers: ISigner[];
  selectedBlocks: ISelectedBlocks;
  settings: ISettings;
  resource: ResourceNames;
  lastPlacedBlock: string;
  blocks: IBlocks;
  currentDocument: string;
  currentPage: number;
  dateFormat: IDateFormat;
}

const toolbarSelector = (state: IRootState) => ({
  activeBlockType: state.senderBuild.activeBlockType,
  activeSigner: state.senderBuild.activeSigner,
  signers: state.senderBuild.signers,
  selectedBlocks: state.senderBuild.selectedBlocks,
  settings: state.pageFrame.settings,
  resource: state.senderBuild.resource,
  lastPlacedBlock: state.senderBuild.lastPlacedBlock,
  blocks: state.senderBuild.blocks,
  currentDocument: state.senderBuild.currentDocument,
  currentPage: state.senderBuild.currentPage,
  dateFormat: state.pageFrame.settings?.user.dateFormat,
});

export const Toolbar = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const dispatch = useDispatch();
  const {
    activeBlockType,
    activeSigner,
    signers,
    selectedBlocks,
    settings,
    resource,
    lastPlacedBlock,
    blocks,
    currentDocument,
    currentPage,
    dateFormat,
  }: IState = useShallowSelector(toolbarSelector);
  const classes = useStyles();
  const [numberOfBlocksToDisplay, setNumberOfBlocksToDisplay] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeCollapsedBlock, setActiveCollapsedBlock] = useState<any>(null);
  const isMdScreen = isMediumScreenMediaQuery();

  const [showDatePrefenceModal, setShowDatePrefenceModal] = React.useState(false);

  const mediaQueries = [
    { active: useMediaQuery('(max-width:1330px) and (min-width: 1201px)'), blocksToShow: 6 },
    { active: useMediaQuery('(max-width:1200px) and (min-width: 1140px)'), blocksToShow: 5 },
    { active: useMediaQuery('(max-width:1139px) and (min-width: 1024px)'), blocksToShow: 4 },
  ];

  const numberOfBlocksToShow = mediaQueries.find((q) => q.active)?.blocksToShow;
  const blockTypeArray = BLOCK_TYPES.slice(1);
  const currentSigner =
    activeSigner === 'default'
      ? (signers.find((signer: ISigner) => signer.signerId === '1') as ISigner)
      : signers.find((signer: ISigner) => signer.signerId === activeSigner)!;

  const filteredSigners = signers.filter((signer) => signer.signingGroup !== '');

  const disabledBlocks =
    settings.account && settings.account[resource] ? settings.account[resource]!.blockTypes!.disabled : [];

  if (!flags.SHOW_TIME_BLOCK) {
    disabledBlocks.push(BLOCK_TYPE_KEYS.TIME);
  }
  const showDateFormatPreference = flags && flags['enable-date-stamping-preferences'];

  // TODO: this bit ` && blockType.key !== 'Rectangle'` should be behind a flag in the future
  const blocksToDisplay = blockTypeArray.filter(
    (blockType) => !disabledBlocks.includes(blockType.key) && blockType.key !== 'Rectangle'
  );
  const blocksToShow = numberOfBlocksToShow ? blocksToDisplay.slice(0, numberOfBlocksToShow) : blocksToDisplay;
  const blocksToCollapse = numberOfBlocksToShow
    ? blocksToDisplay.slice(numberOfBlocksToShow, blocksToDisplay.length)
    : [];
  if (blocksToCollapse.find((b) => b.key === lastPlacedBlock) && (activeBlockType === '' || activeBlockType === null)) {
    const blockTab = blocksToCollapse.find((b) => b.key === lastPlacedBlock);
    blocksToShow.push(blockTab!);
  }
  if (blocksToDisplay.length !== numberOfBlocksToDisplay) {
    setNumberOfBlocksToDisplay(blocksToDisplay.length);
  }
  const selectSigner = (e: any) => {
    dispatch(actions.changeActiveSigner(e.target.value as string));
    dispatch(actions.selectBlocks(currentDocument, currentPage, []));
  };

  const moreButtonRef = useRef(null);
  const handleMoreButtonClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMoreMenuClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (type: any) => {
    setAnchorEl(null);
    setActiveCollapsedBlock(type);
  };

  const lastPlacedBlockFromMoreMenu =
    (activeBlockType === null || activeBlockType === '') && blocksToCollapse.find((b) => b.key === lastPlacedBlock);
  const moreButtonRootClass =
    activeCollapsedBlock || lastPlacedBlockFromMoreMenu ? cx([classes.tab, classes.collapsedMoreButton]) : classes.tab;
  window.onresize = handleMoreMenuClose;

  const isDisabled = () => {
    if (selectedBlocks.blockIndices.length === 1) {
      const block: IBlock =
        blocks[selectedBlocks.documentId!][selectedBlocks.pageIndex!][selectedBlocks.blockIndices[0]];
      if (block.groupId || block.readOnly) {
        return true;
      }
      return false;
    }
    return false;
  };

  if (isMdScreen) {
    return '';
  }
  const updateDateFormat = (val: string) => {
    dispatch(
      pageFrameActions.updateUserSettings({
        dateFormat: {
          preference: val,
          selectionModalViewed: true,
        },
      })
    );
    setShowDatePrefenceModal(!showDatePrefenceModal);
  };
  return (
    <div className={classes.wrapper} style={{ top: isMdScreen ? '63px' : '' }}>
      <FormControl className={classes.signer} id={chameleonElId(3)}>
        <InputLabel className={classes.signerLabel}>Signer</InputLabel>
        <Select
          variant="filled"
          value={activeSigner}
          onChange={(e) => selectSigner(e)}
          disabled={isDisabled()}
          classes={{ root: classes.selectRoot, select: classes.select }}
          data-spec="signerDropdown"
        >
          {filteredSigners.map((signer: ISigner) => (
            <MenuItem key={signer.signerId} value={signer.signerId}>
              <div className={classes.menuInnerWrapper}>
                <Label className={classes.icon} style={{ color: signer.color }} />
                <span className={classes.signerText}>
                  {signer.firstName} {signer.lastName}
                </span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showDateFormatPreference && showDatePrefenceModal && (
        <TimeStampPreferenceModal updateDateFormat={updateDateFormat} initialSelection={dateFormat.preference} />
      )}
      {!isMdScreen && (
        <Tabs
          classes={{ root: classes.tabs, indicator: classes.tabsIndicator }}
          value={activeBlockType || false}
          variant="fullWidth"
          scrollButtons="off"
          data-spec="blockTabs"
          id={chameleonElId(4)}
          style={{ display: isMdScreen ? 'none' : '' }}
        >
          {blocksToShow.map((type, i) => {
            const selected = activeBlockType === type.key;
            const backgroundColor = selected ? hex2rgba(currentSigner.color!, 0.1) : 'inherit';
            const boxShadow = selected ? `0 3px 0 0 ${currentSigner.color} inset` : 'none';

            const handleClickBlock = (e: any) => {
              dispatch(actions.changeActiveBlockType(type.key));
              const isDateOrSignature = type.key === BLOCK_TYPE_KEYS.DATE || type.key === BLOCK_TYPE_KEYS.SIGNATURE;
              const isTheCurrentBlockTypeActive = activeBlockType !== type.key;
              if (isDateOrSignature && isTheCurrentBlockTypeActive && !dateFormat.selectionModalViewed && dateFormat.preference === DateFormatType.Default) {
                setShowDatePrefenceModal(true);
              } else {
                setShowDatePrefenceModal(false);
              }
            };

            return (
              <Tab
                key={type.key}
                style={{ backgroundColor, boxShadow }}
                classes={{ root: classes.tab, selected: classes.selected }}
                icon={type.icon}
                label={type.name}
                value={type.key}
                disableRipple
                data-spec={`block-tab-${type.key}`}
                tabIndex={0}
                onClick={handleClickBlock}
              />
            );
          })}
          {activeCollapsedBlock && !blocksToShow.includes(activeCollapsedBlock) ? (
            <Tab
              key={activeCollapsedBlock.key}
              style={{
                backgroundColor:
                  activeBlockType === activeCollapsedBlock!.key ? hex2rgba(currentSigner.color!, 0.1) : 'inherit',
                boxShadow: activeBlockType === activeCollapsedBlock.key ? `0 3px 0 0 ${currentSigner.color} inset` : '',
              }}
              classes={{ root: classes.tab, selected: classes.selected }}
              icon={activeCollapsedBlock.icon}
              label={activeCollapsedBlock.name}
              value={activeCollapsedBlock.key}
              disableRipple
              data-spec={`block-tab-${activeCollapsedBlock.key}`}
              onClick={() => dispatch(actions.changeActiveBlockType(activeCollapsedBlock.key))}
              tabIndex={0}
            />
          ) : (
            ''
          )}
          {numberOfBlocksToShow
            ? [
                <Tab
                  key="more"
                  classes={{ root: moreButtonRootClass, selected: classes.selected }}
                  icon={activeCollapsedBlock || lastPlacedBlockFromMoreMenu ? <MoreVertIcon /> : <MoreHorizIcon />}
                  label={activeCollapsedBlock || lastPlacedBlockFromMoreMenu ? <ArrowDropDownIcon /> : 'More'}
                  disableRipple
                  ref={moreButtonRef}
                  onClick={handleMoreButtonClick}
                  data-spec="moreButton"
                  tabIndex={0}
                />,
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={handleMoreMenuClose}
                  keepMounted
                  key="menu"
                >
                  {blocksToCollapse.map((type, i) => {
                    if (
                      (type.key !== lastPlacedBlock && activeBlockType !== type.key) ||
                      activeCollapsedBlock !== type
                    ) {
                      return (
                        <MenuItem
                          className={classes.moreMenuItems}
                          onClick={() => {
                            dispatch(actions.changeActiveBlockType(type.key));
                            handleMoreMenuClose();
                            handleItemClick(type);
                          }}
                          tabIndex={0}
                          key={i}
                          data-spec={`more-menu-block-tab-${type.key}`}
                        >
                          <SvgIcon className={classes.collapsedMenuIcon}>{type.icon}</SvgIcon>
                          {type.name}
                        </MenuItem>
                      );
                    }
                  })}
                </Menu>,
              ]
            : ''}
        </Tabs>
      )}
    </div>
  );
};

export default withLaunchDarkly(Toolbar);
