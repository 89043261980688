import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoading } from '../pageFrame/actions';
import * as actions from './actions';
import { ITemplateResponse } from '../../lib/api/template';
import { ITemplateRecipient, ITemplate } from './types';
import { IDocument } from '../senderBuild/types';
import { IRootState } from '../index';
import * as templateApi from '../../lib/api/template';
import { progressPercentageForDocuments } from '../../lib/api/common/progressPercentage';

const ActionTypes = actions.ActionTypes;

const getTemplateId = (state: IRootState) =>
  state.templateCreate.template ? state.templateCreate.template.id : undefined;

export function* handleFetchTemplate(action: ReturnType<typeof actions.fetchTemplate>) {
  try {
    yield put(setLoading(true));
    const res = yield call(templateApi.getTemplate, action.payload);
    const template = res.result;
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Template GET: ${res.status}`);
    }
    if (!template) {
      window.location.pathname = '/notfound';
    } else {
      template.documents = template.documents.map((doc: { id: string; name: string; numPages: number }) => {
        const { id, name, numPages } = doc;
        const transformedDocument = {
          documentId: id,
          fileName: name,
          numberOfPages: numPages,
          ...doc,
        };
        return transformedDocument;
      });

      yield put(actions.fetchTemplateDone(template!));
    }
  } catch (err) {
    yield put(actions.apiError('Error fetching Template'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleUpdateTemplate(action: ReturnType<typeof actions.updateTemplate>) {
  try {
    const templateId = yield select(getTemplateId);
    const res: ITemplateResponse<ITemplate> = yield call(templateApi.updateTemplate, templateId, action.payload);
    const template = res.result;
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Template PATCH: ${res.status}`);
    }
    yield put(actions.updateTemplateDone(template!));
  } catch (err) {
    yield put(actions.apiError('Error updating Template'));
  }
}

export function* handleUploadDocument(action: ReturnType<typeof actions.uploadDocument>) {
  const { tempId: temporaryDocumentId, file } = action.payload;
  try {
    const templateId = yield select(getTemplateId);
    const res = yield call(templateApi.uploadDocumentToTemplate, templateId, file, temporaryDocumentId);
    const document = res.result;
    if (res.status !== 201) {
      throw Error(`Non-201 Status Code of Template Document POST: ${res.status}`);
    }

    const { id, links, name, numPages, order } = document;
    const transformedDocument = {
      id,
      links,
      name,
      numPages,
      order,
      ...document,
    };

    yield put(actions.uploadDocumentDone(action.payload.tempId, transformedDocument!));
  } catch (err) {
    yield put(actions.apiError('Error uploading Document'));
    progressPercentageForDocuments[temporaryDocumentId] = {
      ...progressPercentageForDocuments[temporaryDocumentId],
      error: 'A problem occured, please try uploading again',
    };
  }
}

export function* handleUpdateRecipient(action: ReturnType<typeof actions.updateRecipient>) {
  try {
    const templateId = yield select(getTemplateId);
    const recipient = yield select((state) =>
      state.templateCreate.template!.recipients.find((r: ITemplateRecipient) => r.id === action.payload.recipientId)
    )!;
    let res: ITemplateResponse<ITemplateRecipient>;
    if (recipient.isNotCreated) {
      const fullRecipient = { ...recipient, ...action.payload.update };
      delete fullRecipient.isNotCreated;
      res = yield call(templateApi.addRecipientToTemplate, templateId, fullRecipient);
      if (res.status !== 201) {
        throw Error(`Non-201 Status Code of Template Recipient POST: ${res.status}`);
      }
    } else {
      res = yield call(
        templateApi.updateTemplateRecipient,
        templateId,
        action.payload.recipientId,
        action.payload.update
      );
      if (res.status !== 200) {
        throw Error(`Non-200 Status Code of Template Recipient PATCH: ${res.status}`);
      }
    }
    yield put(actions.updateRecipientDone(action.payload.recipientId, res.result!));
  } catch (err) {
    yield put(actions.apiError('Error updating recipient'));
  }
}

export function* handleDeleteDocument(action: ReturnType<typeof actions.deleteDocument>) {
  try {
    const templateId = yield select(getTemplateId);
    const { status }: ITemplateResponse<null> = yield call(templateApi.deleteDocument, templateId, action.payload);
    if (status !== 204) {
      throw Error(`Non-204 Status Code of Template Document DELETE: ${status}`);
    }
  } catch (err) {
    yield put(actions.apiError('Error deleting Document'));
  }
}

export function* handleReorderDocuments(action: ReturnType<typeof actions.reorderDocuments>) {
  try {
    const documentIds = action.payload.map((document: IDocument) => document.documentId!);

    const templateId = yield select(getTemplateId);
    const res: ITemplateResponse<ITemplate> = yield call(
      templateApi.reorderDocumentsInTemplate,
      templateId,
      documentIds
    );
    const template = res.result;
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Template PATCH: ${res.status}`);
    }
    yield put(actions.updateTemplateDone(template!));
  } catch (err) {
    yield put(actions.apiError('Error updating Template'));
  }
}

export function* handleDeleteRecipient(action: ReturnType<typeof actions.deleteRecipient>) {
  try {
    const { templateId, recipient } = action.payload;
    if (recipient.recipientTitle) {
      const { status } = yield call(templateApi.deleteRecipient, templateId, recipient.id);

      if (status !== 204) {
        throw Error(`Expected 204 when deleting a recipient, but received ${status}`);
      }
    }
    yield put(actions.deleteRecipientDone(recipient.id));
  } catch (err) {
    yield put(actions.apiError('Error deleting recipient'));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH_TEMPLATE, handleFetchTemplate),
    takeEvery(ActionTypes.UPDATE_TEMPLATE, handleUpdateTemplate),
    takeEvery(ActionTypes.UPLOAD_DOCUMENT, handleUploadDocument),
    takeEvery(ActionTypes.UPDATE_RECIPIENT, handleUpdateRecipient),
    takeEvery(ActionTypes.DELETE_DOCUMENT, handleDeleteDocument),
    takeEvery(ActionTypes.REORDER_DOCUMENTS, handleReorderDocuments),
    takeEvery(ActionTypes.DELETE_RECIPIENT, handleDeleteRecipient),
  ]);
}
