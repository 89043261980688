import { action } from 'typesafe-actions';
import {
  IApplyTemplateState,
  IBlockWithSignerNumber,
  ITemplateAppliedFromDynamoDB,
  ITemplatesAppliedFromMongoDB,
  ITemplateMetadata,
  IDocumentMapping,
} from './types';
import { IEnvelope, IPageDimensions, ITemplateQueryParams } from '../senderBuild/types';

export enum ActionTypes {
  RESET = 'applyTemplate/RESET',
  FETCH_ENVELOPE = 'applyTemplate/FETCH_ENVELOPE',
  FETCH_ENVELOPE_DONE = 'applyTemplate/FETCH_ENVELOPE_DONE',
  FETCH_TEMPLATES = 'applyTemplate/FETCH_TEMPLATES',
  FETCH_TEMPLATES_DONE = 'applyTemplate/FETCH_TEMPLATES_DONE',
  SELECT_DOCUMENT = 'applyTemplate/SELECT_DOCUMENT',
  SELECT_PAGE = 'applyTemplate/SELECT_PAGE',
  SHOW_TEMPLATES_SECTION = 'applyTemplate/SHOW_TEMPLATES_SECTION',
  PREVIEW_TEMPLATE = 'applyTemplate/PREVIEW_TEMPLATE',
  PREVIEW_TEMPLATE_DONE = 'applyTemplate/PREVIEW_TEMPLATE_DONE',
  FINALIZE = 'applyTemplate/FINALIZE',
  FINALIZE_DONE = 'applyTemplate/FINALIZE_DONE',
  FINALIZE_BULK_TEMPLATES = 'applyTemplate/FINALIZE_BULK_TEMPLATES',
  FINALIZE_BULK_TEMPLATES_DONE = 'applyTemplate/FINALIZE_BULK_TEMPLATES_DONE',
  FETCH_TEMPLATES_APPLIED = 'applyTemplate/FETCH_TEMPLATES_APPLIED',
  FETCH_TEMPLATES_APPLIED_DONE = 'applyTemplate/FETCH_TEMPLATES_APPLIED_DONE',
  FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS = 'applyTemplate/FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS',
  FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS_DONE = 'applyTemplate/FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS_DONE',
  UPDATE_DOCUMENT_MAP = 'applyTemplate/UPDATE_DOCUMENT_MAP',
}

export const reset = (newStore?: IApplyTemplateState) => action(ActionTypes.RESET, newStore);
export const fetchEnvelope = (id: string) => action(ActionTypes.FETCH_ENVELOPE, id);
export const fetchEnvelopeDone = (envelope: IEnvelope, pageDimensions?: IPageDimensions) =>
  action(ActionTypes.FETCH_ENVELOPE_DONE, { envelope, pageDimensions });
export const fetchTemplates = (templateQueryParams?: ITemplateQueryParams, fetchShares?: boolean) =>
  action(ActionTypes.FETCH_TEMPLATES, { templateQueryParams, fetchShares });
export const fetchTemplatesDone = (templates: ITemplateMetadata[]) =>
  action(ActionTypes.FETCH_TEMPLATES_DONE, templates);
export const selectDocument = (documentId: string) => action(ActionTypes.SELECT_DOCUMENT, documentId);
export const selectPage = (pageIndex: number) => action(ActionTypes.SELECT_PAGE, pageIndex);
export const showTemplatesSection = () => action(ActionTypes.SHOW_TEMPLATES_SECTION);
export const previewTemplate = (templateId?: string) => action(ActionTypes.PREVIEW_TEMPLATE, templateId);
export const previewTemplateDone = (blocks: IBlockWithSignerNumber[]) =>
  action(ActionTypes.PREVIEW_TEMPLATE_DONE, blocks);
export const finalize = (templateId: string, envelopeId: string, data: { documentId: string }) =>
  action(ActionTypes.FINALIZE, { templateId, envelopeId, data });
export const finalizeDone = () => action(ActionTypes.FINALIZE_DONE);
export const finalizeBulkTemplates = (envelopeId: string, templatesList: IDocumentMapping[]) =>
  action(ActionTypes.FINALIZE_BULK_TEMPLATES, { envelopeId, templatesList });
export const finalizeBulkTemplatesDone = () => action(ActionTypes.FINALIZE_BULK_TEMPLATES_DONE);
export const fetchTemplatesApplied = (envelopeId: string, useMongoDBModel: boolean) =>
  action(ActionTypes.FETCH_TEMPLATES_APPLIED, { envelopeId, useMongoDBModel });
export const fetchTemplatesAppliedDone = (
  templates: ITemplatesAppliedFromMongoDB | ITemplateAppliedFromDynamoDB[],
  useMongoDBModel: boolean
) => action(ActionTypes.FETCH_TEMPLATES_APPLIED_DONE, { templates, useMongoDBModel });
export const fetchTemplatesAppliedForDocuments = (envelopeId: string) =>
  action(ActionTypes.FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS, envelopeId);
export const fetchTemplatesAppliedForDocumentsDone = (
  templates: ITemplatesAppliedFromMongoDB | ITemplateAppliedFromDynamoDB[]
) => action(ActionTypes.FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS_DONE, templates);
export const updateDocumentMap = (documentId: string, templatesList: IDocumentMapping[]) =>
  action(ActionTypes.UPDATE_DOCUMENT_MAP, { documentId, templatesList });
