import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { colors } from '@skyslope/mache';
import Typography from '@material-ui/core/Typography';
import Footer from '../components/common/Footer';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import { IRootState } from '../store';
import * as actions from '../store/envelopeDocuments/actions';
import * as templateActions from '../store/applyTemplate/actions';
import { IEnvelope, IDocument, DocumentStatus } from '../store/senderBuild/types';
import { errorToast } from '../store/pageFrame/actions';
import { progressPercentageForDocuments } from '../lib/api/common/progressPercentage';
import { HeaderContext } from '../context/header-context';
import FooterLinks from '../components/common/FooterLinks';
import { chameleonElId, preferencesUrl } from '../lib/constants';
import { ISettings } from '../store/pageFrame/types';
import { IDocumentMapping } from '../store/applyTemplate/types';
import { getUsersGroups } from '../lib/api/account/accountApi';
import NavHeader from '../common/navigation';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';
import Documents from '../common/documents';
import { IDoc } from '../common/documents/types';
import useUserInfo from '../auth/useUserInfo';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 68,
    top: 0,
    overflow: 'auto',
  },
  nextButton: {
    padding: '10px 40px',
  },
  title: {
    marginBottom: 40,
    marginTop: 80,
    textAlign: 'center',
  },
  paper: {
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 1200,
    minHeight: 600,
    width: '100%',
    boxShadow: 'none',
    border: `1px solid ${colors.grey[400]}`,
    '@media(max-width: 1280px)': {
      width: '95%',
    },
  },
  centerHeader: {
    justifyContent: 'center',
  },
  documents: {
    overflow: 'auto',
    marginBottom: theme.spacing(8),
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    width: 'calc(100% - 64px)',
  },
  skyslopeFooter: {
    position: 'absolute',
    bottom: -48,
    left: 0,
    right: 0,
  },
}));

interface IState {
  envelope: IEnvelope;
  settings: ISettings;
  settingsFetched: boolean;
  documentMapping: IDocumentMapping[];
  fetchedAppliedTemplatesForDocuments: boolean;
  updatingTemplateApplication: boolean;
}

const selector = (state: IRootState) => ({
  envelope: state.envelopeDocuments.envelope,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  documentMapping: state.applyTemplate.documentMapping,
  fetchedAppliedTemplatesForDocuments: state.applyTemplate.fetchedAppliedTemplatesForDocuments,
  updatingTemplateApplication: state.applyTemplate.updatingTemplateApplication,
});

const EnvelopeDocuments = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerContext = React.useContext(HeaderContext);
  const userInfo = useUserInfo();
  const areTemplatesEnabled = flags && flags['enable-templates-access'];
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const useMongoDBModel = flags && flags['enable-new-apply-templates-logic'];

  const sleep = (n: number) => new Promise((res) => setTimeout(res, n));
  const envelopeFetched = !!store.envelope;
  useEffect(() => {
    if (store.settingsFetched) {
      headerContext.setContent(
        <NavHeader
          labelName="Add documents"
          breadcrumb={{ name: 'Envelope Management', onClick: () => history.push('/envelopes') }}
          progressBarPercent={25}
          hideNavItems={hideNavItems}
          showBreezeApp={showBreezeApp}
          preferencesUrl={preferencesUrl}
          showPreferences={showPreferencesMenuItem}
          userOrigin={store.settings?.account.userOrigin}
          getUsersGroups={getUsersGroups}
          flags={flags}
        />
      );
      if (!envelopeFetched) {
        dispatch(actions.fetchEnvelope(params.id));
      }
    }
    return () => {
      headerContext.setContent(null);
      dispatch(actions.reset());
    };
  }, [store.settingsFetched]);

  useEffect(() => {
    if (!store.fetchedAppliedTemplatesForDocuments && userInfo?.provisioned) {
      dispatch(templateActions.fetchTemplatesAppliedForDocuments(params.id));
    }
  }, [store.fetchedAppliedTemplatesForDocuments, userInfo?.provisioned]);

  useEffect(() => {
    const handleFetchEnvelope = async () => {
      await sleep(1000);
      dispatch(actions.fetchEnvelope(params.id));
    };
    const areDocumentsUploading =
      store.envelope && store.envelope.documents.some((doc) => doc.uploadStatus === DocumentStatus.Uploading);
    if (areDocumentsUploading && store.envelope.documents.length) {
      handleFetchEnvelope();
    }
  }, [store.envelope]);

  if (!envelopeFetched) {
    return null;
  }

  const handleUploadDocument = (tempId: string, file: File) => {
    dispatch(actions.uploadDocument(tempId, file));
  };

  const handleReorderDocuments = (documents: IDocument[]) => {
    dispatch(actions.reorderDocuments(documents));
  };

  const handleDeleteDocument = (documentId: string) => {
    const appliedTemplate = store.documentMapping?.find((d) => d.envelopeDocumentId === documentId);
    if (appliedTemplate) {
      if (!useMongoDBModel) {
        dispatch(templateActions.finalize(appliedTemplate.envelopeDocumentId, params.id, { documentId }));
      } else {
        const filteredDocuments = store.documentMapping.filter((d) => d.envelopeDocumentId != documentId);
        dispatch(templateActions.finalizeBulkTemplates(params.id, filteredDocuments));
      }
    }
    dispatch(templateActions.updateDocumentMap(documentId, store.documentMapping));
    dispatch(actions.deleteDocument(documentId));
  };

  const handleUpdateDocuments = (documents: IDocument[]) => {
    dispatch(actions.updateDocuments(documents));
  };

  const handleError = (errStr: string) => {
    dispatch(errorToast(errStr));
  };

  const footer = (
    <Footer>
      {hideNavItems ? (
        ''
      ) : (
        <Button color="primary" data-spec="exit" onClick={() => history.push('/envelopes')}>
          Exit
        </Button>
      )}
      {areTemplatesEnabled ? (
        <Button
          id={chameleonElId(20)}
          color="primary"
          onClick={() => history.push(`/envelopes/${params.id}/apply-template`)}
          disabled={store.envelope.documents.length === 0 || store.envelope.documents.some((d) => d.isUploading)}
        >
          Apply Template
        </Button>
      ) : (
        ''
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push(`/envelopes/${params.id}/recipients`)}
        className={classes.nextButton}
        disabled={
          store.envelope.documents.length === 0 ||
          store.envelope.documents.some((d) => d.isUploading) ||
          store.envelope.documents.some(
            (d) => d.uploadStatus === DocumentStatus.Uploading || d.uploadStatus === DocumentStatus.Failed
          )
        }
        data-spec="next-button"
      >
        Next
      </Button>
    </Footer>
  );

  return (
    <div className={classes.wrapper}>
      <Typography variant="h3" className={classes.title}>
        Upload your documents to get started
      </Typography>
      <Paper className={classes.paper}>
        <Documents
          documents={store.envelope.documents as IDoc[]}
          handleUploadDocument={handleUploadDocument}
          handleUpdateDocuments={handleUpdateDocuments}
          handleReorderDocuments={handleReorderDocuments}
          handleDeleteDocument={handleDeleteDocument}
          handleError={handleError}
          progressPercentageForDocuments={progressPercentageForDocuments}
          rootClassName={store.envelope.documents.length === 0 ? classes.centerHeader : ''}
          documentsClassName={classes.documents}
          disableDelete={store.updatingTemplateApplication || !store.fetchedAppliedTemplatesForDocuments}
        />
        {footer}
        <div className={classes.skyslopeFooter}>
          <FooterLinks showSocialIcons={false} customMarginTop="0px" />
        </div>
      </Paper>
    </div>
  );
};

export default withLaunchDarkly(EnvelopeDocuments);
