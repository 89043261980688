import { colors } from '@skyslope/mache';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import ListItem from '@material-ui/core/ListItem';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { validateInput } from '@digisign3-ui/utils';
import { IGroup, IRecipient, RecipientRole } from '../types';
import { ReactComponent as NeedsToSignIcon } from '../../../images/need_to_sign.svg';
import { ReactComponent as ReceiveCopyIcon } from '../../../images/receive_copy.svg';
import RecipientTextField from './RecipientTextField';
import { chameleonElId, checkIfRecipientIsSender } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(4),
  },
  addIcon: {
    height: 20,
    width: 20,
    margin: '-4px 4px -4px -4px',
  },
  recipient: {
    backgroundColor: 'white',
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    border: `1px solid ${colors.grey[400]}`,
    marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'right',
  },
  recipientAddForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  twoColumns: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '1fr 1fr',
  },
  twoRows: {
    display: 'grid',
  },
  label: {
    width: 'auto',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginTop: theme.spacing(2),
  },
  icon: {
    paddingRight: theme.spacing(3),
    height: 24,
  },
}));

type Props = {
  recipient: IRecipient | undefined;
  signingGroups: IGroup[];
  returnToRecipientList: () => void;
  saveRecipient: (recipient: IRecipient, isNew: boolean) => void;
  recipients: IRecipient[];
  lastGroupEmpty: boolean;
  contacts: any[];
  modal: boolean;
};

const RecipientAddOrEdit = (props: Props) => {
  const classes = useStyles();
  const { recipient, modal } = props;
  const [disabled, setDisabled] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<RecipientRole>(RecipientRole.Signer);
  const [signingGroupId, setSigningGroupId] = useState(props.signingGroups[0]?.id || '');
  const [error, setError] = useState({
    firstname: false,
    lastname: false,
    email: false,
    role: false,
  });

  let autofocus = 'firstname';
  if (recipient)
    autofocus = !recipient.firstName
      ? 'firstname'
      : !recipient.lastName
      ? 'lastname'
      : !recipient.email
      ? 'email'
      : 'firstname';

  const fieldsToRender = [
    {
      type: 'firstname',
      label: 'First Name',
      helperText: 'Please enter a first name.',
      handleOnChange: (str) => setFirstname(str),
      state: firstname,
    },
    {
      type: 'lastname',
      label: 'Last Name',
      helperText: 'Please enter a last name.',
      handleOnChange: (str) => setLastname(str),
      state: lastname,
    },
    {
      type: 'email',
      label: 'Email',
      helperText: 'Email must be a valid email.',
      handleOnChange: (str) => setEmail(str),
      state: email,
    },
  ];

  useEffect(() => {
    if (recipient) {
      setFirstname(recipient.firstName);
      setLastname(recipient.lastName);
      setEmail(recipient.email?.trim() || '');
      setRole(recipient.role);
      setSigningGroupId(recipient.group?.id || props.signingGroups[0]?.id || '');
      if (checkIfRecipientIsSender(recipient)) {
        setDisabled(true);
      }
    }
  }, [recipient]);

  const setStateValue = (e: any, callback: React.Dispatch<React.SetStateAction<any>>) => {
    callback(e.target.value);
  };

  const validateFormInput = (e: any, str: string) => {
    return setError({ ...error, [str]: validateInput(e, str) });
  };

  const handleSave = () => {
    let newRecipient: IRecipient;
    let group;
    if (role === RecipientRole.Signer) {
      if (signingGroupId === 'new') {
        group = {
          id: uuid(),
          name: 'Signing Group',
          order: props.signingGroups.length,
        };
      } else {
        group = props.signingGroups.find((sg) => sg.id === signingGroupId);
      }
    }
    if (!recipient) {
      newRecipient = {
        email: email.trim(),
        firstName: firstname,
        lastName: lastname,
        color: '',
        role,
        id: uuid(),
        group,
        isPlaceholder: false,
      };
    } else {
      newRecipient = {
        ...recipient,
        firstName: firstname,
        lastName: lastname,
        email: email?.trim(),
        role,
        group,
      };
    }
    clearForm();
    props.saveRecipient(newRecipient, !recipient);
    props.returnToRecipientList();
  };

  const cancelEditWrapper = () => {
    clearForm();
    props.returnToRecipientList();
  };

  const clearForm = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setRole(RecipientRole.Signer);
    setSigningGroupId('');
  };

  const isValidInput = (str: string, attr: string) => {
    if (str.trim().length !== 0) {
      error[attr] = false;
      return true;
    }
    error[attr] = true;
    return false;
  };

  const areThereAnyErrors = () => {
    if (
      email?.length <= 0 ||
      email === null ||
      firstname?.length <= 0 ||
      firstname === null ||
      lastname?.length <= 0 ||
      lastname === null
    ) {
      return true;
    }
    return Object.values(error).includes(true);
  };

  const handleChange = (field: any, e: any) => {
    field.handleOnChange(e.target.value);
    validateFormInput(e.target.value, field.type);
  };

  const handleContactSelect = (e: any, queryType: string) => {
    if (e) {
      setFirstname(e.firstName);
      setLastname(e.lastName);
      setEmail(e.email);
      validateFormInput(e.firstName, 'firstname');
      validateFormInput(e.lastName, 'lastname');
      validateFormInput(e.email, 'email');
    } else if (queryType === 'firstName') {
      setFirstname('');
    } else if (queryType === 'lastName') {
      setLastname('');
    } else {
      setEmail('');
    }
  };

  return (
    <div className={classes.wrapper} data-spec="add-recipient-form">
      <Typography variant="overline">NEW RECIPIENT DETAILS</Typography>
      <div className={classes.recipientAddForm}>
        {fieldsToRender.map((field) => (
          <RecipientTextField
            disabled={disabled}
            autoFocus={field.type === autofocus}
            label={field.label}
            helperText={error[field.type] && field.helperText}
            placeholder={field.label}
            handleOnChange={(e) => {
              handleChange(field, e);
            }}
            error={error[field.type]}
            value={field.state}
            dataSpec={`${field.type}-new`}
            contacts={props.contacts}
            handleContactSelect={handleContactSelect}
          />
        ))}
        <div className={classes.twoColumns}>
          <div className={classes.twoRows}>
            <InputLabel className={classes.label} id={chameleonElId(12)}>
              Action Required
            </InputLabel>
            <Select
              classes={{ select: classes.select }}
              variant="filled"
              displayEmpty
              onChange={(e) => setStateValue(e, setRole)}
              value={role}
              required
              error={isValidInput(role, 'role')}
              disabled={recipient?.isPlaceholder && modal}
            >
              <ListItem value={RecipientRole.Signer}>
                <NeedsToSignIcon className={classes.icon} />
                Needs to sign
              </ListItem>
              <ListItem value={RecipientRole.CarbonCopy}>
                <ReceiveCopyIcon className={classes.icon} />
                Receives a copy
              </ListItem>
            </Select>
          </div>
          <div className={classes.twoRows}>
            <InputLabel className={classes.label}>Signing Group</InputLabel>
            <Select
              variant="filled"
              displayEmpty
              onChange={(e) => setStateValue(e, setSigningGroupId)}
              value={role === RecipientRole.Signer ? signingGroupId : ''}
              disabled={role !== RecipientRole.Signer || !props.recipients.some((r) => r.role === RecipientRole.Signer)}
              required
            >
              {props.signingGroups.map((sg, i) => (
                <ListItem key={sg.id} value={sg.id}>
                  {i + 1}
                </ListItem>
              ))}
              {props.signingGroups.length < 4 &&
              !props.lastGroupEmpty &&
              props.recipients.length > props.signingGroups.length ? (
                <ListItem value="new">{props.signingGroups.length + 1}</ListItem>
              ) : (
                ''
              )}
            </Select>
          </div>
        </div>
        {!!recipient?.title ? (
          <div className={classes.twoRows} style={{ marginTop: '16px' }}>
            <InputLabel className={classes.label}>Role</InputLabel>
            <Select disabled value={recipient.title}>
              <ListItem value={recipient.title}>{recipient.title}</ListItem>
            </Select>
          </div>
        ) : (
          ''
        )}
        <div className={classes.buttonWrapper}>
          <Button
            data-spec="cancel"
            variant="text"
            style={{ marginRight: '24px' }}
            color="primary"
            onClick={cancelEditWrapper}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            data-spec="saveButton"
            disabled={areThereAnyErrors()}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecipientAddOrEdit;
