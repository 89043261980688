import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from '../common/Spinner';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1000,
  },
}));

const SavingOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Spinner />
    </div>
  );
};

export default SavingOverlay;
