import React, { useEffect, useState } from 'react';
import { useAuth } from '@skyslope/auth-react';
import { getUserManager } from '../auth/oktaConfig';
import { logoutCleanup } from '../auth';

const Logout = () => {
  const { authState } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    if (authState.isAuthenticated && !isLoggingOut) {
      setIsLoggingOut(true);      
      logoutCleanup();
      const um = getUserManager();
      um.startLogout();
    }
  }, [authState, isLoggingOut]);

  return <div>Logging out...</div>;
};

export default Logout;
