import React from 'react';
import { FormControl, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import BlockToolbar from '../../common/BlockToolbar';
import { IBlock, ISigner } from '../../../store/senderBuild/types';
import { Label } from '@material-ui/icons';
import { colors } from '@skyslope/mache';
import { BLOCK_TYPE_KEYS } from '../../../lib/constants';
import { generateFullName } from '../../../lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    borderRadius: '5px',
  },
  formControl: {
    borderRadius: '5px',
  },
}));

interface IProps {
  blocks: IBlock[];
  isDisabled: boolean;
  selectSigner: (e: any) => void;
  signers: ISigner[];
  blockOptions?: any;
}

const SignerBlockToolbar = (props: IProps) => {
  const { blocks, isDisabled, selectSigner, signers } = props;
  const classes = useStyles();
  const multipleBlocks = blocks?.length > 1;
  const assignableBlocks = blocks?.filter((block) => block.assignedTo);
  const blocksAllHaveSameSigner = assignableBlocks?.every(
    (block) => block.assignedTo === assignableBlocks[0].assignedTo
  );
  const blocksAreAllReadOnlyOrStrike = blocks?.every(
    (block) => block.readOnly || [BLOCK_TYPE_KEYS.STRIKE, BLOCK_TYPE_KEYS.RECTANGLE].includes(block.blockType)
  );

  const MenuLabel = (name: string, color: string) => {
    return (
      <div className="items-center flex name-label">
        <Label className="h-7 w-7 mr-2" style={{ color: color }} />
        <span className="overflow-hidden text-ellipsis" style={{ color: colors.grey[800] }}>
          {name}
        </span>
      </div>
    );
  };

  return (
    <BlockToolbar
      styleProps={{
        cursor: 'move',
        position: 'absolute',
        zIndex: 9999,
      }}
      id={`signerBlockMenu`}
      blockOptions={props.blockOptions}
    >
      <div className="flex">
        <FormControl className="py-1 px-2 min-w-[250px] max-w-[250px]">
          <Select
            displayEmpty
            variant="filled"
            value={blocksAllHaveSameSigner ? blocks[0].assignedTo! : multipleBlocks ? 'Multiple' : ''}
            onChange={(e) => selectSigner(e)}
            disabled={isDisabled}
            classes={{ select: classes.select }}
            data-spec="blockToolbarSignerDropdown"
            renderValue={(value) => {
              if (blocksAreAllReadOnlyOrStrike) {
                return <>{MenuLabel(' ', 'transparent')}</>;
              } else if (!blocksAllHaveSameSigner && multipleBlocks) {
                return <>{MenuLabel('Multiple', `${colors.grey['800']}`)}</>;
              } else {
                const currentSigner = signers.find((signer) => signer.signerId === assignableBlocks[0].assignedTo);
                return <>{MenuLabel(generateFullName(currentSigner), currentSigner?.color)}</>;
              }
            }}
          >
            {signers.map((signer: ISigner) => (
              <MenuItem classes={{ root: classes.formControl }} key={signer.signerId} value={signer.signerId}>
                {MenuLabel(generateFullName(signer), signer?.color)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </BlockToolbar>
  );
};

export default SignerBlockToolbar;
