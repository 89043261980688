import React from 'react';
import { Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { colors, elevation } from '@skyslope/mache';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import * as actions from '../../store/senderBuild/actions';
import DrawingLayer from './DrawingLayer';
import {
  IBlock,
  IBlocks,
  IGroup,
  IGroups,
  IPageDimensions,
  ISelectedBlocks,
  ISigner,
} from '../../store/senderBuild/types';
import Block from './Block';
import CopyLayer from './CopyLayer';
import SelectBox from './SelectBox';
import { BLOCK_TYPE_KEYS } from '../../lib/constants';
import { createRandomId } from '../../lib/randomId';
import { ReactComponent as CheckIcon } from '../../images/unEditableCheck.svg';
import { isSkySlopeMobileApp } from '../../common/utils';
import { isMediumScreenMediaQuery } from '../../lib/isSmallScreen';
import { LaunchDarklyFlags } from '../../common/launchDarkly';
import Droppable from '../../common/drag/Droppable';

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    position: 'relative',
  },
  mobilePageWrapper: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  pageNumber: {
    fontSize: '14px',
    color: colors.grey[800],
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  pageContainer: {
    position: 'relative',
    boxShadow: elevation.normal[12],
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  visibilityPixel: {
    position: 'absolute',
    top: '40%',
    zIndex: 0,
    width: 1,
    height: 1,
  },
  responsiveDocument: {
    '@global canvas': {
      width: '100% !important',
      height: 'auto !important',
    },
    position: 'relative',
  },
}));

interface IProps {
  observer: IntersectionObserver;
  documentId: string;
  pageIndex: number;
  totalPages: number;
  fullPage: number;
  pageBlocks: IBlock[];
  selectedBlocks: number[];
  flags?: LaunchDarklyFlags;
  visiblePage: number;
  isDownloaded?: boolean;
  vizDisabled?: boolean;
}

const selector = (state: IRootState) => ({
  id: state.senderBuild.id,
  zoom: state.senderBuild.zoom,
  signers: state.senderBuild.signers,
  activeBlockType: state.senderBuild.activeBlockType,
  activeSigner: state.senderBuild.activeSigner,
  pageDimensions: state.senderBuild.pageDimensions,
  selectedBlocks: state.senderBuild.selectedBlocks,
  isTextBlockEditingEnabled: state.senderBuild.isTextBlockEditingEnabled,
  blocks: state.senderBuild.blocks,
  checkboxGroups: state.senderBuild.groups,
});

interface IState {
  id: string;
  zoom: number;
  signers: ISigner[];
  activeBlockType: string;
  activeSigner: string;
  pageDimensions: IPageDimensions;
  selectedBlocks: ISelectedBlocks;
  isTextBlockEditingEnabled: boolean;
  blocks: IBlocks;
  checkboxGroups: IGroups;
}

const PdfPage = (props: IProps) => {
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pageBlocks, flags, selectedBlocks } = props;
  const pageDimensions = store.pageDimensions[props.documentId][props.pageIndex];
  const currentBlockType = store.activeBlockType;
  const isMdScreen = isMediumScreenMediaQuery();
  const isMobileApp = isSkySlopeMobileApp();
  const setPageDimensions = (page: any) => {
    if (!store.pageDimensions[props.documentId][props.pageIndex]) {
      dispatch(actions.setPageDimensions(props.documentId, props.pageIndex, page.view[3], page.view[2]));
    }
  };

  const convertToGroup = (block: IBlock, blockIndex: number) => {
    const groupId = createRandomId();

    const y = block.y! + block.height! + 6;
    if (y + block.height! >= pageDimensions.height) {
      return;
    }
    const newBlock = {
      ...block,
      blockId: createRandomId(),
      y,
      groupId,
    };

    const group: IGroup = {
      envelopeId: store.id,
      pageNumber: props.pageIndex,
      blockGroupType: 'CheckboxGroup',
      id: groupId,
      blockIds: [block.blockId!], // just the initial block, we'll add the new block after they're created
      documentId: props.documentId,
      validation: {
        rule: 'gte',
        value: 1,
      },
    };
    dispatch(actions.createGroup(group, [newBlock], block.blockId));
  };

  const renderUneditableTextBox = (block: IBlock) => {
    return (
      <div
        style={{
          left: block.x! * store.zoom,
          top: block.y! * store.zoom,
          width: block.width! * store.zoom,
          height: block.height! * store.zoom,
          fontSize: block.fontSize! * store.zoom,
          position: 'absolute',
          fontFamily: 'courier',
          fontWeight: 'bold',
        }}
      >
        {block.value}
      </div>
    );
  };

  const renderUneditableCheckbox = (block: IBlock) => {
    return (
      <div
        style={{
          left: block.x! * store.zoom,
          top: block.y! * store.zoom,
          width: block.width! * store.zoom,
          height: block.height! * store.zoom,
          position: 'absolute',
        }}
      >
        <CheckIcon style={{ position: 'absolute' }} />
      </div>
    );
  };

  const createNewBlock = (block: IBlock, select = true) => {
    if (isMdScreen) return;
    dispatch(actions.createNewBlock(props.documentId, props.pageIndex, block, select));
  };

  const selectBlocks = (
    documentId: string,
    pageIndex: number,
    blockIndices: number[],
    multiSelect?: boolean,
    selectThroughGroup?: boolean
  ) => {
    dispatch(actions.clearMouseCoords());
    dispatch(actions.selectBlocks(documentId, pageIndex, blockIndices, multiSelect, selectThroughGroup));
  };

  React.useEffect(() => {
    const element = document.getElementById(`page-${props.fullPage}`);
    if (element) {
      props.observer?.observe(element);
    }
    return () => {
      const element = document.getElementById(`page-${props.fullPage}`);
      if (element) {
        props.observer?.unobserve(element);
      }
    };
  }, [props.observer, props.fullPage]);

  React.useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      const escapeIsPressed = e.key === 'Escape';
      const blocksAreSelected = selectedBlocks.length > 0;
      if (escapeIsPressed && store.isTextBlockEditingEnabled) dispatch(actions.disableTextEditing());
      else if (escapeIsPressed && !blocksAreSelected && currentBlockType) dispatch(actions.changeActiveBlockType(''));
      else if (escapeIsPressed && blocksAreSelected)
        dispatch(actions.selectBlocks(props.documentId, props.pageIndex, []));
    };
    document.addEventListener('keydown', escapeListener);
    return () => document.removeEventListener('keydown', escapeListener);
  });

  const selectBoxBorderOnly = props.selectedBlocks.length === 1 && !!pageBlocks[props.selectedBlocks[0]].groupId;

  // If a single block is selected, and it's in a group, we need to get the other blocks in the group
  // so we can still render the border around the group
  const blockGroupIndices: number[] = [];
  if (selectBoxBorderOnly) {
    pageBlocks.forEach((b, i) => {
      if (b.groupId === pageBlocks[props.selectedBlocks[0]].groupId) {
        blockGroupIndices.push(i);
      }
    });
  }
  const showSelectBox = props.selectedBlocks.length > 1 || selectBoxBorderOnly;
  const pagesToShow = store.zoom <= 0.75 ? 3 : 2;
  const renderPdf =
    props.isDownloaded &&
    props.fullPage < props.visiblePage + pagesToShow &&
    props.fullPage > props.visiblePage - pagesToShow;
  const height = store.pageDimensions[props.documentId][props.pageIndex].height * store.zoom;
  const width = store.pageDimensions[props.documentId][props.pageIndex].width * store.zoom;

  const checkIfAllCheckboxesInAGroupAreSelected = (blockGroupId: string) => {
    const blockGroup = store.checkboxGroups[props.documentId!]?.[props.pageIndex!]?.[blockGroupId!];
    if (blockGroup) {
      let blockIndicesForCurrentGroup: number[] = [];
      blockGroup.blockIds.forEach((blockId) => {
        const groupBlockIdIndex = pageBlocks.findIndex((block) => block.blockId === blockId);
        blockIndicesForCurrentGroup.push(groupBlockIdIndex);
      });
      return blockIndicesForCurrentGroup.every((indexInGroup) =>
        store.selectedBlocks.blockIndices.includes(indexInGroup)
      );
    }
    return false;
  };

  const selectBlockToolbarSigner = (e: any) => {
    store.selectedBlocks.blockIndices?.forEach((blockIndex) => {
      const index = store.selectedBlocks.blockIndices.findIndex((i) => i == blockIndex);
      const block: IBlock =
        store.blocks[store.selectedBlocks.documentId!][store.selectedBlocks.pageIndex!][
          store.selectedBlocks.blockIndices[index]
        ];
      if (block.readOnly) return;
      if (block.groupId && !checkIfAllCheckboxesInAGroupAreSelected(block.groupId)) return;
      dispatch(
        actions.editBlock(store.selectedBlocks.documentId!, store.selectedBlocks.pageIndex!, blockIndex, {
          assignedTo: ![BLOCK_TYPE_KEYS.STRIKE, BLOCK_TYPE_KEYS.RECTANGLE].includes(block.blockType)
            ? e.target.value
            : null,
        })
      );
    });
  };

  const isBlockSignerToolbarDisabled = () => {
    if (store.selectedBlocks.blockIndices?.length === 1) {
      const block: IBlock =
        store.blocks[store.selectedBlocks.documentId!][store.selectedBlocks.pageIndex!][
          store.selectedBlocks.blockIndices[0]
        ];
      return block.readOnly || !!block.groupId;
    }
    if (store.selectedBlocks.blockIndices?.length > 1) {
      let fullBlocks: IBlock[] = [];
      store.selectedBlocks.blockIndices.forEach((b, i) => {
        const currentBlock =
          store.blocks[store.selectedBlocks.documentId!][store.selectedBlocks.pageIndex!][
            store.selectedBlocks.blockIndices[i]
          ];
        fullBlocks.push(currentBlock);
      });
      return fullBlocks.every(
        (block) =>
          block.readOnly ||
          [BLOCK_TYPE_KEYS.STRIKE, BLOCK_TYPE_KEYS.RECTANGLE].includes(block.blockType) ||
          (block.groupId && !checkIfAllCheckboxesInAGroupAreSelected(block.groupId))
      );
    }
    return false;
  };

  return (
    <div
      className={`pdf-viewer-page ${isMobileApp ? classes.mobilePageWrapper : classes.pageWrapper}`}
      id={`page-${props.fullPage}`}
      style={{ opacity: props.vizDisabled ? 0 : 1 }}
      data-document-id={props.documentId}
      data-page-index={props.pageIndex}
      data-full-page={props.fullPage}
    >
      {props.visiblePage === props.fullPage && <a id="scroll" />}
      <div id={`doc-${props.documentId}-page-${props.pageIndex}`} className={classes.pageNumber}>
        Page {props.pageIndex + 1}/{props.totalPages}
      </div>
      <div
        className={classes.pageContainer}
        style={{
          height,
          width,
        }}
        id={`pageContainer-${props.fullPage}`}
      >
        {renderPdf && (
          <Droppable id={`id-${props.pageIndex}`}>
            <Page
              pageNumber={props.pageIndex + 1}
              scale={store.zoom}
              onLoadSuccess={setPageDimensions}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              loading=""
              className={isMdScreen ? classes.responsiveDocument : ''}
            />
            <DrawingLayer
              pageIndex={props.pageIndex}
              createNewBlock={createNewBlock}
              documentId={props.documentId}
              pageBlocks={pageBlocks}
              deselectBlocks={selectBlocks.bind(null, props.documentId, props.pageIndex, [])}
              flags={flags}
            />
            <CopyLayer documentId={props.documentId} pageIndex={props.pageIndex} />
            {pageBlocks.map((block, i) => {
              if (props.selectedBlocks.length !== 1 && props.selectedBlocks.includes(i)) {
                return '';
              }
              if (block.isEditingDisabled && block.blockType === BLOCK_TYPE_KEYS.TEXT) {
                return renderUneditableTextBox(block);
              }
              if (block.isEditingDisabled && block.blockType === BLOCK_TYPE_KEYS.CHECKBOX && block.value) {
                return renderUneditableCheckbox(block);
              }
              if (block.isEditingDisabled && block.blockType === BLOCK_TYPE_KEYS.CHECKBOX && !block.value) {
                return null;
              }
              return (
                <Block
                  key={block.blockId}
                  documentId={props.documentId}
                  pageIndex={props.pageIndex}
                  blockIndex={i}
                  selected={props.selectedBlocks.includes(i)}
                  selectedBlocks={props.selectedBlocks}
                  block={block}
                  selectBlocks={selectBlocks}
                  currentSelectedBlocks={props.selectedBlocks}
                  zoom={store.zoom}
                  pageWidth={pageDimensions.width}
                  pageHeight={pageDimensions.height}
                  blocks={pageBlocks}
                  convertToGroup={convertToGroup}
                />
              );
            })}
          </Droppable>
        )}

        {showSelectBox && (
          <SelectBox
            documentId={props.documentId}
            pageIndex={props.pageIndex}
            pageBlocks={pageBlocks}
            blocksIndices={selectBoxBorderOnly ? blockGroupIndices : props.selectedBlocks}
            dontRenderBlocks={selectBoxBorderOnly}
            fullSelectedBlocks={props.selectedBlocks}
            selectBlocks={selectBlocks}
            isBlockSignerToolbarDisabled={isBlockSignerToolbarDisabled()}
            selectSigner={selectBlockToolbarSigner}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(PdfPage);
