import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Label } from '@material-ui/icons';
import { colors, elevation } from '@skyslope/mache';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ISigner, ISigningGroup } from '../../../store/senderBuild/types';
import * as actions from '../../../store/senderBuild/actions';
import { useDispatch, useShallowSelector } from '../../../lib/reduxHooks';
import { IRootState } from '../../../store';
import SigningGroup from './SigningGroup';
import { CarbonCopyRecipients } from '../CarbonCopyRecipients';
import RecipientsModal from '../RecipientsModal';
import { ResourceNames } from '../../../lib/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(0.5),
  },
  checkbox: {
    marginLeft: theme.spacing(1.5),
  },
  groupsWrapper: {
    marginTop: theme.spacing(0.5),
  },
  signgingGroupName: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  signingGroup: {
    '&:last-child': {
      borderLeft: 0,
      paddingBottom: 0,
    },
    paddingBottom: theme.spacing(2),
    position: 'relative',
    paddingTop: 2,
  },
  groupNumber: {
    position: 'absolute',
    top: 0,
    left: -12,
    height: 24,
    width: 24,
    backgroundColor: 'white',
    color: colors.grey[700],
    border: `1px solid ${colors.grey[500]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plusSign: {
    fontSize: 22,
  },
  newGroup: {
    backgroundColor: colors.blue[50],
    border: `2px dashed ${colors.blue[100]}`,
    textAlign: 'center',
    padding: theme.spacing(1),
    color: colors.grey[700],
    marginTop: theme.spacing(1),
  },
  newGroupActive: {
    backgroundColor: colors.blue[100],
    borderColor: colors.blue[200],
    color: colors.blue[300],
    fontSize: 28,
    lineHeight: '20px',
  },
  draggableSigner: {
    marginLeft: theme.spacing(-1),
    '&:hover': {
      cursor: 'pointer',
      boxShadow: elevation.normal[12],
      backgroundColor: '#FFFFFF',
    },
  },
  drag: {
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(2),
    color: colors.grey[500],
    width: 28,
    height: 28,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 6,
    paddingBottom: 6,
  },
  draggableRow: {
    paddingLeft: theme.spacing(1),
  },
  overflowContainer: {
    overflow: 'hidden',
  },
  entry: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  email: {
    fontSize: 13,
    lineHeight: '16px',
  },
  dropIndicator: {
    width: '100%',
    height: 3,
    backgroundColor: colors.blue[800],
    '&:after': {
      width: 4,
      height: 4,
      backgroundColor: '#ffffff',
      border: `2px solid ${colors.blue[800]}`,
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      marginTop: -2,
      marginLeft: -2,
    },
  },
  indicatorIsShowing: {
    marginBottom: -3,
  },
  sectionTitle: {
    color: '#8BA0B4',
    fontWeight: 700,
    fontSize: '13px',
    marginRight: '8px',
    letterSpacing: '1.6px',
  },
  shelfBorder: {
    borderTop: '1px dashed #AFBECD',
    flex: 1,
    marginRight: '32px',
  },
  signerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const renderSigner = (classes: any, signer: ISigner, draggable = false, index: number) => {
  if (signer.role === 'CarbonCopy') return null;
  return (
    <div key={signer.signerId} className={`${classes.row} ${draggable ? classes.draggableRow : ''}`} tabIndex={0} >
      <Label className={classes.icon} style={{ color: signer.color }} />
      <div className={classes.overflowContainer} data-spec="signer">
        <Typography variant="body2" className={classes.entry}>
          {signer.firstName} {signer.lastName}
        </Typography>
        <Typography variant="body1" className={`${classes.entry} ${classes.email}`}>
          {signer.email}
        </Typography>
      </div>
    </div>
  );
};

const envelopeShelfSelector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
  signingGroups: state.senderBuild.signingGroups,
  errorShow: state.pageFrame.errorShow,
  successShow: state.pageFrame.successShow,
  resource: state.senderBuild.resource,
});

interface IState {
  signers: ISigner[];
  signingGroups: ISigningGroup[];
  successShow: boolean;
  errorShow: boolean;
  resource: ResourceNames;
}

interface IProps {
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}

export default (props: IProps) => {
  const classes = useStyles();
  const { menuAnchor, closeMenu } = props;
  const [recipientsModalOpen, setRecipientsModalOpen] = React.useState(false);
  const { signers, signingGroups, successShow, errorShow, resource }: IState = useShallowSelector(envelopeShelfSelector);
  const dispatch = useDispatch();

  const toggleRecipientsModal = () => {
    closeMenu();
    setRecipientsModalOpen(false);
  };
  const handleEditRecipientsClick = () => {
    closeMenu();
    setRecipientsModalOpen(true);
  };

  React.useEffect(() => {
    if (successShow || errorShow) {
      toggleRecipientsModal();
    }
  }, [successShow, errorShow]);

  const moveSigner = (signerId: string, newGroupId: string) => {
    dispatch(actions.editSigner(signerId, { signingGroup: newGroupId }));
  };

  return (
    <>
      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={closeMenu}>
        <MenuItem data-spec="editRecipients" onClick={handleEditRecipientsClick}>
          Edit Recipients
        </MenuItem>
      </Menu>
      <div className={classes.wrapper}>
        <div className={signers.length > 1 ? classes.groupsWrapper : ''}>
          {signers.length < 2 || resource === ResourceNames.TEMPLATE
            ? signers.map((s, i) => renderSigner(classes, s, false, i))
            : signingGroups.map((signingGroup, i) => (
                <SigningGroup
                  key={signingGroup.id}
                  signingGroup={signingGroup}
                  signers={signers.filter(s => s.signingGroup === signingGroup.id)}
                  index={i}
                  moveSigner={moveSigner}
                  totalSigningGroups={signingGroups.length}
                />
              ))}
          <CarbonCopyRecipients />
        </div>
        {recipientsModalOpen ? (
          <RecipientsModal open={recipientsModalOpen} toggleModal={toggleRecipientsModal} />
        ) : null}
      </div>
    </>
  );
};
