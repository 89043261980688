import { ActionTypes } from './actions';
import { IAppliedTemplates, IApplyTemplateState, IDocumentMapping } from './types';
import update from 'immutability-helper';

export const initialState: IApplyTemplateState = {
  envelope: undefined,
  selectedDocumentId: undefined,
  selectedPageIndex: 0,
  showTemplatesSection: false,
  appliedTemplates: {},
  pageDimensions: {},
  fetchedAppliedTemplates: false,
  hadPreviouslyAppliedTemplates: false,
  fetchedAppliedTemplatesForDocuments: false,
  documentMapping: [],
  updatingTemplateApplication: false,
  templateApplied: false,
};

export default (state: IApplyTemplateState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_ENVELOPE_DONE: {
      const { envelope, pageDimensions } = action.payload;
      return update(state, {
        envelope: { $set: envelope },
        selectedDocumentId: { $set: envelope.documents.length ? envelope.documents[0].documentId : undefined },
        pageDimensions: { $set: pageDimensions },
      });
    }
    case ActionTypes.SELECT_DOCUMENT: {
      return update(state, {
        selectedDocumentId: { $set: action.payload },
        selectedPageIndex: { $set: 0 },
        showTemplatesSection: { $set: true },
      });
    }
    case ActionTypes.SELECT_PAGE: {
      return update(state, {
        selectedPageIndex: { $set: action.payload },
      });
    }
    case ActionTypes.SHOW_TEMPLATES_SECTION: {
      return update(state, {
        showTemplatesSection: { $set: true },
      });
    }
    case ActionTypes.FETCH_TEMPLATES_DONE: {
      return update(state, {
        templates: { $set: action.payload },
      });
    }
    case ActionTypes.PREVIEW_TEMPLATE: {
      const template = state.templates!.find((t) => t.id === action.payload)!;
      if (template) {
        return update(state, {
          appliedTemplates: {
            [state.selectedDocumentId!]: { $set: { metadata: template } },
          },
        });
      }
      // if template isn't found, we're removing a template from a document.
      return update(state, {
        appliedTemplates: { $unset: [state.selectedDocumentId!] },
      });
    }
    case ActionTypes.PREVIEW_TEMPLATE_DONE: {
      return update(state, {
        appliedTemplates: {
          [state.selectedDocumentId!]: { blocks: { $set: action.payload } },
        },
      });
    }
    case ActionTypes.FETCH_TEMPLATES_APPLIED_DONE: {
      let templatesMap: IAppliedTemplates = {};
      let hadTemplatesApplied: boolean = false;
      const { templates, useMongoDBModel } = action.payload;
      if (!useMongoDBModel) {
        if (templates.length > 0) {
          templatesMap = templates.reduce(
            (acc, template) => {
              const docId = template.data.documentId;
              const templateApplied = state.templates?.find((t) => t.id === template.templateId);
              acc[docId] = { metadata: templateApplied, blocks: template.blocks };
              return acc;
            },
            { ...state.appliedTemplates }
          );
          hadTemplatesApplied = true;
        }
      } else {
        if (templates.templatesApplied?.length > 0) {
          templatesMap = templates.documentMapping.reduce(
            (acc, { templateId, envelopeDocumentId }) => {
              const templateApplied = state.templates?.find((t) => t.id === templateId);
              const blocks = templates.blockMapping?.find((bm) => bm.templateId === templateId).blocks;
              acc[envelopeDocumentId] = { metadata: templateApplied, blocks };
              return acc;
            },
            { ...state.appliedTemplates }
          );
          hadTemplatesApplied = true;
        }
      }
      return update(state, {
        appliedTemplates: { $set: templatesMap },
        fetchedAppliedTemplates: { $set: true },
        hadPreviouslyAppliedTemplates: { $set: hadTemplatesApplied },
      });
    }
    case ActionTypes.FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS_DONE: {
      const templates = action.payload;
      const hadTemplatesApplied: boolean = !!templates.templatesApplied?.length;
      return update(state, {
        documentMapping: { $set: templates.documentMapping || [] },
        fetchedAppliedTemplatesForDocuments: { $set: true },
        hadPreviouslyAppliedTemplates: { $set: hadTemplatesApplied },
      });
    }
    case ActionTypes.UPDATE_DOCUMENT_MAP: {
      let templatesMap: IDocumentMapping[] = [];
      const { documentId, templatesList } = action.payload;
      templatesMap = templatesList.filter((t: IDocumentMapping) => t.envelopeDocumentId != documentId);
      return update(state, {
        documentMapping: { $set: templatesMap },
      });
    }
    case ActionTypes.FINALIZE: {
      return update(state, {
        updatingTemplateApplication: { $set: true },
      });
    }
    case ActionTypes.FINALIZE_DONE: {
      return update(state, {
        updatingTemplateApplication: { $set: false },
      });
    }
    case ActionTypes.FINALIZE_BULK_TEMPLATES: {
      return update(state, {
        updatingTemplateApplication: { $set: true },
      });
    }
    case ActionTypes.FINALIZE_BULK_TEMPLATES_DONE: {
      return update(state, {
        updatingTemplateApplication: { $set: false },
        templateApplied: { $set: true },
      });
    }
    default: {
      return state;
    }
  }
};
