import React from 'react';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import AutoFix from '../../images/auto_fix.svg';
import InputLabel from '@material-ui/core/InputLabel';
import { getSmartEmailConfig } from '../../lib/api/envelope/envelope';
import UndoIcon from '../../images/undo.svg';
import { Check } from '@material-ui/icons';
import { generateEmailBody } from '@digisign3-ui/sender/src/lib/utils';
import { useSmartEmailContext } from '@digisign3-ui/sender/src/context/smart-email-context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isSmallScreenMediaQuery } from '@digisign3-ui/sender/src/lib/isSmallScreen';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

type Props = {
  envelopeId: string;
  emailBody: string;
  setEmailBody: (body: string) => void;
};

const tones = ['Professional', 'Friendly', 'Urgent'];

const SmartEmail = (props: Props) => {
  const { tone, setTone, loading, setLoading, originalBody, setOriginalBody } = useSmartEmailContext();
  const mobile = isSmallScreenMediaQuery();
  const changeTone = async (t: string) => {
    setTone(t);
    setLoading(true);
    if (!tone) {
      setOriginalBody(props.emailBody);
    }
    const response = await getSmartEmailConfig({
      tone: t.toLowerCase(),
      body: originalBody || props.emailBody,
      envelopeId: props.envelopeId,
    });
    props.setEmailBody(response);
    setLoading(false);
  };

  const handleUndo = () => {
    props.setEmailBody(originalBody);
    setOriginalBody('');
    setTone('');
  };

  const disabled = !props.emailBody || props.emailBody === generateEmailBody();

  return (
    <>
      <div className="flex items-center justify-between">
        <InputLabel>Email Body</InputLabel>
        {originalBody && (
          <Button onClick={handleUndo}>
            <img src={UndoIcon} alt="Undo Icon" className="block pr-2" />
            Undo
          </Button>
        )}
      </div>
      <div className="bg-gradient-to-r from-purple-500 to-blue-400 p-4 flex gap-3 items-center max-sm:fixed max-sm:bottom-[71px] left-0 right-0 z-10">
        <div className="text-white flex flex-col md:flex-row md:gap-2 md:items-center">
          <div className="flex items-center gap-3">
            <img src={AutoFix} alt="Auto Fix" />
            <b className="sm:whitespace-nowrap">
              {disabled ? 'Enhanced Emails' : mobile ? 'Enhance to sound' : 'Enhance my email to sound:'}
            </b>
          </div>
          {disabled && <div className="text-white md:whitespace-nowrap py-2">Type your message, choose the tone.</div>}
        </div>
        {!disabled &&
          !mobile &&
          tones.map((t) => (
            <Button
              key={t}
              id={t}
              className={cx(
                'bg-white bg-opacity-25 text-white hover:bg-opacity-50 px-3 text-[14px] py-2',
                !loading && tone === t && 'bg-opacity-100 text-black',
                loading && tone !== t && 'opacity-50'
              )}
              onClick={() => changeTone(t)}
              disabled={loading || tone === t}
            >
              <div className="relative">
                {loading && tone === t && (
                  <CircularProgress size={18} className="absolute inset-0 m-0 p-0 text-white -left-[1px] -top-[1px]" />
                )}
                <Check
                  className={cx(
                    'mr-1 rounded-full border border-solid text-[12px] p-0.5',
                    loading && 'border-0',
                    !loading && tone === t ? 'bg-blue-800 border-transparent text-white' : 'border-white'
                  )}
                />
              </div>
              {t}
            </Button>
          ))}
        {!disabled && mobile && (
          <div className="flex justify-end w-full">
            <Select
              value={tone}
              fullWidth
              onChange={(e) => changeTone(e.target.value as string)}
              className="max-w-[250px]"
              classes={{
                root: cx('bg-white border-none flex', !tone && 'bg-opacity-25'),
                selectMenu: cx('rounded-full', !tone && 'text-white'),
                icon: cx(!tone && 'text-white'),
              }}
            >
              {tones.map((t) => {
                return (
                  <MenuItem key={t} value={t}>
                    {tone === t && (
                      <Check className="mr-2 rounded-full border border-solid text-[12px] p-0.5 bg-blue-800 border-transparent text-white relative top-[2px]" />
                    )}
                    {t}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
        {disabled && !mobile && (
          <div className="flex flex-grow justify-end select-none">
            <div className="bg-grey-900 bg-opacity-50 text-white px-3 py-1 rounded-xl text-[14px]">BETA</div>
          </div>
        )}
      </div>
    </>
  );
};

export default SmartEmail;
