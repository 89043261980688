import { action } from 'typesafe-actions';
import { ITemplateTag } from '../templateCreate/types';

export enum ActionTypes {
  RESET = 'templateTagging/RESET',
  FETCH_TAGS = 'templateTagging/FETCH_TAGS',
  FETCH_TAGS_DONE = 'templateTagging/FETCH_TAGS_DONE',
  CHANGE_TAG = 'templateTagging/CHANGE_TAG',
  CHANGE_NAME = 'templateTagging/CHANGE_NAME',
  SAVE = 'templateTagging/SAVE',
  SAVE_DONE = 'templateTagging/SAVE_DONE',
}

export const reset = () => action(ActionTypes.RESET);
export const fetchTags = (templateId: string) => action(ActionTypes.FETCH_TAGS, templateId);
export const fetchTagsDone = (name: string, tags: ITemplateTag[]) =>
  action(ActionTypes.FETCH_TAGS_DONE, { name, tags });
export const changeTag = (tagTypeId: string, values: string[]) => action(ActionTypes.CHANGE_TAG, { tagTypeId, values });
export const changeName = (name: string) => action(ActionTypes.CHANGE_NAME, name);
export const save = (templateId: string) => action(ActionTypes.SAVE, templateId);
export const saveDone = () => action(ActionTypes.SAVE_DONE);
