import * as Sentry from '@sentry/react';
import { useAuth, useUserClaims } from '@skyslope/auth-react';
import { useEffect, useState } from 'react';
import { provisionUser } from '../lib/api/userService/userService';
import { oktaDS3Iss } from '../lib/constants';
import { useDispatch } from '../lib/reduxHooks';
import * as actions from '../store/pageFrame/actions';
import { useSelector } from 'react-redux';

export type User = {
  userId: string;
  oktaUserId: string;
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  oktaGroups: string[];
  subscriberId?: number;
  primeUserId?: string;
  primeUserGuid?: string;
  primeContactType?: string;
}

export type UserInfoApi = {
  user: User;
  impersonating?: User;
}

export type UserInfo = {
  provisioned: boolean;
  digisignAccountId: string;
  oktaUserId: string;
  senderId: string;
  senderFirstName: string;
  senderLastName: string;
  senderEmail: string;
  subscriberId: string;
  primeContactType: string;
  digisign_fnf_account_id?: string;
  isPrimeAuthenticated: boolean;
  impersonatedPrimeUserGuid?: string;
};
const selector = (state: IRootState) => ({
  isFetchingUserInfo: state.pageFrame.isFetchingUserInfo,
  isUserInfoFetched: state.pageFrame.isUserInfoFetched,

});

interface IState {
  isFetchingUserInfo: boolean;
  isUserInfoFetched: boolean;

}

export default function useUserInfo() {
  const { isFetchingUserInfo, isUserInfoFetched }: IState = useSelector(selector);
  const dispatch = useDispatch();
  const { userManager, authState } = useAuth();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const { claims } = authState.accessToken ?? {};
  const { claims: userClaims } = useUserClaims();

  const [digisignAccountId, setDigisignAccountId] = useState<string | null>(null);
  const [needsProvisioning, setNeedsProvisioning] = useState<boolean>(true);
  const [delegatedUserOktaId, setDelegatedUserOktaId] = useState<string | null | undefined>(null);
  const [needsToSetUserInfo, setNeedsToSetUserInfo] = useState<boolean>(false);


  const setImpersonatedUserInfo = (userInfoTemp: UserInfoApi) => {
    setUserInfo({
      provisioned: !needsProvisioning,
      digisignAccountId,
      oktaUserId: delegatedUserOktaId,
      senderId: userInfoTemp.impersonating?.userId,
      senderFirstName: userInfoTemp.impersonating?.firstName,
      senderLastName: userInfoTemp.impersonating?.lastName,
      senderEmail: userInfoTemp.impersonating?.email,
      subscriberId: userInfoTemp.impersonating?.subscriberId,
      primeContactType: userInfoTemp.impersonating?.primeContactType,
      digisign_fnf_account_id: claims.digisign_fnf_account_id,
      isPrimeAuthenticated: !!userInfoTemp.impersonating?.oktaGroups?.find((g: string) => g === '/prime/users') ?? false,
      impersonatedPrimeUserGuid: userInfoTemp.impersonating?.primeUserGuid,
    });
  };


  useEffect(() => {
    if (!isUserInfoFetched) { return }
    setNeedsToSetUserInfo(false);
    const userInfoString = sessionStorage.getItem('userInfo');
    const storedUserInfo = userInfoString ? JSON.parse(userInfoString) : null;
    setImpersonatedUserInfo(storedUserInfo);
  }, [isUserInfoFetched]);

  useEffect(() => {
    if (!needsToSetUserInfo) {
      return;
    }
    if (!delegatedUserOktaId) {
      setUserInfo({
        provisioned: !needsProvisioning,
        digisignAccountId,
        oktaUserId: claims.OktaUserId || claims.uid,
        senderId: claims.UserId || claims.user_id || claims.sub,
        senderFirstName: claims.first_name || claims.FirstName || claims.given_name,
        senderLastName: claims.last_name || claims.LastName || claims.family_name,
        senderEmail: claims.email || claims.SenderEmail || claims.Email || claims.sub,
        subscriberId: claims.subscriber_id + '',
        primeContactType: userClaims?.prime_contact_type || claims.prime_contact_type,
        digisign_fnf_account_id: claims.digisign_fnf_account_id,
        isPrimeAuthenticated: !!claims.groups?.find((g: string) => g === '/prime/users') ?? false,
      });
      setNeedsToSetUserInfo(false);
    } else if (isUserInfoFetched) {
      const userInfoString = sessionStorage.getItem('userInfo');
      const storedUserInfo = userInfoString ? JSON.parse(userInfoString) : null;
      setImpersonatedUserInfo(storedUserInfo);
      setNeedsToSetUserInfo(false);
    } else if (!isFetchingUserInfo) {
      dispatch(actions.updateisFetchingUserInfo(true));
      dispatch(actions.fetchUserInfo(digisignAccountId));
    }

  }, [needsToSetUserInfo]);

  useEffect(() => {
    if (!claims || !userManager || (authState.idToken && !userClaims)) {
      setUserInfo(null);
      return;
    }
    const digisignAccountIdTemp = claims.AccountId ||
      claims.account_id ||
      claims.digisign_prime_account_id ||
      claims.digisign_forms_account_id ||
      claims.digisign_fnf_account_id
    setDigisignAccountId(digisignAccountIdTemp)
    const delegatedUserOktaIdTemp = claims.delegated_user_id;

    if (delegatedUserOktaIdTemp) {
      setDelegatedUserOktaId(delegatedUserOktaIdTemp);
    }

    const isDS3OktaToken = claims.iss === oktaDS3Iss;
    // For impersonated users this will never provision because the user_id is the impersonated user's id
    // Now, we check for this during the fetchUserInfo as well because we'll know at that point if the impersonated
    // user exists in the user service
    const needsProvisioningTemp = !(claims.UserId || claims.user_id) ||
      (isDS3OktaToken && !claims.groups?.find((g: string) => g.includes(`/digisign/${digisignAccountIdTemp}`)));

    setNeedsProvisioning(needsProvisioningTemp);
    if (needsProvisioningTemp) {
      provisionUser(digisignAccountIdTemp).then(({ userId }) => {
        userManager._oktaAuth.oktaAuth.tokenManager.renew('accessToken');
        Sentry.captureMessage('User Provisioned', {
          tags: {
            userId,
          },
          level: 'log',
        });
      });
    }

    setNeedsToSetUserInfo(true);
  }, [claims, userManager, userClaims]);

  return userInfo;
}
