import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import RecipientAutoComplete from '../RecipientAutoComplete';
import { IContact } from '../types';

type IProps = {
  autoFocus?: boolean;
  helperText: string;
  handleOnChange: (e: any) => void;
  value: string;
  dataSpec?: string;
  name?: string;
  disabled?: boolean;
  contacts: IContact[];
  handleContactSelect: (e: any, queryType: string) => void;
  addRecipientNode: any;
};

const RecipientSearch = (props: IProps) => {
  const filterContacts = (value: string) => {
    const normalizedValue = value.trim().toLowerCase();

    return (contact: IContact) =>
      contact.firstName?.toLowerCase().includes(normalizedValue) ||
      contact.lastName?.toLowerCase().includes(normalizedValue) ||
      contact.email?.toLowerCase().includes(normalizedValue);
  };
  const getOptionLabel = (option: IContact) => 
    `${option.firstName} ${option.lastName} \n${option.email}`;

  return (
    <RecipientAutoComplete
      autoFocus={props.autoFocus}
      label=''
      helperText={props.helperText}
      placeholder='Search Contacts'
      handleOnChange={props.handleOnChange}
      error={false}
      value={props.value}
      dataSpec={props.dataSpec}
      name={props.name}
      disabled={props.disabled}
      contacts={props.contacts}
      handleContactSelect={props.handleContactSelect}
      filterMethod={filterContacts}
      getOptionLabel={getOptionLabel}
      clearOnSelect
      roundInput
      emptyResultsNode={props.addRecipientNode}
      popupIcon={<SearchIcon />}
    />
  );
};

export default RecipientSearch;
