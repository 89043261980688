import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import subGroupsEmpty from '../../images/subGroupsEmpty.svg';
import { HeaderContext } from '../../context/header-context';
import { preferencesUrl, ResourceNames } from '../../lib/constants';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import { ISettings } from '../../store/pageFrame/types';
import FooterLinks from '../common/FooterLinks';
import EmptyState from './EmptyState';
import Table from '../common/Table';
import * as actions from '../../store/templateSharing/actions';
import * as templateCreateActions from '../../store/templateCreate/actions';
import { IShareRecord, ITemplateUser, SubGroup } from '../../store/templateSharing/types';
import useDebounce from '../../lib/useDebounce';
import { ITemplate } from '../../store/templateCreate/types';
import Search from '../common/Search';
import { setLoading } from '../../store/pageFrame/actions';
import NoResultsState from '../common/NoResultsState';
import { checkIfTemplateIsSharedToUserInOffice, checkIfTemplateSharedToAnyUsersGroup } from '../../lib/utils';
import { getUsersGroups } from '../../lib/api/account/accountApi';
import { withLaunchDarkly, LaunchDarklyFlags } from '../../common/launchDarkly';
import NavHeader from '../../common/navigation';
import SkeletonTable from '../../common/loading/SkeletonTable';
import useBottomScrollListener from '../../hooks/useBottomScrollListener';
import useUserInfo from '../../auth/useUserInfo';
import { OfficeUser, UserOffice } from '../../lib/api/prime/prime';
import { getOfficesToSearch } from '../../store/templateSharing/saga';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 1000,
    width: '100%',
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
  },
  emptyStateRoot: {
    maxWidth: 1200,
    width: '100%',
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: colors.grey[700],
    marginBottom: theme.spacing(3),
  },
  tableControls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  templateCount: {
    display: 'flex',
    alignItems: 'center',
  },
  templateCountIcon: {
    marginRight: theme.spacing(1),
    color: colors.grey[500],
  },
  plusIcon: {
    margin: '-2px 0px -2px -6px',
    height: 18,
  },
  hotdogButton: {
    padding: 6,
    margin: -7,
  },
  foundSkeleton: {
    width: 200,
  },
  divider: {
    height: 1,
    width: '100%',
    marginTop: 40,
    marginBottom: 32,
    backgroundColor: colors.grey[400],
  },
  tabLinks: {
    display: 'flex',
  },
  tabLink: {
    padding: '6px 15px 4px 15px',
    margin: '4px 2px 1px 0',
    position: 'relative',
    '&:hover,&:focus': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
      borderTopRightRadius: '15px',
      borderTopLeftRadius: '15px',
      outline: 'none',
    },
    userSelect: 'none',
  },
  tabLinkSelected: {
    backgroundColor: colors.blue[800],
    height: '3px',
    position: 'absolute',
    left: '0',
    bottom: '0',
    right: '0',
    margin: '0 14px',
    borderRadius: '2px',
  },
  subGroupCountIcon: {
    marginRight: theme.spacing(1),
    color: colors.grey[600],
  },
  subGroupCount: {
    display: 'flex',
    alignItems: 'center',
    color: colors.grey[800],
  },
  rowClassName: {
    maxWidth: '120px',
  },
}));

interface IProps {
  flags?: LaunchDarklyFlags;
  renderGroupsTable?: boolean;
  renderOfficeTable?: boolean;
}

interface IState {
  template: ITemplate;
  search: string;
  isFetching: boolean;
  settings?: ISettings;
  settingsFetched?: boolean;
  isLoading: boolean;
  users: ITemplateUser[];
  totalUsers: number;
  endReached: boolean;
  userExclusions: string[];
  sharedToAllParentGroups: boolean;
  subGroups: SubGroup[];
  sharedIds: IShareRecord[];
  orderByDesc: boolean;
  orderOfficeByDesc: boolean;
  sharesFetched: boolean;
  offices: UserOffice[];
  fetchingOfficeUsers: boolean;
  usersFromOffice: OfficeUser[];
}

const selector = (state: IRootState) => ({
  template: state.templateCreate.template,
  search: state.templateManagement.search,
  isFetching: state.templateSharing.isFetching,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  isLoading: state.pageFrame.loading,
  users: state.templateSharing.users,
  totalUsers: state.templateSharing.totalUsers,
  endReached: state.templateSharing.endReached,
  userExclusions: state.templateSharing.userExclusions,
  sharedToAllParentGroups: state.templateSharing.sharedToAllParentGroups,
  subGroups: state.templateSharing.subGroups,
  sharedIds: state.templateSharing.sharedIds,
  orderByDesc: state.templateSharing.orderByDesc,
  orderOfficeByDesc: state.templateSharing.orderOfficeByDesc,
  sharesFetched: state.templateSharing.sharesFetched,
  offices: state.templateSharing.offices,
  fetchingOfficeUsers: state.templateSharing.fetchingOfficeUsers,
  usersFromOffice: state.templateSharing.usersFromOffices,
});

export const TemplateShare = (props: IProps) => {
  const { flags, renderGroupsTable, renderOfficeTable } = props;
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const {
    settings,
    users,
    template,
    endReached,
    userExclusions,
    sharedToAllParentGroups,
    subGroups,
    sharedIds,
    orderByDesc,
    orderOfficeByDesc,
    sharesFetched,
    offices,
    fetchingOfficeUsers,
    usersFromOffice,
  } = store;
  const { oktaUserId } = useUserInfo() ?? {};
  const params = useParams<{ id: string }>();
  const headerContext = useContext(HeaderContext);
  const classes = useStyles();
  const history = useHistory();
  const [resetTableScroll, setResetTableScroll] = useState(false);
  const [showBottomLoader, setShowBottomLoader] = useState(false);
  const [firstRenderDone, setFirstRenderDone] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [search, setSearch] = useState('');
  const [currentTab, setCurrentTab] = useState(renderGroupsTable ? 'Groups' : renderOfficeTable ? 'Offices' : 'People');
  // This hook prevents the rendering of an empty state when the user backspaces the search input until it is empty
  const [alreadySearched, setAlreadySearched] = useState(false);
  const debouncedSearch = useDebounce(search, 200);
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const showGroupsTable = flags && flags['template-group-sharing'];
  const officeSharingEnabled = flags && flags['template-office-sharing'];
  const [usersWithoutCurrentUser, setUsersWithoutCurrentUser] = useState<ITemplateUser[]>([]);

  const TABS = {
    People: {
      statuses: null,
      key: 'people',
      label: 'People',
    },
    Groups: {
      statuses: null,
      key: 'groups',
      label: 'Groups',
    },
    Offices: {
      statuses: null,
      key: 'offices',
      label: 'Offices',
    },
  };

  const handleSetSelectedStatus = (tab: string) => {
    if (currentTab !== tab) {
      setCurrentTab(tab);
    }
  };

  const pageTabs = (
    <div className={classes.tabLinks}>
      {Object.keys(TABS).map((tab, index) => {
        return (!showGroupsTable && tab === 'Groups') || (!officeSharingEnabled && tab === 'Offices') ? (
          <></>
        ) : (
          <Typography
            key={tab}
            tabIndex={index + 1}
            className={`${classes.tabLink} `}
            onClick={(e: React.MouseEvent<HTMLParagraphElement>) => {
              handleSetSelectedStatus(tab);
              e.currentTarget.blur();
            }}
            variant={currentTab === tab ? 'body2' : 'body1'}
            data-spec={`${tab}-tab`}
          >
            {tab}
            {currentTab === tab ? <div className={classes.tabLinkSelected} /> : null}
          </Typography>
        );
      })}
    </div>
  );

  // First time the page loads, fetching users and share information
  // and making an async call to get user's id
  useEffect(() => {
    if (oktaUserId) {
      dispatch(setLoading(true));
      dispatch(
        actions.fetchTemplateShareInformation(
          20,
          1,
          debouncedSearch,
          params.id,
          oktaUserId!,
          officeSharingEnabled
        )
      );
      dispatch(templateCreateActions.fetchTemplate(params.id));
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [oktaUserId]);

  // moving from tabs, loads the header and content in pageTabs
  useEffect(() => {
    headerContext.setContent(
      <>
        <NavHeader
          labelName={`${template ? `Sharing ${template?.name}` : ''}`}
          breadcrumb={{ name: 'Templates', onClick: () => history.push('/templates') }}
          hideNavItems={hideNavItems}
          showBreezeApp={showBreezeApp}
          preferencesUrl={preferencesUrl}
          showPreferences={showPreferencesMenuItem}
          userOrigin={settings?.account.userOrigin}
          onSharingPage
          getUsersGroups={getUsersGroups}
          flags={flags}
        />
        {pageTabs}
      </>
    );
    return () => {
      headerContext.setContent(null);
    };
  }, [currentTab, template]);

  useEffect(() => {
    if (showBottomLoader) {
      setShowBottomLoader(false);
    }
  }, [store.endReached]);

  const peopleTabColumns = [
    { key: 'check', label: '' },
    { key: 'name', label: 'Name', boldText: true },
    { key: 'email', label: 'Email' },
  ];

  const groupTabColumns = [
    { key: 'check', label: '' },
    { key: 'group', label: 'Group', boldText: true, sortKey: 'GROUP' },
    {
      key: 'people',
      label: 'People',
      cell: (subGroup: SubGroup) => (
        <Typography style={{ display: 'flex', marginLeft: '130px', color: colors.grey[700] }}>
          <PersonIcon />
          {subGroup.count}
        </Typography>
      ),
    },
  ];

  const officeTabColumns = [
    { key: 'check', label: '' },
    { key: 'officeName', label: 'Office', boldText: true, sortKey: 'OFFICE' },
  ];

  // changes in search input
  useEffect(() => {
    if (!oktaUserId) {
      return;
    }
    if (firstRenderDone) {
      setResetTableScroll(true);
      dispatch(setLoading(true));
      if (debouncedSearch === '') {
        // Reset default users
        dispatch(actions.fetchTemplateShareInformation(20, 1, debouncedSearch, params.id, oktaUserId, officeSharingEnabled));
        setIsFetching(false);
      } else {
        setResetTableScroll(true);
        // The limit doesn't matter here, we always want to fetch up to 20 users the first time we search for a user
        dispatch(actions.fetchUsers(20, 1, debouncedSearch));
    }
    }
  }, [debouncedSearch]);

  const fetchMoreUsers = React.useCallback(() => {
    if (users.length && !isFetching) {
      setIsFetching(true);
      setResetTableScroll(true);
      setShowBottomLoader(true);
      // For people tab in share template, using ceiling since the same user is removed from the list
      dispatch(actions.fetchUsers(20, Math.ceil(users.length / 20) + 1, debouncedSearch, true));
      setResetTableScroll(false);
    }
  }, [debouncedSearch, users, isFetching]);

  // There is change in ShareIds
  // Updates: users, offices and usersFromOffice if there was a change in in officce selection
  useEffect(() => {
    if (!oktaUserId) {
      return;
    }
    if (!sharedToAllParentGroups && firstRenderDone && officeSharingEnabled && !fetchingOfficeUsers && 
        sharedIds.some(s=> s.shareType === 'Office' || s.shareType === 'AllOffice')) {
      dispatch(actions.fetchingOfficeUsers());
      const officeGuids = getOfficesToSearch(sharedIds);
      dispatch(actions.fetchOfficeUsers(officeGuids));
    }
    // all sharedIds were cleaned, un-checked all users
    if (sharedIds.length === 0) { 
      const updatedUsers = users.map((u) => {
        return {
          ...u,
          isTemplateShared: false,
        };
      });
      dispatch(actions.updateUsers(updatedUsers));
    } else {
      const updatedUsers = users.map((u) => {
        const isUserExcluded = userExclusions.includes(u.oktaUserId);

        if (
          !isUserExcluded &&
          (sharedIds.some((s) => s.id === u.oktaUserId) ||
            (officeSharingEnabled && checkIfTemplateIsSharedToUserInOffice(u, usersFromOffice, userExclusions)) ||
            (checkIfTemplateSharedToAnyUsersGroup(sharedIds, u.userGroups)))
        ) {
          u.isTemplateShared = true;
        } else {
          u.isTemplateShared = false;
        }
        return u;
      });
      dispatch(actions.updateUsers(updatedUsers));
    }
  }, [sharedIds.length, usersFromOffice.length]);

  // There is a change in users
  // Sets UsersWithoutCurrentUser to render and clear all flags
  useEffect(() => {
    setUsersWithoutCurrentUser(users.filter((u) => u.id !== oktaUserId));
    setShowBottomLoader(false);
    setResetTableScroll(false);
    setIsFetching(false);
    setFirstRenderDone(true);
  }, [users]);

  useBottomScrollListener(() => {
    if (!endReached) {
      fetchMoreUsers();
    }
  });

  const handleShareTemplateToUser = (user: ITemplateUser) => {
    user.isTemplateShared = !user.isTemplateShared;
    const sharedToASubGroup = checkIfTemplateSharedToAnyUsersGroup(sharedIds, user.userGroups);
    const sharedToAnOffice = officeSharingEnabled
      ? usersFromOffice.some((u) => u.oktaUserID === user.oktaUserId)
      : false;
    dispatch(
      actions.toggleTemplateShareToUser(
        user,
        template.name,
        params.id,
        sharedToAllParentGroups,
        sharedToASubGroup,
        sharedToAnOffice
      )
    );
  };

  const handleShareTemplateToSubGroup = (group: SubGroup, alreadyShared: boolean) => {
    dispatch(
      actions.toggleTemplateShareToSubGroup(
        template,
        group,
        alreadyShared,
        sharedToAllParentGroups,
        subGroups,
        sharedIds
      )
    );
  };

  const handleShareTemplateToOffice = (office: UserOffice, alreadyShared: boolean) => {
    dispatch(
      actions.toggleTemplateShareToOffice(
        office.officeGuid,
        office.officeName,
        template.id,
        template.name,
        alreadyShared,
        sharedIds
      )
    );
  };

  const handleShareTemplateToAllGroups = (templateAlreadyShared: boolean) => {
    dispatch(
      actions.toggleTemplateShareToAllUsersGroups(templateAlreadyShared, params.id, officeSharingEnabled, offices)
    );
  };

  const handleSearchChange = (value: string) => {
    if (!alreadySearched && value !== '' && value.trim() !== '') {
      setAlreadySearched(true);
    }
    setSearch(value);
  };

  const toggleGroupSort = () => {
    dispatch(actions.toggleGroupsSort());
  };

  const toggleOfficeSort = () => {
    dispatch(actions.toggleOfficesSort());
  };

  const searchContent = () => {
    // If showing all users (no search), then the user is removed from the count
    // if it is a search result, remove users only at the time it was removed
    const totalUsersUpdate = Math.max(((!debouncedSearch && store.totalUsers)? 
      store.totalUsers -1 : 
      (usersWithoutCurrentUser.length === store.totalUsers? store.totalUsers: store.totalUsers -1 )), 0);
    return (
      <>
        <div className={classes.divider} />
        <div className={classes.tableControls}>
          <Typography variant="overline" className={classes.templateCount} data-spec="template-count">
            <PersonIcon className={classes.templateCountIcon} />
            {store.isFetching && !totalUsersUpdate
              ? 'FETCHING USERS...'
              : `${totalUsersUpdate} ${totalUsersUpdate === 1 ? 'PERSON' : 'PEOPLE'}`}
          </Typography>
        </div>
      </>
    );
  };

  const displaySubGroupsCount = () => {
    return (
      <div className={classes.tableControls}>
        <Typography variant="overline" className={classes.subGroupCount}>
          <GroupWorkIcon className={classes.subGroupCountIcon} />
          {`${subGroups.length} GROUP${subGroups.length === 1 ? '' : 'S'}`}
        </Typography>
      </div>
    );
  };

  const contentToDisplay = () => {
    if (!store.isLoading && store.settingsFetched && sharesFetched) {
      if (currentTab === 'People') {
        if (store.totalUsers === 0 && search) {
          return <NoResultsState resource={ResourceNames.TEMPLATE} />;
        }
        if (store.totalUsers) {
          return (
            <>
              {searchContent()}
              <Table
                columns={peopleTabColumns}
                rows={usersWithoutCurrentUser}
                showBottomLoader={showBottomLoader}
                resetScroll={resetTableScroll}
                checkboxOnClick={handleShareTemplateToUser}
                userExclusions={userExclusions}
                toggleTemplateShareToAllGroups={handleShareTemplateToAllGroups}
                isFetching={store.isFetching}
                sharedToAllParentGroups={sharedToAllParentGroups}
                currentTab={currentTab}
                onSharingPage
                sharedIds={sharedIds || []}
                subGroups={subGroups}
              />
            </>
          );
        }
      } else if (currentTab === 'Groups') {
        if (!subGroups.length) {
          return (
            <EmptyState
              image={subGroupsEmpty}
              heading="There are no groups, yet."
              subtitle="Contact your administrator for more information about groups."
            />
          );
        }
        return (
          <>
            {displaySubGroupsCount()}
            <Table
              columns={groupTabColumns}
              rows={subGroups}
              checkboxOnClick={handleShareTemplateToSubGroup}
              userExclusions={userExclusions}
              isFetching={store.isFetching}
              sharedToAllParentGroups={sharedToAllParentGroups}
              currentTab={currentTab}
              onSharingPage
              sharedIds={sharedIds}
              currentSortKey="GROUP"
              currentSortDesc={orderByDesc}
              onClickHeader={toggleGroupSort}
            />
          </>
        );
      } else {
        return (
          <Table
            columns={officeTabColumns}
            rows={offices}
            isFetching={store.isFetching}
            sharedIds={sharedIds}
            checkboxOnClick={handleShareTemplateToOffice}
            sharedToAllParentGroups={sharedToAllParentGroups}
            currentTab={currentTab}
            onSharingPage
            currentSortKey="OFFICE"
            currentSortDesc={orderOfficeByDesc}
            onClickHeader={toggleOfficeSort}
            cellClassName={classes.rowClassName}
          />
        );
      }
    }
    return (
      <>
        <div data-spec="skeleton-loading-table">
          {currentTab === 'People' && <div className={classes.divider} />}
          <div className={classes.tableControls}>
            <Typography variant="overline" className={classes.templateCount} data-spec="template-count">
              {currentTab === 'Groups' ? (
                <GroupWorkIcon className={classes.subGroupCountIcon} />
              ) : (
                <PersonIcon className={classes.templateCountIcon} />
              )}
              <span className={classes.foundSkeleton}>
                <Skeleton animation="wave" />
              </span>
            </Typography>
          </div>
          <SkeletonTable columns={currentTab === 'Groups' ? groupTabColumns : peopleTabColumns} />
        </div>
      </>
    );
  };

  return (
    <div className={!subGroups.length ? classes.emptyStateRoot : classes.root}>
      {currentTab === 'People' ? <Search data-spec="searchBar" value={search} onChange={handleSearchChange} /> : ''}
      {contentToDisplay()}
      <FooterLinks showSocialIcons />
    </div>
  );
};

export default withLaunchDarkly(TemplateShare);
