import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@skyslope/mache';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { IRecipient, RecipientRole } from '../types';
import Recipient from './Recipient';
import Droppable from '../../drag/Droppable';

const useStyles = makeStyles((theme: Theme) => ({
  groupHeader: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(4),
    paddingBottom: 16,
    borderBottom: `1px solid ${colors.grey[400]}`,
  },
  newGroup: {
    border: `2px dashed ${colors.blue[100]}`,
    textAlign: 'center',
    padding: theme.spacing(2),
    color: colors.grey[700],
    marginTop: 16,
  },
  newGroupActive: {
    backgroundColor: colors.blue[50],
  },
  dropIndicator: {
    width: '100%',
    height: 3,
    marginTop: 8,
    marginBottom: -11,
    backgroundColor: colors.blue[800],
    position: 'relative',
    '&:after': {
      width: 4,
      height: 4,
      backgroundColor: '#ffffff',
      border: `2px solid ${colors.blue[800]}`,
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      marginTop: -2,
      marginLeft: -2,
    },
  },
}));

interface IProps {
  groupId?: string;
  recipients?: IRecipient[];
  headerText: string;
  updateRecipient: (recipientId: string, newRole: RecipientRole, groupId?: string) => void;
  groupRole: RecipientRole;
  modal: boolean;
  isOver: boolean;
  disableRecipientList?: boolean;
}

const RecipientGroup = (props: IProps) => {
  const classes = useStyles();
  const { modal, isOver, disableRecipientList } = props;

  return (
    <Droppable id={props.groupId || props.groupRole}>
      <div data-spec="recipient-group">
        <Typography variant="overline" className={classes.groupHeader}>
          {props.headerText}
        </Typography>
        <div>
          {props.recipients?.length ? (
            <>
              {props.recipients.map((r) => (
                <div key={r.id}>
                  <Recipient recipient={r} modal={modal} isMobile={disableRecipientList} />
                </div>
              ))}
              {isOver ? <div className={classes.dropIndicator} /> : ''}
            </>
          ) : (
            <div className={`${classes.newGroup}  ${isOver ? classes.newGroupActive : ''}`}>
              Drag signer here to add a group
            </div>
          )}
        </div>
      </div>
    </Droppable>
  );
};

export default RecipientGroup;
