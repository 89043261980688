// @ts-nocheck
import { useEffect, useState } from 'react';
import useUserInfo from '../auth/useUserInfo';
import { getUserEnvelopeCounts } from '../lib/utils';

function initChmln() {
  /* Chameleon - better user onboarding */ !(function (t, n, o) {
    var a = 'chmln',
      e = 'adminPreview',
      c = 'setup identify alias track clear set show on off custom help _data'.split(' ');
    if ((n[a] || (n[a] = {}), n[a][e] && (n[a][e] = !1), !n[a].root)) {
      (n[a].accountToken = o), (n[a].location = n.location.href.toString()), (n[a].now = new Date());
      for (var s = 0; s < c.length; s++)
        !(function () {
          var t = (n[a][c[s] + '_a'] = []);
          n[a][c[s]] = function () {
            t.push(arguments);
          };
        })();
      var i = t.createElement('script');
      (i.src = 'https://fast.trychameleon.com/messo/' + o + '/messo.min.js'), (i.async = !0), t.head.appendChild(i);
    }
  })(document, window, 'SIMVvFC0cW5aRmXMWnBWuSrsLp6oY3utpm8ZD5ciik5ByF-1H74NM-Bgha6VrqZM0nduVB');
}

export default function useChameleon() {
  const [init, setInit] = useState(false);
  const userInfo = useUserInfo();

  useEffect(() => {
    if (!init && userInfo) {
      initChmln();
      getUserEnvelopeCounts().then((res) => {
        window.chmln?.identify(userInfo.oktaUserId, {
          Email: userInfo.senderEmail,
          FirstName: userInfo.senderFirstName,
          LastName: userInfo.senderLastName,
          Ds3UserId: userInfo.senderId,
          SubscriberId: userInfo.subscriberId,
          UserType: userInfo.primeContactType,
          FnfUser: !!userInfo.digisign_fnf_account_id,
          Ds3EnvelopesCreated: res.Ds3EnvelopesCreated,
          Ds3EnvelopesSent: res.Ds3EnvelopesSent,
          Ds3EnvelopesCompleted: res.Ds3EnvelopesCompleted,
        });
      });
      setInit(true);
    }
  }, [init, userInfo]);
}
