import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { colors } from '@skyslope/mache';
import { useDropzone, FileRejection } from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from '@material-ui/lab/Skeleton';
import { IDoc } from './types';
import { ReactComponent as UploadIcon } from '../../images/upload.svg';
import Document from './Document';
import { removeSpecialCharacters } from '../utils';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
  uploadCircleWrapper: {
    width: '300px',
    height: '300px',
    '@media (max-height: 768px)': {
      width: '175px',
      height: '175px',
    },
    '@media (max-width: 768px)': {
      width: '175px',
      height: '175px',
    },
    '@media (max-width: 600px)': {
      width: '125px',
      height: '125px',
    },
  },
  uploadCircle: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    border: `2px dashed ${colors.grey[400]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  uploadCircleOverlay: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: 'rgba(225, 241, 255, 0.9)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    fontSize: 160,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    userSelect: 'none',
  },
  pdfOnlyText: {
    marginTop: theme.spacing(3),
    color: colors.grey[500],

    '@media (max-height: 768px)': {
      display: 'none',
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  uploadText: {
    color: colors.grey[700],
    marginTop: 24,
    marginBottom: 24,
    textAlign: 'center',
  },
  uploadIcon: {
    '@media (max-width: 600px)': {
      width: '75px',
    },
  },
  addIcon: {
    height: 20,
    width: 20,
    margin: '-4px 4px -4px -4px',
  },
  documents: {
    width: '100%',
  },
  documentsFooter: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    minHeight: '55px',
    borderTop: `1px solid ${colors.grey[400]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
}));

interface IProps {
  documents: IDoc[];
  handleUploadDocument: (tempId: string, file: File) => void;
  handleReorderDocuments: (documents: IDoc[]) => void;
  handleDeleteDocument: (documentId: string) => void;
  handleUpdateDocuments: (documents: IDoc[]) => void;
  handleError: (errStr: string) => void;
  progressPercentageForDocuments: any;
  header?: (obj: { isDragActive: boolean; open: Function }) => void;
  footer?: (obj: { isDragActive: boolean; open: Function }) => void;
  inlineRemoveButton?: boolean;
  smallerPadding?: boolean;
  rootClassName?: string;
  documentsClassName?: string;
  isFetching: boolean;
  envelopeDocumentsFetchComplete: boolean;
  disableDelete: boolean;
}

const isUploading = (documents: IDoc[]) => {
  return documents.some(doc => doc.isUploading);
};

const Documents = (props: IProps) => {
  const { documents } = props;
  const classes = useStyles(props);

  const [documentsCopy, setDocumentsCopy] = React.useState(documents);
  const [documentsToReorder, setDocumentsToReorder] = React.useState<IDoc[]>([]);
  const [documentsUploading, setDocumentsUploading] = React.useState(false);

  const handleDragEnd = event => {
    const { active, over, activatorEvent } = event;
    const classNameIsString = typeof activatorEvent.target.className === 'string';
    if (classNameIsString && activatorEvent.target.className?.includes('Button') && activatorEvent.target.innerText === 'Remove') {
      props.handleDeleteDocument(active.id);
    }
    else if (active.id !== over.id) {
      const { index } = findDocument(over.id);
      handleDropDocument(active.id, index);
    }
  };

  React.useEffect(() => {
    setDocumentsUploading(isUploading(documents));
    setDocumentsCopy(documents);
  }, [documents]);

  React.useEffect(() => {
    if (!documentsUploading && documentsToReorder.length > 0) {
      props.handleReorderDocuments(documentsCopy);
      setDocumentsToReorder([]);
    } else if (!documentsUploading && documents.length > 0) {
      props.handleReorderDocuments(documents);
    }
  }, [documentsUploading]);

  const onDrop = React.useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    acceptedFiles.forEach(file => {
      const tempId = uuidv4();
      props.handleUploadDocument(tempId, file);
    });

    if (!rejectedFiles || rejectedFiles.length === 0) return;

    if (rejectedFiles[0].errors[0]?.code === 'file-invalid-type') {
      props.handleError('Only documents of type PDF, DOCX, DOC and DOCM are accepted.');
    } else {
      props.handleError('Only documents smaller than 25MB are accepted!');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: 'application/pdf,.docx,.doc,.docm',
    noClick: true,
    maxSize: 25 * 1024 * 1024,
    onDrop,
  });

  const findDocument = (id: string) => {
    const document = documentsCopy.filter(c => `${c.documentId}` === id)[0];
    return {
      document,
      index: documentsCopy.indexOf(document),
    };
  };

  const handleDropDocument = (draggedId: string, overIndex: number) => {
    const { document, index } = findDocument(draggedId);
    const tempDocs = update(documentsCopy, {
      $splice: [
        [index, 1],
        [overIndex, 0, document],
      ],
    });
    if (isUploading(documentsCopy)) {
      props.handleUpdateDocuments(tempDocs);
      setDocumentsToReorder(tempDocs);
    } else {
      props.handleReorderDocuments(tempDocs);
    }
  };

  const renderHeader = () => {
    if (props.header) {
      return props.header({ isDragActive, open });
    }
    if (documents.length === 0) {
      return (
        <>
          <div className={classes.uploadCircleWrapper}>
            <div className={classes.uploadCircle}>
              {isDragActive && <div className={classes.uploadCircleOverlay}>+</div>}
              <UploadIcon className={classes.uploadIcon} />
              <Typography variant="body1" className={classes.pdfOnlyText}>
                Documents of types PDF, DOC, DOCX and DOCM are accepted
                <br />
                Maximum Size 25MB
              </Typography>
            </div>
          </div>
          <Typography variant="body1" className={classes.uploadText}>
            Drag and drop your files here or
            <br />
            use the button below.
          </Typography>
        </>
      );
    }
  };

  const renderFooter = () => {
    if (props.footer) {
      return props.footer({ isDragActive, open });
    }
    return (
      <div className={classes.documentsFooter}>
        <Button color="primary" onClick={open}>
          <AddIcon className={classes.addIcon} /> Upload Document
        </Button>
      </div>
    );
  };

  const renderDocuments = () => {
    if (props.isFetching && !props.envelopeDocumentsFetchComplete) {
      const skeletonArray = new Array(3).fill(<Skeleton animation="wave" style={{ height: '100%' }} />);
      return (
        <div className={`${classes.documents} ${props.documentsClassName || ''}`}>
          {skeletonArray.map(skeleton => {
            return (
              <div
                style={{
                  height: '71px',
                  border: '1px solid #CAD5E0',
                  padding: '12px',
                  marginTop: '12px',
                  marginBottom: '12px',
                }}
              >
                {skeleton}
              </div>
            );
          })}
        </div>
      );
    }
    if (documentsCopy.length > 0) {
      return (
        <div className={`${classes.documents} ${props.documentsClassName || ''}`} data-spec="documents">
          {documentsCopy.map((document, index) => {
            const docId = document.documentId ? document.documentId : document.id;
            return (
              <Document
                firstDocument={index === 0}
                key={docId}
                order={index}
                id={docId}
                isUploading={document.isUploading!}
                fileName={removeSpecialCharacters(document.fileName)}
                numberOfPages={document.numberOfPages!}
                findDocument={findDocument}
                handleDeleteDocument={props.handleDeleteDocument}
                totalDocuments={documents.length}
                progressPercentageForDocuments={props.progressPercentageForDocuments}
                inlineRemoveButton={props.inlineRemoveButton}
                smallerPadding={props.smallerPadding}
                disableDelete={props.isFetching || document.isUploading || props.disableDelete}
              />
            );
          })}
        </div>
      );
    }
    return null;
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} autoScroll={false}>
      <SortableContext items={documentsCopy.map(doc => doc.documentId)} strategy={verticalListSortingStrategy}>
        <div {...getRootProps({ className: `${classes.dropZone} ${props.rootClassName || ''}` })}>
          <input {...getInputProps()} />
          {renderHeader()}
          {renderDocuments()}
          {renderFooter()}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default Documents;
