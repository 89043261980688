import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import { StyledMenu, StyledMenuItem } from '../common/StyledMenu';
import { STATUSES } from './utils';
import Pill from '../common/Pill';

interface IProps {
  statuses: string[];
  statusFilter: string;
  setStatusFilter: (status: string) => void;
  selectedStatus: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: colors.grey[800],
    marginTop: '10px',
    '&:focus': {
      border: `1px solid ${colors.blue[100]}`,
    },
  },
  statusIcon: {
    color: colors.grey[500],
  },
  filterText: {
    marginRight: theme.spacing(1),
  },
  statusText: {
    margin: '0 5px',
  },
}));

const FilterStatus = (props: IProps) => {
  const { statuses, statusFilter, setStatusFilter, selectedStatus } = props;
  const buttonRef = React.useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classes = useStyles();
  const currentStatusFilter = STATUSES[statusFilter];
  const filtersToDisplay = statuses.filter((status: string) => status !== selectedStatus);

  const openMenu = (event: any) => {
    setIsMenuOpen(true);
    buttonRef.current = event.currentTarget;
  };

  const handleSetStatusFilter = (status: string) => {
    setIsMenuOpen(false);
    setStatusFilter(status);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Typography variant="body1" className={classes.filterText}>
          Filter results by:
        </Typography>
        <Button onClick={openMenu} aria-label="Status Filter">
          <WidgetsIcon className={classes.statusIcon} />
          <span className={classes.statusText}>Status</span>
          <ArrowDropDownIcon />
        </Button>
        <StyledMenu
          keepMounted
          anchorEl={buttonRef.current}
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {filtersToDisplay.map((status: string) => (
            <StyledMenuItem
              aria-label={`${status} filter`}
              data-spec={`filter-status-menu-item-${STATUSES[status].key}`}
              key={STATUSES[status].key}
              onClick={() => handleSetStatusFilter(status)}
            >
              {STATUSES[status].label}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </div>
      {statusFilter ? (
        <div className={classes.wrapper}>
          <Pill
            key={currentStatusFilter.key}
            text={currentStatusFilter.label}
            onDelete={() => setStatusFilter('')}
            icon={<WidgetsIcon />}
            pillDetail="filter"
          />
        </div>
      ) : null}
    </>
  );
};

export default FilterStatus;
