import { action } from 'typesafe-actions';
import { IFileResponse } from '../../../dtm/types';

export enum ActionTypes {
  FETCH_PROPERTIES = 'dtm/FETCH_PROPERTIES',
  FETCH_PROPERTIES_DONE = 'dtm/FETCH_PROPERTIES_DONE',
  FETCH_PROPERTIES_ERROR = 'dtm/FETCH_PROPERTIES_ERROR',
  END_OF_PROPERTIES_REACHED = 'dtm/END_OF_PROPERTIES_REACHED',
  RESET_END_OF_PROPERTIES_REACHED = 'dtm/RESET_END_OF_PROPERTIES_REACHED',
  RESET_PROPERTIES = 'dtm/RESET_PROPERTIES',
}

export const fetchProperties = ({
  searchValue,
  sortAsc,
  searchType,
}: {
  searchValue: string;
  sortAsc: boolean;
  searchType: string;
}) => action(ActionTypes.FETCH_PROPERTIES, { searchValue, sortAsc, searchType });
export const fetchPropertiesDone = (
  properties: IFileResponse,
  lastSalesCursor: string,
  lastListingCursor: string,
  searchType: string
) => action(ActionTypes.FETCH_PROPERTIES_DONE, { properties, lastSalesCursor, lastListingCursor, searchType });
export const endOfPropertiesReached = () => action(ActionTypes.END_OF_PROPERTIES_REACHED);
export const resetEndOfPropertiesReached = () => action(ActionTypes.RESET_END_OF_PROPERTIES_REACHED);
export const resetProperties = () => action(ActionTypes.RESET_PROPERTIES);
