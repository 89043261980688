import { action } from 'typesafe-actions';
import { IEnvelopeManagementState, IEnvelopeMetadata } from './types';

export enum ActionTypes {
  RESET = 'envelopeManagement/RESET',
  FETCH_ENVELOPES = 'envelopeManagement/FETCH_ENVELOPES',
  FETCH_ENVELOPES_DONE = 'envelopeManagement/FETCH_ENVELOPES_DONE',
  CREATE_NEW_ENVELOPE = 'envelopeManagement/CREATE_NEW_ENVELOPE',
  CREATE_NEW_ENVELOPE_DONE = 'envelopeManagement/CREATE_NEW_ENVELOPE_DONE',
  DELETE_ENVELOPE = 'envelopeManagement/DELETE_ENVELOPE',
  DELETE_ENVELOPE_DONE = 'envelopeManagement/DELETE_ENVELOPE_DONE',
  CANCEL_ENVELOPE = 'envelopeManagement/CANCEL_ENVELOPE',
  CANCEL_ENVELOPE_DONE = 'envelopeManagement/CANCEL_ENVELOPE_DONE',
  OPEN_PDF_PREVIEW = 'envelopeManagement/OPEN_PDF_PREVIEW',
  CLOSE_PDF_PREVIEW = 'envelopeManagement/CLOSE_PDF_PREVIEW',
  UPDATE_ENVELOPES = 'envelopeManagement/UPDATE_ENVELOPES',
  CORRECT_ENVELOPE = 'envelopeManagement/CORRECT_ENVELOPE',
  CORRECT_ENVELOPE_DONE = 'envelopeManagement/CORRECT_ENVELOPE_DONE',
  RENAME_ENVELOPE = 'envelopeManagement/RENAME_ENVELOPE',
  RENAME_ENVELOPE_DONE = 'envelopeManagement/RENAME_ENVELOPE_DONE',
}

export const reset = (newStore?: IEnvelopeManagementState) => action(ActionTypes.RESET, newStore);
export const fetchEnvelopes = (search = '', status = 'All', orderBy = 'DATE_UPDATED', orderByDesc = true) =>
  action(ActionTypes.FETCH_ENVELOPES, { search, status, orderBy, orderByDesc });
export const fetchEnvelopesDone = (
  search: string,
  status: string,
  orderBy: string,
  orderByDesc: boolean,
  envelopes: IEnvelopeMetadata[],
  totalEnvelopeCount: number,
  endReached: boolean
) =>
  action(ActionTypes.FETCH_ENVELOPES_DONE, {
    search,
    status,
    orderBy,
    orderByDesc,
    envelopes,
    totalEnvelopeCount,
    endReached,
  });
export const createNewEnvelope = (impersonatedPrimeUserGuid?: string) => action(ActionTypes.CREATE_NEW_ENVELOPE, impersonatedPrimeUserGuid);
export const createNewEnvelopeDone = (envelope: IEnvelopeMetadata) =>
  action(ActionTypes.CREATE_NEW_ENVELOPE_DONE, envelope);
export const deleteEnvelope = (envelopeId: string) => action(ActionTypes.DELETE_ENVELOPE, envelopeId);
export const envelopeDeleted = (envelopeId: string) => action(ActionTypes.DELETE_ENVELOPE_DONE, envelopeId);
export const cancelEnvelope = (envelopeId: string, cancelBody: { canceledReason: string }) =>
  action(ActionTypes.CANCEL_ENVELOPE, { envelopeId, cancelBody });
export const envelopeCanceled = (envelopeId: string) => action(ActionTypes.CANCEL_ENVELOPE_DONE, envelopeId);
export const openPdfPreview = (envelope: IEnvelopeMetadata) => action(ActionTypes.OPEN_PDF_PREVIEW, envelope);
export const closePdfPreview = () => action(ActionTypes.CLOSE_PDF_PREVIEW);
export const updateEnvelopes = (envelopes: IEnvelopeMetadata[]) => action(ActionTypes.UPDATE_ENVELOPES, envelopes);
export const correctEnvelope = (envelopeId: string) => action(ActionTypes.CORRECT_ENVELOPE, envelopeId);
export const correctEnvelopeDone = (envelopeId: string) => action(ActionTypes.CORRECT_ENVELOPE_DONE, envelopeId);
export const renameEnvelope = (envelopeId: string, updatedName: string) => action(ActionTypes.RENAME_ENVELOPE, { envelopeId, updatedName });
export const renameEnvelopeDone = (envelopeId: string, updatedName: string) => action(ActionTypes.RENAME_ENVELOPE_DONE, { envelopeId, updatedName });
