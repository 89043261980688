import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  Button,
} from '@material-ui/core';
import { colors } from '@skyslope/mache';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(4, 4, 1),
  },
  title: {
    color: colors.grey[900],
    fontWeight: 'bold',
    fontSize: theme.spacing(3.25),
  },
  body: {
    color: colors.grey[800],
    fontWeight: 400,
    fontSize: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1.75, 4),
    margin: theme.spacing(2),
    borderRadius: theme.spacing(4),
  },
}));

export type InfoBox = {
  title?: string;
  body?: string;
};

interface InfoBoxDialogProps {
  isOpen: boolean;
  onClose: () => void;
  infoBox?: InfoBox;
}

export const InfoBoxDialog = ({ isOpen, onClose, infoBox }: InfoBoxDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog classes={{ paper: classes.dialog }} open={isOpen} onClose={() => onClose()} maxWidth="md">
      <DialogTitle>
        <Typography variant="h4" className={classes.title}>
          {infoBox?.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.body}>{infoBox?.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" className={classes.button} onClick={() => window.history.back()}>
          Back
        </Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={onClose}>
          Let&apos;s Get Started!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoBoxDialog;
