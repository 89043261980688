import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    '&:first-child': {
      borderRight: `1px solid ${colors.grey[400]}`,
    },
  },
}));

interface IProps {
  children: ReactNode;
  id?: string;
}

const Section = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.section} id={props.id}>
      {props.children}
    </div>
  );
};

export default Section;
