import update from 'immutability-helper';
import { ITemplateTag } from '../templateCreate/types';
import { ActionTypes } from './actions';
import { ITemplateTaggingState } from './types';

export const initialState: ITemplateTaggingState = {
  name: '',
  tagsById: {},
  saveDone: false,
};

export default (state: ITemplateTaggingState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_TAGS_DONE: {
      const tagsById = {};
      action.payload.tags.forEach((tag: ITemplateTag) => {
        if (tag.tagTypeId in tagsById) {
          tagsById[tag.tagTypeId].push(tag.value);
        } else {
          tagsById[tag.tagTypeId] = [tag.value];
        }
      });
      return update(state, {
        name: { $set: action.payload.name },
        tagsById: { $set: tagsById },
      });
    }
    case ActionTypes.CHANGE_TAG: {
      return update(state, {
        tagsById: {
          [action.payload.tagTypeId]: { $set: action.payload.values },
        },
      });
    }
    case ActionTypes.CHANGE_NAME: {
      return update(state, {
        name: { $set: action.payload },
      });
    }
    case ActionTypes.SAVE_DONE: {
      return update(state, {
        saveDone: { $set: true },
      });
    }
    default: {
      return state;
    }
  }
};
