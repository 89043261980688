import { useEffect, useState } from 'react';
import useUserInfo from '../auth/useUserInfo';

export default function useClarity() {
  const [init, setInit] = useState(false);
  const userInfo = useUserInfo();

  useEffect(() => {
    if (!init && userInfo) {
      window.clarity('set', 'okta_id', userInfo.oktaUserId);
      window.clarity('set', 'pathname', window.location.pathname);
      setInit(true);
    }
  }, [init, userInfo]);
}
