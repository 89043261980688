import React, { useState, useEffect } from 'react';
import MailIcon from '@material-ui/icons/Mail';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { generateEmailSubject, generateEmailBody } from '../../lib/utils';
import { IEmailConfig } from '../../store/senderBuild/types';
import { IRootState } from '../../store';
import { useShallowSelector } from '../../lib/reduxHooks';
import EditEmailModal from './EditEmailModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    icon: {
      color: colors.grey[500],
      cursor: 'pointer',
    },
    emailSubjectWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: '#3F5B77',
      marginTop: 4,
    },
    emailBodyWrapper: {
      display: 'flex',
      marginLeft: '46px',
      wordBreak: 'break-word',
      color: colors.grey[800],
      lineHeight: '24px',
    },
    emailBody: {
      whiteSpace: 'break-spaces',
      marginTop: 0,
      width: 232,
    },
    subjectWrap: {
      wordBreak: 'break-word',
      overflow: 'hidden',
      paddingRight: '11px',
      position: 'relative',
      lineHeight: '24px',
      maxHeight: '48px',
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      textTransform: 'uppercase',
      alignItems: 'center',
      height: 48,
    },
  })
);

const emailSettingsSelector = (state: IRootState) => ({
  emailConfig: state.senderBuild.emailConfig,
  id: state.senderBuild.id,
});

interface IEmailSettingsState {
  emailConfig: IEmailConfig;
  id: string;
}

interface IProps {
  menuAnchor: HTMLElement | null;
  closeMenu: () => void;
}

export const EmailSettings = (props: IProps) => {
  const classes = useStyles();
  const { menuAnchor, closeMenu } = props;
  const { emailConfig }: IEmailSettingsState = useShallowSelector(emailSettingsSelector);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [showCustomEmail, setShowCustomEmail] = useState<Boolean>(false);
  const [showEditEmailModal, setShowEditEmailModal] = useState(false);

  const toggleModal = () => {
    setShowEditEmailModal(!showEditEmailModal);
    if (showEditEmailModal) {
      closeMenu();
    }
  };

  const subjectRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (emailConfig.loading) {
      setShowCustomEmail(true);
    } else {
      setShowCustomEmail(false);
      setSubjectAndBody();
    }
  }, [emailConfig.loading]);

  useEffect(() => {
    const element = document.querySelector('h4');
    if (element) {
      ellipsisText(element);
    }
  }, [showCustomEmail]);

  const setSubjectAndBody = () => {
    setEmailSubject(emailConfig.subject ? emailConfig.subject : generateEmailSubject());
    setEmailBody(emailConfig.body ? emailConfig.body : generateEmailBody());
  };

  const ellipsisText = (e: any) => {
    const charArray = e.textContent.split('');
    while (e.scrollHeight > e.offsetHeight) {
      charArray.pop();
      e.innerHTML = charArray.join('') + '...';
    }
  };

  return (
    <>
      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={closeMenu}>
        <MenuItem onClick={toggleModal} data-spec="editMessage">
          Edit Message
        </MenuItem>
      </Menu>
      <div className={classes.emailSubjectWrapper}>
        <MailIcon className={classes.icon} style={{ marginRight: '16px', fontSize: '30px', cursor: 'default' }} />
        <h4
          data-spec="subject"
          style={{ marginTop: '0px', marginBottom: '0px' }}
          className={classes.subjectWrap}
          ref={subjectRef}
        >
          {emailSubject}
        </h4>
      </div>
      <div className={classes.emailBodyWrapper}>
        <p data-spec="body" className={classes.emailBody}>
          {emailBody}
        </p>
      </div>
      {showEditEmailModal ? (
        <EditEmailModal toggleModal={toggleModal} subject="Edit message" action="Save" send={false} />
      ) : null}
    </>
  );
};

export default EmailSettings;
