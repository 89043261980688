import { ActionTypes } from './actions';
import update from 'immutability-helper';
import { DocumentStatus, IDocument, IDocumentsState } from './types';

export const initialState: IDocumentsState = {
  envelopeId: '',
  file: undefined,
  fileDocuments: [],
  envelopeDocuments: [],
  isFetching: true,
  fetchError: false,
  allDocumentsUploaded: false,
  envelopeDocumentsFetchComplete: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH: {
      return update(state, {
        envelopeId: { $set: action.payload.envelopeId },
      });
    }
    case ActionTypes.FETCH_DONE: {
      const allDocumentsUploaded: boolean = action.payload.envelopeDocuments.every(
        (doc: IDocument) => doc.uploadStatus === DocumentStatus.Uploaded
      );
      const hasError: boolean = action.payload.envelopeDocuments.every(
        (doc: IDocument) => doc.uploadStatus === DocumentStatus.Failed
      );
      return update(state, {
        isFetching: { $set: false },
        file: { $set: action.payload.file },
        fileDocuments: { $set: action.payload.fileDocuments },
        envelopeDocuments: { $set: action.payload.envelopeDocuments },
        allDocumentsUploaded: { $set: allDocumentsUploaded },
        fetchError: { $set: hasError },
      });
    }
    case ActionTypes.SET_DOCUMENTS: {
      const documents = action.payload;
      return update(state, {
        envelopeDocuments: { $set: documents },
        envelopeDocumentsFetchComplete: { $set: true },
      });
    }
    case ActionTypes.API_ERROR: {
      return { ...state, isFetching: false, fetchError: true };
    }
    case ActionTypes.UPLOAD_DOCUMENT: {
      const newDocument = {
        fileName: action.payload.file.name,
        url: '',
        documentId: action.payload.tempId,
        numberOfPages: 0,
        blocks: [],
        blockGroups: [],
        isUploading: true,
      };
      return update(state, { envelopeDocuments: { $push: [newDocument] } });
    }

    case ActionTypes.UPLOAD_PRIME_DOCUMENT: {
      const newDocument = {
        fileName: action.payload.fileName,
        url: '',
        documentId: action.payload.tempId,
        numberOfPages: 0,
        blocks: [],
        blockGroups: [],
        isUploading: true,
      };
      return update(state, { envelopeDocuments: { $push: [newDocument] } });
    }
    case ActionTypes.UPLOAD_DOCUMENT_DONE: {
      const i = state.envelopeDocuments.findIndex((d) => d.documentId === action.payload.tempId);
      return update(state, {
        envelopeDocuments: {
          [i]: {
            documentId: { $set: action.payload.document.documentId },
            numberOfPages: { $set: action.payload.document.numberOfPages },
            isUploading: { $set: false },
          },
        },
      });
    }
    case ActionTypes.UPDATE_DOCUMENTS:
    case ActionTypes.REORDER_DOCUMENTS: {
      return update(state, {
        envelopeDocuments: { $set: action.payload },
      });
    }
    case ActionTypes.DELETE_DOCUMENT: {
      const index = state.envelopeDocuments.findIndex((d) => d.documentId === action.payload);
      return update(state, {
        envelopeDocuments: { $splice: [[index, 1]] },
      });
    }
    default: {
      return state;
    }
  }
};
