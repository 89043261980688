import axios from 'axios';
import { IBlock } from '../../../../store/senderBuild/types';
import { TEMPLATES_URL } from '../../../constants';
import { graphQlRequest } from '../index';
import { headers } from '../../common/helper';
import { getAccessToken } from '../../../../auth/oktaConfig';

export const addBlock = async (templateId: string, documentId: string, block: IBlock) => {
  const accessToken = await getAccessToken();

  const blockWithValueCleanedUp = cleanBlockForAdd(block);
  const { data, status } = await axios.post(
    `${TEMPLATES_URL}/templates/${templateId}/documents/${documentId}/blocks`,
    blockWithValueCleanedUp,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};
const cleanBlockForAdd = (block: IBlock) => {
  const strings = ['Signature', 'Initials', 'FullName', 'DateSigned', 'Strike', 'Rectangle'];
  if (strings.includes(block.blockType)) {
    const { value, ...blockWithoutValue } = block;
    return blockWithoutValue;
  } else if (['Checkbox', 'ReadOnlyCheckbox'].includes(block.blockType)) {
    // @ts-ignore
    return { ...block, value: block.value === true };
  }
  return { ...block, value: block.value || '' };
};

export const editBlock = async (templateId: string, documentId: string, block: IBlock) => {
  const accessToken = await getAccessToken();
  const { status } = await axios.put(
    `${TEMPLATES_URL}/templates/${templateId}/documents/${documentId}/blocks/${block.blockId}`,
    block,
    headers(accessToken)
  );
  return status;
};

export const deleteBlocks = async (templateId: string, blockIds: string[]) => {
  await graphQlRequest(
    `
    mutation {
      deleteBlocks(templateId: "${templateId}", blockIds: ${JSON.stringify(blockIds)})
    }
  `
  );
};
