import React, { useEffect, useRef, useState } from "react";
import { timeoutInterval } from "../lib/constants";
import IdleTimer from '../lib/IdleTimer';
import TimeoutModal from "./TimeoutModal";


export default function TimeoutModalWrapper() {
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const timer: any = useRef();

  const createIdleTimer = () => {
    timer.current = new IdleTimer({
      timeout: timeoutInterval,
      onTimeout: () => {
        setShowTimeoutModal(true);
      },
    });
    return () => {
      timer.current.cleanUp();
    };
  };

  const storageListener = (e: StorageEvent) => {
    if (e.key === '_modalOpen' || e.key === '_expiredTime') {
      const isModalOpen = localStorage.getItem('_modalOpen');
      if (isModalOpen === 'false') {
        setShowTimeoutModal(false);
      } else if (isModalOpen === 'true') {
        setShowTimeoutModal(true);
      }
    }
  };

  useEffect(() => {
    if (window.location.pathname !== '/') {
      const expiredTime = localStorage.getItem('_expiredTime');
      if (expiredTime) {
        const newExpiredTime = Date.now() + timeoutInterval * 1000;
        localStorage.setItem('_expiredTime', newExpiredTime);
      }
      createIdleTimer();
      localStorage.setItem('_modalOpen', false);
      window.addEventListener('storage', storageListener);
      return () => {
        window.removeEventListener('storage', storageListener);
      };
    } 
      return () => {
        window.removeEventListener('storage', storageListener);
      };
    
  }, [window.location.pathname]);

  useEffect(() => {
    if (showTimeoutModal) {
      localStorage.setItem('_modalOpen', true);
    } else {
      localStorage.setItem('_modalOpen', false);
    }
  }, [showTimeoutModal]);

  if (!showTimeoutModal) return null;
  			
  return (
    <TimeoutModal
      closeModal={() => {
        setShowTimeoutModal(false);
      }}
    />
  );
};

