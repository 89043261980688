export interface IContact {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  city: string;
  fax: string;
  isCompany: boolean;
  isShared: boolean;
  phone: string;
  state: string;
  streetName: string;
  streetNumber: string;
  zip: string;
}

export enum UserOrigins {
  Forms = 'FORMS',
  DOP = 'DOP',
  Prime = 'PRIME',
}
