import React, { useState } from 'react';

interface IContextState {
  content: JSX.Element | null;
  setContent: (content: JSX.Element | null) => void;
}

export const HeaderContext = React.createContext({} as IContextState);

const HeaderContextProvider = (props: any) => {
  const [content, setContent] = useState<JSX.Element | null>(null);

  return <HeaderContext.Provider value={{ content, setContent }}>{props.children}</HeaderContext.Provider>;
};

export default HeaderContextProvider;
