import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Button } from '@material-ui/core';
import produce from 'immer';
import { useParams } from 'react-router-dom';
import Table from '../common/Table';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import {
  IAuditHistoryBody,
  IEnvelopeHistoryBody,
  IEventData,
  IRecipientAddOrDeleteEventData,
  IRecipientBody,
} from '../../store/envelopeHistory/types';
import { IRootState } from '../../store';
import { getTimezoneCode, concatStrings, bold, boldAll, concatObject } from '../../lib/utils';
import { eventNames, emailTypes , EnvelopeStatuses } from '../../lib/constants';
import ResendModal from '../envelopeManagement/ResendModal';
import * as auditHistoryActions from '../../store/envelopeHistory/actions';
import { hideAlert } from '../../store/pageFrame/actions';
import { LaunchDarklyFlags } from '../../common/launchDarkly';

dayjs.extend(advancedFormat);

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  activityWrap: {
    maxWidth: '541px',
    wordWrap: 'break-word',
  },
}));

interface IState {
  isFetching: boolean;
  auditHistory: IAuditHistoryBody[];
  envelope: IEnvelopeHistoryBody;
  recipientsWhoHaveNotViewed: IRecipientBody[];
}

const selector = (state: IRootState) => ({
  isFetching: state.envelopeHistory.isFetching,
  auditHistory: state.envelopeHistory.auditHistory,
  envelope: state.envelopeHistory.envelope,
  recipientsWhoHaveNotViewed: state.envelopeHistory.recipientsWhoHaveNotViewed,
});

const emailNames = {
  [emailTypes.ReviewAndSign]: 'Review And Sign',
  [emailTypes.Completed]: 'Envelope Completed',
  [emailTypes.Canceled]: 'Envelope Canceled',
};

type RecipientInfoMap = { [id: string]: IRecipientBody };
type AuditEventWithRecipientInfo = IAuditHistoryBody & { recipientInfo: RecipientInfoMap };

const isRecipientUpdate = (event: IAuditHistoryBody) => {
  return event.eventName === eventNames.SignerUpdated || event.eventName === eventNames.CarbonCopyUpdated;
};

const isRecipientAdd = (event: IAuditHistoryBody) => {
  return event.eventName === eventNames.SignerAdded || event.eventName === eventNames.CarbonCopyAdded;
};

const isRecipientDelete = (event: IAuditHistoryBody) => {
  return event.eventName === eventNames.SignerDeleted || event.eventName === eventNames.CarbonCopyDeleted;
};

const getEventsWithRecipientInfo = (
  events: IAuditHistoryBody[],
  recipientInfoMap: RecipientInfoMap
): AuditEventWithRecipientInfo[] => {
  const result: AuditEventWithRecipientInfo[] = [];
  let currentStateOfRecipients = recipientInfoMap;
  events.forEach((event) => {
    if (isRecipientUpdate(event)) {
      const eventData = event.eventData as IEventData;
      const recipientInfo = produce(currentStateOfRecipients, (draft) => {
        draft[eventData.recipientId!] = {
          ...draft[eventData.recipientId!],
          firstName: eventData.originalFirstName,
          lastName: eventData.originalLastName,
          email: eventData.originalEmail,
        };
      });
      currentStateOfRecipients = recipientInfo;
    }
    if (isRecipientAdd(event)) {
      const eventData = event.eventData as IRecipientAddOrDeleteEventData;
      const recipientInfo = produce(currentStateOfRecipients, (draft) => {
        // @ts-ignore
        delete draft[eventData.id];
      });
      currentStateOfRecipients = recipientInfo;
    }
    if (isRecipientDelete(event)) {
      const eventData = event.eventData as IRecipientAddOrDeleteEventData;
      const recipientInfo = produce(currentStateOfRecipients, (draft) => {
        // @ts-ignore
        draft[eventData.id] = event.eventData;
      });
      currentStateOfRecipients = recipientInfo;
    }
    result.push({ ...event, recipientInfo: currentStateOfRecipients });
  });

  return result.filter(
    (e) =>
      ![
        eventNames.SignerDeleted,
        eventNames.CarbonCopyDeleted,
        eventNames.SignerAdded,
        eventNames.CarbonCopyAdded,
      ].includes(e.eventName)
  );
};

interface IProps {
  flags: LaunchDarklyFlags;
}

const ActivityLog = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { envelope, auditHistory, recipientsWhoHaveNotViewed }: IState = useShallowSelector(selector);
  const [showResendModal, setShowResendModal] = React.useState(false);
  const { senderFirstName, senderMiddleName, senderLastName } = envelope;
  const senderName = concatStrings(senderFirstName, senderMiddleName, senderLastName);
  const recipientInfo = envelope.recipients.reduce((acc, r) => ({ ...acc, [r.id]: r }), {});
  const params = useParams<{ id: string }>();
  const { flags } = props;

  const clickHandler = () => {
    dispatch(auditHistoryActions.fetch(params.id));
    dispatch(hideAlert());
    return setShowResendModal(false);
  };

  const handleEventType = (event: AuditEventWithRecipientInfo) => {
    const eventData = event.eventData as IEventData;
    const originalRecipientName = concatStrings(
      eventData.originalFirstName,
      eventData.originalLastName,
      `(${eventData.originalEmail})`
    );
    const updatedRecipientName = concatStrings(
      eventData.updatedFirstName,
      eventData.updatedLastName,
      `(${eventData.updatedEmail!})`
    );

    let userName = event.userName?.trim() || senderName;
    const isDelegate = event.userType === 'sender' && event.userId !== envelope.senderId;
    const canceledBySystem = event.userType === 'system';
    if (isDelegate){
      userName = `${userName} on behalf of ${senderName}`;
    }
    const allRecipients = Object.values(event.recipientInfo);
    const recipientName = concatObject(
      allRecipients.find((r) => (r.id === event.userId && !eventData.recipientId) || r.id === eventData.recipientId)
    );
    const oldEnvelopeName = event.eventName === eventNames.EnvelopeNameUpdated ? eventData.oldEnvelopeName : '';
    const tableCellContent = {
      [eventNames.EnvelopeCreated]: <>Created by {bold(userName)}</>,
      [eventNames.EnvelopeCorrecting]: <>Envelope being corrected by {bold(userName)}</>,
      [eventNames.EnvelopeViewed]: <>Viewed by {bold(recipientName)}</>,
      [eventNames.EnvelopeSigned]: <>Signed by {bold(recipientName)}</>,
      [eventNames.EnvelopeCanceled]: canceledBySystem ? 'Envelope canceled by system' : 'Envelope Canceled',
      [eventNames.EnvelopeCompleted]: 'Envelope Completed',
      [eventNames.SignatureAccepted]: <>{bold(recipientName)} has adopted their electronic signature</>,
      [eventNames.WaitingFor]: <>Waiting for {boldAll(recipientsWhoHaveNotViewed)} to view documents</>,
      [eventNames.EnvelopeDownloaded]: (
        <>
          Envelope Downloaded by{' '}
          {allRecipients.find((r) => r.id === event.userId) ? bold(recipientName) : bold(userName)}
        </>
      ),
      [eventNames.SignerUpdated]: (
        <>
          Details for {bold(originalRecipientName)} updated to {bold(updatedRecipientName)}
        </>
      ),
      [eventNames.CarbonCopyUpdated]: (
        <>
          Details for {bold(originalRecipientName)} updated to {bold(updatedRecipientName)}
        </>
      ),
      [eventNames.ESignDisclosureAccepted]: (
        <>{bold(recipientName)} has agreed to the ESIGN Consumer Disclosure and Consent</>
      ),
      [eventNames.EnvelopeSent]: (
        <>
          Sent to {boldAll(allRecipients)} by {bold(userName)}
        </>
      ),
      [eventNames.NotificationRequested]: (
        <>
          {emailNames[eventData.emailType!]} email sent to{' '}
          {allRecipients.find((r) => eventData.recipientId != null && r.id === eventData.recipientId) ? bold(recipientName) : bold(userName)}
        </>
      ),
      [eventNames.EnvelopeNameUpdated]: (
        oldEnvelopeName ? 
        <>
          Envelope name was updated from {bold(oldEnvelopeName)} to {bold(eventData.newEnvelopeName)} by {bold(userName)}
        </> :
        <>
          Envelope name was updated to {bold(eventData.newEnvelopeName)} by {bold(userName)}
        </>
      ),
    };

    return tableCellContent[event.eventName];
  };

  const activityLogColumns = [
    {
      key: 'eventName',
      label: 'Activity',
      cell: (e: AuditEventWithRecipientInfo) => (
        <Typography className={classes.activityWrap} data-spec={e.eventName}>
          {handleEventType(e)}
        </Typography>
      ),
    },
    {
      key: 'date',
      label: 'Date',
      align: 'left' as const,
      cell: (e: AuditEventWithRecipientInfo) => (
        <Typography className={classes.noWrap}>
          {e.eventName !== eventNames.WaitingFor ? dayjs(e.date).format('MMMM Do, YYYY') : ''}
        </Typography>
      ),
    },
    {
      key: 'time',
      label: 'Time',
      align: 'left' as const,
      cell: (e: AuditEventWithRecipientInfo) => (
        <Typography className={classes.noWrap}>
          {e.eventName !== eventNames.WaitingFor ? dayjs(e.time).format('hh:mm A').concat(' ', getTimezoneCode()) : ''}
        </Typography>
      ),
    },
    {
      key: 'action',
      label: 'Action',
      align: 'left' as const,
      cell: (e: AuditEventWithRecipientInfo) => (
        <Typography className={classes.noWrap}>
          {e.eventName !== eventNames.WaitingFor || envelope.status === EnvelopeStatuses.CORRECTING ? (
            ''
          ) : (
            <Button data-spec="resend-button" color="primary" onClick={() => setShowResendModal(true)}>
              Resend Envelope
            </Button>
          )}
        </Typography>
      ),
    },
  ];

  return (
    <div className={classes.wrapper}>
      <Typography variant="overline">Activity Log</Typography>
      <Paper className={classes.paper} elevation={0}>
        <Table
          activityLog
          idKey="id"
          columns={activityLogColumns}
          rows={getEventsWithRecipientInfo(auditHistory, recipientInfo)}
        />
      </Paper>
      {showResendModal && <ResendModal data-spec="resend-modal" close={clickHandler} flags={flags} />}
    </div>
  );
};

export default ActivityLog;
