import { Tabs } from '@material-ui/core';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import Tab from '@material-ui/core/Tab';
import React from 'react';
import { IRootState } from '../../store';
import { IBlock, IBlocks, ISelectedBlocks } from '../../store/senderBuild/types';
import { BLOCK_TYPES } from '../../lib/constants';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteIcon from '@material-ui/icons/Delete';
import * as actions from '../../store/senderBuild/actions';

const selector = (state: IRootState) => ({
  selectedBlock: state.senderBuild.selectedBlocks,
  blocks: state.senderBuild.blocks,
});

interface IState {
  selectedBlock: ISelectedBlocks;
  blocks: IBlocks;
}

export const MobileBlockSettings = () => {
  const dispatch = useDispatch();
  const { selectedBlock, blocks }: IState = useShallowSelector(selector);
  const block: IBlock = blocks[selectedBlock.documentId!][selectedBlock.pageIndex!][selectedBlock.blockIndices[0]];
  if (!block) {
    return <></>;
  }
  const currentBlockType = BLOCK_TYPES.find((bt) => bt.key === block.blockType);

  const handleBlockClick = (type: string) => {
    if (type === 'Optional') {
      let update: any = {};
      update.required = !block.required;
      dispatch(
        actions.editBlock(selectedBlock.documentId!, selectedBlock.pageIndex!, selectedBlock.blockIndices[0], update)
      );
    }
    if (type === 'Delete') {
      dispatch(actions.deleteBlocks(selectedBlock.documentId!, selectedBlock.pageIndex!, [block.blockId!]));
      dispatch(actions.changeActiveBlockType(''));
    }
  };

  return (
    <div
      className="w-full"
      style={{ borderBottom: '1px solid #CAD5E0', height: '79px' }}
      data-spec="mobileBlockSettings"
    >
      <Tabs className="h-full" classes={{ flexContainer: 'h-full justify-center' }}>
        {currentBlockType!.mobileOptions!.map((option: string, index: number) => {
          let icon;
          if (option === 'Optional' && block.required) {
            icon = <CheckBoxOutlineBlankIcon />;
          } else if (option === 'Optional' && !block.required) {
            icon = <CheckBoxIcon />;
          } else if (option === 'Delete') {
            icon = <DeleteIcon />;
          }
          return (
            <Tab
              key={index}
              onClick={() => handleBlockClick(option)}
              label={option}
              value={option}
              className={'text-blue-800 flex-1 opacity-100'}
              icon={icon}
              data-spec={`setting-${option}`}
              classes={{ wrapper: 'capitalize' }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
export default MobileBlockSettings;
