import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from '@skyslope/mache';
import { useHistory, useLocation } from 'react-router-dom';
import { ISettings } from '../store/pageFrame/types';
import { IRootState } from '../store';
import Spinner from '../components/common/Spinner';
import Header from '../components/pageFrame/Header';
import { useShallowSelector, useDispatch } from '../lib/reduxHooks';
import { hideErrorToast, fetchGlobalToken, getSettings } from '../store/pageFrame/actions';
import { isSkySlopeMobileApp } from '../common/utils';
import { UserOrigins } from '../lib/types';
import useUserInfo from '../auth/useUserInfo';
import { ResourceNames } from '../lib/constants';
import { useDS3Paywall } from '../hooks/paywall/use-ds3-paywall';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflow: 'auto',
    padding: '24px 0',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  mobileContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflow: 'auto',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  error: {
    backgroundColor: colors.error,
  },
  success: {
    backgroundColor: '#308252',
  },
  container: {
    display: 'flex',
  },
}));

interface IState {
  loading: boolean;
  errorShow: boolean;
  msg: string;
  successShow: boolean;
  settingsFetched: boolean;
  settings?: ISettings;
  senderBuildResource?: ResourceNames;
  fetchedUserSubscription?: boolean;
  fetchedGlobalToken?: boolean;
}

const selector = (state: IRootState) => ({
  loading: state.pageFrame.loading,
  errorShow: state.pageFrame.errorShow,
  msg: state.pageFrame.msg,
  successShow: state.pageFrame.successShow,
  settingsFetched: state.pageFrame.settingsFetched,
  settings: state.pageFrame.settings,
  senderBuildResource: state.senderBuild.resource,
  fetchedUserSubscription: state.pageFrame.fetchedUserSubscription,
  fetchedGlobalToken: state.pageFrame.fetchedGlobalToken,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PageFrame = (props: { children: any }) => {
  const {
    loading,
    errorShow,
    msg,
    successShow,
    settingsFetched,
    settings,
    senderBuildResource,
    fetchedGlobalToken,
  }: IState = useShallowSelector(selector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const isMobileApp = isSkySlopeMobileApp();
  const userInfo = useUserInfo();
  const [headerSize, setHeaderSize] = useState(0);

  const isOnSubRequiredPage =
    pathname.includes('documents') ||
    pathname.includes('recipients') ||
    pathname.includes('properties') ||
    senderBuildResource === ResourceNames.ENVELOPE;
  const isOnSubscriptionPage = pathname.includes('subscriptions');

  const paywall = useDS3Paywall();

  React.useEffect(() => {
    if (!fetchedGlobalToken && settingsFetched) {
      dispatch(fetchGlobalToken());
    }
  }, [fetchedGlobalToken, settingsFetched]);

  React.useEffect(() => {
    if (userInfo !== null && !userInfo.isPrimeAuthenticated) {
      localStorage.setItem('_isStandaloneUser', 'true');
    }
  }, [userInfo]);

  React.useEffect(() => {
    if (paywall.isLoading) return;

    const shouldRedirectToSubscriptions =
      !paywall.isAllowedDS3Access && isOnSubRequiredPage && !isMobileApp && paywall.isExpired;

    if (shouldRedirectToSubscriptions) {
      const previousUrl = window.location.href;
      history.push({
        pathname: '/subscriptions',
        state: { url: previousUrl },
      });
    } else if (!paywall.isExpired && isOnSubscriptionPage) {
      if (document.referrer.includes('send.skyslope') && !document.referrer.includes('subscriptions')) {
        history.goBack();
      } else {
        history.push({ pathname: '/envelopes' });
      }
    }
  }, [paywall]);

  React.useEffect(() => {
    const shouldFetchSettings = !settingsFetched && userInfo?.provisioned && !pathname.includes('logout');
    if (shouldFetchSettings) {
      dispatch(getSettings(userInfo.digisignAccountId, userInfo.senderId));
    }
  }, [settingsFetched, userInfo, pathname]);

  const snackbarClass = successShow ? classes.success : errorShow ? classes.error : '';
  const snackbarContent = successShow ? (
    <div className={classes.container}>
      <CheckCircleIcon style={{ marginRight: '8px' }} />
      {msg}
    </div>
  ) : errorShow ? (
    <>{msg}</>
  ) : (
    ''
  );

  function handleCloseSnackbar(_: unknown, reason: string) {
    if (reason === 'clickaway') return;
    dispatch(hideErrorToast());
  }

  const redirectHeader =
    settingsFetched && (!settings?.account.userOrigin || settings?.account.userOrigin === UserOrigins.Prime);

  return (
    <div className={classes.root}>
      <Header
        showTrialBanner={paywall.trial.showBanner}
        handleDismissBanner={paywall.trial.dismissBanner}
        daysLeft={paywall.trial.daysLeft}
        isTrialExpired={paywall.trial.isExpired}
        setHeaderSize={setHeaderSize}
        redirectHeader={redirectHeader}
      />
      <div
        className={
          isMobileApp ? classes.mobileContent : paywall.trial.showBanner ? classes.content + ' p-0' : classes.content
        }
        style={{ minHeight: `calc(100vh - ${headerSize}px - 48px)` }}
      >
        {loading && <Spinner />}
        {props.children}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={errorShow || successShow}
        autoHideDuration={1500}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent className={snackbarClass} message={snackbarContent} />
      </Snackbar>
    </div>
  );
};

export default PageFrame;
