import React, { useEffect, useState } from 'react';
import { IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReorderIcon from '@material-ui/icons/Reorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline';
import { ReactComponent as GenericDocumentIcon } from '../../images/document_generic.svg';
import ProgressBar from './ProgressBar';
import { ReactComponent as CheckIcon } from '../../images/check_circle.svg';
import { ReactComponent as ErrorIcon } from '../../images/error_circle.svg';
import { DocumentStatus, IDoc } from './types';
import { chameleonElId } from '../../lib/constants';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const useStyles = makeStyles((theme: Theme) => ({
  focusButton: {
    '&:focus': {
      backgroundColor: colors.blue[50],
    },
  },
  document: {
    display: 'flex',
    flexFlow: 'row',
    padding: '12px 0',
    backgroundColor: '#FFFFFF',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  templateDocument: {
    '&:last-child': {
      paddingBottom: 50,
    },
  },
  documentContent: {
    display: 'flex',
    flexFlow: 'row',
  },
  documentText: {
    margin: `0px ${theme.spacing(2)}px`,
    flexGrow: 1,
    wordBreak: 'break-word',
  },
  documentInformationContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    border: `1px solid ${colors.grey[400]}`,
  },
  documentUploadInformation: (props: IProps) => ({
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    padding: props.smallerPadding
      ? 12
      : `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
  }),
  progress: {
    padding: '11px 0',
    borderRadius: '25px',
    marginTop: 4,
  },
  error: {
    color: colors.error,
    margin: '0px',
    padding: '0px',
    fontSize: '14px',
  },
  icon: (props: IProps) => ({
    height: props.smallerPadding ? 40 : 54,
    width: props.smallerPadding ? 36 : 49,
  }),
  deleteIcon: {
    color: colors.grey[500],
    alignSelf: 'center',
    padding: 4,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: colors.blue[50],
      color: colors.blue[800],
    },
  },
  documentTextFlexWrapper: {
    '& p': {
      lineHeight: 'normal',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '380px',
  },
  reorderIcon: {
    color: `${colors.grey[400]}`,
    cursor: 'move',
    alignSelf: 'center',
    marginRight: theme.spacing(2),
  },
  errorIcon: (props: IProps) => ({
    color: `${colors.error}`,
    alignSelf: 'center',
    overflow: 'visible',
    paddingRight: props.inlineRemoveButton ? 10 : 0,
  }),
  pdfInformation: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
    fontSize: '14px',
  },
  checkCircleIcon: {
    width: '16px',
    paddingRight: '4px',
  },
  statusText: {
    color: colors.grey[700],
    fontSize: '14px',
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  dragPlaceholder: {
    backgroundColor: colors.grey[200],
    height: '107px',
    width: '100%',
  },
  dropIndicatorBottom: {
    width: '100%',
    height: 3,
    marginTop: 8,
    marginBottom: -11,
    backgroundColor: colors.blue[800],
    position: 'relative',
    '&:after': {
      width: 4,
      height: 4,
      backgroundColor: '#ffffff',
      border: `2px solid ${colors.blue[800]}`,
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      marginTop: -2,
      marginLeft: -2,
    },
  },
  dropIndicatorTop: {
    width: '100%',
    height: 3,
    marginTop: -18,
    marginBottom: 15,
    backgroundColor: colors.blue[800],
    position: 'relative',
    '&:before': {
      width: 4,
      height: 4,
      backgroundColor: '#ffffff',
      border: `2px solid ${colors.blue[800]}`,
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      marginTop: -2,
      marginLeft: -2,
    },
  },
}));

interface IProps {
  id: string;
  order: number;
  findDocument: (id: string) => { index: number; document: IDoc };
  isUploading: boolean;
  fileName: string;
  numberOfPages: number;
  handleDeleteDocument: (documentId: string) => void;
  totalDocuments: number;
  progressPercentageForDocuments: any;
  inlineRemoveButton?: boolean;
  smallerPadding?: boolean;
  firstDocument: boolean;
  disableDelete: boolean;
}

const SenderDocument = (props: IProps) => {
  const classes = useStyles(props);
  const {
    id,
    findDocument,
    fileName,
    isUploading,
    numberOfPages,
    handleDeleteDocument,
    totalDocuments,
    progressPercentageForDocuments,
    order,
    firstDocument,
    disableDelete,
  } = props;
  const currentDocument = findDocument(id).document;
  const [error, setError] = useState(false);
  const [showRemoveButton, setShowRemoveButton] = useState(true);
  const [percentage, setPercentage] = useState(
    progressPercentageForDocuments[id]
      ? progressPercentageForDocuments[id].uploadPercentage
      : !!currentDocument && currentDocument.uploadStatus === DocumentStatus.Uploading
      ? 100
      : 0
  );
  const isTemplatePage = /\/templates/.test(window.location.toString());

  useEffect(() => {
    const interval = setInterval(() => {
      const documentProgressInformation = progressPercentageForDocuments[id];
      setPercentage(
        documentProgressInformation
          ? documentProgressInformation.uploadPercentage
          : !!currentDocument && currentDocument.uploadStatus === DocumentStatus.Uploading
          ? 100
          : 0
      );
      if (documentProgressInformation && documentProgressInformation.error) {
        setError(true);
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    setShowRemoveButton(true);
  }, [order]);

  useEffect(() => {
    if (currentDocument && currentDocument.uploadStatus === DocumentStatus.Uploading) {
      setPercentage(100);
    }
  }, [isUploading]);

  const deleteDocument = (documentId: string) => {
    delete progressPercentageForDocuments[documentId];
    handleDeleteDocument(documentId);
  };

  const renderPdfUploadInformation = () => {
    if (currentDocument && currentDocument.uploadStatus === DocumentStatus.Failed) {
      return <p className={classes.error}>Error uploading document. Please try again.</p>;
    }
    if (error && progressPercentageForDocuments[id]) {
      return <p className={classes.error}>{progressPercentageForDocuments[id].error}</p>;
    }
    if (isUploading && percentage < 100) {
      return <div className={classes.statusText}>Uploading {percentage}%...</div>;
    }
    if (
      (isUploading && percentage === 100) ||
      (currentDocument && currentDocument.uploadStatus === DocumentStatus.Uploading)
    ) {
      return <div className={classes.statusText}>Finishing Upload...</div>;
    }
    return (
      <span className={classes.pdfInformation}>
        <CheckIcon className={classes.checkCircleIcon} />
        <div className={classes.statusText}>{`${numberOfPages} page${numberOfPages === 1 ? '' : 's'}`}</div>
      </span>
    );
  };
  const { attributes, listeners, setNodeRef, transform, transition, overIndex, newIndex, active } = useSortable({
    id: props.id,
  });

  return (
    <div
      className={`${classes.document} ${isTemplatePage ? classes.templateDocument : ''}`}
      data-spec="document"
      id={`${order}`}
    >
      <div
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        className={classes.documentInformationContainer}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
          zIndex: active?.id === props.id ? 100 : undefined,
        }}
      >
        {overIndex === 0 && newIndex === 1 ? <div className={classes.dropIndicatorTop} /> : ''}
        <div className={classes.documentUploadInformation}>
          {totalDocuments > 1 && !error && !isUploading && (
            <span id={firstDocument ? chameleonElId(11) : ''}>
              <ReorderIcon className={classes.reorderIcon} />
            </span>
          )}
          <div>
            <GenericDocumentIcon className={classes.icon} />
          </div>
          <div className={classes.documentText}>
            <div className={classes.documentTextFlexWrapper}>
              <Typography variant="body2">{fileName}</Typography>
              {renderPdfUploadInformation()}
            </div>
          </div>
          {!!error && <ErrorIcon className={classes.errorIcon} />}
          {props.inlineRemoveButton && currentDocument && currentDocument.uploadStatus !== DocumentStatus.Uploading && (
            <Button
              color="primary"
              onClick={() => deleteDocument(id!)}
              data-spec="document-delete-button"
              className={classes.focusButton}
              disabled={disableDelete}
            >
              <RemoveCircleIcon className={classes.iconMargin} />
              Remove
            </Button>
          )}
        </div>
        {overIndex === newIndex + 1 ? <div className={classes.dropIndicatorBottom} /> : ''}
        {(isUploading || (currentDocument && currentDocument.uploadStatus === DocumentStatus.Uploading)) && !error ? (
          <ProgressBar documentId={id} value={percentage} />
        ) : null}
      </div>
      {!props.inlineRemoveButton && (
        <IconButton
          disabled={disableDelete}
          className={classes.deleteIcon}
          style={{ visibility: (isUploading && !error) || !showRemoveButton ? 'hidden' : 'visible' }}
        >
          <CloseIcon onClick={() => deleteDocument(id!)} data-spec="document-delete-button" />
        </IconButton>
      )}
    </div>
  );
};

export default SenderDocument;
