import { IBlocks, IGroups } from '../store/senderBuild/types';
import {
  getBlocks,
  getGroupIds,
  getInvalidCheckboxes,
  getInvalidCheckboxGroups,
  getListOfGroups,
  removeGroupIdsFromBlocks,
  removeInvalidBlockIdsFromGroups,
  validateAndCorrectTextBlocks,
} from './utils';

export default (blocks: IBlocks, blockGroups: IGroups, sendEnvelope: boolean, zoom: number) => {
  if (sendEnvelope) {
    const blockArray = getBlocks(blocks);
    const groupIds = getGroupIds(blockGroups);
    const groupArray = getListOfGroups(blockGroups);
    const invalidCheckboxes = getInvalidCheckboxes(blockArray, groupIds, groupArray);
    const invalidCheckboxGroups = getInvalidCheckboxGroups(groupArray, blockArray, groupIds);
    const updatedBlocks = removeGroupIdsFromBlocks(invalidCheckboxes);
    const updatedGroups = removeInvalidBlockIdsFromGroups(invalidCheckboxGroups, blockArray);
    const updatedTextBlocks = validateAndCorrectTextBlocks(blockArray, zoom);
    const mergedUpdatedBlocks = [...updatedBlocks, ...updatedTextBlocks];
    return { updatedBlocks: mergedUpdatedBlocks, updatedGroups };
  }
  return null;
};
