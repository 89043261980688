import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useDispatch } from 'react-redux';
import { hideAlert } from '../../store/pageFrame/actions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';

const alertTypeMap = {
  ERROR: {
    color: '#D20004',
    icon: <ErrorOutlineIcon />,
  },
  SUCCESS: {
    color: '#47B676',
    icon: <CheckCircleOutlineIcon />,
  },
  WARNING: {
    color: '#FFAB00',
    icon: <WarningIcon />,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: (props: IProps) => ({
    border: `1px solid ${alertTypeMap[props.type].color}`,
    display: 'flex',
    maxWidth: '634px',
    alignItems: 'center',
    marginTop: '32px',
  }),
  element: (props: IProps) => ({
    backgroundColor: `${alertTypeMap[props.type].color}`,
    color: 'white',
    width: '56px',
    height: '56px',
    textAlign: 'center',
    marginRight: '24px',
  }),
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

interface IProps {
  type: string;
  message: string;
  id: string;
}

export const Alert = (props: IProps) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  return (
    <ClickAwayListener onClickAway={() => dispatch(hideAlert())}>
      <div id={props.id} className={classes.wrapper}>
        <div className={classes.element}>
          <div className={classes.icon}>{alertTypeMap[props.type].icon}</div>
        </div>
        {props.message}
      </div>
    </ClickAwayListener>
  );
};
