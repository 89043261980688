import React, { useState } from 'react';
import { colors } from '@skyslope/mache';
import { makeStyles, styled } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { sizing } from '@material-ui/system';
import { brokerManagementUrl } from '../../lib/constants';
import { handleAppLogout } from '../../lib/utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginLeft: '24px',
    color: colors.blue[800],
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
    padding: '6px 8px',
    fontSize: '16px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
      borderRadius: '20px',
      outline: 'none',
    },
  },
  accountIcon: {
    fontSize: '24px',
    marginRight: '9px',
  },
  menuItem: {
    minHeight: '46px',
    '&:hover': {
      backgroundColor: colors.blue[50],
      cursor: 'pointer',
      color: '#0059DA',
    },
    '&:first-child': {
      borderTop: `2px solid ${colors.blue[800]}`,
    },
  },
}));

interface IProps {
  senderFirstName: string;
  senderLastName: string;
  preferencesUrl?: string;
  showPreferences?: boolean;
  showBrokerManagementLink?: boolean;
  user?: { isBrokerAdmin: boolean; isGroupAdmin: boolean };
  showBrokerManagement?: boolean;
}

const UserMenu = (props: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const Menu = styled(MenuList)(sizing);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleKeyDown = (event: React.KeyboardEvent<EventTarget>) => {
    if (event.key === 'Enter') {
      handleToggle();
    }
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handlePreferences = () => {
    window.location.href =
      window.location.hostname === 'localhost' ? 'http://localhost:3000/preferences' : props.preferencesUrl;
  };

  const handleBrokerManagement = () => {
    window.location.assign(`${brokerManagementUrl}?returnUrl=${encodeURIComponent(window.location.href)}`);
  };

  const showBrokerManagementLink = () =>
    props.showBrokerManagement && props.user && (props.user.isBrokerAdmin || props.user.isGroupAdmin);

  const getBrokerManagementText = () =>
    props.user.isBrokerAdmin ? 'Broker Management' : props.user.isGroupAdmin ? 'My Team' : null;

  return (
    <>
      <span
        className={classes.wrapper}
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        ref={anchorRef}
        tabIndex={0}
        id="userMenu"
      >
        <AccountCircle className={classes.accountIcon} />
        {props.senderFirstName} {props.senderLastName}
        <ArrowDropDown />
      </span>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Menu disablePadding width="auto">
                  {showBrokerManagementLink() ? (
                    <MenuItem
                      tabIndex={0}
                      className={classes.menuItem}
                      onClick={() => handleBrokerManagement()}
                      data-spec="my-team-link"
                    >
                      {getBrokerManagementText()}
                    </MenuItem>
                  ) : null}
                  {props.showPreferences && props.preferencesUrl ? (
                    <MenuItem tabIndex={0} className={classes.menuItem} onClick={() => handlePreferences()}>
                      Preferences
                    </MenuItem>
                  ) : null}
                  <MenuItem autoFocus tabIndex={0} className={classes.menuItem} onClick={() => handleAppLogout()}>
                    Logout
                  </MenuItem>
                </Menu>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserMenu;
