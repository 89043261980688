import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import PeopleIcon from '@material-ui/icons/People';
import { colors } from '@skyslope/mache';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { getEnvelopeRecipients, getAuditHistory } from '../../lib/api/envelope/envelope';
import { IEnvelopeMetadata } from '../../store/envelopeManagement/types';
import { IRecipientBody, IAuditHistoryBody } from '../../store/envelopeHistory/types';
import { IRecipient } from '../../common/recipients/types';
import StyledTooltip from '../../common/StyledTooltip';

const recipientsCache: { [envelopeId: string]: IRecipient[] } = {};
const envelopeHistoriesCache: { [envelopeId: string]: IAuditHistoryBody[] } = {};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    borderRadius: '20px',
    padding: '8px 12px',
    margin: '-8px -16px',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: colors.blue[100],
    },
  },
  triangle: {
    width: 0,
    height: 0,
    position: 'absolute',
    bottom: '100%',
    left: 'calc(50% - 4px)',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '8px solid white',
  },
  recipient: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: 8,
      borderBottom: `1px solid ${colors.grey[200]}`,
      paddingBottom: theme.spacing(1),
    },
  },
  email: {
    fontSize: 14,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  recipientIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(5),
  },
}));

interface IProps {
  envelope: IEnvelopeMetadata;
}

const RecipientsCell = (props: IProps) => {
  const { envelope } = props;
  const classes = useStyles();
  const [recipientsList, setRecipientsList] = useState<IRecipient[] | null>(null);
  const [envelopeHistory, setEnvelopeHistory] = useState<IAuditHistoryBody[] | null>(null);

  const loadRecipients = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!recipientsList) {
      if (recipientsCache[envelope.id]) {
        setRecipientsList(recipientsCache[envelope.id]);
        setEnvelopeHistory(envelopeHistoriesCache[envelope.id]);
      } else {
        const recipients = await getEnvelopeRecipients(envelope.id);
        const history = await getAuditHistory(envelope.id);

        recipientsCache[envelope.id] = recipients;
        envelopeHistoriesCache[envelope.id] = history;

        setRecipientsList(recipients);
        setEnvelopeHistory(history);
      }
    }
  };

  const checkRecipientStatus = (r: IRecipientBody) => {
    const downloadedEvents = envelopeHistory
      ?.filter((e: IAuditHistoryBody) => e.eventName === 'EnvelopeDownloaded')
      .map((e: IAuditHistoryBody) => e.userId);
    return r.status === 'Complete' || (r.role === 'CarbonCopy' && downloadedEvents?.includes(r.id))
      ? '#47B676'
      : colors.grey[500];
  };

  const checkRecipientRole = (r: IRecipientBody) => {
    return r.role === 'CarbonCopy' ? (
      <RemoveRedEyeIcon style={{ color: checkRecipientStatus(r) }} />
    ) : (
      <CheckCircleIcon style={{ color: checkRecipientStatus(r) }} />
    );
  };

  const tooltipContent = (
    <>
      <span className={classes.triangle} />
      {recipientsList?.length && envelopeHistory
        ? recipientsList.map((r) => (
            <div key={r.id} className={classes.recipient}>
              <div>
                {r.firstName} {r.lastName}
                <div className={classes.email}>{r.email}</div>
              </div>
              <div className={classes.recipientIcon}>{checkRecipientRole(r)}</div>
            </div>
          ))
        : '...'}
    </>
  );

  return (
    <StyledTooltip
      title={tooltipContent}
      onOpen={loadRecipients}
      disableFocusListener={!envelope.recipientCount}
      disableHoverListener={!envelope.recipientCount}
      disableTouchListener={!envelope.recipientCount}
      interactive
    >
      <div tabIndex={0} className={classes.wrapper}>
        <PeopleIcon className={classes.icon} />
        <Typography variant="body1">{envelope.recipientCount}</Typography>
      </div>
    </StyledTooltip>
  );
};

export default RecipientsCell;
