import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './../senderBuild/Signers/index';

interface IProps {
  index?: number;
}

const getGroupNumber = (n?: number) => {
  if (n !== undefined && n > 0) {
    return 'NEXT TO SIGN';
  } else if (n !== undefined) {
    return 'FIRST TO SIGN';
  }
  return 'RECEIVES A COPY';
};

export const RecipientsHeader = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.signerHeader}>
      <Typography variant="body1" className={classes.sectionTitle}>
        {getGroupNumber(props.index)}
      </Typography>
      <div className={classes.shelfBorder}></div>
    </div>
  );
};
