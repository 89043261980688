import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@skyslope/mache';
import { ReactComponent as Background } from '../../images/progress_background.svg';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: '4px',
    width: '100%',
    backgroundImage: `url(${Background})`,
    borderTop: `1px solid ${colors.grey[400]}`,
    overflow: 'hidden',
  },
  progress: {
    backgroundColor: 'transparent',
    height: '100%',
  },
  barColorPrimary: {
    backgroundImage: `linear-gradient(to right, ${colors.blue[100]}, ${colors.blue[800]})`,
  },
}));

interface IProps {
  documentId: string;
  value: number;
}

const ProgressBar = (props: IProps) => {
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      <LinearProgress
        classes={{ barColorPrimary: classes.barColorPrimary }}
        className={classes.progress}
        variant="determinate"
        value={props.value}
        data-spec="progress-value"
      />
    </div>
  );
};

export default ProgressBar;
