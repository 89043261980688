type CacheFields = {
  id: string;
  status: string;
  updatedDate: string;
  sentDate?: string;
};

export default class Cache {
  private items: { [key: string]: { timeAdded: number; value: CacheFields } };
  private date: { now: () => number };
  private ttl: number;
  constructor(ttl: number, date: { now: () => number }) {
    this.ttl = ttl;
    this.date = date;
    this.items = {};
  }
  set(key: string, value: CacheFields): CacheFields {
    const cacheValue = {
      id: value.id,
      status: value.status,
      updatedDate: value.updatedDate,
      sentDate: value.sentDate,
    };
    this.items[key] = { value: cacheValue, timeAdded: this.date.now() };
    return cacheValue;
  }
  private getItem(key: string) {
    const item = this.items[key];
    if (!item) {
      return null;
    }
    if (this.date.now() > item.timeAdded + this.ttl) {
      delete this.items[key];
      return null;
    }
    return item;
  }
  get(key: string): CacheFields | undefined {
    return this.getItem(key)?.value;
  }
  getSet(key: string, value: CacheFields): CacheFields {
    const cachedItem = this.getItem(key);
    if (!cachedItem || Date.parse(value.updatedDate) > Date.parse(cachedItem.value.updatedDate)) {
      return this.set(key, value);
    }
    return cachedItem.value;
  }
}
