import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { logger } from 'redux-logger';

// Page Frame
import pageFrameSagas from './pageFrame/sagas';
import pageFrameReducers from './pageFrame/reducers';
import { IPageFrameState } from './pageFrame/types';

// Sender Build
import senderBuildSagas from './senderBuild/sagas';
import senderBuildReducers from './senderBuild/reducers';
import { ISenderBuildState } from './senderBuild/types';

// Template Management
import templateManagementSagas from './templateManagement/sagas';
import templateManagementReducers from './templateManagement/reducers';
import { ITemplateManagementState } from './templateManagement/types';

// Template Edit
import templateCreateSagas from './templateCreate/sagas';
import templateCreateReducers from './templateCreate/reducers';
import { ITemplateCreateState } from './templateCreate/types';

// Apply Template Edit
import applyTemplateSagas from './applyTemplate/sagas';
import applyTemplateReducers from './applyTemplate/reducers';
import { IApplyTemplateState } from './applyTemplate/types';

// Template Tagging
import templateTaggingSagas from './templateTagging/sagas';
import templateTaggingReducers from './templateTagging/reducers';
import { ITemplateTaggingState } from './templateTagging/types';

//Template Sharing
import templateSharingReducers from './templateSharing/reducer';
import { ITemplateSharingState } from './templateSharing/types';
import templateSharingSagas from './templateSharing/saga';

// Envelope Management
import envelopeManagementSagas from './envelopeManagement/sagas';
import envelopeManagementReducers from './envelopeManagement/reducers';
import { IEnvelopeManagementState } from './envelopeManagement/types';

// Envelope Documents
import envelopeDocumentsSagas from './envelopeDocuments/sagas';
import envelopeDocumentsReducers from './envelopeDocuments/reducers';
import { IEnvelopeDocumentsState } from './envelopeDocuments/types';

// Envelope History
import envelopeHistorySagas from './envelopeHistory/sagas';
import envelopeHistoryReducers from './envelopeHistory/reducers';
import { IEnvelopeHistoryState } from './envelopeHistory/types';

// DTM
import { dtmSagas, dtmReducers, IDTMState } from './dtm';

function* rootSaga() {
  yield all([
    fork(pageFrameSagas),
    fork(senderBuildSagas),
    fork(templateManagementSagas),
    fork(templateCreateSagas),
    fork(applyTemplateSagas),
    fork(templateTaggingSagas),
    fork(envelopeManagementSagas),
    fork(envelopeDocumentsSagas),
    fork(envelopeHistorySagas),
    fork(templateSharingSagas),
    ...dtmSagas,
  ]);
}

const rootReducer = combineReducers({
  pageFrame: pageFrameReducers,
  senderBuild: senderBuildReducers,
  templateManagement: templateManagementReducers,
  templateCreate: templateCreateReducers,
  templateSharing: templateSharingReducers,
  applyTemplate: applyTemplateReducers,
  templateTagging: templateTaggingReducers,
  envelopeManagement: envelopeManagementReducers,
  envelopeDocuments: envelopeDocumentsReducers,
  envelopeHistory: envelopeHistoryReducers,
  dtm: dtmReducers,
});

export interface IRootState {
  pageFrame: IPageFrameState;
  senderBuild: ISenderBuildState;
  templateManagement: ITemplateManagementState;
  templateCreate: ITemplateCreateState;
  templateSharing: ITemplateSharingState;
  applyTemplate: IApplyTemplateState;
  templateTagging: ITemplateTaggingState;
  envelopeManagement: IEnvelopeManagementState;
  envelopeDocuments: IEnvelopeDocumentsState;
  envelopeHistory: IEnvelopeHistoryState;
  dtm: IDTMState;
}

export default function configureStore(initialState: Partial<IRootState> = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware: any[] = [sagaMiddleware];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }
  const enhancers = [applyMiddleware(...middleware)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  const composeEnhancers =
    process.env.NODE_ENV !== 'test' &&
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  // @ts-ignore
  const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));
  sagaMiddleware.run(rootSaga);
  return store;
}
