import axios from 'axios';
import { API_URL } from '../../constants';
import { getAccessToken } from '../../../auth/oktaConfig';
import { headers } from '../common/helper';

export const getSignerToken = async (requestBody: { envelopeId: string; signerId: string; signerEmail: string }) => {
  const accessToken = await getAccessToken();
  const { data } = await axios.post(`${API_URL}/signerToken`, requestBody, headers(accessToken));
  return data.access_token;
};

export const provisionUser = async (accountId: string) => {
  const accessToken = await getAccessToken();
  const { data } = await axios.post(`${API_URL}/accounts/${accountId}/user`, {}, headers(accessToken));
  return data;
};

export const getUsers = async (accountId: string, limit: number, skip: number, search?: string) => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(
    `${API_URL}/accounts/${accountId}/users?limit=${limit}${skip ? `&skip=${skip}` : ''}`,
    headers(accessToken)
  );
  return data;
};

export const getUserInfo = async (): Promise<{ data: any, status: number }> => {
  try {
    const accessToken = await getAccessToken();
    const response = await axios.get(`${API_URL}/userInfo`, headers(accessToken));
    return response;
  } catch (error) {
    return { data: {}, status: error.response?.status || 500 };
  }
};
