import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import { getGlobalToken } from '../../../auth/oktaConfig';
import { IRecipient, RecipientRole } from '../../../common/recipients/types';
import { IFile, IFileResponse } from '../../../dtm/types';
import { IFileDocument } from '../../../store/dtm/documents/types';
import { IContact, IListingContacts, ISaleContacts, IUser } from '../../../store/dtm/recipients/types';
import { primeApiUrl } from '../../constants';
import { propertyToString } from '../../utils';
import { primeAuthHeaders } from '../common/helper';

const graphQlRequest = async (query: string) => {
  const accessToken = await getGlobalToken();
  return axios.post(`${primeApiUrl}/graphql`, { query }, primeAuthHeaders(accessToken));
};

export interface UserOffice {
  officeGuid: string;
  officeName: string;
}

export type OfficeUser = {
  userGuid: string;
  oktaUserID: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const createPrimeContact = async (contact: { firstName: string; lastName: string; email: string }) => {
  const token = await getGlobalToken();
  const response = await axios.post(`${primeApiUrl}/contacts`, contact, primeAuthHeaders(token));
  if (response.status !== 200) {
    throw Error(`Expected 200, but received ${response.status}. ${response.statusText}`);
  }
  return response.data;
};

export const getContactsForPrimeUser = async () => {
  const token = await getGlobalToken();
  const { data } = await axios.get(`${primeApiUrl}/contacts`, primeAuthHeaders(token));
  return data.value.contacts;
};

interface GetFilesRequest {
  lastListingCursor?: string;
  lastSalesCursor?: string;
  listingEnd?: boolean;
  salesEnd?: boolean;
  searchValue?: string;
  sortAsc: boolean;
}

export const getFiles = async ({
  lastListingCursor,
  lastSalesCursor,
  listingEnd,
  salesEnd,
  searchValue,
  sortAsc,
}: GetFilesRequest): Promise<IFileResponse> => {
  const oneYearAgo = dayjs().year(dayjs().year() - 1);
  const createdAfterDate = oneYearAgo.format('YYYY-MM-DD') || '2020-05-01';
  const lastListingCursorString = lastListingCursor && lastListingCursor.length ? `after: "${lastListingCursor}"` : '';
  const lastSalesCursorString = lastSalesCursor && lastSalesCursor.length ? `after: "${lastSalesCursor}"` : '';
  const propertyAddressSearch = searchValue ? `propertyAddress: "${searchValue}"` : '';
  const pageSize = 20;
  const listingQuery = listingEnd
    ? ''
    : `listingsConnection(first: ${pageSize}, createdAfter: "${createdAfterDate}", status: "Active,Pending,Incomplete,Expired", ${lastListingCursorString}, ${propertyAddressSearch}, sortAscending: ${sortAsc}) {
    totalCount
    edges {
      cursor
      node {
        id
        createdOn
        expirationDate
        modifiedOn
        objectType
        status
        property {
          streetNumber
          direction
          streetAddress
          unit
          city
          state
          zip
        }
        agent {
          id
        }
      }
    }
  }`;
  const salesQuery = salesEnd
    ? ''
    : `salesConnection(first: ${pageSize}, createdAfter: "${createdAfterDate}", status: "Active,Pending,Incomplete,Expired", ${lastSalesCursorString}, ${propertyAddressSearch}, sortAscending: ${sortAsc}) {
    totalCount,
    edges {
      cursor
      node {
        id
        listingGuid
        createdOn
        modifiedOn
        objectType
        status
        property {
          streetNumber
          direction
          streetAddress
          unit
          city
          state
          zip
        }
        agent {
          id
        }
      }
    }
  }`;
  const request = await graphQlRequest(`{${listingQuery} ${salesQuery}}`);

  const { listingsConnection, salesConnection } = request.data.data;
  const filteredListingsConnection = listingEnd
    ? []
    : listingsConnection.edges.map((listing) => {
        const cursor = listing.cursor;
        return { ...listing.node, cursor };
      });
  const filteredSalesConnection = salesEnd
    ? []
    : salesConnection.edges.map((sale) => {
        const cursor = sale.cursor;
        return { ...sale.node, cursor };
      });
  filteredSalesConnection &&
    filteredSalesConnection.forEach((sale) => {
      sale.expirationDate = null;
    });

  const fileSort = (left: IFile, right: IFile) => {
    const d1 = new Date(left.modifiedOn).valueOf();
    const d2 = new Date(right.modifiedOn).valueOf();

    return sortAsc ? d1 - d2 : d2 - d1;
  };
  // We sort twice as many files as we need to account for
  // any mismatches in dates between the sales and listings
  const files: IFile[] = filteredListingsConnection
    .concat(filteredSalesConnection)
    .map((file: IFile) => {
      return {
        ...file,
        propertyStr: propertyToString(file.property),
      };
    })
    .sort(fileSort)
    .slice(0, pageSize);

  const totalCount =
    ((listingsConnection && listingsConnection.totalCount) || 0) +
    ((salesConnection && salesConnection.totalCount) || 0);

  const reversedFiles = files.slice().reverse();
  const getCursor = (conn: any[], currentCursor: string, filetype: string) => {
    const lastOfType = reversedFiles.find((f) => f.objectType === filetype);
    // If the last item of the cursor is also the last item in the DB, return an empty cursor
    if (!conn.length || (conn.length < pageSize && lastOfType && lastOfType.id === conn[conn.length - 1].id)) return '';
    // If we don't have any of that type in the list it means we just pulled everything from
    // the other list, and so we pull the same records next time
    if (!lastOfType) return currentCursor;

    return lastOfType.cursor;
  };
  const updatedLastListingCursor = getCursor(filteredListingsConnection, lastListingCursor, 'listing');
  const updatedLastSalesCursor = getCursor(filteredSalesConnection, lastSalesCursor, 'sale');
  return {
    properties: files,
    totalCount,
    lastListingCursor: updatedLastListingCursor,
    lastSalesCursor: updatedLastSalesCursor,
  };
};

export const getFileDocuments = async (fileType: 'sale' | 'listing', fileId: string): Promise<IFileDocument[]> => {
  const accessToken = await getGlobalToken();
  const { data } = await axios.get(
    `${primeApiUrl}/files/${fileType}s/${fileId}/documents`,
    primeAuthHeaders(accessToken)
  );
  return data.value.documents;
};

export const getFile = async (fileType: 'sale' | 'listing', fileId: string): Promise<IFile> => {
  const accessToken = await getGlobalToken();
  const { data } = await axios.get(`${primeApiUrl}/files/${fileType}s/${fileId}`, primeAuthHeaders(accessToken));
  const file: IFile = fileType === 'listing' ? data.value.listing : data.value.sale;
  return { ...file, propertyStr: propertyToString(file.property) };
};

const assignRecipientTitle = (contacts: ISaleContacts | IListingContacts) => {
  if (contacts.sellers) contacts.sellers.forEach((s) => (s.title = 'Seller/Landlord'));
  if (contacts.miscContact) contacts.miscContact!.title = 'Misc. Contact';
  if (contacts.buyers) contacts.buyers.forEach((b) => (b.title = 'Purchaser/Tenant'));
  if (contacts.attorneyContact) contacts.attorneyContact!.forEach((a) => (a.title = 'Attorney'));
  if (contacts.escrowContact) contacts.escrowContact!.title = 'Escrow';
  if (contacts.titleContact) contacts.titleContact!.title = 'Title';
  if (contacts.lenderContact) contacts.lenderContact!.title = 'Lender';
  if (contacts.otherSideAgentContact) contacts.otherSideAgentContact!.title = 'Agent On Other Side'; // TODO should other side agent be something else?
};

const getUser = async (userGuid: string): Promise<IUser> => {
  const accessToken = await getGlobalToken();
  const { data } = await axios.get(`${primeApiUrl}/users/${userGuid}`, primeAuthHeaders(accessToken));
  const user: IUser = data?.value?.user;
  return user;
};

export const getContacts = async (fileType: 'sale' | 'listing', fileId: string, impersonatedPrimeUserGuid?: string): Promise<IRecipient[]> => {
  const accessToken = await getGlobalToken();
  const { prime_user_guid } = jwt_decode(accessToken); // Grab prime user guid from claims
  const { data } = await axios.get(`${primeApiUrl}/files/${fileType}s/${fileId}`, primeAuthHeaders(accessToken));
  const allContacts: IContact[] = [];
  const allAgents: IUser[] = [];
  let agentGuid: string, coAgentGuids: string[];

  if (!data) {
    return [];
  }
  if (fileType === 'listing') {
    const listing: IListingContacts = data.value.listing;
    ({ agentGuid, coAgentGuids } = listing);
    assignRecipientTitle(listing);
    allContacts.push(...[listing.miscContact].concat(listing.sellers));
  } else {
    const sale: ISaleContacts = data.value.sale;
    ({ agentGuid, coAgentGuids } = sale);
    assignRecipientTitle(sale);
    allContacts.push(
      ...[
        sale.escrowContact,
        sale.lenderContact,
        sale.miscContact,
        sale.otherSideAgentContact,
        sale.titleContact,
      ].concat(sale.sellers, sale.buyers, sale.attorneyContact)
    );
  }

  // get agents
  // filter out the logged in user when retrieving the agent
  if (agentGuid && agentGuid !== (impersonatedPrimeUserGuid || prime_user_guid)) {
    try {
      const agent = await getUser(agentGuid);
      agent && (agent.title = 'Agent');
      allAgents.push(agent);
    } catch {}
  }

  // filter out the logged in user when retrieving co-agents
  const index = coAgentGuids?.indexOf((impersonatedPrimeUserGuid || prime_user_guid));
  if (index >= 0) {
    coAgentGuids.splice(index, 1);
  }

  if (coAgentGuids) {
    const coAgents = await Promise.all(
      coAgentGuids.map(async (coAgentGuid) => {
        try {
          const coAgent = await getUser(coAgentGuid);
          coAgent && (coAgent.title = 'Co-Agent');
          return coAgent;
        } catch {
          return null;
        }
      })
    );
    allAgents.push(...(coAgents.filter((c) => c !== null) as IUser[]));
  }

  // add contacts
  const optionalRecipients: IRecipient[] = allContacts
    .filter((c) => !!c)
    .map((c) => ({
      firstName: c.firstName,
      lastName: c.lastName,
      email: c.email,
      id: c.contactGuid,
      role: RecipientRole.Signer,
      title: c.title,
    }));

  // add agents as contacts
  optionalRecipients.push(
    ...allAgents
      .filter((c) => !!c)
      .map((c) => ({
        firstName: c.firstName,
        lastName: c.lastName,
        email: c.email,
        id: c.userGuid,
        role: RecipientRole.Signer,
        title: c.title,
      }))
  );

  return optionalRecipients;
};

export const getHeadersForPrimeApi = (token: string) => {
  return { Authorization: `Bearer ${token}`, 'Auth-Secondary': true, 'X-Okta-Authorization': true };
};

export const getOffices = async () => {
  const token = await getGlobalToken();
  const res: UserOffice[] = await axios
    .get(`${primeApiUrl}/offices`, {
      headers: getHeadersForPrimeApi(token),
    })
    .then((res) => {
      return res.data.value.offices;
    })
    .catch((e) => {
      return e;
    });
  return res;
};

export const getUsersInOffice = async (officeGuid: string) => {
  // if not values is passed to officeGuid, it returns the all users in all offices 
  if(!officeGuid) return [];
  const token = await getGlobalToken();
  const res: OfficeUser[] = await axios
    .get(`${primeApiUrl}/users?officeGuid=${officeGuid}`, {
      headers: getHeadersForPrimeApi(token),
    })
    .then((res) => {
      return res.data.value.users;
    })
    .catch((e) => {
      return e;
    });
  return res;
};
