import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { preferencesUrl, ResourceNames } from '../../lib/constants';
import { getUsersGroups } from '../../lib/api/account/accountApi';
import StyledTooltip from '../../common/StyledTooltip';
import AppMenu from '../../common/navigation/AppMenu';
import UserMenu from '../../common/navigation/UserMenu';
import { UserOrigins } from '../../lib/types';
import CollapseOnScroll from '../../common/CollapseOnScroll';
import { getUserAdminRoles } from '../../common/utils';
import { LaunchDarklyFlags } from '../../common/launchDarkly';
import useUserInfo from '../../auth/useUserInfo';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
  },
  headerNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 14,
    marginLeft: 10,
  },
  navLink: {
    fontSize: 16,
    color: colors.grey[800],
    '&:hover,&:focus': {
      textDecoration: 'none',
    },
    padding: '10px 15px',
  },
  selectedNavLink: {
    fontWeight: 'bold',
  },
  unselectedNavLink: {
    '&:hover,&:focus': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
      borderRadius: '20px',
      outline: 'none',
    },
  },
  navTitle: {
    marginBottom: 16,
  },
  navContent: {
    marginLeft: 24,
  },
  navLinks: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  navApps: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '14px',
    letterSpacing: '.4px',
    color: colors.grey[800],
  },
  tooltip: {
    marginTop: 0,
  },
}));

interface IProps {
  children?: any;
  resource: string;
  flags?: LaunchDarklyFlags;
  userOrigin?: UserOrigins;
}

const transformedString = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1) + 's';
};

const ManagementHeader = (props: IProps) => {
  const { children, resource, flags, userOrigin } = props;
  const { senderFirstName, senderLastName } = useUserInfo() ?? {};
  const classes = useStyles();
  const transformedResource = transformedString(resource);
  const areTemplatesEnabled = flags && flags['enable-templates-access'];
  const hideNavItems =
    (flags && flags['hide-build-ui-elements']) ||
    (userOrigin !== UserOrigins.Prime && userOrigin !== UserOrigins.Forms);
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const showBrokerManagementMenuItem = flags && flags['enable-broker-management-link'];
  const [user, setUser] = useState<{ isBrokerAdmin: boolean; isGroupAdmin: boolean }>({
    isBrokerAdmin: false,
    isGroupAdmin: false,
  });

  const componentToRender = (page: string) => {
    return resource === page ? (
      <Typography className={`${classes.navLink} ${classes.selectedNavLink}`}>{transformedResource}</Typography>
    ) : (
      <Link href={`/${page}s`} className={`${classes.navLink} ${classes.unselectedNavLink}`}>
        {transformedString(page)}
      </Link>
    );
  };

  useEffect(() => {
    getUserAdminRoles(setUser, getUsersGroups);
  }, []);

  return (
    <div className={classes.header}>
      <CollapseOnScroll>
        <div className={classes.headerNav}>
          <div className={classes.navLinks}>
            {componentToRender(ResourceNames.ENVELOPE)}
            {areTemplatesEnabled ? (
              componentToRender(ResourceNames.TEMPLATE)
            ) : (
              <StyledTooltip
                title={
                  <>
                    We are hustling to bring
                    <br />
                    you templates soon.
                  </>
                }
                classes={{ tooltip: classes.tooltip }}
                arrow
              >
                <Typography
                  tabIndex={0}
                  className={`${classes.navLink} ${classes.unselectedNavLink}`}
                  style={{ color: colors.grey[500], cursor: 'default' }}
                >
                  Templates
                </Typography>
              </StyledTooltip>
            )}
          </div>
          <div className={classes.navApps}>
            {hideNavItems ? '' : <AppMenu showBreezeApp={showBreezeApp} />}
            <UserMenu
              senderFirstName={senderFirstName}
              senderLastName={senderLastName}
              showPreferences={showPreferencesMenuItem}
              preferencesUrl={preferencesUrl}
              user={user}
              showBrokerManagement={showBrokerManagementMenuItem}
            />
          </div>
        </div>
      </CollapseOnScroll>
      <div className={classes.navContent}>
        <Typography variant="h3" className={classes.navTitle}>
          {transformedResource}
        </Typography>
        {children}
      </div>
    </div>
  );
};

export default ManagementHeader;
