import update from 'immutability-helper';
import { ActionTypes } from './actions';
import { ITemplateManagementState, ITemplateMetadata } from './types';

export const initialState: ITemplateManagementState = {
  isFetching: true,
  templates: [],
  fetchError: false,
  search: '',
  createdId: undefined,
  newEnvelopeId: undefined,
};

export default (state: ITemplateManagementState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_TEMPLATES_DONE: {
      const templates: ITemplateMetadata[] = action.payload;
      return update(state, {
        isFetching: { $set: false },
        templates: { $set: templates },
      });
    }
    case ActionTypes.FETCH_TEMPLATES_ERROR: {
      return update(state, {
        isFetching: { $set: false },
        fetchError: { $set: true },
      });
    }
    case ActionTypes.CREATE_TEMPLATE_DONE: {
      return update(state, {
        createdId: { $set: action.payload.id },
      });
    }
    case ActionTypes.CREATE_ENVELOPE_FROM_TEMPLATE_DONE: {
      const envelopeId = action.payload;
      return update(state, {
        newEnvelopeId: { $set: envelopeId },
      });
    }
    case ActionTypes.FILTER_TEMPLATES: {
      return update(state, {
        search: { $set: action.payload.search },
      });
    }
    default: {
      return state;
    }
  }
};
