import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as Sent } from '../../images/sent.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '128px',
    maxWidth: '600px',
    marginLeft: 16,
    marginRight: 16,
  },
  text: {
    marginTop: 36,
  },
});

const Done = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sent />
      <Typography variant="h5" className={classes.text}>
        Your envelope has been sent!
      </Typography>
    </div>
  );
};

export default Done;
