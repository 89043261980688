import React from "react";
import { Button } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LensIcon from '@material-ui/icons/Lens';
import { StrikeColors } from "../../../lib/constants";
import BlockToolbar from "../../common/BlockToolbar";
import { IBlock } from "../../../store/senderBuild/types";

interface IProps {
  block: IBlock;
  handleColorClick: (color: string) => void;
  blockOptions?: any;
}

const StrikeBlockToolbar = (props: IProps) => {
  const { block, handleColorClick } = props;
  const strikeColor = block.color?.toLowerCase() || StrikeColors.Black;

  return (
    <BlockToolbar styleProps={{
      cursor: 'move',
      position: 'absolute',
      zIndex: '9999',
      }} 
      id={`${block.blockId}-menu`} 
      menuText="Strike Color:"
      blockOptions={props.blockOptions}>
      <div className="flex mr-3">
          <Button data-spec="black-strike-button" disabled={strikeColor === StrikeColors.Black} onClick={() => handleColorClick(StrikeColors.Black)} 
            className='mr-1.5 text-black p-0 min-w-0 hover:bg-transparent'>
            {strikeColor === StrikeColors.Black ? 
              <CheckCircleIcon className='bg-white'/> :
              <LensIcon/>}
          </Button> 

          <Button data-spec="red-strike-button" disabled={strikeColor === StrikeColors.Red} onClick={() => handleColorClick(StrikeColors.Red)}
            className='mr-1.5 p-0 min-w-0 hover:bg-transparent' style={{ color: StrikeColors.Red }}>
            {strikeColor === StrikeColors.Red ? 
              <CheckCircleIcon className='bg-white'/> :
              <LensIcon />}
          </Button>
      </div>
    </BlockToolbar>
  )
};

export default StrikeBlockToolbar;