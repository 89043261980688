import { all, call, put, takeEvery, select, takeLatest, fork } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { ResourceNames, BLOCK_TYPE_KEYS } from '../../lib/constants';
import pdfCache from '../../lib/pdfCache';
import * as actions from './actions';
import * as pageFrameActions from '../pageFrame/actions';
import { setLoading, errorToast } from '../pageFrame/actions';
import {
  envelopeFetch,
  senderCreateBlock,
  senderCreateBlocksThenGroup,
  senderDeleteBlock,
  senderEditBlock,
  senderGroupEdit,
} from './sagaHandlers/envelope';
import { IRootState } from '../index';
import {
  templateFetch,
  templateBlockCreate,
  templateDeleteBlocks,
  templateEditBlock,
  createBlocksThenGroup,
  templateGroupEdit,
} from './sagaHandlers/template';
import { envelopeApi } from '../../lib/api/envelope';
import { userService } from '../../lib/api/userService';
import { primeApi } from '../../lib/api/prime';
import { updateRecipients, getRecipients } from '../../lib/api/envelope/recipients';
import { IBlock, ISenderBuildState, IGroup, IBlocks, IDocument } from './types';
import { deleteBlockGroup, editBlock, editBlockGroup } from '../../lib/api/envelope/envelope';
import { cleanUpInvalidBlockValuesAndLogToSentry, getBlockDocumentId } from '../../lib/utils';

const { ActionTypes } = actions;
const getState = (state: IRootState) => state.senderBuild;
const getResource = (state: IRootState) => state.senderBuild.resource;
const getDocuments = (state: IRootState) => state.senderBuild.documents;
const getEnvelopeId = (state: IRootState) => state.senderBuild.id;
const getBlocks = (state: IRootState) => state.senderBuild.blocks;

export function* handleFetch(action: ReturnType<typeof actions.fetch>) {
  try {
    yield put(setLoading(true));
    const resource = yield select(getResource);
    let result;
    if (resource === ResourceNames.ENVELOPE) {
      result = yield envelopeFetch(action.payload.id);
    } else if (resource === ResourceNames.TEMPLATE) {
      result = yield templateFetch(action.payload.id);
    }

    yield put(pageFrameActions.updateRecipientColorCounter(result.recipientColorCounter));
    yield put(actions.fetchDone(result, action.payload.userInfo));
    yield put(actions.fetchEnvelopePdfs());
  } catch (err) {
    console.log('Error fetching envelope: ', err);
    yield put(actions.fetchError('Error fetching Envelope'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleFetchEnvelopePdfs() {
  try {
    yield put(setLoading(true));
    const documents: IDocument[] = yield select(getDocuments);

    for (const doc of documents) {
      if (doc.presignedURL) {
        pdfCache[doc.documentId] = doc.presignedURL;
      } else {
        // TODO add presignedURL in templateService https://skyslope.atlassian.net/browse/DIGI-1887
        yield call(envelopeApi.getPdfBlobFromUrl, doc.url!);
      }
      yield put(actions.fetchEnvelopePdfDone(doc.documentId));
    }
  } catch (e) {
    yield put(actions.fetchEnvelopePdfError());
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleUpdateEmailConfig(action: ReturnType<typeof actions.updateEmailConfig>) {
  const resource = yield select(getResource);
  if (resource === ResourceNames.ENVELOPE) {
    try {
      yield call(envelopeApi.updateEmailConfig, action.payload.envelopeId, action.payload.emailConfig);
      yield put(actions.updateEmailConfigSuccess(action.payload.emailConfig.subject, action.payload.emailConfig.body));

      if (action.payload.sendEnvelope) {
        yield put(actions.sendEnvelope());
      }
    } catch (err) {
      let error = 'Please check your email body and subject for special characters.';
      if (action.payload.sendEnvelope) {
        Sentry.captureMessage('Failed to send envelope', {
          tags: {
            envelopeId: action.payload.envelopeId,
          },
          extra: {
            err,
            payload: action.payload,
          },
          level: 'error',
        });
        error = `Failed to send envelope. ${error}`;
      }
      yield put(errorToast(error));
      yield put(actions.updateEmailConfigError());
    }
  }
}

export function* handleSendEnvelope(action: ReturnType<typeof actions.sendEnvelope>) {
  const resource = yield select(getResource);
  if (resource === ResourceNames.ENVELOPE) {
    try {
      const senderBuild = yield select(getState);
      const res = yield call(envelopeApi.sendEnvelope, senderBuild.id);
      if (res.errors) {
        throw Error(res.errors);
      }
      yield put(actions.sendEnvelopeDone());
    } catch (err) {
      yield put(actions.sendEnvelopeError());
    }
  }
}

export function* handlePasteBlocks(action: ReturnType<typeof actions.pasteBlocks>) {
  const resource = yield select(getResource);
  const envelopeId = yield select(getEnvelopeId);
  const { documentId, pageIndex, blocks, groups } = action.payload;
  if (resource === ResourceNames.TEMPLATE) {
    yield put(actions.setAutoSaving(true));
    let remainingBlocks: IBlock[] = [...blocks];
    if (groups) {
      const groupApiCalls = [];
      for (const tempGroupId in groups) {
        const newGroup = groups[tempGroupId];
        const blockGroups = blocks.filter(block => block.groupId === tempGroupId);
        const blockGroupIds = blockGroups.map(block => block.blockId);
        remainingBlocks = remainingBlocks.filter(block => !blockGroupIds.includes(block.blockId!));
        newGroup.blockIds = [];
        groupApiCalls.push(createBlocksThenGroup(blockGroups, newGroup));
      }
      yield all(groupApiCalls);
    }
    const blockApiCalls: Generator[] = [];
    remainingBlocks.forEach(block => {
      blockApiCalls.push(templateBlockCreate(documentId, pageIndex, block));
    });
    const blockIds = yield all(blockApiCalls);
    remainingBlocks.forEach((block, i) => {
      block.blockId = blockIds[i];
    });
    yield put(actions.setAutoSaving(false));
  } else {
    try {
      yield put(actions.setAutoSaving(true));
      let remainingBlocks: IBlock[] = [...blocks];
      if (groups) {
        const groupApiCalls = [];
        for (const tempGroupId in groups) {
          const newGroup = groups[tempGroupId];
          const blockGroups = blocks.filter(block => block.groupId === tempGroupId);
          const blockGroupIds = blockGroups.map(block => block.blockId);
          remainingBlocks = remainingBlocks.filter(block => !blockGroupIds.includes(block.blockId!));
          newGroup.blockIds = [];
          groupApiCalls.push(senderCreateBlocksThenGroup(blockGroups, newGroup));
        }
        yield all(groupApiCalls);
      }
      const blockApiCalls: Generator[] = [];
      remainingBlocks.forEach(block => {
        blockApiCalls.push(senderCreateBlock(documentId, pageIndex, block));
      });
      const blockIds = yield all(blockApiCalls);
      remainingBlocks.forEach((block, i) => {
        block.blockId = blockIds[i];
      });
    } catch (e) {
      Sentry.captureMessage('Failed to paste blocks', {
        tags: {
          envelopeId,
        },
        extra: {
          e,
          payload: action.payload,
        },
        level: 'error',
      });
      yield put(pageFrameActions.errorToast('Failed to create block, please try again.'));
    } finally {
      yield put(actions.setAutoSaving(false));
    }
  }
  yield put(actions.pasteBlocksDone(documentId, pageIndex, blocks, groups));
}

export function* handleCreateBlock(action: ReturnType<typeof actions.createNewBlock>) {
  const resource = yield select(getResource);
  const envelopeId = yield select(getEnvelopeId);
  if (resource === ResourceNames.TEMPLATE) {
    yield put(actions.setAutoSaving(true));
    const id = yield templateBlockCreate(action.payload.documentId, action.payload.pageIndex, action.payload.block);
    yield put(
      actions.createNewBlockDone(action.payload.documentId, action.payload.pageIndex, action.payload.block.blockId!, id)
    );
    if (action.payload.block.blockType !== BLOCK_TYPE_KEYS.STRIKE)
      yield put(
        actions.updateBlockSizingCache(
          action.payload.block.height!,
          action.payload.block.width!,
          action.payload.block.blockType
        )
      );
    yield put(actions.setAutoSaving(false));
  } else {
    try {
      const envelopeId: string = yield select(getEnvelopeId);
      yield put(actions.setAutoSaving(true));
      let { block } = action.payload;
      block = cleanUpInvalidBlockValuesAndLogToSentry(block, envelopeId, 'Block had null values');

      const id = yield senderCreateBlock(action.payload.documentId, action.payload.pageIndex, block);
      yield put(
        actions.createNewBlockDone(
          action.payload.documentId,
          action.payload.pageIndex,
          action.payload.block.blockId!,
          id
        )
      );
      if (action.payload.block.blockType !== BLOCK_TYPE_KEYS.STRIKE)
        yield put(actions.updateBlockSizingCache(block.height!, block.width!, block.blockType));
    } catch (err) {
      Sentry.captureMessage('Error adding block', {
        tags: {
          envelopeId,
        },
        extra: {
          err,
          payload: action.payload,
        },
        level: 'error',
      });
      yield put(pageFrameActions.errorToast('Failed to create block, please refresh and try again.'));
      yield put(
        actions.deleteBlocksDone(
          action.payload.documentId,
          action.payload.pageIndex,
          [action.payload.block.blockId],
          ResourceNames.ENVELOPE
        )
      );
    } finally {
      yield put(actions.setAutoSaving(false));
    }
  }
}

export function* handleEditBlock(action: ReturnType<typeof actions.editBlock>) {
  const resource = yield select(getResource);
  const envelopeId = yield select(getEnvelopeId);
  if (resource === ResourceNames.TEMPLATE) {
    yield put(actions.setAutoSaving(true));
    yield templateEditBlock(
      action.payload.documentId,
      action.payload.pageIndex,
      action.payload.blockIndex,
      action.payload.updateObj
    );
    yield put(actions.setAutoSaving(false));
  } else {
    try {
      const senderBuild = yield select(getState);
      if (!senderBuild.saving) {
        yield put(actions.setAutoSaving(true));
      }
      yield senderEditBlock(action.payload.documentId, action.payload.pageIndex, action.payload.blockIndex);
    } catch (e) {
      Sentry.captureMessage('Error editing block', {
        tags: {
          envelopeId,
        },
        extra: {
          e,
          payload: action.payload,
        },
        level: 'error',
      });
      yield put(pageFrameActions.errorToast('Failed to edit block, please refresh and try again.'));
    } finally {
      yield put(actions.setAutoSaving(false));
    }
  }
}

export function* deleteBlocksFromGroupHelper(group: IGroup, blockIds: string[], documentId: string, pageIndex: number) {
  const senderBuild: ISenderBuildState = yield select(getState);
  const envelopeId: string = yield select(getEnvelopeId);
  const blockIdToEdit = group.blockIds?.find(id => !blockIds.includes(id));
  // verify block and group exist before trying to delete - DIGI-2016
  if (blockIdToEdit && senderBuild.blocks[documentId][pageIndex].find(b => b.blockId === blockIdToEdit)) {
    const blockToEdit: IBlock = senderBuild.blocks[documentId][pageIndex].find(b => b.blockId === blockIdToEdit);
    const blockToEditIndex = senderBuild.blocks[documentId][pageIndex].indexOf(blockToEdit);
    yield put(actions.removeGroupIdFromBlock(documentId, pageIndex, blockToEditIndex));
    yield call(deleteBlockGroup, envelopeId, documentId, group.id, blockIdToEdit);
  } else {
    yield call(deleteBlockGroup, envelopeId, documentId, group.id);
  }
  // call reducer to delete block group from store
  yield put(actions.deleteBlockGroupDone(group.id, documentId, pageIndex));
}

function* deleteBlocksTemplate(resource: ResourceNames, action: ReturnType<typeof actions.deleteBlocks>): any {
  yield put(actions.setAutoSaving(true));
  yield templateDeleteBlocks(action.payload.documentId, action.payload.pageIndex, action.payload.blockIds);
  yield put(actions.setAutoSaving(false));
}

function* deleteBlocksSingleBlockInGroup(
  action: ReturnType<typeof actions.deleteBlocks>,
  senderBuild: ISenderBuildState,
  block: IBlock
): any {
  const group: IGroup = senderBuild?.groups?.[action.payload.documentId]?.[action.payload.pageIndex]?.[block.groupId!];

  // if group doesn't exist, delete checkbox as regular block - DIGI-2016
  if (!group) {
    yield senderDeleteBlock(action.payload.documentId, action.payload.pageIndex, action.payload.blockIds);
    return;
  }

  // if only one group is left after deleting block, then delete group
  const shouldDeleteGroup = group.blockIds.length === 2 || group.blockIds.length - action.payload.blockIds.length === 1;
  if (shouldDeleteGroup) {
    yield call(
      deleteBlocksFromGroupHelper,
      group,
      action.payload.blockIds,
      action.payload.documentId,
      action.payload.pageIndex
    );
  }

  // if the group has more than 2 elements
  if (!shouldDeleteGroup) {
    // remove the blockId to delete from the groupIds in GroupEdit
    const blockIds = group?.blockIds.filter(id => !action.payload.blockIds.includes(id));
    yield senderGroupEdit(action.payload.documentId, action.payload.pageIndex, group.id, { blockIds });
  }
}

function* deleteBlocksCheckboxWithSameGroupId(action: ReturnType<typeof actions.deleteBlocks>, group: IGroup): any {
  const blockIds = group?.blockIds.filter(id => !action.payload.blockIds.includes(id));
  yield senderGroupEdit(action.payload.documentId, action.payload.pageIndex, group.id, { blockIds });
}

function* deleteBlocksMultiWithAtLeastOneWithGroupId(
  action: ReturnType<typeof actions.deleteBlocks>,
  senderBuild: ISenderBuildState,
  blocks: (IBlock | undefined)[]
): any {
  // build list of deleteBlocksFromGroupHelper api calls
  const groups: any[] = [];
  // build list of senderDeleteBlock api calls for non-group blocks
  const nonGroupBlocks: any[] = [];
  // build a set of groups that have been modified
  const seenGroups = new Set();

  blocks.forEach(block => {
    // verify if group exist before trying to delete from group - DIGI-2016
    const groupExists =
      block?.groupId && senderBuild.groups?.[action.payload.documentId]?.[action.payload.pageIndex]?.[block?.groupId];
    // if group exists
    if (groupExists) {
      const group = senderBuild.groups?.[action.payload.documentId]?.[action.payload.pageIndex]?.[block?.groupId];
      // a one call per group
      if (!seenGroups.has(group)) {
        seenGroups.add(group);
        // add a call to delete blocksIds from group
        groups.push(
          deleteBlocksFromGroupHelper(
            group,
            action.payload.blockIds,
            action.payload.documentId,
            action.payload.pageIndex
          )
        );
      }
    }
    // block with no groupId or the group doesn't exist or was already deleted - DIGI-2016
    if (!groupExists) nonGroupBlocks.push(block?.blockId);
  });

  // all the call to delete blocks with groups
  yield all(groups);

  // call to delete all blocks with no group
  if (nonGroupBlocks.length)
    yield senderDeleteBlock(action.payload.documentId, action.payload.pageIndex, nonGroupBlocks);
}

function* deleteBlockCaptureError(action: ReturnType<typeof actions.deleteBlocks>, envelopeId: string, e: Error): any {
  Sentry.captureMessage('Error deleting blocks', {
    tags: {
      envelopeId,
    },
    extra: {
      e,
      payload: action.payload,
    },
    level: 'error',
  });
  if (!e.message.includes('404')) {
    yield put(pageFrameActions.errorToast('Failed to delete block, please refresh and try again.'));
  }
}

export function* handleDeleteBlocks(action: ReturnType<typeof actions.deleteBlocks>): any {
  let error = false;
  const resource = yield select(getResource);

  if (resource === ResourceNames.TEMPLATE) yield* deleteBlocksTemplate(resource, action);

  if (resource === ResourceNames.ENVELOPE) {
    const store = yield select(getState);
    const envelopeId = yield select(getEnvelopeId);

    try {
      const { isAutoSaving } = store;

      // If autosaving, cancel deleteBlock action
      if (isAutoSaving) {
        yield put(actions.deleteBlocksCancelled());
        return;
      }

      yield put(actions.setAutoSaving(true));
      const senderBuild: ISenderBuildState = yield select(getState);

      // deleting only one block
      if (action.payload.blockIds.length === 1) {
        const block: IBlock = senderBuild.blocks[action.payload.documentId][action.payload.pageIndex].find(
          b => b.blockId === action.payload.blockIds[0]
        )!;

        // if block is part of a group and this group exist
        if (block.groupId) yield* deleteBlocksSingleBlockInGroup(action, senderBuild, block);

        // if block doesn't have groupId
        if (!block.groupId)
          yield senderDeleteBlock(action.payload.documentId, action.payload.pageIndex, action.payload.blockIds);
      }

      // deleting multiple blocks
      if (action.payload.blockIds.length > 1) {
        const blocks = action.payload.blockIds.map(id =>
          senderBuild.blocks[action.payload.documentId][action.payload.pageIndex].find(block => id === block.blockId)
        );

        const groups = senderBuild?.groups?.[action.payload.documentId]?.[action.payload.pageIndex];

        const firstGroupId = blocks?.find(block => block?.groupId)?.groupId;
        const groupFound = firstGroupId ? groups?.[firstGroupId] : undefined;

        // Deleting several checkboxes from the same group, but not all.
        // Special case for deleting blocks from checkbox group using Checkbox Menu - bug DIGI-2104
        // @ts-ignore
        const shouldDeleteGroup = groupFound?.blockIds?.length - blocks?.length > 1;

        const isEveryBlockInSameGroup =
          groupFound &&
          blocks?.[0]?.groupId &&
          blocks.every(block => block?.groupId && block?.groupId === blocks?.[0]?.groupId);

        if (isEveryBlockInSameGroup && shouldDeleteGroup)
          yield* deleteBlocksCheckboxWithSameGroupId(action, groupFound);

        if (!(isEveryBlockInSameGroup && shouldDeleteGroup)) {
          // at least one block has groupId
          if (firstGroupId) yield* deleteBlocksMultiWithAtLeastOneWithGroupId(action, senderBuild, blocks);

          // none of the blocks has groupId
          if (!firstGroupId)
            yield senderDeleteBlock(action.payload.documentId, action.payload.pageIndex, action.payload.blockIds);
        }
      }
    } catch (e) {
      error = true;
      yield* deleteBlockCaptureError(action, envelopeId, e);
    } finally {
      yield put(actions.setAutoSaving(false));
    }
  }

  if (!error) {
    yield put(
      actions.deleteBlocksDone(action.payload.documentId, action.payload.pageIndex, action.payload.blockIds, resource)
    );
  } else {
    yield put(actions.setAutoSaving(false));
  }
}

export function* handleCreateGroup(action: ReturnType<typeof actions.createGroup>) {
  const resource = yield select(getResource);
  const envelopeId = yield select(getEnvelopeId);
  const { group, initialBlockId, newBlocks } = action.payload;

  if (resource === ResourceNames.TEMPLATE) {
    yield put(actions.setAutoSaving(true));
    yield createBlocksThenGroup(newBlocks, group);
    yield put(actions.setAutoSaving(false));
  } else {
    try {
      yield put(actions.setAutoSaving(true));
      yield senderCreateBlocksThenGroup(newBlocks, group);
    } catch (e) {
      Sentry.captureMessage('Failed to create block group', {
        tags: {
          envelopeId,
        },
        extra: {
          e,
          payload: action.payload,
        },
        level: 'error',
      });
      yield put(pageFrameActions.errorToast('Failed to create group, please refresh and try again.'));
    } finally {
      yield put(actions.setAutoSaving(false));
    }
  }

  yield put(actions.createGroupDone(group, newBlocks, initialBlockId));
}

export function* handleAddNewBlocksToGroup(action: ReturnType<typeof actions.addNewBlocksToGroup>) {
  const resource = yield select(getResource);
  const envelopeId = yield select(getEnvelopeId);
  const { group, newBlocks } = action.payload;

  if (resource === ResourceNames.TEMPLATE) {
    yield put(actions.setAutoSaving(true));
    const apiCalls: Generator[] = [];
    newBlocks.forEach(newBlock => {
      const { groupId, ...blockWithoutGroupId } = newBlock;
      apiCalls.push(templateBlockCreate(group.documentId, group.pageNumber, blockWithoutGroupId));
    });
    const blockIds = yield all(apiCalls);
    const newBlockGroupIds = [...group.blockIds, ...blockIds];
    yield templateGroupEdit(group.documentId, group.pageNumber, group.id, { blockIds: newBlockGroupIds });
    newBlocks.forEach((newBlock, i) => {
      newBlock.blockId = blockIds[i];
    });
    yield put(actions.setAutoSaving(false));
  } else {
    try {
      yield put(actions.setAutoSaving(true));
      const apiCalls: Generator[] = [];
      newBlocks.forEach(newBlock => {
        const { groupId, ...blockWithoutGroupId } = newBlock;
        apiCalls.push(senderCreateBlock(group.documentId, group.pageNumber, blockWithoutGroupId));
      });
      const blockIds = yield all(apiCalls);
      const newBlockGroupIds = [...group.blockIds, ...blockIds];
      yield senderGroupEdit(group.documentId, group.pageNumber, group.id, { blockIds: newBlockGroupIds });
      newBlocks.forEach((newBlock, i) => {
        newBlock.blockId = blockIds[i];
      });
    } catch (e) {
      Sentry.captureMessage('Failed to add block to group', {
        tags: {
          envelopeId,
        },
        extra: {
          e,
          payload: action.payload,
        },
        level: 'error',
      });
      yield put(pageFrameActions.errorToast('Failed to create block, please refresh and try again.'));
    } finally {
      yield put(actions.setAutoSaving(false));
    }
  }

  yield put(actions.addNewBlocksToGroupDone(group, newBlocks));
}

export function* handleEditBlockGroup(action: ReturnType<typeof actions.editBlockGroup>) {
  const resource = yield select(getResource);
  const envelopeId = yield select(getEnvelopeId);

  if (resource === ResourceNames.TEMPLATE) {
    yield put(actions.setAutoSaving(true));
    yield templateGroupEdit(
      action.payload.documentId,
      action.payload.pageIndex,
      action.payload.groupId,
      action.payload.updateObj
    );
    yield put(actions.setAutoSaving(false));
  } else {
    try {
      yield put(actions.setAutoSaving(true));
      yield senderGroupEdit(
        action.payload.documentId,
        action.payload.pageIndex,
        action.payload.groupId,
        action.payload.updateObj
      );
    } catch (e) {
      Sentry.captureMessage('Failed to edit block group', {
        tags: {
          envelopeId,
        },
        extra: {
          e,
          payload: action.payload,
        },
        level: 'error',
      });
      yield put(pageFrameActions.errorToast('Failed to edit group, please refresh and try again.'));
    } finally {
      yield put(actions.setAutoSaving(false));
    }
  }
}

export function* handleFetchRecipients(action: ReturnType<typeof actions.fetchRecipients>) {
  const { envelopeId } = action.payload;
  try {
    const res = yield call(getRecipients, envelopeId, true);
    yield put(actions.fetchRecipientsDone(res));
  } catch (e) {
    console.error('Error fetching recipients.');
  }
}

export function* handleUpdateRecipients(action: ReturnType<typeof actions.updateRecipients>) {
  yield put(actions.setRecipientsLoading(true));
  const containsSigner = action.payload.recipients.find(r => r.role === 'Signer');
  if (action.payload.recipients.length === 0 || !containsSigner) {
    yield put(actions.updateRecipientsError());
    yield put(pageFrameActions.errorToast('Error saving recipients.'));
    return;
  }
  try {
    const res = yield call(updateRecipients, action.payload.recipients, action.payload.envelopeId);
    const signers = res.data;
    yield put(actions.updateRecipientsDone(signers, action.payload.userInfo));
    yield put(pageFrameActions.successToast('Recipient changes saved.'));
  } catch (e) {
    yield put(actions.updateRecipientsError());
    yield put(pageFrameActions.errorToast('Error saving recipients.'));
  } finally {
    yield put(actions.setRecipientsLoading(false));
  }
}

export function* handleFetchSignerToken(action: ReturnType<typeof actions.fetchSignerToken>) {
  try {
    const signerToken = yield call(userService.getSignerToken, action.payload.requestBody);
    yield put(actions.fetchSignerTokenDone(signerToken));
  } catch (e) {
    yield put(pageFrameActions.errorToast('Error loading envelope. Please try again.'));
  }
}

export function* handleCreatePrimeContact(action: ReturnType<typeof actions.createPrimeContact>) {
  try {
    yield call(primeApi.createPrimeContact, action.payload);
  } catch (e) {
    yield put(pageFrameActions.errorToast('Error saving contact.'));
  }
}

export function* handleFetchContacts(action: ReturnType<typeof actions.fetch>) {
  try {
    const contacts = yield call(primeApi.getContactsForPrimeUser);
    yield put(actions.fetchContactsDone(contacts));
  } catch (e) {
    console.log('Error fetching contacts.');
  }
}

export function* deleteBlocksSaga() {
  yield takeEvery(ActionTypes.DELETE_BLOCKS, handleDeleteBlocks);
}

export function* validateBlocksBeforeSending(action: ReturnType<typeof actions.validateBlocksBeforeSending>) {
  try {
    let { blocks } = action.payload;
    const { groups } = action.payload;
    const { emailConfig } = action.payload;
    const { sendEnvelope } = action.payload;
    const blockApiCalls: any[] = [];
    const groupApiCalls: any[] = [];
    const envelopeBlocks: IBlocks = yield select(getBlocks);
    const envelopeId = yield select(getEnvelopeId);
    if (blocks.length) {
      blocks.forEach(block => {
        const documentId = getBlockDocumentId(block.blockId!, envelopeBlocks);
        blockApiCalls.push(editBlock(envelopeId, documentId, block));
      });
    }
    if (groups.length) {
      groups.forEach(group => {
        blockApiCalls.push(editBlockGroup(envelopeId, group));
      });
    }
    yield all(blockApiCalls);
    yield all(groupApiCalls);
    yield put(actions.updateEmailConfig(envelopeId, emailConfig, sendEnvelope));
  } catch (e) {
    yield put(pageFrameActions.errorToast('Error sending envelope. Please contact support.'));
  }
}

export function* handleClearBlocks(action: ReturnType<typeof actions.clearBlocks>) {
  const documents: IDocument[] = yield select(getDocuments);
  try {
    yield all(documents.filter(d => action.payload.documentIds.includes(d.documentId)).map(d => call(envelopeApi.setDocumentBlocks, action.payload.envelopeId, d.documentId, [])));
  } catch (e) {
    yield put(pageFrameActions.errorToast('Error clearing blocks. Please refresh and try again.'));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH, handleFetch),
    takeEvery(ActionTypes.FETCH_ENVELOPE_PDFS, handleFetchEnvelopePdfs),
    takeEvery(ActionTypes.UPDATE_EMAIL_CONFIG, handleUpdateEmailConfig),
    takeEvery(ActionTypes.SEND_ENVELOPE, handleSendEnvelope),
    takeEvery(ActionTypes.CREATE_NEW_BLOCK, handleCreateBlock),
    takeEvery(ActionTypes.PASTE_BLOCKS, handlePasteBlocks),
    takeLatest(ActionTypes.EDIT_BLOCK, handleEditBlock),
    takeEvery(ActionTypes.UPDATE_RECIPIENTS, handleUpdateRecipients),
    fork(deleteBlocksSaga),
    takeEvery(ActionTypes.CREATE_GROUP, handleCreateGroup),
    takeEvery(ActionTypes.ADD_NEW_BLOCKS_TO_GROUP, handleAddNewBlocksToGroup),
    takeEvery(ActionTypes.EDIT_GROUP, handleEditBlockGroup),
    takeEvery(ActionTypes.FETCH_RECIPIENTS, handleFetchRecipients),
    takeEvery(ActionTypes.FETCH_SIGNER_TOKEN, handleFetchSignerToken),
    takeEvery(ActionTypes.CREATE_PRIME_CONTACT, handleCreatePrimeContact),
    takeEvery(ActionTypes.FETCH_CONTACTS, handleFetchContacts),
    takeEvery(ActionTypes.VALIDATE_BLOCKS_BEFORE_SENDING, validateBlocksBeforeSending),
    takeEvery(ActionTypes.CLEAR_BLOCKS, handleClearBlocks),
  ]);
}
