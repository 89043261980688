import { useEffect, useState } from 'react';
import useUserInfo from '../auth/useUserInfo';

export default function useHeap() {
  const [init, setInit] = useState(false);
  const userInfo = useUserInfo();

  useEffect(() => {
    if (!init && userInfo) {
      window.heap?.identify(userInfo.oktaUserId);
      window.heap?.addUserProperties({
        Ds3UserId: userInfo.senderId,
        email: userInfo.senderEmail,
        UserType: userInfo.primeContactType,
        SubscriberId: userInfo.subscriberId,
        FnfUser: !!userInfo.digisign_fnf_account_id,
      });
      setInit(true);
    }
  }, [init, userInfo]);
}
