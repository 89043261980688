import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import noResultsFound from '../../images/no_results_found.svg';

import { ResourceNames } from '../../lib/constants';

interface IProps {
  resource?: ResourceNames;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconWrapper: {
    width: '280px',
    height: '280px',
  },
  noResultsImg: {
    width: '100%',
    height: '100%',
  },
}));

const textMap = {
  [ResourceNames.ENVELOPE]: 'Try changing your search terms or filters to find what you’re looking for.',
  [ResourceNames.TEMPLATE]: 'Try changing your search terms to find what you’re looking for.',
};

const NoResultsState = (props: IProps) => {
  const classes = useStyles();
  const { resource } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classes.iconWrapper}>
        {/* To be later replaced */}
        <img src={noResultsFound} alt="No Results Found" className={classes.noResultsImg} />
      </div>
      <div className={classes.textWrapper}>
        <Typography variant="h2">No results found</Typography>
        <Typography variant="body1">{resource ? textMap[resource] : textMap.template}</Typography>
      </div>
    </div>
  );
};

export default NoResultsState;
