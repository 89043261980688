import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { HeaderContext } from '../context/header-context';
import { IRootState } from '../store';
import { useShallowSelector, useDispatch } from '../lib/reduxHooks';
import { ISettings } from '../store/pageFrame/types';
import ManagementHeader from '../components/common/ManagementHeader';
import FooterLinks from '../components/common/FooterLinks';
import * as actions from '../store/pageFrame/actions';
import Spinner from '../components/common/Spinner';
import {
  signaturePreference,
  signaturePreferenceOptions,
  keepBlocksSelectedPreference,
  keepBlockSelectedOptions,
  PreferencePanelData,
  PreferencePanelOption,
  emailDocsAsAttachmentPreferenceOptions,
  emailDocsAsAttachmentPreference,
  dateFormatPreference,
  dateFormatPreferenceOptions,
  automatedEmailsReminderPreference,
  automatedEmailsReminderPreferenceOptions,
} from '../lib/preferencePanels';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';

const selector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
  signerGroups: state.senderBuild.signingGroups,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  contacts: state.senderBuild.recipientsModal.contacts,
});

interface IState {
  settings: ISettings;
  settingsFetched: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  checked: {
    color: `${colors.blue[800]} !important`,
  },
   borderTop: {
    borderTop: `1px solid ${colors.grey[300]}`,
  },
}));


const RadioLabel = (props: { option: PreferencePanelOption }) => {
  const { option } = props;
  const mainLabelText = option.isDefault ? (
    <>
      <span className="font-bold text-[19px] tracking-[.16px]">{option.label} </span>
      <span className="text-[19px]">(default)</span>
    </>
  ) : (
    <span className="font-bold text-[19px] tracking-[.16px]">{option.label}</span>
  );
  return (
    <div>
      {mainLabelText}
      <br />
      <span className="text-['16px'] whitespace-normal break-words">{option.subLabel}</span>
    </div>
  );
};

const PreferencePanel = (props: {
  data: PreferencePanelData;
  initialSelection: string;
  options: PreferencePanelOption[];
  onSave: (val: string) => void;
  dataSpec?: string;
}) => {
  const classes = useStyles();
  const { data, options, onSave, dataSpec} = props;
  const [hoverActive, setHoverActive] = React.useState(false);
  const [currentSelection, setCurrentSelection] = React.useState(props.initialSelection);
  const isButtonDisabled = currentSelection === props.initialSelection;
  const setGifOrImage = (option: PreferencePanelOption) => {
    return hoverActive ? option.onHoverImage : option.image;
  };
  const initialOption = options.find((o) => o.value === currentSelection) || options.find((o) => o.isDefault);

  const gif = setGifOrImage(initialOption!);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSelection((e.target as HTMLInputElement).value);
  };

  return (
    <div className="mb-24">
      <Typography className="flex items-center" variant="h3">
        <img className="h-16 w-16 mr-4" src={data.titleImage} alt="title logo" />
        {data.title}
      </Typography>
      <Card className="p-10 mt-8" data-spec={dataSpec}>
        <div className="flex">
          <div className="flex-1 mr-8">
            <img
              className="w-[486px] border h-[294px] border-solid border-[#CAD5E0]"
              onMouseEnter={() => setHoverActive(true)}
              onMouseLeave={() => setHoverActive(false)}
              src={gif}
              alt=""
            />
          </div>
          <div className="flex-1 ml-[30px]">
            <FormControl className="is-1">
              <RadioGroup
                aria-label={data.ariaLabel}
                value={currentSelection}
                onChange={handleChange}
                data-spec="radio-control"
              >
                {options.map((o: PreferencePanelOption, index) => {
                  const className = `items-start px-6 py-7 hover:bg-[${colors.blue[50]}] ${index > 0 ? classes.borderTop:''}`;
                  return (
                    <FormControlLabel
                      className={className}
                      value={o.value}
                      control={
                        <Radio
                          classes={{ checked: classes.checked }}
                          color="primary"
                          style={{color: colors.grey[600] }}
                        />
                      }
                      label={<RadioLabel option={o} />}
                      data-spec={o.dataSpec}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Card>
      <Button
        className="flex float-right mt-6"
        variant="contained"
        color="primary"
        disabled={isButtonDisabled}
        onClick={() => onSave(currentSelection)}
        data-spec={data.dataSpec}
      >
        Save
      </Button>
    </div>
  );
};

const UserPreferences = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const dispatch = useDispatch();
  const { settings, settingsFetched }: IState = useShallowSelector(selector);
  const headerContext = React.useContext(HeaderContext);
  const [loading, setLoading] = React.useState(true);
  const showEmailSettingsPreference = flags && flags['enable-email-settings-preference'];
  const showDateFormatPreference = flags && flags['enable-date-stamping-preferences'];
  const showAutomatedRemindersFlag = flags && flags['automated-reminders'];

  React.useEffect(() => {
    headerContext.setContent(
      <ManagementHeader
        resource="Preference"
        flags={flags}
        userOrigin={settings?.account.userOrigin}
      />
    );
  }, [settingsFetched]);

  React.useEffect(() => {
    if (settingsFetched) {
      setLoading(false);
    }
  }, [settingsFetched]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col max-w-[1200px]">
      <PreferencePanel
        data={signaturePreference}
        initialSelection={settings.user.autoStampDateTime.preference || 'None'}
        options={signaturePreferenceOptions}
        onSave={(val: string) => {
          dispatch(
            actions.updateUserSettings({
              autoStampDateTime: {
                preference: val,
              },
            })
          );
        }}
      />
      {showDateFormatPreference &&
        <PreferencePanel
          data={dateFormatPreference}
          initialSelection={settings.user.dateFormat?.preference || 'MM/DD/YYYY'}
          options={dateFormatPreferenceOptions}
          onSave={(val: string) => {
            dispatch(
              actions.updateUserSettings({
                dateFormat: {
                  preference: val,
                },
              })
            );
          }}
        />
      }
      <PreferencePanel
        data={keepBlocksSelectedPreference}
        initialSelection={(settings.user.keepBlocksSelected || false).toString()}
        options={keepBlockSelectedOptions}
        onSave={(val: string) => {
          dispatch(
            actions.updateUserSettings({
              keepBlocksSelected: val === 'true',
            })
          );
        }}
      />
      {!showEmailSettingsPreference ? <></> :
        <PreferencePanel
          dataSpec='attachmentSettings'
          data={emailDocsAsAttachmentPreference}
          initialSelection={(settings.user.emailDocsAsAttachment !== undefined ? settings.user.emailDocsAsAttachment : true).toString()}
          options={emailDocsAsAttachmentPreferenceOptions}
          onSave={(val: string) => {
            dispatch(
              actions.updateUserSettings({
                emailDocsAsAttachment: val === 'true',
              })
            );
          }}
        />}
      {showAutomatedRemindersFlag ? 
      <PreferencePanel 
      dataSpec={automatedEmailsReminderPreference.dataSpec} 
      data={automatedEmailsReminderPreference}
      initialSelection={(settings.user.automatedReminders?.enabled !== undefined ? settings.user.automatedReminders.enabled : true).toString()}
      options={automatedEmailsReminderPreferenceOptions}
      onSave={(val: string) => {
        dispatch(actions.updateUserSettings({
          automatedReminders: {
            enabled: val === 'true',
          }
        }))
      }}/> : <></>}
      <div className="absolute bottom-[40px] left-[100px] right-[100px]">
        <FooterLinks showSocialIcons customMarginTop="0px" />
      </div>
    </div>
  );
};

export default withLaunchDarkly(UserPreferences);
