import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalCancelButton, ModalConfirmationButton, ModalText, ModalTitle } from '../../common/Modal';
import { cancelEnvelope } from '../../store/envelopeManagement/actions';

interface IProps {
  envelopeId: string;
  close: () => void;
}

const cancelConfig = {
  canceledReason: 'This envelope was not ready to be sent.',
};

const CancelModal = (props: IProps) => {
  const dispatch = useDispatch();
  const { envelopeId, close } = props;

  const onClickCancel = () => {
    dispatch(cancelEnvelope(envelopeId, cancelConfig));
    close();
  };

  const title = <ModalTitle>Are you sure you want to cancel your envelope?</ModalTitle>;
  const content = (
    <>
      <ModalText>
        Canceling this envelope will remove access for your recipients to sign or view this envelope.
      </ModalText>
    </>
  );
  const actionButtons = (
    <>
      <ModalCancelButton onClick={close} aria-label="keep envelope">
        Keep Envelope
      </ModalCancelButton>
      <ModalConfirmationButton onClick={onClickCancel} aria-label="confirm cancel envelope">
        Cancel Envelope
      </ModalConfirmationButton>
    </>
  );
  return <Modal toggleModal={close} title={title} content={content} actionButtons={actionButtons} />;
};

export default CancelModal;
