import { EnvelopeStatuses } from '@digisign3-ui/sender/src/lib/constants';
import { IEnvelopeMetadata } from '@digisign3-ui/sender/src/store/envelopeManagement/types';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import React from 'react';

export const STATUSES = {
  [EnvelopeStatuses.ALL]: {
    statuses: null,
    key: 'all',
    label: 'All',
    dateColumn: {
      key: 'updatedDate',
      label: 'Updated',
      sortKey: 'DATE_UPDATED',
      cell: (envelope: IEnvelopeMetadata) => (
        <Typography variant="body1">{dayjs(envelope.updatedDate).format('MM/DD/YYYY h:mm A')}</Typography>
      ),
    },
  },
  [EnvelopeStatuses.COMPLETED]: {
    statuses: [EnvelopeStatuses.COMPLETED],
    key: 'completed',
    label: 'Completed',
    dateColumn: {
      key: 'completedDate',
      label: 'Completed',
      sortKey: 'DATE_COMPLETED',
      cell: (envelope: IEnvelopeMetadata) => (
        <Typography variant="body1">{dayjs(envelope.completedDate).format('MM/DD/YYYY h:mm A')}</Typography>
      ),
    },
  },
  [EnvelopeStatuses.SENT]: {
    statuses: [
      EnvelopeStatuses.SENT,
      EnvelopeStatuses.INPROGRESS,
      EnvelopeStatuses.CORRECTING,
      EnvelopeStatuses.CANCELED,
    ],
    key: 'sentDate',
    label: 'Sent',
    showFilter: true,
    dateColumn: {
      key: 'sentDate',
      label: 'Sent',
      sortKey: 'DATE_SENT',
      cell: (envelope: IEnvelopeMetadata) =>
        envelope.status === 'Canceled' ? (
          ''
        ) : (
          <Typography variant="body1">{dayjs(envelope.sentDate).format('MM/DD/YYYY h:mm A')}</Typography>
        ),
    },
  },
  [EnvelopeStatuses.DRAFT]: {
    statuses: [EnvelopeStatuses.DRAFT],
    key: 'draft',
    label: 'Draft',
    dateColumn: {
      key: 'createdDate',
      label: 'Created',
      sortKey: 'DATE_CREATED',
      cell: (envelope: IEnvelopeMetadata) => (
        <Typography variant="body1">{dayjs(envelope.createdDate).format('MM/DD/YYYY h:mm A')}</Typography>
      ),
    },
  },
  [EnvelopeStatuses.CORRECTING]: {
    statuses: [EnvelopeStatuses.CORRECTING],
    key: 'correcting',
    label: 'Correcting',
  },
  [EnvelopeStatuses.INPROGRESS]: {
    statuses: [EnvelopeStatuses.INPROGRESS, EnvelopeStatuses.SENT],
    key: 'inProgress',
    label: 'In Progress',
  },
  [EnvelopeStatuses.CANCELED]: {
    statuses: [EnvelopeStatuses.CANCELED],
    key: 'canceled',
    label: 'Canceled',
  },
};
