import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { hex2rgba, generateFileName } from '../../lib/utils';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import { Document, Page } from 'react-pdf';
import ZoomButtons from '../common/ZoomButtons';
import { ZOOM_INCREMENT } from '../../lib/constants';
import VisibilitySensor from 'react-visibility-sensor';
import Dialog from '@material-ui/core/Dialog';
import { IDocumentBody } from '../../store/envelopeHistory/types';
import { getEnvelopeDocuments } from '../../lib/api/envelope/envelope';
import { useDispatch } from 'react-redux';
import { downloadEnvelope } from '../../store/envelopeHistory/actions';
import Skeleton from '@material-ui/lab/Skeleton';
import { PDFPageProxy } from 'react-pdf/dist/Page';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
  },
  paper: {
    paddingLeft: 32,
    backgroundColor: hex2rgba(colors.grey[900], 0.85),
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 64,
    backgroundColor: colors.grey[900],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    padding: '0 24px',
    whiteSpace: 'nowrap',
  },
  subject: {
    color: '#FFFFFF',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  whiteText: {
    color: '#FFFFFF',
  },
  closeButton: {
    color: colors.grey[500],
  },
  downloadButton: {
    color: '#FFFFFF',
    marginRight: 8,
    padding: '6px 12px',
    '&:hover, &:focus': {
      backgroundColor: 'rgba(255,255,255,0.15)',
    },
  },
  buttonIcon: {
    marginRight: 4,
  },
  toolbarWrapper: {
    position: 'fixed',
    bottom: 48,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: hex2rgba(colors.grey[900], 0.85),
    color: 'white',
    borderRadius: 4,
  },
  toolbarSection: {
    padding: '16px 0',
    width: 173,
    textAlign: 'center',
    '&:first-child': {
      borderRight: `1px solid ${colors.grey[700]}`,
    },
  },
  pdfs: {
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 80,
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  skeleton: {
    backgroundColor: 'rgba(255,255,255,0.75)',
  },
  pageWrapper: {
    position: 'relative',
    backgroundColor: 'white',
    '&:not(:first-child)': {
      marginTop: 32,
    },
  },
  visibilityPixel: {
    position: 'absolute',
    left: '50%',
    top: '40%',
    zIndex: 0,
    width: 1,
    height: 1,
  },
  document: {
    width: '100%',
  },
  pageFlex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  pageSide: {
    flexGrow: 1,
  },
}));

interface IProps {
  envelopeId: string;
  subject: string;
  close: () => void;
}

const PdfPreview = (props: IProps) => {
  const { envelopeId, subject, close } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [zoom, setZoom] = React.useState(1.25);
  const [visiblePage, setVisiblePage] = React.useState(0);
  const [isFetching, setIsFetching] = React.useState(true);
  const [documents, setDocuments] = React.useState<IDocumentBody[]>([]);
  const [fullPagesPageDimensions, setFullPagesPageDimensions] = React.useState<any[]>([]);
  const [firstPageLoaded, setFirstPageLoaded] = React.useState(false);

  React.useEffect(() => {
    const init = async () => {
      const docs = await getEnvelopeDocuments(envelopeId);
      setDocuments(docs);
      const numFullPages = docs.reduce((acc, curr) => {
        return acc + curr.numberOfPages;
      }, 0);
      const fullPages = new Array(numFullPages).fill({});
      setFullPagesPageDimensions(fullPages);
      setIsFetching(false);
    };
    init();
  }, []);

  if (isFetching) {
    return null;
  }

  const download = (print?: boolean) => {
    const envelopeFileName = generateFileName(documents);
    dispatch(downloadEnvelope(envelopeId, envelopeFileName, print));
  };

  const handlePageLoad = (page: PDFPageProxy, fullPage: number) => {
    const pageHeight = page.height;
    const width = page.width;
    const pageDimensions = fullPagesPageDimensions[fullPage];
    pageDimensions.height = pageHeight;
    pageDimensions.width = width;
    setFullPagesPageDimensions(fullPagesPageDimensions);
  };

  const getPageHeight = (currPage: number) => {
    return fullPagesPageDimensions[currPage].height ? fullPagesPageDimensions[currPage].height : null;
  };

  const getPageWidth = (currPage: number) => {
    return fullPagesPageDimensions[currPage].width ? fullPagesPageDimensions[currPage].width : null;
  };

  const totalPages = documents.reduce((sum, doc) => sum + doc.numberOfPages, 0);
  let fullPage = 0;
  return (
    <Dialog open fullScreen PaperProps={{ className: classes.paper }} className={classes.wrapper} onClose={close}>
      <header className={classes.header}>
        <Typography variant="body2" className={classes.subject}>
          {subject}
        </Typography>
        <div>
          <Button
            variant="text"
            aria-label="download pdf"
            className={classes.downloadButton}
            onClick={() => download()}
          >
            <DownloadIcon className={classes.buttonIcon} /> Download
          </Button>
          <Button
            variant="text"
            aria-label="print pdf"
            className={classes.downloadButton}
            onClick={() => download(true)}
          >
            <PrintIcon className={classes.buttonIcon} /> Print
          </Button>
          <IconButton onClick={close} aria-label="close pdf preview" className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      <div className={classes.pdfs}>
        {!firstPageLoaded && (
          <Skeleton
            className={classes.skeleton}
            variant="rect"
            animation="wave"
            height={792 * zoom}
            width={612 * zoom}
          />
        )}
        {documents.map((document) => {
          const doc = (
            <Document
              file={document.presignedURL}
              key={document.fileName}
              loading=""
              className={classes.document}
              externalLinkTarget="_blank"
            >
              {Array(document.numberOfPages)
                .fill(null)
                .map((_, pageIndex: number) => {
                  const currPage = fullPage + pageIndex;
                  const renderPdf = currPage < visiblePage + 3 && currPage > visiblePage - 3;
                  const pageWidth = document.pages.length
                    ? document.pages[pageIndex].width * zoom
                    : getPageWidth(currPage)
                    ? getPageWidth(currPage)
                    : 612 * zoom;
                  const pageHeight = document.pages.length
                    ? document.pages[pageIndex].height * zoom
                    : getPageHeight(currPage)
                    ? getPageHeight(currPage)
                    : 792 * zoom;
                  return (
                    <div key={currPage} className={classes.pageFlex}>
                      <div className={classes.pageSide} onClick={close} />
                      <div
                        key={currPage}
                        className={classes.pageWrapper}
                        style={{
                          width: pageWidth,
                          height: pageHeight,
                        }}
                      >
                        <VisibilitySensor
                          onChange={(isVisible) => {
                            isVisible && setVisiblePage(currPage);
                          }}
                        >
                          <div className={classes.visibilityPixel} />
                        </VisibilitySensor>
                        {renderPdf && (
                          <Page
                            pageNumber={pageIndex + 1}
                            scale={zoom}
                            renderInteractiveForms={false}
                            renderTextLayer={false}
                            loading=""
                            onRenderSuccess={
                              !firstPageLoaded && currPage === 0 ? () => setFirstPageLoaded(true) : undefined
                            }
                            onLoadSuccess={(page) => {
                              handlePageLoad(page, currPage);
                            }}
                          />
                        )}
                      </div>
                      <div className={classes.pageSide} onClick={close} />
                    </div>
                  );
                })}
            </Document>
          );
          fullPage += document.numberOfPages;
          return doc;
        })}
      </div>
      <div className={classes.toolbarWrapper}>
        <div className={classes.toolbar}>
          <div className={classes.toolbarSection}>
            <Typography variant="body2" className={classes.whiteText}>
              Page {visiblePage + 1}/<span data-spec="total-pages">{totalPages}</span>
            </Typography>
          </div>
          <div className={classes.toolbarSection}>
            <ZoomButtons
              zoom={zoom}
              zoomIn={() => setZoom(zoom + ZOOM_INCREMENT)}
              zoomOut={() => setZoom(zoom - ZOOM_INCREMENT)}
              darkMode
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PdfPreview;
