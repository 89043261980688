import update from 'immutability-helper';
import { ActionTypes } from './actions';
import { IEnvelopeManagementState } from './types';
import { resetPageInfo } from '../../lib/api/common/pagedGraphQLRequest';
import produce from 'immer';
import { EnvelopeStatuses } from '../../lib/constants';

export const initialState: IEnvelopeManagementState = {
  init: false,
  envelopes: [],
  totalEnvelopeCount: 0,
  search: '',
  status: 'All',
  orderBy: 'DATE_UPDATED',
  orderByDesc: true,
  envelopeRedirect: undefined,
  endReached: false,
  pdfPreview: undefined
};

export default (state: IEnvelopeManagementState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      resetPageInfo();
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_ENVELOPES_DONE: {
      const { search, status, orderBy, orderByDesc, envelopes, totalEnvelopeCount, endReached } = action.payload;

      const newEnvelopes =
        search !== state.search ||
          status !== state.status ||
          orderBy !== state.orderBy ||
          orderByDesc !== state.orderByDesc
          ? []
          : [...state.envelopes];
      newEnvelopes.push(...envelopes);
      return update(state, {
        search: { $set: search },
        status: { $set: status },
        orderBy: { $set: orderBy },
        orderByDesc: { $set: orderByDesc },
        init: { $set: true },
        envelopes: { $set: newEnvelopes },
        totalEnvelopeCount: { $set: totalEnvelopeCount },
        endReached: { $set: endReached },
      });
    }
    case ActionTypes.CREATE_NEW_ENVELOPE_DONE: {
      return update(state, {
        envelopeRedirect: { $set: action.payload },
      });
    }
    case ActionTypes.DELETE_ENVELOPE_DONE: {
      return produce(state, draft => {
        draft.envelopes = state.envelopes.filter(e => e.id !== action.payload);
        draft.totalEnvelopeCount -= 1;
      });
    }
    case ActionTypes.CANCEL_ENVELOPE_DONE: {
      state.envelopes.find(i => i.id === action.payload)!.status = 'Canceled';
      state.envelopes.find(i => i.id === action.payload)!.updatedDate = new Date().toString();
      return update(state, {
        envelopes: { $set: state.envelopes },
      });
    }
    case ActionTypes.OPEN_PDF_PREVIEW: {
      return produce(state, draft => {
        draft.pdfPreview = action.payload;
      });
    }
    case ActionTypes.CLOSE_PDF_PREVIEW: {
      return produce(state, draft => {
        draft.pdfPreview = undefined;
      });
    }
    case ActionTypes.UPDATE_ENVELOPES: {
      return update(state, {
        envelopes: { $set: action.payload },
      });
    }
    case ActionTypes.CORRECT_ENVELOPE_DONE: {
      return produce(state, draft => {
        const index = draft.envelopes.findIndex(e => e.id === action.payload);
        draft.envelopes[index].status = EnvelopeStatuses.CORRECTING;
        draft.envelopeRedirect = draft.envelopes[index];
      });
    }
    case ActionTypes.RENAME_ENVELOPE_DONE: {
      const { envelopeId, updatedName } = action.payload;
      return produce(state, draft => {
        const index = draft.envelopes.findIndex(e => e.id === envelopeId);
        draft.envelopes[index].name = updatedName;
        draft.envelopes[index].updatedDate = new Date().toString();
      });
    }
    default: {
      return state;
    }
  }
};
