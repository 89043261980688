import { action } from 'typesafe-actions';
import { ISettings, IUserSettings, IUserSubscription } from './types';

export enum ActionTypes {
  SET_LOADING = 'pageFrame/SET_LOADING',
  ERROR_TOAST = 'pageFrame/ERROR_TOAST',
  SUCCESS_TOAST = 'pageFrame/SUCCESS_TOAST',
  HIDE_ERROR_TOAST = 'pageFrame/HIDE_ERROR_TOAST',
  GET_ACCOUNT_SETTINGS = 'pageFrame/GET_ACCOUNT_SETTINGS',
  SET_ACCOUNT_SETTINGS = 'pageFrame/SET_ACCOUNT_SETTINGS',
  UPDATE_USER_SUBSCRIPTION = 'pageFrame/UPDATE_USER_SUBSCRIPTION',
  UPDATE_USER_SUBSCRIPTION_DONE = 'pageFrame/UPDATE_USER_SUBSCRIPTION_DONE',
  SHOW_ALERT = 'envelopeHistory/SHOW_ALERT',
  HIDE_ALERT = 'envelopeHistory/HIDE_ALERT',
  UPDATE_USER_SETTINGS = 'envelopeManagement/UPDATE_USER_SETTINGS',
  UPDATE_USER_SETTINGS_DONE = 'envelopeManagement/UPDATE_USER_SETTINGS_DONE',
  UPDATE_RECIPIENT_COLOR_COUNTER = 'pageFrame/UPDATE_RECIPIENT_COLOR_COUNTER',
  UPDATE_IS_FETCHING_USER_INFO = 'pageFrame/UPDATE_IS_FETCHING_USER_INFO',
  UPDATE_IS_USER_INFO_FETCHED = 'pageFrame/UPDATE_IS_USER_INFO_FETCHED',
  FETCH_USER_INFO = 'pageFrame/FETCH_USER_INFO',
  GET_GLOBAL_TOKEN = 'pageFrame/GET_GLOBAL_TOKEN',
  GET_GLOBAL_TOKEN_DONE = 'pageFrame/GET_GLOBAL_TOKEN_DONE',
}

export const setLoading = (loading: boolean) => action(ActionTypes.SET_LOADING, loading);
export const errorToast = (msg: string) => action(ActionTypes.ERROR_TOAST, msg);
export const successToast = (msg: string) => action(ActionTypes.SUCCESS_TOAST, msg);
export const hideErrorToast = () => action(ActionTypes.HIDE_ERROR_TOAST);
export const getSettings = (digisignAccountId: string, senderId: string) =>
  action(ActionTypes.GET_ACCOUNT_SETTINGS, { digisignAccountId, senderId });
export const setSettings = (settings: ISettings) => action(ActionTypes.SET_ACCOUNT_SETTINGS, settings);
export const updateUserSubscription = (showToast?: boolean) => action(ActionTypes.UPDATE_USER_SUBSCRIPTION, showToast);
export const updateUserSubscriptionDone = (subscription?: IUserSubscription) =>
  action(ActionTypes.UPDATE_USER_SUBSCRIPTION_DONE, subscription);
export const showAlert = (payload: { message: string; type: string }) => action(ActionTypes.SHOW_ALERT, payload);
export const hideAlert = () => action(ActionTypes.HIDE_ALERT);
export const updateUserSettings = (settings: Partial<IUserSettings>) =>
  action(ActionTypes.UPDATE_USER_SETTINGS, settings);
export const updateUserSettingsDone = (settings: IUserSettings) =>
  action(ActionTypes.UPDATE_USER_SETTINGS_DONE, settings);
export const updateRecipientColorCounter = (counter: number) =>
  action(ActionTypes.UPDATE_RECIPIENT_COLOR_COUNTER, counter);
export const updateisFetchingUserInfo = (value: boolean) => action(ActionTypes.UPDATE_IS_FETCHING_USER_INFO, value);
export const updateIsUserInfoFetched = (value: boolean) => action(ActionTypes.UPDATE_IS_USER_INFO_FETCHED, value);
export const fetchUserInfo = (accountId?: string) => action(ActionTypes.FETCH_USER_INFO, accountId);
export const fetchGlobalToken = () => action(ActionTypes.GET_GLOBAL_TOKEN);
export const fetchGlobalTokenDone = () => action(ActionTypes.GET_GLOBAL_TOKEN_DONE);
