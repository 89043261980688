import React, { useState } from 'react';

type SmartEmailState = {
  tone: string;
  setTone: (tone: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  originalBody: string;
  setOriginalBody: (body: string) => void;
};

export const SmartEmailContext = React.createContext({} as SmartEmailState);

export const SmartEmailContextProvider = (props: any) => {
  const [tone, setTone] = useState('');
  const [loading, setLoading] = useState(false);
  const [originalBody, setOriginalBody] = useState('');

  return (
    <SmartEmailContext.Provider value={{ tone, setTone, loading, setLoading, originalBody, setOriginalBody }}>
      {props.children}
    </SmartEmailContext.Provider>
  );
};

export const useSmartEmailContext = () => React.useContext(SmartEmailContext);
