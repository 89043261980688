import { ActionTypes } from './actions';
import update from 'immutability-helper';
import { IRecipientsState } from './types';

export const initialState: IRecipientsState = {
  file: undefined,
  optionalRecipients: [],
  recipients: [],
  isFetching: true,
  fetchError: false,
  done: false,
  contacts: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_DONE: {
      const { file, optionalRecipients, recipients } = action.payload;
      return update(state, {
        isFetching: { $set: false },
        file: { $set: file },
        optionalRecipients: { $set: optionalRecipients },
        recipients: { $set: recipients },
      });
    }
    case ActionTypes.UPDATE_RECIPIENTS: {
      return { ...state, recipients: action.payload };
    }
    case ActionTypes.UPDATE_OPTIONAL_RECIPIENTS: {
      return { ...state, optionalRecipients: action.payload };
    }
    case ActionTypes.SAVE: {
      return { ...state, isFetching: true };
    }
    case ActionTypes.SAVE_DONE: {
      return { ...state, done: true };
    }
    case ActionTypes.FETCH_CONTACTS_DONE: {
      return update(state, {
        contacts: { $set: action.payload },
      });
    }
    case ActionTypes.CLEAR_CONTACTS: {
      return update(state, {
        contacts: { $set: [] },
      });
    }
    default: {
      return state;
    }
  }
};
