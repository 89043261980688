import { action } from 'typesafe-actions';
import {
  IEnvelopeHistoryBody,
  IAuditHistoryBody,
  IEnvelopeHistoryState,
  ICreateEmailBody,
  IRecipientBody,
} from './types';
export enum ActionTypes {
  RESET = 'envelopeHistory/RESET',
  FETCH = 'envelopeHistory/FETCH',
  FETCH_DONE = 'envelopeHistory/FETCH_DONE',
  DOWNLOAD_ENVELOPE = 'envelopeHistory/DOWNLOAD_ENVELOPE',
  DOWNLOAD_CERTIFICATE = 'envelopeHistory/DOWNLOAD_CERTIFICATE',
  RESEND = 'envelopeHistory/RESEND',
  RESEND_DONE = 'envelopeHistory/RESEND_DONE',
  SEND_EMAIL = 'envelopeHistory/SEND_EMAIL',
  SEND_EMAIL_DONE = 'envelopeHistory/SEND_EMAIL_DONE',
  UPDATE_RECIPIENTS = 'envelopeHistory/UPDATE_RECIPIENTS',
  UPDATE_RECIPIENTS_DONE = 'envelopeHistory/UPDATE_RECIPIENTS_DONE',
  UPDATE_RECIPIENTS_ERROR = 'envelopeHistory/UPDATE_RECIPIENTS_ERROR',
  FETCH_CONTACTS = 'envelopeHistory/FETCH_CONTACTS',
  FETCH_CONTACTS_DONE = 'envelopeHistory/FETCH_CONTACTS_DONE',
  UPDATE_RECIPIENT = 'envelopeHistory/UPDATE_RECIPIENT',
}

export const reset = (newStore?: IEnvelopeHistoryState) => action(ActionTypes.RESET, newStore);
export const fetch = (envelopeId: string) => action(ActionTypes.FETCH, envelopeId);
export const fetchDone = (auditHistory: IAuditHistoryBody[], envelopeHistory?: IEnvelopeHistoryBody) =>
  action(ActionTypes.FETCH_DONE, { auditHistory, envelopeHistory });
export const downloadEnvelope = (envelopeId: string, fileName: string, print?: boolean) =>
  action(ActionTypes.DOWNLOAD_ENVELOPE, { envelopeId, fileName, print });
export const downloadCertificate = (envelopeId: string, fileName: string, print?: boolean) =>
  action(ActionTypes.DOWNLOAD_CERTIFICATE, { envelopeId, fileName, print });
export const resend = (envelopeId: string) => action(ActionTypes.RESEND, envelopeId);
export const resendDone = (envelopeId: string, envelopeHistory?: IEnvelopeHistoryBody) =>
  action(ActionTypes.RESEND_DONE, { envelopeId, envelopeHistory });
export const sendEmail = (envelopeId: string, createEmailConfig: ICreateEmailBody) =>
  action(ActionTypes.SEND_EMAIL, { envelopeId, createEmailConfig });
export const sendEmailDone = () => action(ActionTypes.SEND_EMAIL_DONE);
export const updateRecipients = (recipients: IRecipientBody[], envelopeId: string) =>
  action(ActionTypes.UPDATE_RECIPIENTS, { recipients, envelopeId });
export const updateRecipientsDone = (recipients: IRecipientBody[]) =>
  action(ActionTypes.UPDATE_RECIPIENTS_DONE, recipients);
export const updateRecipientsError = (error: boolean | null) => action(ActionTypes.UPDATE_RECIPIENTS_ERROR, error);
export const fetchContacts = () => action(ActionTypes.FETCH_CONTACTS);
export const fetchContactsDone = (contacts: any) => action(ActionTypes.FETCH_CONTACTS_DONE, contacts);
export const updateRecipient = (recipient: IRecipientBody, updatedRecipients: IRecipientBody[], envelopeId: string) =>
  action(ActionTypes.UPDATE_RECIPIENT, { recipient, updatedRecipients, envelopeId });
