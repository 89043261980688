import React from 'react';
import { colors } from '@skyslope/mache';
import { makeStyles, TableRow, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableMui from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Skeleton from '@material-ui/lab/Skeleton';
import SkeletonRow from './SkeletonRow';

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    backgroundColor: '#FFFFFF',
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
    height: 64,
  },
  wrapper: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${colors.grey[400]}`,
    borderBottomWidth: 2,
  },
  tableCell: {
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
  },
  backgroundOverride: {
    backgroundColor: 'inherit',
  },
  tableHeader: {
    display: 'table',
    marginLeft: -16,
    padding: '8px 16px',
    borderRadius: '50px',
  },
  headerLabel: {
    display: 'table-cell',
  },
  noColumnsHeader: {
    width: 185,
  },
}));

interface IProps {
  columns?: any[];
  numberOfColumns?: number;
  rowsToShow?: number;
}

const SkeletonTable = (props: IProps) => {
  const { columns, rowsToShow, numberOfColumns } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <TableMui stickyHeader>
        <TableHead>
          <TableRow className={classes.tableRow}>
            {columns
              ? columns.map((col, index) => (
                  <TableCell
                    key={index}
                    classes={{ root: classes.tableCell, stickyHeader: classes.backgroundOverride }}
                    align={col.align || 'left'}
                  >
                    <div className={classes.tableHeader}>
                      <Typography className={classes.headerLabel} variant="body2">
                        {col.label}
                      </Typography>
                    </div>
                  </TableCell>
                ))
              : [...Array(numberOfColumns || 3)].map((value, index) => (
                  <TableCell
                    key={index}
                    classes={{ root: classes.tableCell, stickyHeader: classes.backgroundOverride }}
                    align="left"
                  >
                    <div className={classes.tableHeader}>
                      <Typography className={classes.headerLabel} variant="body2">
                        <Skeleton className={classes.noColumnsHeader} animation="wave" />
                      </Typography>
                    </div>
                  </TableCell>
                ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(rowsToShow || 10)].map(i => (
            <SkeletonRow key={i} columns={columns} numberOfColumns={numberOfColumns} />
          ))}
        </TableBody>
      </TableMui>
    </div>
  );
};

export default SkeletonTable;
