import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalCancelButton, ModalConfirmationButton, ModalText, ModalTitle } from '../../common/Modal';
import { deleteEnvelope } from '../../store/envelopeManagement/actions';

interface IProps {
  envelopeId: string;
  close: () => void;
}

const DeleteEnvelopeModal = (props: IProps) => {
  const dispatch = useDispatch();
  const { envelopeId, close } = props;

  const onClickDelete = () => {
    dispatch(deleteEnvelope(envelopeId));
    close();
  };

  const title = <ModalTitle>Are you sure you want to delete your draft?</ModalTitle>;
  const content = (
    <>
      <ModalText>Deleting a draft cannot be undone.</ModalText>
      <ModalText>All signers and documents will be permanently deleted.</ModalText>
    </>
  );
  const actionButtons = (
    <>
      <ModalCancelButton onClick={close} aria-label="keep envelope">
        Keep Envelope
      </ModalCancelButton>
      <ModalConfirmationButton onClick={onClickDelete} aria-label="confirm delete envelope">
        Delete Draft
      </ModalConfirmationButton>
    </>
  );
  return <Modal toggleModal={close} title={title} content={content} actionButtons={actionButtons} />;
};

export default DeleteEnvelopeModal;
