import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import DescriptionIcon from '@material-ui/icons/Description';
import Skeleton from '@material-ui/lab/Skeleton';
import SkeletonTable from '../../../common/loading/SkeletonTable';
import Search from '../../../components/common/Search';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: 1200,
    width: '100%',
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px 70px`,
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  divider: {
    height: 1,
    width: '100%',
    marginTop: 40,
    marginBottom: 32,
    backgroundColor: colors.grey[400],
  },
  tableControls: {
    marginBottom: theme.spacing(3),
  },
  count: {
    display: 'flex',
    alignItems: 'center',
  },
  countIcon: {
    marginRight: theme.spacing(1),
    color: colors.grey[500],
  },
  foundSkeleton: {
    width: 200,
  },
}));

const LoadingTable = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="h3" className={classes.header}>
        Select a property
      </Typography>
      <Search value="" onChange={() => {}} />
      <div className={classes.divider} />
      <div className={classes.tableControls}>
        <Typography variant="overline" className={classes.count} data-spec="count">
          <DescriptionIcon className={classes.countIcon} />
          <span className={classes.foundSkeleton}>
            <Skeleton animation="wave" />
          </span>
        </Typography>
      </div>
      <SkeletonTable />
    </div>
  );
};

export default LoadingTable;
