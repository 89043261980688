import update from 'immutability-helper';
import { ActionTypes } from './actions';
import { IPropertiesState } from './types';

export const initialState: IPropertiesState = {
  properties: [],
  totalProperties: 0,
  totalCount: 0,
  lastListingCursor: '',
  lastSalesCursor: '',
  listingEnd: false,
  salesEnd: false,
  initialPropertiesFetched: false,
};

export default (state: IPropertiesState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROPERTIES_DONE: {
      const { properties } = action.payload;
      const isEndOfListings = properties.lastListingCursor === '';
      const isEndOfSales = properties.lastSalesCursor === '';
      const updatedProperties = action.payload.searchType !== 'scroll' ? [] : [...state.properties];
      updatedProperties.push(...properties.properties);
      return update(state, {
        properties: {
          $set: updatedProperties,
        },
        totalCount: { $set: properties.totalCount },
        lastListingCursor: { $set: properties.lastListingCursor },
        lastSalesCursor: { $set: properties.lastSalesCursor },
        salesEnd: { $set: isEndOfSales },
        listingEnd: { $set: isEndOfListings },
        initialPropertiesFetched: !state.initialPropertiesFetched
          ? { $set: true }
          : { $set: state.initialPropertiesFetched },
      });
    }
    case ActionTypes.RESET_END_OF_PROPERTIES_REACHED: {
      return update(state, {
        salesEnd: { $set: false },
        listingEnd: { $set: false },
        lastListingCursor: { $set: '' },
        lastSalesCursor: { $set: '' },
      });
    }
    case ActionTypes.RESET_PROPERTIES: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};
