import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import { colors } from '@skyslope/mache';
import CollapseOnScroll from '../CollapseOnScroll';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 14,
    marginTop: 2,
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  },
  sharingPageBreadcrumb: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    marginBottom: 27,
  },
  arrowLeft: {
    color: colors.grey[500],
    height: 28,
    width: 28,
    marginLeft: -8,
  },
  name: {
    fontSize: 20,
    marginTop: -2,
  },
  progressBar: (props: IProps) => ({
    position: 'absolute',
    height: 4,
    backgroundImage: `linear-gradient(to right, ${colors.blue[100]}, ${colors.blue[800]})`,
    bottom: 0,
    left: 0,
    width: '100%',
    transition: props.progressBarPercent ? 'transform 0.5s ease-in-out' : undefined,
  }),
}));

interface IBreadcrumb {
  name: string;
  onClick: () => void;
}

interface IProps {
  name: string;
  breadcrumb?: IBreadcrumb;
  progressBarPercent?: number;
  onSharingPage?: boolean;
}

const HeaderTitle = (props: IProps) => {
  const { name, breadcrumb, progressBarPercent, onSharingPage } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      {onSharingPage ? (
        <CollapseOnScroll>
          {breadcrumb && (
            <Typography className={classes.sharingPageBreadcrumb} variant="body1" onClick={breadcrumb.onClick}>
              <ArrowLeftIcon className={classes.arrowLeft} />
              {breadcrumb.name}
            </Typography>
          )}
        </CollapseOnScroll>
      ) : (
        <>
          {breadcrumb && (
            <Typography className={classes.breadcrumb} variant="body1" onClick={breadcrumb.onClick}>
              <ArrowLeftIcon className={classes.arrowLeft} />
              {breadcrumb.name}
            </Typography>
          )}
        </>
      )}
      <Typography className={onSharingPage ? '' : classes.name} variant="h3">
        {name}
      </Typography>
      {progressBarPercent && (
        <div
          className={classes.progressBar}
          style={{ transform: `translateX(-${100 - progressBarPercent}%)` }}
          aria-label={`Create progress bar at ${progressBarPercent}%`}
          data-spec={`progress-bar-${progressBarPercent}`}
        />
      )}
    </div>
  );
};

export default HeaderTitle;
