import { all, call, put, takeEvery } from 'redux-saga/effects';
import { errorToast, setLoading } from '../pageFrame/actions';
import * as actions from './actions';
import { ITemplateResponse } from '../../lib/api/template';
import { ITemplateMetadata } from './types';
import * as templateApi from '../../lib/api/template';

const ActionTypes = actions.ActionTypes;

export function* handleFetchTemplates(action: ReturnType<typeof actions.fetchTemplates>) {
  try {
    const res: ITemplateResponse<ITemplateMetadata[]> = yield call(templateApi.getTemplates, action.payload.fetchCreatedDate);
    const templates = res.result;
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Templates GET: ${res.status}`);
    }
    yield put(actions.fetchTemplatesDone(templates!));
  } catch (err) {
    yield put(actions.fetchTemplatesError('Error fetching Templates'));
  }
}

export function* handleCreateTemplate(action: ReturnType<typeof actions.createTemplate>) {
  try {
    yield put(setLoading(true));
    const res: ITemplateResponse<ITemplateMetadata> = yield call(templateApi.createTemplate);
    const template = res.result;
    if (res.status !== 201) {
      throw Error(`Non-201 Status Code of Templates POST: ${res.status}`);
    }
    yield put(actions.createTemplateDone(template!));
  } catch (err) {
    yield put(actions.createTemplateError('Error Creating Template'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleDeleteTemplate(action: ReturnType<typeof actions.deleteTemplate>) {
  try {
    const res: ITemplateResponse<ITemplateMetadata> = yield call(templateApi.deleteTemplate, action.payload.templateId);
    if (res.status !== 204) {
      throw Error(`Non-204 Status Code of Template DELETE: ${res.status}`);
    }
    yield put(actions.fetchTemplates(action.payload.fetchCreatedDate));
  } catch (err) {
    yield put(actions.deleteTemplateError('Error Deleting Template'));
  }
}

export function* handleCreateEnvelopeFromTemplate(action: ReturnType<typeof actions.createEnvelopeFromTemplate>) {
  try {
    const res: ITemplateResponse<{ id: string }> = yield call(templateApi.createEnvelopeFromTemplate,
      action.payload.templateId, action.payload.impersonatedPrimeUserGuid);
    if (res.status !== 201) {
      throw Error(`Non-201 status when creating an envelope: ${res.status}`);
    }
    const envelopeId = res.result.id;
    yield put(actions.createEnvelopeFromTemplateDone(envelopeId));
  } catch (err) {
    yield put(errorToast('Failed to create envelope. Please try again.'));
  } finally {
    yield put(setLoading(false));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH_TEMPLATES, handleFetchTemplates),
    takeEvery(ActionTypes.CREATE_TEMPLATE, handleCreateTemplate),
    takeEvery(ActionTypes.DELETE_TEMPLATE, handleDeleteTemplate),
    takeEvery(ActionTypes.CREATE_ENVELOPE_FROM_TEMPLATE, handleCreateEnvelopeFromTemplate),
  ]);
}
