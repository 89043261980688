import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { primeApi } from '../../../lib/api/prime';
import { IRootState } from '../..';
import { IFileResponse } from '../../../dtm/types';

const ActionTypes = actions.ActionTypes;

const getLastListingCursor = (state: IRootState) => state.dtm.properties.lastListingCursor;
const getLastSalesCursor = (state: IRootState) => state.dtm.properties.lastSalesCursor;
const getListingEnd = (state: IRootState) => state.dtm.properties.listingEnd;
const getSalesEnd = (state: IRootState) => state.dtm.properties.salesEnd;

export function* handleFetchProperties(action: ReturnType<typeof actions.fetchProperties>) {
  try {
    const lastListingCursor: string = yield select(getLastListingCursor);
    const lastSalesCursor: string = yield select(getLastSalesCursor);
    const listingEnd: boolean = yield select(getListingEnd);
    const salesEnd: boolean = yield select(getSalesEnd);
    const searchValue = action.payload.searchValue;
    const sortAsc = action.payload.sortAsc;
    const searchType = action.payload.searchType;
    if (salesEnd && listingEnd) {
      yield put(actions.endOfPropertiesReached());
    } else {
      const res: IFileResponse = yield call(primeApi.getFiles, {
        lastListingCursor,
        lastSalesCursor,
        listingEnd,
        salesEnd,
        searchValue,
        sortAsc,
      });
      yield put(actions.fetchPropertiesDone(res, res.lastSalesCursor, res.lastListingCursor, searchType));
    }
  } catch (err) {
    console.log('Error fetching properties');
  }
}

export default function* rootSaga() {
  yield all([takeLatest(ActionTypes.FETCH_PROPERTIES, handleFetchProperties)]);
}
