import { action } from 'typesafe-actions';

import { IEnvelope, IDocument } from '../senderBuild/types';
import { IEnvelopeDocumentsState } from './types';

export enum ActionTypes {
  RESET = 'envelopeDocuments/RESET',
  API_ERROR = 'envelopeDocuments/API_ERROR',
  FETCH_ENVELOPE = 'envelopeDocuments/FETCH_ENVELOPE',
  FETCH_ENVELOPE_DONE = 'envelopeDocuments/FETCH_ENVELOPE_DONE',
  UPLOAD_DOCUMENT = 'envelopeDocuments/UPLOAD_DOCUMENT',
  UPLOAD_DOCUMENT_DONE = 'envelopeDocuments/UPLOAD_DOCUMENT_DONE',
  DELETE_DOCUMENT = 'envelopeDocuments/DELETE_DOCUNENT',
  REORDER_DOCUMENTS = 'envelopeDocuments/REORDER_DOCUMENTS',
  UPDATE_DOCUMENTS = 'envelopeDocuments/UPDATE_DOCUMENTS',
}

export const reset = (newStore?: IEnvelopeDocumentsState) => action(ActionTypes.RESET, newStore);
export const apiError = (error?: string) => action(ActionTypes.API_ERROR, error);
export const fetchEnvelope = (id: string) =>
  action(ActionTypes.FETCH_ENVELOPE, { id });
export const fetchEnvelopeDone = (envelope: IEnvelope) => action(ActionTypes.FETCH_ENVELOPE_DONE, envelope);

export const uploadDocument = (tempId: string, file: File) => action(ActionTypes.UPLOAD_DOCUMENT, { tempId, file });
export const uploadDocumentDone = (tempId: string, document: IDocument) =>
  action(ActionTypes.UPLOAD_DOCUMENT_DONE, { tempId, document });

export const deleteDocument = (documentId: string) => action(ActionTypes.DELETE_DOCUMENT, documentId);
export const updateDocuments = (documents: IDocument[]) => action(ActionTypes.UPDATE_DOCUMENTS, documents);
export const reorderDocuments = (documents: IDocument[]) => action(ActionTypes.REORDER_DOCUMENTS, documents);
