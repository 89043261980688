import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as pageFrameActions from '../../pageFrame/actions';
import { primeApi } from '../../../lib/api/prime';
import { envelopeApi, recipientsApi } from '../../../lib/api/envelope';
import { errorToast, successToast } from '../../pageFrame/actions';
import { IRootState } from '../..';
import { IEnvelopeMetadata } from '../envelopes/types';
import { IRecipient } from '../../../common/recipients/types';
import { IFile } from '../../../dtm/types';
import { getBestRecipientMatchForOptionalRecipient, getRecipientColor, sortRecipients } from '@digisign3-ui/sender/src/lib/utils';
import { colors } from '@skyslope/mache';

const ActionTypes = actions.ActionTypes;

export function* handleFetch(action: ReturnType<typeof actions.fetch>) {
  try {
    const metadata: IEnvelopeMetadata = yield call(envelopeApi.getEnvelopeMetadata, action.payload.envelopeId);
    yield put(pageFrameActions.updateRecipientColorCounter(metadata.recipientColorCounter));
    const file: IFile = yield call(primeApi.getFile, metadata.fileType, metadata.listingGuid ?? metadata.saleGuid);
    const [optionalPrimeRecipients, recipients] = yield all([
      call(primeApi.getContacts, metadata.fileType, metadata.listingGuid ?? metadata.saleGuid, action.payload.impersonatedPrimeUserGuid),
      call(recipientsApi.getRecipients, action.payload.envelopeId, true),
    ]);
    
    // Assign color to recipients
    recipients.forEach((r: IRecipient, i) => {
      r.color = getRecipientColor(r.recipientColorCounter, r.title);
    });
    // optional =  file contacts from dtm
    // recipients = expected recipients from the applied template and/or added recipients
    const mappedRecipientIds: string[] = [];
    // get the optional placeholders from recipients
    optionalPrimeRecipients.forEach((or: IRecipient, i) => {
      const recipient = getBestRecipientMatchForOptionalRecipient(mappedRecipientIds, recipients, or);
      const index = recipients.indexOf(recipient);

      // if recipient title matches with a optionalRecipient title
      if (index >= 0) {
        if (!recipients[index].email) {
          // First time after appplying a template
          or.id = recipients[index].id;
          or.color = recipients[index].color;
          or.group = { id: 'default', name: 'default', order: 0 };
          or.isActive = recipients[index].isActive || !!(or.firstName && or.email);
          or.isPlaceholder = true;
          or.recipientColorCounter = recipients[index].recipientColorCounter;
          recipients[index] = { ...or };
        } else {
          if (recipients[index].email === null) {
            recipients[index].email = '';
          } 
          if (recipients[index].firstName === null) {
            recipients[index].firstName = '';
          }
          (optionalPrimeRecipients[i] = { ...recipients[index] });
        }
        // recipients id already taken
        mappedRecipientIds.push(recipients[index].id);
      } else {
        or.isActive = false;
        or.isPlaceholder = false;
        or.color = colors.grey[600];
        optionalPrimeRecipients[i] = or;
      }
    });
    sortRecipients(recipients);
    yield put(actions.fetchDone(file, optionalPrimeRecipients, recipients));
  } catch (err) {
    yield put(errorToast('Failed to fetch recipients, please refresh!'));
  }
}

export function* handleSave(action: ReturnType<typeof actions.save>) {
  try {
    const recipients = yield select((state: IRootState) => state.dtm.recipients.recipients);
    yield call(recipientsApi.updateRecipients, recipients, action.payload);
    yield put(successToast('Recipient changes saved.'));
    yield put(actions.saveDone());
  } catch (e) {
    yield put(errorToast('Failed to save recipients'));
  }
}

export function* handleFetchContacts(action: ReturnType<typeof actions.fetchContacts>) {
  try {
    const contacts: any = yield call(primeApi.getContactsForPrimeUser);
    yield put(actions.fetchContactsDone(contacts));
  } catch (e) {
    console.log('Error fetching contacts');
  }
}

export function* handleCreatePrimeContact(action: ReturnType<typeof actions.createPrimeContact>) {
  try {
    yield call(primeApi.createPrimeContact, action.payload);
  } catch (e) {
    console.log('Error saving contact to prime');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ActionTypes.FETCH, handleFetch), takeEvery(ActionTypes.SAVE, handleSave)]);
  yield all([takeEvery(ActionTypes.FETCH_CONTACTS, handleFetchContacts)]);
  yield all([takeEvery(ActionTypes.CREATE_PRIME_CONTACT, handleCreatePrimeContact)]);
}
