import update from 'immutability-helper';
import produce from 'immer';
import { MAX_ZOOM, MIN_ZOOM, ZOOM_INCREMENT } from '../../lib/constants';
import {
  cleanUpInvalidBlockValuesAndLogToSentry,
  generateEmailBody,
  generateEmailSubject,
  getRecipientColor,
  sortRecipients,
} from '../../lib/utils';
import { ActionTypes } from './actions';
import { IBlock, IGroup, ISenderBuildState, ISigner } from './types';
import { IRecipient } from '../../common/recipients/types';

export const initialState: ISenderBuildState = {
  resource: undefined,
  id: undefined,
  zoom: 1.25,
  documents: [],
  blocks: {},
  groups: {},
  activeBlockType: '',
  activeSigner: '',
  carbonCopy: [],
  selectedBlocks: {
    documentId: undefined,
    pageIndex: undefined,
    blockIndices: [],
    blockTypes: undefined,
    x: 0,
    y: 0,
  },
  copiedBlocks: {
    blocks: undefined,
    startX: undefined,
    startY: undefined,
  },
  inputFocused: false,
  signers: [],
  signingGroups: [],
  saving: false,
  done: false,
  isEditingField: false,
  pageDimensions: {},
  config: {},
  emailConfig: { subject: null, body: null, loading: false },
  fetchError: false,
  isAutoSaving: false,
  copyCache: {
    blocks: [],
    pasteCounter: 0,
  },
  currentPage: undefined,
  currentDocument: undefined,
  recipientsModal: {
    recipients: [],
    signingGroups: [],
    loading: false,
    contacts: [],
  },
  recipientsFetched: false,
  lastPlacedBlock: '',
  isTextBlockEditingEnabled: false,
  status: '',
  signerToken: '',
  isDeletingBlocks: false,
  isCreatingBlock: false,
  mobileSigningOrderOpen: false,
  blockSizingCache: { height: 0, width: 0, blockType: '' },
  readyToSendModalOpen: false,
  mouseCoords: { x: null, y: null },
  blockMenuPos: { x: null, y: null },
};

export interface IGroupState {
  [documentId: string]: {
    [pageNumber: number]: {
      [groupId: string]: IGroup;
    };
  };
}

export default (state: ISenderBuildState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.SET_AUTOSAVING: {
      return update(state, {
        isAutoSaving: { $set: action.payload },
      });
    }
    case ActionTypes.FETCH: {
      return update(state, {
        id: { $set: action.payload.id },
        resource: { $set: action.payload.resource },
      });
    }
    case ActionTypes.FETCH_DONE: {
      const { result } = action.payload;
      const {signers} = result;
      if (signers.every(signer => !signer.color)) {
        signers.forEach((signer: ISigner) => {
          signer.color = getRecipientColor(signer.recipientColorCounter);
        });
      }
      sortRecipients(signers);
      const updatedStatus = result.status ? result.status : state.status;
      return update(state, {
        documents: { $set: result.documents },
        activeSigner: { $set: result.signers.length ? result.signers[0].signerId : '' },
        blocks: { $set: result.blocks },
        signers: { $set: result.signers },
        carbonCopy: { $set: result.carbonCopy },
        signingGroups: { $set: result.signingGroups },
        pageDimensions: { $set: result.pageDimensions },
        groups: { $set: result.groups },
        emailConfig: { $set: result.emailConfig },
        config: { $set: result.config },
        status: { $set: updatedStatus },
        isDeletingBlocks: { $set: false },
        isCreatingBlock: { $set: false },
      });
    }
    case ActionTypes.FETCH_ENVELOPE_PDF_DONE: {
      return produce(state, draftState => {
        draftState.documents.find(d => d.documentId === action.payload)!.isDownloaded = true;
      });
    }
    case ActionTypes.ZOOM_IN: {
      const zoom = state.zoom < MAX_ZOOM ? state.zoom + ZOOM_INCREMENT : state.zoom;
      return update(state, {
        zoom: { $set: zoom },
      });
    }
    case ActionTypes.ZOOM_OUT: {
      const zoom = state.zoom > MIN_ZOOM ? state.zoom - ZOOM_INCREMENT : state.zoom;
      return update(state, {
        zoom: { $set: zoom },
      });
    }
    case ActionTypes.SET_ZOOM: {
      return update(state, {
        zoom: { $set: action.payload },
      });
    }
    case ActionTypes.CREATE_NEW_BLOCK: {
      const { documentId, pageIndex, block } = action.payload;
      const pageBlocks = state.blocks[documentId][pageIndex] || [];
      block.isCreating = true;
      const cleanBlock = cleanUpInvalidBlockValuesAndLogToSentry(block, state.id, 'Block had null values');
      state = update(state, {
        isCreatingBlock: { $set: true },
        blocks: {
          [documentId]: {
            [pageIndex]: {
              $set: [...pageBlocks, cleanBlock],
            },
          },
        },
        lastPlacedBlock: { $set: cleanBlock.blockType },
      });

      if (action.payload.select) {
        state = update(state, {
          selectedBlocks: {
            documentId: { $set: documentId },
            pageIndex: { $set: pageIndex },
            blockIndices: { $set: [state.blocks[documentId][pageIndex].length - 1] },
            blockTypes: { $set: state.blocks[documentId][pageIndex].map(b => b.blockType) },
          },
        });
      }
      return state;
    }
    case ActionTypes.CREATE_NEW_BLOCK_DONE: {
      const { documentId, pageIndex, tempId, blockId } = action.payload;
      const pageBlocks = state.blocks[documentId][pageIndex] || [];
      const blockIndex = pageBlocks.findIndex(b => b.blockId === tempId);
      return update(state, {
        isCreatingBlock: { $set: false },
        blocks: {
          [documentId]: {
            [pageIndex]: {
              [blockIndex]: {
                blockId: { $set: blockId },
                isCreating: { $set: false },
              },
            },
          },
        },
      });
    }
    case ActionTypes.EDIT_BLOCK: {
      const updatedKeys = {};
      for (const key in action.payload.updateObj) {
        updatedKeys[key] = { $set: action.payload.updateObj[key] };
      }
      return update(state, {
        blocks: {
          [action.payload.documentId]: {
            [action.payload.pageIndex]: {
              [action.payload.blockIndex]: { ...updatedKeys },
            },
          },
        },
      });
    }
    case ActionTypes.REMOVE_GROUP_ID_FROM_BLOCK: {
      return update(state, {
        blocks: {
          [action.payload.documentId]: {
            [action.payload.pageIndex]: {
              [action.payload.blockIndex]: { $unset: ['groupId'] },
            },
          },
        },
      });
    }

    case ActionTypes.DELETE_BLOCK_GROUP_DONE: {
      const { documentId, pageIndex, groupId } = action.payload;
      return update(state, {
        groups: {
          [documentId]: {
            [pageIndex]: {
              $unset: [groupId],
            },
          },
        },
      });
    }
    case ActionTypes.DELETE_BLOCKS: {
      return update(state, {
        isDeletingBlocks: { $set: true },
      });
    }
    case ActionTypes.DELETE_BLOCKS_DONE: {
      const { documentId, pageIndex, blockIds, resource } = action.payload;
      let pageBlocks = state.blocks[documentId][pageIndex];
      let groups = state.groups[documentId]?.[pageIndex];
      let newSelectedBlocks: number[] = [];
      const blockGroupIds = blockIds.map((blockId: string) => pageBlocks.find(b => b.blockId === blockId)!.groupId);
      blockIds.forEach((blockId: string) => {
        const blockIndex = pageBlocks.findIndex(b => b.blockId === blockId)!;
        const block = pageBlocks[blockIndex];
        // delete the block
        pageBlocks = update(pageBlocks, { $splice: [[blockIndex, 1]] });
        if (block.groupId) {
          const group = groups?.[block.groupId];
          if (group) {
            // If it's the last block in the group, delete the group
            if (group.blockIds.length === 1) {
              groups = update(groups, { $unset: [group.id] });
              // otherwise just remove the block id from the group
            } else {
              groups = update(groups, {
                [group.id]: {
                  blockIds: {
                    $splice: [[group.blockIds.indexOf(blockId!), 1]],
                  },
                },
              });
            }
          }
        }
      });

      // When deleting checkboxes from a group using the shelf, it's possible to delete only some of the blocks
      // that are selected, so we don't want to automatically deselect everything
      if (blockGroupIds[0] && blockGroupIds.every((g: string) => g === blockGroupIds[0])) {
        newSelectedBlocks = pageBlocks
          .filter(block => block.groupId === blockGroupIds[0])
          .map(block => pageBlocks.findIndex(b => b.blockId === block.blockId));
      }
      return update(state, {
        isDeletingBlocks: { $set: false },
        blocks: {
          [documentId]: {
            [pageIndex]: { $set: pageBlocks },
          },
        },
        groups: groups
          ? {
              [documentId]: {
                [pageIndex]: { $set: groups },
              },
            }
          : {},
        selectedBlocks: {
          blockIndices: { $set: newSelectedBlocks },
        },
      });
    }
    case ActionTypes.SELECT_BLOCKS: {
      const { documentId, pageIndex, selectThroughGroup } = action.payload;
      let { multiSelect, blockIndices } = action.payload;
      const { selectedBlocks, blocks } = state;
      // Prevent multiSelect if switching to a different doc/page
      if (multiSelect) {
        if (documentId !== selectedBlocks.documentId || pageIndex !== selectedBlocks.pageIndex) {
          multiSelect = false;
        }
      }

      const alreadySelectedBlockIndices = multiSelect ? selectedBlocks.blockIndices : [];
      const pageBlocks = blocks[documentId][pageIndex];
      const newBlocks = blockIndices.map((i: number) => pageBlocks[i]);

      // If any of the selected blocks were in a group, add in all the blocks from those groups, unless
      // it's a single block being selected through a group
      if (!selectThroughGroup) {
        const selectedGroups = newBlocks.filter((b: IBlock) => b && !!b.groupId).map((b: IBlock) => b.groupId);
        pageBlocks.forEach((block, i) => {
          if (
            block.groupId &&
            selectedGroups.includes(block.groupId) &&
            !blockIndices.includes(i) &&
            !alreadySelectedBlockIndices.includes(i)
          ) {
            blockIndices.push(i);
          }
        });
      }

      // Remove any accidental duplicates
      blockIndices = blockIndices
        .filter((i: number) => !alreadySelectedBlockIndices.includes(i))
        .sort((a: number, b: number) => a - b);

      if (multiSelect) {
        blockIndices = action.payload.blockIndices
          .concat(state.selectedBlocks.blockIndices)
          .sort((a: number, b: number) => a - b);
        blockIndices = blockIndices.filter((item: number, i: number) => blockIndices.indexOf(item) === i);
      }
      return update(state, {
        selectedBlocks: {
          documentId: { $set: documentId },
          pageIndex: { $set: pageIndex },
          blockIndices: { $set: blockIndices },
          blockTypes: { $set: state.blocks[documentId][pageIndex].map(b => b.blockType) },
        },
        isEditingField: { $set: false },
      });
    }
    case ActionTypes.FETCH_ERROR: {
      return update(state, {
        fetchError: { $set: true },
      });
    }
    case ActionTypes.CHANGE_ACTIVE_BLOCK_TYPE: {
      const type = action.payload === state.activeBlockType ? null : action.payload;
      if (type) {
        state = update(state, {
          selectedBlocks: {
            documentId: { $set: undefined },
            pageIndex: { $set: undefined },
            blockIndices: { $set: [] },
          },
        });
      }

      return update(state, {
        activeBlockType: { $set: type },
      });
    }
    case ActionTypes.CHANGE_ACTIVE_SIGNER: {
      return update(state, {
        activeSigner: { $set: action.payload },
      });
    }
    case ActionTypes.COPY_BLOCKS: {
      return update(state, {
        copiedBlocks: {
          blocks: { $set: action.payload.blocks },
          startX: { $set: action.payload.startX },
          startY: { $set: action.payload.startY },
        },
        selectedBlocks: {
          blockIndices: { $set: [] },
        },
      });
    }
    case ActionTypes.PASTE_BLOCKS_DONE: {
      const { documentId, pageIndex, groups } = action.payload;
      const mergedBlocks = state.blocks[documentId][pageIndex].concat(action.payload.blocks);
      const newIndicies = action.payload.blocks.map((block: IBlock, i: number) => {
        return state.blocks[documentId][pageIndex].length + i;
      });

      const newGroups = { ...state.groups };
      if (groups) {
        newGroups[documentId] = { ...(newGroups[documentId] || {}) };
        newGroups[documentId][pageIndex] = { ...(newGroups[documentId][pageIndex] || {}) };
        Object.keys(groups).forEach(groupId => {
          const group = groups[groupId];
          newGroups[documentId][pageIndex][group.id] = group;
        });
      }

      return update(state, {
        groups: { $set: newGroups },
        copiedBlocks: {
          blocks: { $set: undefined },
          startX: { $set: undefined },
          startY: { $set: undefined },
        },
        blocks: {
          [action.payload.documentId]: {
            [action.payload.pageIndex]: {
              $set: mergedBlocks,
            },
          },
        },
        selectedBlocks: {
          documentId: { $set: action.payload.documentId },
          pageIndex: { $set: action.payload.pageIndex },
          blockIndices: {
            $set: newIndicies,
          },
          x: { $set: action.payload.blocks[0].x! },
          y: { $set: action.payload.blocks[0].y! },
        },
        copyCache: {
          pasteCounter: { $set: state.copyCache.pasteCounter + 1 },
        },
      });
    }
    case ActionTypes.SEND_ENVELOPE: {
      return update(state, {
        saving: { $set: true },
        zoom: { $set: 1.25 },
      });
    }
    case ActionTypes.SEND_ENVELOPE_DONE: {
      return update(state, {
        saving: { $set: false },
        done: { $set: true },
        status: { $set: 'Sent' },
      });
    }
    case ActionTypes.SEND_ENVELOPE_ERROR: {
      return update(state, {
        saving: { $set: false },
        status: { $set: 'Error' },
      });
    }
    case ActionTypes.EDITING_FIELD: {
      const {isEditingField} = state;
      return update(state, {
        isEditingField: { $set: !isEditingField },
      });
    }
    case ActionTypes.EDIT_GROUP: {
      const { documentId, pageIndex, groupId } = action.payload;
      const updatedKeys = {};
      for (const key in action.payload.updateObj) {
        updatedKeys[key] = { $set: action.payload.updateObj[key] };
      }
      return update(state, {
        groups: {
          [documentId]: {
            [pageIndex]: {
              [groupId]: {
                ...updatedKeys,
              },
            },
          },
        },
      });
    }
    case ActionTypes.SET_PAGE_DIMENSIONS: {
      const { documentId, pageIndex, pageHeight, pageWidth } = action.payload;
      return update(state, {
        pageDimensions: {
          [documentId]: {
            [pageIndex]: {
              $set: { pageWidth, pageHeight },
            },
          },
        },
      });
    }
    case ActionTypes.CREATE_NEW_SIGNING_GROUP: {
      return update(state, {
        signingGroups: { $push: [action.payload.group] },
      });
    }
    case ActionTypes.EDIT_SIGNER: {
      const updatedKeys = {};
      for (const key in action.payload.updateObj) {
        updatedKeys[key] = { $set: action.payload.updateObj[key] };
      }
      const index = state.signers.findIndex(s => s.signerId === action.payload.signerId);
      const oldSigner = state.signers[index];
      state = update(state, {
        signers: {
          [index]: { ...updatedKeys },
        },
      });
      // Group specific logic
      if ('signingGroup' in updatedKeys && oldSigner.signingGroup) {
        // Remove old signing group if it's now empty
        const oldSigningGroupLength = state.signers.filter(s => s.signingGroup === oldSigner.signingGroup).length;
        if (oldSigningGroupLength === 0) {
          const oldGroupIndex = state.signingGroups.findIndex(sg => sg.id === oldSigner.signingGroup);
          state = update(state, {
            signingGroups: {
              $splice: [[oldGroupIndex, 1]],
            },
          });
        }

        // Move signer to end of signers array so it appears at the bottom of the group
        const signer = state.signers[index];
        state = update(state, {
          signers: { $splice: [[index, 1]] },
        });

        state = update(state, {
          signers: { $push: [signer] },
        });
      }
      return state;
    }
    case ActionTypes.UPDATE_EMAIL_CONFIG: {
      return update(state, {
        emailConfig: {
          loading: { $set: true },
        },
      });
    }
    case ActionTypes.UPDATE_EMAIL_CONFIG_DONE: {
      const body = action.payload.body || generateEmailBody();
      const subject = action.payload.subject || generateEmailSubject();

      return update(state, {
        emailConfig: {
          body: { $set: body },
          subject: { $set: subject },
          loading: { $set: false },
        },
      });
    }
    case ActionTypes.UPDATE_EMAIL_CONFIG_ERROR: {
      return update(state, {
        emailConfig: {
          loading: { $set: false },
        },
      });
    }
    case ActionTypes.COPY_BLOCKS_TO_CACHE: {
      const {blocks} = action.payload;
      return update(state, {
        copyCache: {
          blocks: { $set: blocks },
          pasteCounter: { $set: 1 },
        },
      });
    }
    case ActionTypes.SET_CURRENT_PAGE: {
      return update(state, {
        currentPage: {
          $set: action.payload.pageNumber,
        },
        copyCache: {
          pasteCounter: { $set: 0 },
        },
      });
    }
    case ActionTypes.SET_CURRENT_DOCUMENT: {
      return update(state, {
        currentDocument: {
          $set: action.payload.documentId,
        },
      });
    }
    case ActionTypes.CREATE_GROUP_DONE: {
      // The all-in-one super reducer that
      // 1. creates a new group
      // 2. edits the initial block (if any) to be a part of the new group
      // 3. creates any new blocks as part of the group and selects them
      const { group, initialBlockId, newBlocks } = action.payload;
      const { documentId, pageNumber } = group;
      const groups = { ...state.groups };
      groups[documentId] = { ...(groups[documentId] || {}) };
      groups[documentId][pageNumber] = { ...(groups[documentId][pageNumber] || {}) };
      groups[documentId][pageNumber][group.id] = group;

      const pageBlocks = state.blocks[documentId][pageNumber];
      let newPageBlocks = [...pageBlocks, ...newBlocks];
      let initialBlockIndex;
      if (initialBlockId) {
        initialBlockIndex = pageBlocks.findIndex(block => block.blockId === initialBlockId);
        newPageBlocks = update(newPageBlocks, {
          [initialBlockIndex]: {
            groupId: { $set: group.id },
          },
        });
      }

      const newSelectedIndices = [...state.selectedBlocks.blockIndices];
      for (let i = 0; i < newBlocks.length; i++) {
        newSelectedIndices.push(pageBlocks.length + i);
      }
      // Include initial block to new group
      if (initialBlockIndex && !newSelectedIndices.includes(initialBlockIndex))
        newSelectedIndices.unshift(initialBlockIndex);
      return update(state, {
        groups: { $set: groups },
        blocks: {
          [documentId]: {
            [pageNumber]: { $set: newPageBlocks },
          },
        },
        selectedBlocks: {
          blockIndices: { $set: newSelectedIndices },
        },
      });
    }
    case ActionTypes.ADD_NEW_BLOCKS_TO_GROUP_DONE: {
      // This does the same things as CREATE_GROUP_DONE, except doesn't create a new group
      const { group, newBlocks } = action.payload;
      const { documentId, pageNumber } = group;

      const pageBlocks = state.blocks[documentId][pageNumber];

      const newIds = [...state.groups[documentId][pageNumber][group.id].blockIds];
      const newSelectedIndices = newIds.map(id => pageBlocks.findIndex(b => b.blockId === id));

      newIds.push(...newBlocks.map((b: IBlock) => b.blockId));
      for (let i = 0; i < newBlocks.length; i++) {
        newSelectedIndices.push(pageBlocks.length + i);
      }

      return update(state, {
        groups: {
          [documentId]: {
            [pageNumber]: {
              [group.id]: {
                blockIds: { $set: newIds },
              },
            },
          },
        },
        blocks: {
          [documentId]: {
            [pageNumber]: { $push: newBlocks },
          },
        },
        selectedBlocks: {
          blockIndices: { $set: newSelectedIndices },
        },
      });
    }
    case ActionTypes.FOCUS_INPUT: {
      return update(state, {
        inputFocused: {
          $set: true,
        },
        isTextBlockEditingEnabled: {
          $set: false,
        },
      });
    }
    case ActionTypes.BLUR_INPUT: {
      return update(state, {
        inputFocused: {
          $set: false,
        },
      });
    }
    case ActionTypes.SET_RECIPIENTS_AND_SIGNING_GROUPS_MODAL: {
      const { recipients, signingGroups } = action.payload;
      return update(state, {
        recipientsModal: {
          recipients: {
            $set: recipients,
          },
          signingGroups: {
            $set: signingGroups,
          },
        },
      });
    }
    case ActionTypes.UPDATE_RECIPIENTS: {
      return update(state, {
        recipientsModal: {
          loading: {
            $set: true,
          },
        },
      });
    }
    case ActionTypes.UPDATE_RECIPIENTS_DONE: {
      const recipients = action.payload.res;
      const { senderEmail, senderFirstName, senderLastName } = action.payload.userInfo;
      if (recipients.every(recipient => !recipient.color)) {
        recipients.forEach((signer: IRecipient) => {
          signer.color = getRecipientColor(signer.recipientColorCounter, signer.title);
        });
      }
      sortRecipients(recipients);
      const signingGroups = state.recipientsModal.signingGroups.sort((a, b) => a.order - b.order);
      signingGroups.forEach(g => {
        const group = recipients.find((s: IRecipient) => s.group && s.group.order === g.order);
        if (group) {
          g.id = group.group.id;
        }
      });
      const newRecipientIds = {};
      recipients.forEach((r: IRecipient) => {
        if (r.role !== 'CarbonCopy') {
          newRecipientIds[r.id] = r.id;
        }
      });
      const blocks = produce(state.blocks, draftBlocks => {
        Object.keys(draftBlocks).forEach((docId: string) => {
          Object.keys(draftBlocks[docId]).forEach((page: string) => {
            draftBlocks[docId][page] = draftBlocks[docId][page].filter(
              (block: IBlock) => !block.assignedTo || block.assignedTo in newRecipientIds
            );
          });
        });
      });
      const filteredSigners = recipients.filter((r: IRecipient) => r.isActive);
      const signers = filteredSigners.map((r: IRecipient, i: number) => {
        return {
          signerId: r.id,
          email: r.email,
          firstName: r.firstName,
          middleName: r.middleName,
          lastName: r.lastName,
          signingStatus: 'InProgress',
          signingGroup: r.group ? r.group.id : r.role === 'CarbonCopy' ? '' : 'default',
          color: r.color,
          recipientColorCounter: r.recipientColorCounter,
          ...(r.group && { signingGroup: r.group.id }),
        };
      });
      const activeSigner = signers.find((signer: any) => signer.signingGroup !== '');
      return update(state, {
        recipientsModal: {
          saveSuccess: {
            $set: true,
          },
        },
        signers: {
          $set: signers,
        },
        signingGroups: {
          $set: signingGroups,
        },
        activeSigner: {
          $set: activeSigner.signerId,
        },
        blocks: {
          $set: blocks,
        },
      });
    }
    case ActionTypes.UPDATE_RECIPIENTS_ERROR: {
      return update(state, {
        recipientsModal: {
          loading: {
            $set: false,
          },
          saveSuccess: {
            $set: false,
          },
        },
      });
    }
    case ActionTypes.SET_RECIPIENTS_LOADING: {
      return update(state, {
        recipientsModal: {
          loading: { $set: action.payload },
        },
      });
    }
    case ActionTypes.FETCH_RECIPIENTS_DONE: {
      const { recipients } = action.payload;
      return update(state, {
        recipientsModal: {
          recipients: {
            $set: recipients,
          },
        },
        recipientsFetched: {
          $set: true,
        },
      });
    }
    case ActionTypes.ENABLE_TEXT_BLOCK_EDITING: {
      return update(state, {
        isTextBlockEditingEnabled: {
          $set: true,
        },
      });
    }
    case ActionTypes.DISABLE_TEXT_BLOCK_EDITING: {
      return update(state, {
        isTextBlockEditingEnabled: {
          $set: false,
        },
      });
    }
    case ActionTypes.FETCH_SIGNER_TOKEN_DONE: {
      return update(state, {
        signerToken: { $set: action.payload },
      });
    }
    case ActionTypes.FETCH_CONTACTS_DONE: {
      return update(state, {
        recipientsModal: {
          contacts: {
            $set: action.payload.contacts,
          },
        },
      });
    }
    case ActionTypes.SHOW_MOBILE_SIGNING_ORDER: {
      return update(state, {
        mobileSigningOrderOpen: {
          $set: true,
        },
      });
    }
    case ActionTypes.HIDE_MOBILE_SIGNING_ORDER: {
      return update(state, {
        mobileSigningOrderOpen: {
          $set: false,
        },
      });
    }
    case ActionTypes.UPDATE_BLOCK_SIZING_CACHE: {
      return update(state, {
        blockSizingCache: {
          [action.payload.blockType]: {
            $set: {
              height: action.payload.height,
              width: action.payload.width,
            },
          },
        },
      });
    }
    case ActionTypes.TOGGLE_READY_TO_SEND_MODAL: {
      return update(state, {
        readyToSendModalOpen: {
          $set: !state.readyToSendModalOpen,
        },
      });
    }
    case ActionTypes.SET_MOUSE_COORDS: {
      const { x, y } = action.payload;
      return update(state, {
        mouseCoords: {
          $set: {
            x,
            y,
          },
        },
      });
    }
    case ActionTypes.CLEAR_MOUSE_COORDS: {
      return update(state, {
        mouseCoords: {
          $set: {
            x: null,
            y: null,
          },
        },
      });
    }
    case ActionTypes.SET_BLOCK_MENU_POS: {
      const { x, y } = action.payload;
      return update(state, {
        blockMenuPos: {
          $set: {
            x,
            y,
          },
        },
      });
    }
    case ActionTypes.ADD_BLOCKS_BULK: {
      const blocks = produce(state.blocks, draft => {
        action.payload.blocks.forEach(b => {
          draft[b.documentId][b.pageNumber].push(b);
        });
      });

      return { ...state, blocks };
    }
    case ActionTypes.CLEAR_BLOCKS: {
      const {documentIds} = action.payload
      const blocks = produce(state.blocks, draftBlocks => {
        for (const docId in draftBlocks) {
          if(documentIds.includes(docId)) {
            for (const page in draftBlocks[docId]) {
              draftBlocks[docId][page] = [];
            }
          }

        }
      });
      return update(state, {
        blocks: { $set: blocks },
        selectedBlocks: {
          documentId: { $set: undefined },
          pageIndex: { $set: undefined },
          blockIndices: { $set: [] },
        },
        copiedBlocks: {
          blocks: { $set: undefined },
          startX: { $set: undefined },
          startY: { $set: undefined },
        },
      });
    }
    default: {
      return state;
    }
  }
};
