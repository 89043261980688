import axios from 'axios';
import { API_URL } from '../../constants';

export interface ICertificateDownloadResponse<T> {
  data: any;
  status: number;
}

export const downloadCertificate = async (envelopeId: string): Promise<ICertificateDownloadResponse<null>> => {
  const response = await axios.get(`${API_URL}/envelopes/${envelopeId}/auditCertificate`, {
    responseType: 'blob',
  });
  const { data, status } = response;
  return {
    data,
    status,
  };
};
