import { action } from 'typesafe-actions';
import { IFile } from '../../../dtm/types';
import { IDocumentsState, IFileDocument, IDocument } from './types';

export enum ActionTypes {
  RESET = 'dtmDocuments/RESET',
  API_ERROR = 'dtmDocuments/API_ERROR',
  FETCH = 'dtmDocuments/FETCH',
  FETCH_DONE = 'dtmDocuments/FETCH_DONE',
  UPLOAD_DOCUMENT = 'dtmDocuments/UPLOAD_DOCUMENT',
  UPLOAD_DOCUMENT_DONE = 'dtmDocuments/UPLOAD_DOCUMENT_DONE',
  UPLOAD_PRIME_DOCUMENT = 'dtmDocuments/UPLOAD_PRIME_DOCUMENT',
  DELETE_DOCUMENT = 'dtmDocuments/DELETE_DOCUNENT',
  REORDER_DOCUMENTS = 'dtmDocuments/REORDER_DOCUMENTS',
  UPDATE_DOCUMENTS = 'dtmDocuments/UPDATE_DOCUMENTS',
  SET_DOCUMENTS = 'dtmDocuments/SET_DOCUMENTS',
  FETCH_ENVELOPE_DATA = 'dtmDocuments/FETCH_ENVELOPE_DATA',
}

export const reset = (newStore?: IDocumentsState) => action(ActionTypes.RESET, newStore);
export const apiError = (error?: string) => action(ActionTypes.API_ERROR, error);
export const fetch = (envelopeId: string, disableLoading?: boolean) =>
  action(ActionTypes.FETCH, { envelopeId, disableLoading });
export const fetchDone = (file: IFile, fileDocuments: IFileDocument[], envelopeDocuments: IDocument[]) =>
  action(ActionTypes.FETCH_DONE, { file, fileDocuments, envelopeDocuments });

export const uploadDocument = (tempId: string, file: File) => action(ActionTypes.UPLOAD_DOCUMENT, { tempId, file });
export const uploadDocumentDone = (tempId: string, document: IDocument) =>
  action(ActionTypes.UPLOAD_DOCUMENT_DONE, { tempId, document });

export const uploadPrimeDocument = (tempId: string, fileName: string, url: string) =>
  action(ActionTypes.UPLOAD_PRIME_DOCUMENT, { tempId, fileName, url });

export const deleteDocument = (documentId: string) => action(ActionTypes.DELETE_DOCUMENT, documentId);
export const updateDocuments = (documents: IDocument[]) => action(ActionTypes.UPDATE_DOCUMENTS, documents);
export const reorderDocuments = (documents: IDocument[]) => action(ActionTypes.REORDER_DOCUMENTS, documents);
export const setDocuments = (documents: any) => action(ActionTypes.SET_DOCUMENTS, documents);
export const fetchEnvelopeData = (envelopeId: string) => action(ActionTypes.FETCH_ENVELOPE_DATA, { envelopeId });
