import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppMenu from './AppMenu';
import UserMenu from './UserMenu';
import HeaderTitle from './HeaderTitle';
import { isSmallScreenMediaQuery } from '../../lib/isSmallScreen';
import { UserOrigins } from '../../lib/types';
import CollapseOnScroll from '../CollapseOnScroll';
import { getUserAdminRoles } from '../utils';
import useUserInfo from '../../auth/useUserInfo';

const useStyles = makeStyles(() => ({
  navHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navItems: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

interface IBreadcrumb {
  name: string;
  onClick: () => void;
}

interface IProps {
  labelName: string;
  showBreezeApp: boolean;
  breadcrumb?: IBreadcrumb;
  progressBarPercent?: number;
  hideNavItems?: boolean;
  userOrigin?: UserOrigins;
  preferencesUrl?: string;
  showPreferences?: boolean;
  onSharingPage?: boolean;
  getUsersGroups?: any;
  flags?: LaunchDarklyFlags;
}

const NavHeader = (props: IProps) => {
  const {
    labelName,
    showBreezeApp,
    breadcrumb,
    progressBarPercent,
    hideNavItems,
    userOrigin,
    preferencesUrl,
    showPreferences,
    onSharingPage,
    getUsersGroups,
    flags,
  } = props;
  const classes = useStyles();
  const { senderFirstName, senderLastName } = useUserInfo() ?? {};

  const isSmallScreen = isSmallScreenMediaQuery();
  const isNotPrimeOrFormsUser = userOrigin !== UserOrigins.Prime && userOrigin !== UserOrigins.Forms;
  const showBrokerManagementMenuItem = flags && flags['enable-broker-management-link'];
  const [user, setUser] = useState<{ isBrokerAdmin: boolean; isGroupAdmin: boolean }>({
    isBrokerAdmin: false,
    isGroupAdmin: false,
  });

  useEffect(() => {
    getUserAdminRoles(setUser, getUsersGroups);
  }, []);

  return (
    <span className={classes.navHeader}>
      <HeaderTitle
        onSharingPage={onSharingPage}
        name={labelName}
        breadcrumb={breadcrumb}
        progressBarPercent={progressBarPercent}
      />
      {hideNavItems ? (
        ''
      ) : isSmallScreen ? (
        ''
      ) : onSharingPage ? (
        <CollapseOnScroll>
          <span className={classes.navItems}>
            {isNotPrimeOrFormsUser ? '' : <AppMenu showBreezeApp={showBreezeApp} />}
            <UserMenu
              senderFirstName={senderFirstName}
              senderLastName={senderLastName}
              showPreferences={showPreferences}
              preferencesUrl={preferencesUrl}
              user={user}
              showBrokerManagement={showBrokerManagementMenuItem}
            />
          </span>
        </CollapseOnScroll>
      ) : (
        <span className={classes.navItems}>
          {isNotPrimeOrFormsUser ? '' : <AppMenu showBreezeApp={showBreezeApp} />}
          <UserMenu
            senderFirstName={senderFirstName}
            senderLastName={senderLastName}
            showPreferences={showPreferences}
            preferencesUrl={preferencesUrl}
            user={user}
            showBrokerManagement={showBrokerManagementMenuItem}
          />
        </span>
      )}
    </span>
  );
};

export default NavHeader;
