import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@skyslope/mache';
import update from 'immutability-helper';
import React from 'react';
import { DndContext, DragEndEvent, DragOverEvent } from '@dnd-kit/core';
import { IGroup, IRecipient, RecipientRole } from '../types';
import RecipientGroup from './RecipientGroup';
import { chameleonElId, isSkySlopeMobileApp } from '../../utils';

export const WORD_NUMBERS = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eight',
  'Ninth',
  'Tenth',
];

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '32px 40px',
    borderLeft: `1px solid ${colors.grey[400]}`,
    overflow: 'auto',
  },
  groupHeader: {
    display: 'block',
    width: '100%',
    paddingBottom: 16,
    borderBottom: `1px solid ${colors.grey[400]}`,
  },
  plusIcon: {
    margin: '-2px 0px -2px -6px',
    height: 18,
  },
  addButton: {
    marginTop: 40,
    marginLeft: -8,
  },
}));

interface IProps {
  recipients: IRecipient[];
  signingGroups: IGroup[];
  setRecipients: (recipients: IRecipient[]) => void;
  createNewGroup: () => void;
  modal: boolean;
  disableRecipientList?: boolean;
}

const RecipientGroups = (props: IProps) => {
  const classes = useStyles();
  const [isDropped, setIsDropped] = React.useState(false);
  const [isOver, setIsOver] = React.useState('');
  const { recipients, setRecipients, signingGroups, modal, disableRecipientList } = props;
  const disableRecipientListClass = disableRecipientList ? '!px-4' : '';
  const isMobileApp = isSkySlopeMobileApp();

  const updateRecipient = (recipientId: string, groupId?: string | null) => {
    const recipientIndex = recipients.findIndex((r) => r.id === recipientId);
    let newRecipients;
    if (groupId === 'CarbonCopy') {
      // User is dragging signer to CC group, remove group, set role to CC
      newRecipients = update(recipients, {
        [recipientIndex]: {
          $unset: ['group'],
          role: { $set: 'CarbonCopy' },
        },
      });
    } else {
      // User is dragging CC to signing group, add group and set role to Signer
      const newGroup = signingGroups.find((sg) => sg.id === groupId);
      newRecipients = update(recipients, {
        [recipientIndex]: {
          group: { $set: newGroup },
          role: { $set: 'Signer' },
        },
      });
    }
    setRecipients(newRecipients);
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const recipientId = e.active.id;
    const recipient = recipients.find((r) => r.id === recipientId);
    const groupId = e.over.id;
    updateRecipient(recipientId as string, groupId as string);
    setIsOver('');
  };

  const handleDragOver = (e: DragOverEvent) => {
    setIsOver(e?.over?.id);
  };

  const signers = recipients.filter((r) => r.role === RecipientRole.Signer);
  const cc = recipients.filter((r) => r.role === RecipientRole.CarbonCopy);
  const sortedSigningGroups = signingGroups.sort((a, b) => a.order - b.order);
  return (
    <DndContext onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
      <div
        className={`${classes.wrapper} ${disableRecipientListClass}`}
        id={chameleonElId(15)}
        style={{ paddingTop: '32px', paddingBottom: props.disableRecipientList ? '80px' : '32px' }}
      >
        <Typography variant="h6">
          {isMobileApp ? '' : props.disableRecipientList ? 'Edit Signing Order' : 'Signing order'}
        </Typography>
        <div>
          {sortedSigningGroups.map((signingGroup, i) => (
            <RecipientGroup
              key={signingGroup.id}
              groupId={signingGroup.id}
              recipients={signers.filter((r) => r.group?.id === signingGroup.id)}
              headerText={`WHO SIGNS ${WORD_NUMBERS[i]}`}
              updateRecipient={updateRecipient}
              groupRole={RecipientRole.Signer}
              modal={modal}
              isOver={isOver === signingGroup.id}
              disableRecipientList={disableRecipientList}
            />
          ))}
        </div>
        {signingGroups.length < Math.min(4, recipients.length) ? (
          <Button variant="text" color="primary" onClick={props.createNewGroup} className={classes.addButton}>
            <AddIcon className={classes.plusIcon} />
            Add a Signer Group
          </Button>
        ) : (
          ''
        )}

        <RecipientGroup
          recipients={cc}
          headerText="RECEIVES A COPY OF SIGNED DOCUMENTS"
          updateRecipient={updateRecipient}
          groupRole={RecipientRole.CarbonCopy}
          modal={modal}
          isOver={isOver === 'CarbonCopy'}
          disableRecipientList={disableRecipientList}
        />
      </div>
    </DndContext>
  );
};

export default RecipientGroups;
