import React from 'react';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import * as templateApi from '../../lib/api/template';
import { ITrialBannerStorageObject, IUserTrial } from '../../store/pageFrame/types';
import { isCurrentDatePastExpDate } from '../../common/utils';

const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export function useDS3Trial(args: { hasPaidForDS3: boolean; isPaidStatusLoading: boolean }) {
  const { hasPaidForDS3, isPaidStatusLoading } = args;

  const trial = useGetStartDS3Trial({
    hasPaidForDS3,
    options: { enabled: !isPaidStatusLoading },
  });

  const { bannerStorage, setTrialBannerObjInLocalStorage } = useTrialBannerLocalStorage();
  const [daysLeft, setDaysLeft] = React.useState(0);
  const [isTrialExpired, setIsTrialExpired] = React.useState(false);
  const [showTrialBanner, setShowTrialBanner] = React.useState(false);

  function dismissTrialBanner() {
    setShowTrialBanner(false);
    setTrialBannerObjInLocalStorage({ dismissed: true, dismissalExpiration: Date.now() + ONE_DAY_IN_MILLIS });
  }

  React.useEffect(() => {
    if (hasPaidForDS3 && !isPaidStatusLoading) {
      setTrialBannerObjInLocalStorage(null);
    }
  }, [hasPaidForDS3, isPaidStatusLoading]);

  React.useEffect(() => {
    if (hasPaidForDS3) {
      setShowTrialBanner(false);
      return;
    }

    if (isPaidStatusLoading || trial.isLoading || !trial.data?.endDate) return;

    setDaysLeft(trial.data.daysLeftInTrial);
    setIsTrialExpired(isCurrentDatePastExpDate(trial.data.endDate.toString()));

    const dismissalIsExpired =
      bannerStorage && bannerStorage.dismissalExpiration && bannerStorage.dismissalExpiration < Date.now();

    if (!bannerStorage?.dismissed || dismissalIsExpired) {
      setShowTrialBanner(true);
      setTrialBannerObjInLocalStorage(null);
    }
  }, [trial, hasPaidForDS3]);

  return React.useMemo(
    () => ({
      daysLeft,
      isExpired: isTrialExpired,
      showBanner: showTrialBanner,
      dismissBanner: dismissTrialBanner,
      isLoading: trial.isLoading,
    }),
    [daysLeft, isTrialExpired, showTrialBanner, dismissTrialBanner, trial]
  );
}

export function useGetStartDS3Trial(args: { hasPaidForDS3: boolean; options?: UseQueryOptions<IUserTrial> }) {
  return useQuery(
    ['user-get-ds3-trial'],
    async () => {
      if (args.hasPaidForDS3) return null;

      const res = await templateApi.getUserTrialInfo();
      if (!res.result && res.status >= 400) throw new Error('Failed to get user trial info');
      return res.result;
    },
    args.options
  );
}

const TRIAL_BANNER_LOCAL_STORAGE_KEY = '_trialBanner';
function useTrialBannerLocalStorage() {
  const [bannerStorage, setBannerStorage] = React.useState(
    () => JSON.parse(localStorage.getItem(TRIAL_BANNER_LOCAL_STORAGE_KEY) || 'null') as ITrialBannerStorageObject
  );

  function setTrialBannerObjInLocalStorage(obj: ITrialBannerStorageObject) {
    setBannerStorage(obj);
    localStorage.setItem(TRIAL_BANNER_LOCAL_STORAGE_KEY, JSON.stringify(obj));
  }

  return { bannerStorage, setTrialBannerObjInLocalStorage };
}
