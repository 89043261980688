import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { IRootState } from '../index';
import { ITemplateTag } from '../templateCreate/types';
import * as actions from './actions';
import { errorToast, setLoading } from '../pageFrame/actions';
import { ITemplateResponse } from '../../lib/api/template';
import { ITemplateTagsResult } from './types';
import * as templateApi from '../../lib/api/template';

const ActionTypes = actions.ActionTypes;

const getUpdateBody = (state: IRootState) => {
  const { name, tagsById } = state.templateTagging;
  const tags: ITemplateTag[] = [];
  for (const tagTypeId in tagsById) {
    tagsById[tagTypeId].forEach(value => {
      tags.push({ tagTypeId, value });
    });
  }
  return { name, tags };
};

export function* handleFetchTags(action: ReturnType<typeof actions.fetchTags>) {
  try {
    yield put(setLoading(true));
    const res: ITemplateResponse<ITemplateTagsResult> = yield call(templateApi.getTemplateTags, action.payload);
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Templates Tags GET: ${res.status}`);
    }
    if (!res.result) {
      window.location.pathname = "/notfound";
    } else {
      yield put(actions.fetchTagsDone(res.result!.name || '', res.result!.tags));
    }
  } catch (err) {
    yield put(errorToast('Failed to fetch existing template tags, please try refreshing!'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleSave(action: ReturnType<typeof actions.fetchTags>) {
  try {
    const patchBody = yield select(getUpdateBody);
    const res: ITemplateResponse<any> = yield call(templateApi.updateTemplate, action.payload, patchBody);
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Template PATCH: ${res.status}`);
    }
    yield put(actions.saveDone());
  } catch (err) {
    yield put(errorToast('Failed to update template, please try again'));
  }
}
export default function* rootSaga() {
  yield all([takeEvery(ActionTypes.FETCH_TAGS, handleFetchTags), takeEvery(ActionTypes.SAVE, handleSave)]);
}
