import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import * as actions from '../../store/dtm/documents/actions';
import { IRootState } from '../../store';
import PrimeSection from '../components/documents/PrimeSection';
import EnvelopeSection from '../components/documents/EnvelopeSection';
import { DocumentStatus, IDocument } from '../../store/dtm/documents/types';
import { HeaderContext } from '../../context/header-context';
import { chameleonElId, preferencesUrl } from '../../lib/constants';
import NavHeader from '../../common/navigation';
import { withLaunchDarkly, LaunchDarklyFlags } from '../../common/launchDarkly';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import Footer from '../../components/common/Footer';
import FooterLinks from '../../components/common/FooterLinks';
import { getUsersGroups } from '../../lib/api/account/accountApi';

const useStyles = makeStyles((theme: Theme) => ({
  focusButton: {
    '&:focus': {
      backgroundColor: colors.blue[50],
    },
  },
  wrapper: {
    width: 1200,
  },
  header: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  body: {
    backgroundColor: 'white',
    border: `1px solid ${colors.grey[400]}`,
    display: 'flex',
    marginTop: theme.spacing(4),
    height: '55vh',
  },
  backButton: {
    marginRight: 'auto',
  },
  nextButton: {
    padding: '10px 40px',
  },
}));

interface IState {
  isFetching: boolean;
  nextDisabled: boolean;
  envelopeDocuments: IDocument[];
}

const selector = (state: IRootState) => ({
  isFetching: state.dtm.documents.isFetching,
  nextDisabled:
    state.dtm.documents.envelopeDocuments.length === 0 ||
    state.dtm.documents.envelopeDocuments.some(
      (d) => d.isUploading || d.uploadStatus === DocumentStatus.Uploading || d.uploadStatus === DocumentStatus.Failed
    ),
  envelopeDocuments: state.dtm.documents.envelopeDocuments,
});

const Documents = ({flags}: {flags: LaunchDarklyFlags}) => {
  const store: IState = useShallowSelector(selector);
  const { envelopeDocuments } = store;
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerContext = React.useContext(HeaderContext);
  const areTemplatesEnabled = flags && flags['enable-templates-access'];
  const sleep = (n: number) => new Promise((res) => setTimeout(res, n));
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];


  const handleFetch = async () => {
    await sleep(1000);
    dispatch(actions.fetchEnvelopeData(params.id));
  }

  useEffect(() => {
    headerContext.setContent(
      <NavHeader
        labelName="Add documents"
        breadcrumb={{ name: 'Envelope Management', onClick: () => history.push('/envelopes') }}
        progressBarPercent={40}
        hideNavItems={hideNavItems}
        showBreezeApp={showBreezeApp}
        preferencesUrl={preferencesUrl}
        showPreferences={showPreferencesMenuItem}
        getUsersGroups={getUsersGroups}
        flags={flags}
      />
    );
    dispatch(actions.fetch(params.id));
    return () => {
      headerContext.setContent(null);
      dispatch(actions.reset());
    };
  }, []);

  useEffect(() => {
    const areDocumentsUploading = envelopeDocuments.some((doc) => doc.uploadStatus === DocumentStatus.Uploading);
    if (areDocumentsUploading && envelopeDocuments.length) {
      handleFetch();
    }
  }, [envelopeDocuments]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h3" className={classes.header} align="center">
        Add documents to your envelope
      </Typography>
      <Typography variant="body2" align="center">
        Click “+ Add” to select documents from your property file, and/or you can upload new documents to your envelope.
      </Typography>
      <div className={classes.body}>
        <PrimeSection />
        <EnvelopeSection />
      </div>
      <Footer>
        {hideNavItems ? '' : 
          <>
            <Button
              className={`${classes.backButton} ${classes.focusButton}`}
              color="primary"
              onClick={() => history.push('/dtm/properties')}
            >
              <BackIcon fontSize="small" /> Back
            </Button>
            <Button
              color="primary"
              className={classes.focusButton}
              data-spec="exit"
              onClick={() => history.push('/envelopes')}
            >
              Exit
            </Button>
          </>
        }
        {areTemplatesEnabled ? <Button
          id={chameleonElId(21)}
          color="primary"
          className={classes.focusButton}
          data-spec="apply-template"
          disabled={store.nextDisabled}
          onClick={() => history.push(`/envelopes/${params.id}/apply-template`)}
        >
          Apply Template
        </Button> : ''}
        <Button
          variant="contained"
          color="primary"
          disabled={store.nextDisabled}
          data-spec="next-button"
          onClick={() => history.push(`/dtm/envelopes/${params.id}/recipients`)}
          className={classes.nextButton}
        >
          Next
        </Button>
      </Footer>
      <FooterLinks />
    </div>
  );
};

export default withLaunchDarkly(Documents);
