import * as templateApi from '../../../lib/api/template';
import { getPdfBlobFromUrl } from '../../../lib/api/template';
import { ITemplateResponse } from '../../../lib/api/template';
import { call, all, select, put, CallEffect } from 'redux-saga/effects';
import { BLOCK_TYPE_KEYS, STRIKE_LINE_WIDTH } from '../../../lib/constants';
import pdfCache from '../../../lib/pdfCache';
import { calculateFontSize } from '../../../lib/useAutomaticFontSizing';
import { IRootState } from '../../index';
import { ITemplateDocument, ITemplateRecipient } from '../../templateCreate/types';
import * as actions from '../actions';
import { IBlock, IGroup, IPageDimensions } from '../types';
import { errorToast } from '../../pageFrame/actions';
import { RecipientRole } from '../../../common/recipients/types';
import { cleanUpInvalidBlockValuesAndLogToSentry, getRecipientColor, sortRecipients } from '../../../lib/utils';
import { colors } from '@skyslope/mache';

const selectZoom = (state: IRootState) => state.senderBuild.zoom;
const getId = (state: IRootState) => state.senderBuild.id;

const prepareBlockForTemplateService = (block: IBlock) => {
  const templateApiBlock: any = { ...block };
  if (templateApiBlock.readOnly) {
    if (templateApiBlock.blockType === BLOCK_TYPE_KEYS.TEXT) {
      templateApiBlock.blockType = 'ReadOnlyText';
    } else if (templateApiBlock.blockType === BLOCK_TYPE_KEYS.CHECKBOX) {
      templateApiBlock.blockType = 'ReadOnlyCheckbox';
    }
  }
  if (templateApiBlock.groupId) {
    templateApiBlock.blockGroupId = block.groupId;
    delete templateApiBlock.groupId;
  }

  delete templateApiBlock.isEditingDisabled;
  return templateApiBlock;
};

export function* templateFetch(id: string) {
  const zoom = yield select(selectZoom);
  const res = yield call(templateApi.getTemplate, id);
  const template = res.result!;
  if (res.status !== 200) {
    throw Error(`Non-200 Status Code of Template GET: ${res.status}`);
  }
  const docBlobs: ArrayBuffer[] = yield all(
    template.documents.map((doc: { links?: { downloadPDF?: { href?: string } } }) =>
      call(getPdfBlobFromUrl, doc.links!.downloadPDF!.href!)
    )
  );
  template.documents.forEach((doc: ITemplateRecipient, i: number) => {
    pdfCache[doc.id!] = docBlobs[i];
  });

  const blocks = {};
  template.documents.forEach((doc: ITemplateDocument) => {
    blocks[doc.id!] = {};
    for (let i = 0; i < (doc.numPages || 0); i++) {
      blocks[doc.id!][i] = [];
    }
    doc.blocks?.forEach((block) => {
      if (block.blockType === 'ReadOnlyText') {
        block.blockType = BLOCK_TYPE_KEYS.TEXT;
        block.readOnly = true;
      } else if (block.blockType === 'ReadOnlyCheckbox') {
        block.blockType = BLOCK_TYPE_KEYS.CHECKBOX;
        block.readOnly = true;
      } else if (block.blockType === BLOCK_TYPE_KEYS.STRIKE) {
        block.height = block.height ?? STRIKE_LINE_WIDTH;
      }
      if (block.blockType === BLOCK_TYPE_KEYS.TEXT && block.value && !block.fontSize) {
        block.fontSize = calculateFontSize(block.value as string, 22, block.height!, block.width!, zoom);
      }
      if (block.blockType === BLOCK_TYPE_KEYS.CHECKBOX && typeof block.value === 'string') {
        block.value = block.value === 'true';
      }

      blocks[doc.id!][block.pageNumber].push(block);
    });
  });

  const groups = {};
  template.documents.forEach((doc: ITemplateDocument) => {
    groups[doc.id!] = {};
    for (let i = 0; i < (doc.numPages || 0); i++) {
      groups[doc.id!][i] = {};
    }
    doc.blockGroups.forEach((group) => {
      groups[doc.id!][group.pageNumber][group.id] = group;
    });
  });
  const pageDimensions: IPageDimensions = {};
  template.documents.forEach((doc: ITemplateDocument) => {
    pageDimensions[doc.id!] = doc.pageDimensions;
  });
  template.recipients.forEach((rec: ITemplateRecipient) => {
    if (rec.recipientTitle === 'Me') {
      rec.recipientColorCounter = 0;
    }
  });
  sortRecipients(template.recipients);
  template.recipients.forEach((rec: ITemplateRecipient, i: number) => {
    rec.color = getRecipientColor(i + 1, rec.recipientTitle);
  });
  return {
    documents: template.documents.map((doc: ITemplateDocument) => ({
      fileName: doc.name,
      url: doc.links?.downloadPDF?.href,
      documentId: doc.id,
      numberOfPages: doc.numPages,
    })),
    blocks,
    signers: template.recipients
      .filter((r: ITemplateRecipient) => r.role === RecipientRole.Signer)
      .map((recipient: ITemplateRecipient, i: number) => {
        const name = recipient.recipientTitle;
        return {
          signerId: recipient.id,
          firstName: name,
          lastName: '',
          color: recipient.color,
        };
      }),
    signingGroups: [],
    groups: groups,
    config: {},
    pageDimensions,
  };
}

export function* templateBlockCreate(documentId: string, pageIndex: number, block: IBlock) {
  try {
    const templateId = yield select(getId);
    const templateApiBlock = prepareBlockForTemplateService(block);
    const { result }: ITemplateResponse<any> = yield call(
      templateApi.addBlock,
      templateId,
      documentId,
      templateApiBlock
    );
    return result.id;
  } catch (err) {
    yield put(errorToast('Failed to create block, please try again!'));
  } finally {
  }
}

export function* templateDeleteBlocks(documentId: string, pageIndex: number, blockIds: string[]) {
  try {
    const templateId = yield select(getId);
    const pageGroups = yield select((state: IRootState) => state.senderBuild.groups[documentId][pageIndex]);

    let remainingBlockIds = [...blockIds];

    const apiCalls: CallEffect[] = [];
    // If we're deleting entire block groups, we make those calls separately
    for (const groupId in pageGroups) {
      const blockGroupIds = pageGroups[groupId].blockIds;
      if (blockGroupIds.every((blockId: string) => remainingBlockIds.includes(blockId))) {
        apiCalls.push(call(templateApi.deleteBlockGroup, templateId, documentId, groupId));
        remainingBlockIds = remainingBlockIds.filter((blockId) => !blockGroupIds.includes(blockId));
      }
    }

    // Delete remaining blocks not deleted from groups being deleted
    if (remainingBlockIds.length) {
      apiCalls.push(call(templateApi.deleteBlocks, templateId, remainingBlockIds));
    }
    yield all(apiCalls);
  } catch (err) {
    yield put(errorToast('Failed to delete block, please try again!'));
  }
}

export function* templateEditBlock(documentId: string, pageIndex: number, blockIndex: number, updateObj: any) {
  const originalBlock = yield select((state: IRootState) => {
    return state.senderBuild.blocks[documentId][pageIndex][blockIndex];
  });
  const templateId = yield select(getId);
  const cleanBlock = yield cleanUpInvalidBlockValuesAndLogToSentry(
    originalBlock,
    templateId,
    'templateEditBlock: Block had invalid values',
    true
  );
  try {
    yield put(actions.setAutoSaving(true));
    const newBlock = { ...cleanBlock, ...updateObj };
    const templateApiBlock = prepareBlockForTemplateService(newBlock);
    yield call(templateApi.editBlock, templateId, documentId, templateApiBlock);
  } catch (err) {
    yield put(errorToast('Failed to edit block, please try again!'));
  } finally {
    yield put(actions.setAutoSaving(false));
  }
}

export function* templateCreateGroup(group: IGroup) {
  try {
    const templateId = yield select(getId);
    const { result } = yield call(templateApi.createNewGroup, templateId, group);
    return result.id;
  } catch (err) {
    yield put(errorToast('Failed to create group, please try again!'));
  }
}

export function* templateGroupEdit(documentId: string, pageIndex: number, groupId: string, updateObj: any) {
  try {
    const originalGroup = yield select((state: IRootState) => {
      return state.senderBuild.groups[documentId][pageIndex][groupId];
    });
    const newGroup = { ...originalGroup, ...updateObj };
    const templateId = yield select(getId);
    yield call(templateApi.editBlockGroup, templateId, newGroup);
  } catch (err) {
    yield put(errorToast('Failed to edit group, please try again!'));
  }
}

export function* createBlocksThenGroup(newBlocks: IBlock[], newGroup: IGroup) {
  const apiCalls: Generator[] = [];
  newBlocks.forEach((newBlock) => {
    delete newBlock.blockId;
    delete newBlock.groupId;
    apiCalls.push(templateBlockCreate(newGroup.documentId, newGroup.pageNumber, newBlock));
  });
  const blockIds = yield all(apiCalls);
  newBlocks.forEach((newBlock, i) => {
    newBlock.blockId = blockIds[i];
  });
  newGroup.blockIds.push(...blockIds);
  const groupId = yield templateCreateGroup(newGroup);
  newGroup.id = groupId;
  for (let i = 0; i < newBlocks.length; i++) {
    newBlocks[i].groupId = groupId;
  }
}
