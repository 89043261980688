import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import Description from '@material-ui/icons/Description';
import { colors } from '@skyslope/mache';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Search from '../components/common/Search';
import { StyledMenu, StyledMenuItem } from '../components/common/StyledMenu';
import Table, { IColumn } from '../components/common/Table';
import TaggingFilters from '../components/templateManagement/TaggingFilters';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import useDebounce from '../lib/useDebounce';
import { IRootState } from '../store';
import { ISettings } from '../store/pageFrame/types';
import * as actions from '../store/templateManagement/actions';
import * as pageFrameActions from '../store/pageFrame/actions';
import { ITemplateMetadata } from '../store/templateManagement/types';
import { ConfirmationModal } from '../components/templateManagement/DeleteTemplateConfirmationModal';
import { ResourceNames } from '../lib/constants';
import NoResultsState from '../components/common/NoResultsState';
import ManagementHeader from '../components/common/ManagementHeader';
import { HeaderContext } from '../context/header-context';
import FooterLinks from '../components/common/FooterLinks';
import EmptyState from '../components/templateManagement/EmptyState';
import { LaunchDarklyFlags, withLaunchDarkly } from '../common/launchDarkly';
import useUserInfo from '../auth/useUserInfo';

dayjs.extend(advancedFormat);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 1000,
    width: '100%',
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
  },
  emptyStateRoot: {
    maxWidth: 1200,
    width: '100%',
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableControls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  templateCount: {
    display: 'flex',
    alignItems: 'center',
  },
  templateCountIcon: {
    marginRight: theme.spacing(1),
    color: colors.grey[500],
  },
  plusIcon: {
    margin: '-2px 0px -2px -6px',
    height: 18,
  },
  hotdogButton: {
    padding: 6,
    margin: -7,
  },
}));

export interface ITagFilters {
  [tagId: string]: string;
}

interface IState {
  templates: ITemplateMetadata[];
  search: string;
  isFetching: boolean;
  createdId?: string;
  settings?: ISettings;
  settingsFetched?: boolean;
  isLoading: boolean;
  newEnvelopeId?: string;
}
const selector = (state: IRootState) => ({
  templates: state.templateManagement.templates,
  search: state.templateManagement.search,
  isFetching: state.templateManagement.isFetching,
  createdId: state.templateManagement.createdId,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  isLoading: state.pageFrame.loading,
  newEnvelopeId: state.templateManagement.newEnvelopeId,
});

export const TemplateManagement = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const headerContext = useContext(HeaderContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = React.useState('');
  const [tagFilters, setTagFilters] = React.useState<ITagFilters>({});
  const debouncedSearch = useDebounce(search, 100);
  const [openActionsId, setOpenActionsId] = React.useState('');
  const buttonRef = React.useRef(null);
  const [filteredTemplates, setFilteredTemplates] = React.useState<ITemplateMetadata[]>([]);
  const rootClass = !store.isFetching && store.templates.length === 0 ? classes.emptyStateRoot : classes.root;
  const enableTemplateSharing = flags && flags['enable-template-sharing'];
  const { oktaUserId, impersonatedPrimeUserGuid, isPrimeAuthenticated } = useUserInfo() ?? {};

  useEffect(() => {
    headerContext.setContent(
      <ManagementHeader
        resource={ResourceNames.TEMPLATE}
        flags={flags}
        userOrigin={store.settings?.account.userOrigin}
      />
    );
    return () => {
      headerContext.setContent(null);
    };
  }, [store.settingsFetched]);

  useEffect(() => {
    let { templates } = store;
    if (store.search) {
      const searchLower = store.search.toLowerCase();
      templates = templates.filter((t) => t.name.toLowerCase().includes(searchLower));
    }
    if (Object.keys(tagFilters).length) {
      templates = templates.filter((t) => {
        for (const tagId in tagFilters) {
          if (!t.tags.find((tag) => tag.tagTypeId === tagId && tag.value === tagFilters[tagId])) {
            return false;
          }
        }
        return true;
      });
    }
    if (oktaUserId) {
      templates.forEach(template => {
        if (template.createdBy === oktaUserId) {
          template.createdByName = 'Me'
        }
      });
    };
    setFilteredTemplates(templates);
  }, [store.templates.length, store.search, tagFilters, oktaUserId]);

  useEffect(() => {
    dispatch(actions.fetchTemplates(flags['enable-new-apply-templates-logic']));
    return () => {
      dispatch(actions.reset());
    };
  }, []);

  useEffect(() => {
    if (store.newEnvelopeId) {
      const url = `/envelopes/${store.newEnvelopeId}/documents`;
      history.push(url);
    }
  }), [store.newEnvelopeId];

  useEffect(() => {
    dispatch(actions.filterTemplates(search));
  }, [debouncedSearch]);

  const tableColumns = React.useRef<IColumn[]>(
    (() => {
      const columns = [{ key: 'name', label: 'Name' }];
      const createdByCol = {
        key: 'createdByName',
        label: 'Created By',
      };
      const createdDateCol = {
        key: 'createdDate',
        label: 'Date Created',
        cell: (template: ITemplateMetadata) => (
          <Typography data-spec="createdDate-cell">{dayjs(template.createdDate).format('MM/DD/YY')}</Typography>
        ),
      };
      const editCol = {
        key: 'edit',
        align: 'right',
        label: '',
        cell: (template: ITemplateMetadata) => (
          <IconButton data-spec={`${template.id}-action-menu`} onClick={(e: any) => openMenu(e, template.id)} className={classes.hotdogButton}>
            <MoreVertIcon />
          </IconButton>
        ),
      };

      flags['enable-new-apply-templates-logic'] ? columns.push(createdByCol, createdDateCol, editCol) : columns.push(editCol);

      return columns;
    })()
  );

  const openMenu = (event: any, templateId: string) => {
    setOpenActionsId(templateId);
    buttonRef.current = event.currentTarget;
  };

  const closeMenu = () => {
    setOpenActionsId('');
    buttonRef.current = null;
  };

  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = React.useState(false);

  const toggleModal = () => {
    setShowDeleteTemplateModal(!showDeleteTemplateModal);
    if (showDeleteTemplateModal) {
      closeMenu();
    }
  };

  const deleteTemplate = (templateId: string) => {
    dispatch(actions.deleteTemplate(templateId, flags['enable-new-apply-templates-logic']));
    toggleModal();
  };

  const createTemplate = () => {
    dispatch(actions.createTemplate());
  };

  const editTemplate = async (templateId: string) => {
    const url = `/templates/${templateId}/${store.settings?.account.template?.tagging?.enabled ? 'info' : 'create'}`;
    history.push(url);
  };

  const shareTemplate = async (templateId: string) => {
    const url = `/templates/${templateId}/share`;
    history.push(url);
  };

  const createEnvelopeFromTemplate = async (templateId: string) => {
    dispatch(pageFrameActions.setLoading(true));
    dispatch(actions.createEnvelopeFromTemplate(templateId, impersonatedPrimeUserGuid));
    closeMenu();
  };

  if (store.isLoading || !store.settingsFetched) {
    return null;
  }

  if (store.createdId) {
    editTemplate(store.createdId);
  }

  const content = () => {
    if (filteredTemplates.length > 0) {
      return <Table idKey="id" columns={tableColumns.current} rows={filteredTemplates} isLoading={store.isFetching} managementTable/>;
    }
    if (search) {
      return <NoResultsState resource={ResourceNames.TEMPLATE} />;
    }
    return <Table idKey="id" columns={tableColumns.current} rows={filteredTemplates} isLoading={store.isFetching} managementTable/>;
  };

  const currentTemplate = filteredTemplates.find(template => template.id === openActionsId);
  const userCreatedTemplate = currentTemplate?.createdByName?.toLowerCase() === 'me';

  return (
    <div className={rootClass}>
      {!store.isFetching && store.templates.length === 0 ? (
        <EmptyState data-spec="empty-template-management-state" />
      ) : (
        <>
          <Search onChange={setSearch} value={search} />
          {store.settings?.account.template?.tagging?.enabled ? (
            <TaggingFilters tagFilters={tagFilters} setTagFilters={setTagFilters} />
          ) : (
            ''
          )}
          <div className={classes.tableControls}>
            <Typography variant="overline" className={classes.templateCount} data-spec="template-count">
              <Description className={classes.templateCountIcon} />
              {store.isFetching
                ? 'FETCHING TEMPLATES...'
                : `${filteredTemplates.length} TEMPLATE${filteredTemplates.length !== 1 ? 'S' : ''} FOUND ${
                    filteredTemplates.length < store.templates.length ? '(FILTERED)' : ''
                  }`}
            </Typography>
            <Button onClick={createTemplate} color="primary" variant="contained" data-spec="create-template-button">
              <Add className={classes.plusIcon} /> Create New
            </Button>
          </div>
          {content()}
          <StyledMenu
            anchorEl={buttonRef.current}
            open={Boolean(openActionsId)}
            onClose={closeMenu}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <StyledMenuItem data-spec="create-envelope" onClick={() => createEnvelopeFromTemplate(openActionsId)}>
              Create Envelope
            </StyledMenuItem>
            {userCreatedTemplate && <StyledMenuItem onClick={() => editTemplate(openActionsId)} 
              data-spec="edit-button">
              Edit Template
            </StyledMenuItem>}
            {enableTemplateSharing && userCreatedTemplate && isPrimeAuthenticated ? (
              <StyledMenuItem onClick={() => shareTemplate(openActionsId)} data-spec="share-template">
                Share Template
              </StyledMenuItem>
            ) : (
              ''
            )}
            {userCreatedTemplate && <StyledMenuItem onClick={toggleModal} data-spec="delete-button">
              Delete Template
            </StyledMenuItem>}
          </StyledMenu>
          {showDeleteTemplateModal ? (
            <ConfirmationModal
              data-spec="delete-template-modal"
              toggleModal={toggleModal}
              deleteTemplate={() => deleteTemplate(openActionsId)}
            />
          ) : null}
        </>
      )}
      <FooterLinks showSocialIcons />
    </div>
  );
};

export default withLaunchDarkly(TemplateManagement);
