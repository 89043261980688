import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    borderBottom: `1px solid ${colors.grey[400]}`,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minHeight: 24,
  },
}));

interface IProps {
  children: ReactNode;
}

const SectionHeader = (props: IProps) => {
  const classes = useStyles();

  return <div className={classes.sectionHeader}>{props.children}</div>;
};

export default SectionHeader;
