import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { colors } from '@skyslope/mache';
import { useHistory } from 'react-router';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LaunchDarklyFlags } from '../../common/launchDarkly';
import { IAppliedTemplates } from '../../store/applyTemplate/types';
import { chameleonElId } from '../../lib/constants';
import { IRootState } from '../../store';
import { useShallowSelector } from '../../lib/reduxHooks';
import { UserOrigins } from '../../lib/types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '63px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(5),
    borderTop: `1px solid ${colors.grey[400]}`,
  },
  nextButton: {
    marginLeft: theme.spacing(2),
    maxHeight: '40px',
    minWidth: '81.45px',
  },
  backButton: {
    marginRight: 'auto',
    '&:hover': {
      backgroundColor: colors.blue[50],
    },
  },
}));

interface IState {
  envelopeId: string;
  appliedTemplates: IAppliedTemplates;
  userOrigin: string;
  hadTemplatesApplied: boolean;
  envelopePathPrefix: string;
}

interface IProps {
  applyTemplate: () => void;
  flags: LaunchDarklyFlags;
  loading: boolean;
}

const selector = (state: IRootState) => ({
  envelopePathPrefix: state.applyTemplate.envelope?.config?.senderUi?.envelopePathPrefix,
  envelopeId: state.applyTemplate.envelope?.envelopeId,
  appliedTemplates: state.applyTemplate.appliedTemplates,
  hadTemplatesApplied: state.applyTemplate.hadPreviouslyAppliedTemplates,
  userOrigin: state.pageFrame.settings?.account.userOrigin,
});

const Footer = (props: IProps) => {
  const {
    envelopeId,
    appliedTemplates,
    hadTemplatesApplied,
    userOrigin,
    envelopePathPrefix,
  }: IState = useShallowSelector(selector);
  const classes = useStyles();
  const history = useHistory();
  const hideUIItems = props.flags && props.flags['hide-build-ui-elements'];
  const useMongoDBModel = props.flags && props.flags['enable-new-apply-templates-logic'];
  const nextDisabled = useMongoDBModel
    ? Object.keys(appliedTemplates).length === 0 && !hadTemplatesApplied
    : Object.keys(appliedTemplates).length === 0;

  const skip = () => {
    const envelopesPath = `envelopes/${envelopeId}`;
    if (userOrigin === UserOrigins.DOP) {
      history.push(`/${envelopesPath}`);
    } else if (envelopePathPrefix === 'dtm/') {
      history.push(`/dtm/${envelopesPath}/recipients`);
    } else {
      history.push(`/${envelopesPath}/recipients`);
    }
  };

  const back = () => {
    const envelopesPath = `envelopes/${envelopeId}`;
    if (envelopePathPrefix === 'dtm/') {
      const dtmPath = `dtm/${envelopesPath}/documents`;
      if (window.location.hostname === 'localhost') {
        window.location.href = `http://localhost:8080/${dtmPath}`;
      } else {
        window.location.pathname = `/${dtmPath}`;
      }
    } else {
      history.push(`/${envelopesPath}/documents`);
    }
  };

  return (
    <div className={classes.wrapper}>
      {hideUIItems ? (
        ''
      ) : (
        <>
          <Button className={classes.backButton} color="primary" data-spec="backButton" onClick={back}>
            <BackIcon fontSize="small" />
            Back
          </Button>
        </>
      )}
      {!hadTemplatesApplied && (
        <Button color="primary" data-spec="skipButton" onClick={skip}>
          Skip This Step
        </Button>
      )}
      <Button
        id={chameleonElId(22)}
        variant="contained"
        color="primary"
        data-spec="nextButton"
        className={classes.nextButton}
        disabled={nextDisabled || props.loading}
        onClick={props.applyTemplate}
      >
        {props.loading ? <CircularProgress size={20} /> : 'Next'}
      </Button>
    </div>
  );
};

export default Footer;
