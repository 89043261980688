import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { ITemplateRecipient } from '../../store/templateCreate/types';
import Recipient from './Recipient';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: { },
  recipient: {
    backgroundColor: 'white',
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    border: `1px solid ${colors.grey[400]}`,
    marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'right',
  },
}));

interface IProps {
  recipients: ITemplateRecipient[];
  updateRecipient: (recipientId: string, update: Partial<ITemplateRecipient>) => void;
  changeRecipientTitle: (recipientId: string, title: string) => void;
  recipientTitles: string[];
  deleteDisabled: boolean;
  templateId: string;
  deleteRecipient: (templateId: string, recipient: ITemplateRecipient) => void;
  hideAction: boolean;
  userOrigin: string;
}

const Recipients = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {props.recipients.map((recipient, i) => (
        <Recipient 
          key={i} 
          recipient={recipient}
          updateRecipient={props.updateRecipient}
          changeRecipientTitle={props.changeRecipientTitle}
          recipientTitles={props.recipientTitles}
          disabled={props.deleteDisabled}
          templateId={props.templateId}
          deleteRecipient={props.deleteRecipient}
          index={i}
          hideAction={props.hideAction}
          userOrigin={props.userOrigin}
        />
      ))}
    </div>
  );
};

export default Recipients;
