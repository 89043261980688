import { action } from 'typesafe-actions';
import { ITemplateManagementState, ITemplateMetadata } from './types';

export enum ActionTypes {
  RESET = 'templateManagement/RESET',
  FETCH_TEMPLATES = 'templateManagement/FETCH_TEMPLATES',
  FETCH_TEMPLATES_DONE = 'templateManagement/FETCH_TEMPLATES_DONE',
  FETCH_TEMPLATES_ERROR = 'templateManagement/FETCH_TEMPLATES_ERROR',
  CREATE_TEMPLATE = 'templateManagement/CREATE_TEMPLATE',
  CREATE_TEMPLATE_DONE = 'templateManagement/CREATE_TEMPLATE_DONE',
  CREATE_TEMPLATE_ERROR = 'templateManagement/CREATE_TEMPLATE_ERROR',
  CREATE_ENVELOPE_FROM_TEMPLATE = 'templateManagement/CREATE_ENVELOPE_FROM_TEMPLATE',
  CREATE_ENVELOPE_FROM_TEMPLATE_DONE = 'templateManagement/CREATE_ENVELOPE_FROM_TEMPLATE_DONE',
  FILTER_TEMPLATES = 'templateManagement/FILTER_TEMPLATES',
  DELETE_TEMPLATE = 'templateManagement/DELETE_TEMPLATE',
  DELETE_TEMPLATE_ERROR = 'templateManagement/DELETE_TEMPLATE_ERROR',
}

export const reset = (newStore?: ITemplateManagementState) => action(ActionTypes.RESET, newStore);
export const fetchTemplates = (fetchCreatedDate?: boolean) => action(ActionTypes.FETCH_TEMPLATES, { fetchCreatedDate });
export const fetchTemplatesDone = (templates: ITemplateMetadata[]) =>
  action(ActionTypes.FETCH_TEMPLATES_DONE, templates);
export const fetchTemplatesError = (error: string) => action(ActionTypes.FETCH_TEMPLATES_ERROR, error);
export const createTemplate = () => action(ActionTypes.CREATE_TEMPLATE);
export const createTemplateDone = (template: ITemplateMetadata) => action(ActionTypes.CREATE_TEMPLATE_DONE, template);
export const createTemplateError = (error: string) => action(ActionTypes.CREATE_TEMPLATE_ERROR, error);
export const createEnvelopeFromTemplate = (templateId: string, impersonatedPrimeUserGuid?: string) => 
  action(ActionTypes.CREATE_ENVELOPE_FROM_TEMPLATE, { templateId, impersonatedPrimeUserGuid });
export const createEnvelopeFromTemplateDone = (envelopeId: string) => action(ActionTypes.CREATE_ENVELOPE_FROM_TEMPLATE_DONE, envelopeId);
export const filterTemplates = (search: string) => action(ActionTypes.FILTER_TEMPLATES, { search });
export const deleteTemplate = (templateId: string, fetchCreatedDate?: boolean) => action(ActionTypes.DELETE_TEMPLATE, { templateId, fetchCreatedDate });
export const deleteTemplateError = (error: string) => action(ActionTypes.DELETE_TEMPLATE_ERROR, error);
