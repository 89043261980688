import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { colors } from '@skyslope/mache';
import ActionsMenu from './ActionsMenu';
import { IEnvelopeMetadata } from '../../store/envelopeManagement/types';
import { useDispatch } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { IRecipient } from '../../common/recipients/types';
import { LaunchDarklyFlags } from '../../common/launchDarkly';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  statusActionButton: {
    padding: 6,
    margin: -7,
    borderRadius: '28px !important',
    '&:hover': {
      backgroundColor: `${colors.blue[100]} !important`,
      color: colors.blue[800],
    },
  },
}));

interface IProps {
  envelope: IEnvelopeMetadata;
  sender?: { firstName: string; lastName: string; email: string };
  recipients?: IRecipient[];
  flags: LaunchDarklyFlags;
}

const StatusActionCell = (props: IProps) => {
  const { envelope, sender, recipients, flags } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef(null);
  const dispatch = useDispatch();
  const openMenu = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
    buttonRef.current = event.currentTarget;
    dispatch(actions.fetchRecipients(envelope.id));
  };
  const closeMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
    buttonRef.current = null;
  };
  return (
    <>
      <IconButton
        color="primary"
        onClick={openMenu}
        className={classes.statusActionButton}
        aria-label="More envelope actions"
      >
        <Typography style={{ color: colors.blue[800], fontWeight: 'bold' }}>More</Typography>
        <MoreVertIcon />
      </IconButton>
      <ActionsMenu
        open={Boolean(open)}
        envelope={envelope}
        anchorEl={buttonRef.current}
        onClose={closeMenu}
        sender={sender}
        recipients={recipients}
        flags={flags}
      />
    </>
  );
};

export default StatusActionCell;
