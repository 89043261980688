import React from 'react';
import { Modal, ModalCancelButton, ModalConfirmationButton, ModalText, ModalTitle } from '../../common/Modal';

interface IProps {
  toggleModal: () => void;
  deleteTemplate: () => void;
}

export const ConfirmationModal = (props: IProps) => {
  const title = <ModalTitle>Are you sure you want to delete this template?</ModalTitle>;
  const content = (
    <>
      <ModalText>
        By clicking "Delete Template", you will lose all of your work. Anyone with access to this template will no
        longer be able to view it.
      </ModalText>
      <ModalText> Would you still like to continue? </ModalText>
    </>
  );
  const actionButtons = (
    <>
      <ModalCancelButton onClick={props.toggleModal} aria-label="Cancel">
        Cancel
      </ModalCancelButton>
      <ModalConfirmationButton onClick={props.deleteTemplate} aria-label="delete template">
        Delete Template
      </ModalConfirmationButton>
    </>
  );
  return <Modal {...props} title={title} content={content} actionButtons={actionButtons} />;
};

export default ConfirmationModal;
