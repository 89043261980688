import { Ref } from 'react';

function fillRef(ref: Ref<any> | undefined, value: any) {
  if (!ref) return;

  if (typeof ref === 'function') {
    ref(value);
  } else if (typeof ref === 'object') {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

/**
 * Merge multiple react refs together into a single function ref
 * Adapted from: https://github.com/gregberge/react-merge-refs/blob/master/src/index.js
 */
export default function mergeRefs(...refs: Array<Ref<any> | undefined>) {
  return (value: any) => {
    refs.forEach((ref) => fillRef(ref, value));
  };
}
