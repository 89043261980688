import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalCancelButton, ModalConfirmationButton, ModalText, ModalTitle } from '../../common/Modal';
import { correctEnvelope } from '../../store/envelopeManagement/actions';

interface IProps {
  envelopeId: string;
  close: () => void;
}

const CorrectModal = (props: IProps) => {
  const dispatch = useDispatch();
  const { envelopeId, close } = props;

  const onClickCorrect = () => {
    dispatch(correctEnvelope(envelopeId));
    close();
  };

  const title = <ModalTitle>Are you sure?</ModalTitle>;
  const content = (
    <ModalText>
      Correcting an envelope will require signed recipients to start over and sign again. While you are correcting,
      recipients will not be able to access this envelope.
    </ModalText>
  );
  const actionButtons = (
    <>
      <ModalCancelButton onClick={close} aria-label="cancel correct envelope">
        Cancel
      </ModalCancelButton>
      <ModalConfirmationButton onClick={onClickCorrect} aria-label="confirm correct envelope">
        Correct Envelope
      </ModalConfirmationButton>
    </>
  );
  return <Modal toggleModal={close} title={title} content={content} actionButtons={actionButtons} />;
};

export default CorrectModal;
