import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import Recipients from '../../common/recipients';
import { IRecipient, RecipientRole } from '../../common/recipients/types';
import * as actions from '../../store/dtm/recipients/actions';
import { IRootState } from '../../store';
import { preferencesUrl } from '../../lib/constants';
import { HeaderContext } from '../../context/header-context';
import { withLaunchDarkly, LaunchDarklyFlags } from '../../common/launchDarkly';
import NavHeader from '../../common/navigation';
import { checkIfRecipientIsSender } from '../../common/utils';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import Spinner from '../../components/common/Spinner';
import Footer from '../../components/common/Footer';
import FooterLinks from '../../components/common/FooterLinks';
import { getUsersGroups } from '../../lib/api/account/accountApi';
import useUserInfo from '../../auth/useUserInfo';
import { applyColorAndCounterToNewRecipient, sortRecipients } from '../../lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: 1200,
    maxHeight: 639,
    height: '100%',
  },
  header: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  gridModal: {
    gridTemplateColumns: '380px 1fr',
    border: 'none',
  },
  grid: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${colors.grey[400]}`,
    display: 'grid',
    gridTemplateColumns: '530px 1fr',
    gridAutoFlow: 'column',
    minHeight: '55vh',
  },
  backButton: {
    marginRight: 'auto',
  },
  nextButton: {
    padding: '10px 40px',
  },
}));

const selector = (state: IRootState) => ({
  recipients: state.dtm.recipients.recipients,
  optionalRecipients: state.dtm.recipients.optionalRecipients,
  isFetching: state.dtm.recipients.isFetching,
  done: state.dtm.recipients.done,
  contacts: state.dtm.recipients.contacts,
  colorCounter: state.pageFrame.recipientColorCounter,
});

const RecipientsManagement = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { recipients, optionalRecipients, isFetching, done, contacts, colorCounter } = useShallowSelector(selector);
  const userInfo = useUserInfo();
  const classes = useStyles();
  const headerContext = React.useContext(HeaderContext);
  const [isPrimeUser, setIsPrimeUser] = React.useState(false);
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init || isFetching || !userInfo) return;

    // If sender is already an optional recipient, we done.
    if (
      optionalRecipients.some((o) =>
        checkIfRecipientIsSender(o)
      )
    ) {
      setInit(true);
      return;
    }

    let baseSender = {
      id: userInfo.senderId,
      role: RecipientRole.Signer,
      email: userInfo.senderEmail,
      isSender: true,
      color: colors.blue[800],
      isActive: false,
      isPlaceholder: false,
      title: 'Me',
      firstName: userInfo.senderFirstName,
      lastName: userInfo.senderLastName,
    };

    // If Sender is already a Recipient
    const recipientIndex = recipients.findIndex((r: IRecipient) => r.title?.toLowerCase() === 'me');
    if (recipientIndex > -1) {
      // If the recipient has no email, we need to update the recipient
      if (!recipients[recipientIndex].email) {
        baseSender.id = recipients[recipientIndex].id;
        baseSender.group = { id: 'default', name: 'default', order: 0 };
        baseSender.isActive = true;
        baseSender.isPlaceholder = true;
        baseSender.recipientColorCounter = recipients[recipientIndex].recipientColorCounter;
        const newRecipients = [...recipients];
        newRecipients.splice(recipientIndex, 1, baseSender);
        dispatch(actions.updateRecipients(newRecipients));
        // Otherwise we just use the real recipient to improve our optional recipient
      } else {
        baseSender = {
          ...recipients[recipientIndex],
          isSender: true,
          group: recipients[recipientIndex].group ?? { id: 'default', name: 'default', order: 0 },
          color: colors.blue[800],
        };
      }
    }
    // Add sender to optional recipients
    const newORs = [baseSender, ...optionalRecipients];
    dispatch(actions.updateOptionalRecipients(newORs));
    setInit(true);
  }, [recipients, optionalRecipients, userInfo, init, isFetching]);

  useEffect(() => {
    headerContext.setContent(
      <NavHeader
        labelName="Add recipients"
        breadcrumb={{ name: 'Envelope Management', onClick: () => history.push('/envelopes') }}
        progressBarPercent={80}
        hideNavItems={hideNavItems}
        showBreezeApp={showBreezeApp}
        preferencesUrl={preferencesUrl}
        showPreferences={showPreferencesMenuItem}
        getUsersGroups={getUsersGroups}
        flags={flags}
      />
    );

    dispatch(actions.fetch(params.id, userInfo?.impersonatedPrimeUserGuid));
    if (!contacts.length) {
      dispatch(actions.fetchContacts());
    }

    return () => {
      headerContext.setContent(null);
      dispatch(actions.reset());
    };
  }, []);

  useEffect(() => {
    if (userInfo?.isPrimeAuthenticated) {
      setIsPrimeUser(true);
    }
  }, [userInfo?.isPrimeAuthenticated]);

  const prepareRecipients = () => {
    const updatedRecipients: IRecipient[] = [];
    recipients.forEach((r: IRecipient) => updatedRecipients.push(r));
    optionalRecipients.forEach((or: IRecipient) => {
      if (!updatedRecipients.find((ur) => ur.id === or.id) && !checkIfRecipientIsSender(or) && or.isPlaceholder) {
        updatedRecipients.push(or);
      }
    });
    dispatch(actions.updateRecipients(updatedRecipients));
  };

  const handleNextButton = async () => {
    prepareRecipients();
    dispatch(actions.save(params.id));
  };

  const handleCreatePrimeContact = (contact: {
    firstName: string;
    lastName: string;
    email: string;
    optional: boolean;
    id: string;
  }) => {
    dispatch(actions.createPrimeContact(contact));
  };

  const onChange = (newRecipients: IRecipient[]) => {
    applyColorAndCounterToNewRecipient(newRecipients, recipients, colorCounter);
    sortRecipients(newRecipients);
    dispatch(actions.updateRecipients(newRecipients));
  };
  if (done) {
    history.push(`/envelopes/${params.id}`);
  }

  if (isFetching) {
    return <Spinner />;
  }

  const getIncompletePlaceHolders = () =>
    recipients?.filter(
      (r) => r.isPlaceholder && (r.firstName === null || r.email === null) && r.title.toLowerCase() !== 'me'
  );

  // Filter incompletePlaceholdersProp from state
  const incompletePlaceholdersProp = getIncompletePlaceHolders();
  const nextDisabled = !recipients.length || !recipients.some((r) => r.role === RecipientRole.Signer && r.isActive);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="h3">Recipients & signing order</Typography>
        <Typography component="span" variant="body2" style={{ marginTop: 8 }}>
          Add recipients by clicking on the “+ New Recipient” button in the left side panel
        </Typography>
      </div>
      <div className={classes.grid}>
        <Recipients
          recipients={recipients}
          onChange={onChange}
          optionalRecipients={optionalRecipients}
          contacts={contacts}
          createPrimeContact={handleCreatePrimeContact}
          isPrimeUser={isPrimeUser}
          incompletePlaceholders={incompletePlaceholdersProp}
          colorCounter={colorCounter}
        />
      </div>
      <Footer>
        {hideNavItems ? (
          ''
        ) : (
          <>
            <Button
              className={classes.backButton}
              color="primary"
              onClick={() => history.push(`/dtm/envelopes/${params.id}/documents`)}
            >
              <BackIcon fontSize="small" /> Back
            </Button>
            <Button color="primary" data-spec="exit" onClick={() => history.push('/envelopes')}>
              Exit
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          data-spec="next"
          onClick={handleNextButton}
          className={classes.nextButton}
          disabled={nextDisabled}
        >
          Next
        </Button>
      </Footer>
      <FooterLinks />
    </div>
  );
};

export default withLaunchDarkly(RecipientsManagement);
