import React from 'react';
import { useDraggable } from '@dnd-kit/core';

interface IProps {
  children: any;
  id: string;
  styleProps?: any;
}

const Draggable = (props: IProps) => {
  const { children, id, styleProps } = props;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div ref={setNodeRef} style={{ ...style, touchAction: 'none', ...styleProps }} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

export default Draggable;
