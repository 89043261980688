import React from 'react';
import { useSmartEmailContext } from '@digisign3-ui/sender/src/context/smart-email-context';

const loadingText = {
  Professional: '\u{1F454} Hang tight, just tying my tie...',
  Friendly: '\u2728 One moment please, just working our magic...',
  Urgent: '\u{1f4a8} One second, moving as quick as we can!',
};

const EmailBodyLoading = () => {
  const { tone } = useSmartEmailContext();
  const [currentText, setCurrentText] = React.useState('');
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const text = loadingText[tone] || 'Loading your email...';

  React.useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 100);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [currentIndex, text]);

  return (
    <div className="absolute bg-white z-10 inset-1" style={{}}>
      <div className="text-[22px] text-black p-6">{currentText}</div>
    </div>
  );
};

export default EmailBodyLoading;
