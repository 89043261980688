import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import LocationIcon from '@material-ui/icons/LocationOn';
import { colors } from '@skyslope/mache';
import Pill from './Pill';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  selectedValues: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectedValue: {
    borderRadius: '16px',
    backgroundColor: colors.grey[200],
    border: `1px solid ${colors.grey[500]}`,
    color: colors.grey[800],
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    height: 32,
    minWidth: 120,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  locationIcon: {
    color: colors.grey[500],
    marginRight: theme.spacing(1),
  },
  tagText: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  removeIcon: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  autocompleteTag: {
    display: 'none',
  },
  autocompleteInputRoot: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
}));

interface IProps {
  options: string[];
  values?: string[];
  setValues: (values: string[]) => any;
  label?: string;
  placeholder?: string;
  helperText?: string;
}

const Autocomplete = (props: IProps) => {
  const classes = useStyles();
  const values = props.values || [];

  const removeValue = (value: string) => {
    const newValues = values.filter(v => v !== value);
    props.setValues(newValues);
  };

  return (
    <div className={classes.wrapper}>
      <MuiAutocomplete<string>
        multiple
        renderInput={params => (
          <TextField
            {...params}
            variant="filled"
            label={props.label}
            placeholder={props.placeholder}
            helperText={props.helperText}
          />
        )}
        onChange={(e: any, newValues: string[] | null) => {
          newValues && props.setValues(newValues);
        }}
        value={values}
        options={props.options}
        classes={{
          tag: classes.autocompleteTag,
          inputRoot: classes.autocompleteInputRoot,
        }}
        forcePopupIcon={false}
        filterSelectedOptions
        autoHighlight
        disableClearable
      />

      <div className={classes.selectedValues}>
        {values.map(v => (
          <Pill key={v} text={v} onDelete={() => removeValue(v)} icon={<LocationIcon />} />
        ))}
      </div>
    </div>
  );
};

export default Autocomplete;
