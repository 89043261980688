import React from 'react';
import { colors } from '@skyslope/mache';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as Tab } from '../../images/tab.svg';
import { ISigner } from '../../store/senderBuild/types';
import { isSmallScreenMediaQuery } from '../../lib/isSmallScreen';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    right: '100%',
    position: 'absolute',
    marginRight: theme.spacing(2),
    animation: '$move 0.75s infinite',
    animationDirection: 'alternate',
    willChange: 'transform',
    fontSize: 14,
    alignSelf: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    '& > svg': {
      fontSize: 16,
      paddingRight: 2,
    },
  },
  content: {
    color: 'white',
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    height: 28,
    paddingLeft: theme.spacing(0.5),
  },
  '@keyframes move': {
    from: { transform: 'translateX(0)' },
    to: { transform: 'translateX(-16px)' },
  },
}));

interface IProps {
  signer?: ISigner;
  blockType: any;
  multiSelect?: boolean;
  text?: string;
}

const BlockTag = (props: IProps) => {
  const classes = useStyles();
  const centeredTabText = props.multiSelect || !props.signer;
  const topStyle = centeredTabText ? 'calc(50% - 16px)' : '';
  const width = centeredTabText ? 'calc(100% - 16px)' : 'auto';
  const justifyContent = centeredTabText ? 'center' : 'inherit';
  const isFullScreen = isSmallScreenMediaQuery();

  const color = props.signer ? props.signer.color : colors.grey[500];

  const text = props.text || (
    <>
      {props.signer?.firstName[0] || ''}
      {props.signer?.lastName ? props.signer!.lastName[0] : ''}
    </>
  );
  return (
    <div className={`${classes.wrapper} `} style={{ top: topStyle, marginRight: isFullScreen ? '8px' : '' }}>
      <Tab style={{ color }} height={28} />
      <div className={classes.content} style={{ width, justifyContent }} id="blockTag">
        <div className={classes.icon}>{props.blockType.icon}</div>
        {text}
      </div>
    </div>
  );
};

export default BlockTag;
