import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { convertFieldTypeToQueryType, useDebounce } from '../utils';
import { IContact } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginTop: theme.spacing(2),
    width: '100%',
    '& .Mui-disabled': {
      '-webkit-text-fill-color': colors.grey[700],
      opacity: 1,
    },
  },
  autocompleteInputRoot: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  inputRootRounded: {
    paddingTop: '1px !important',
    paddingBottom: '1px !important',
    borderRadius: '20px',
    border: `1px solid ${colors.grey[400]} !important`
  },
  noOptions: {
    display: 'none',
  },
  option: {
    '&:hover': {
      backgroundColor: colors.blue[50],
    },
    '&[data-focus="true"]': {
      backgroundColor: colors.blue[50],
    },
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  optionValue: {
    color: colors.grey[800],
    fontWeight: 700,
  },
  clearIndicator: {
    display: 'none',
  },
  adornment: {
    color: colors.grey[400],
    marginRight: '-48px',
    display: 'flex'
  },
}));

type IProps = {
  autoFocus?: boolean;
  label: string;
  helperText: string;
  placeholder: string;
  handleOnChange: (e: any) => void;
  error: boolean;
  value: string;
  dataSpec?: string;
  name?: string;
  disabled?: boolean;
  contacts: IContact[];
  filterMethod: (value: string) => (contact: IContact) => boolean;
  handleContactSelect: (e: any, queryType: string) => void;
  getOptionLabel: (option: IContact) => string;
  clearOnSelect?: boolean;
  roundInput?: boolean;
  emptyResultsNode?: any;
  popupIcon?: any;
};

const getPartsForAutocomplete = (field, value) => {
  const matches = match(field, value);
  return parse(field, matches);
};
const RecipientAutoComplete = (props: IProps) => {
  const classes = useStyles();
  const [textValue, setTextValue] = useState('');
  const debouncedValue = useDebounce(textValue, 200);
  const [filteredResults, setFilteredResults] = useState([]);
  const queryType = convertFieldTypeToQueryType(props.label);

  const filterContacts = () => {
    let results = [];
    if (debouncedValue) {
      results = props.contacts
        .filter(props.filterMethod(debouncedValue))
        .sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
      if (results.length === 0 && props.emptyResultsNode) {
        results = ['NO RESULT'];
      }
    }
    setFilteredResults(results);
  };

  useEffect(() => {
    filterContacts();
  }, [debouncedValue]);

  useEffect(() => {
    setTextValue(props.value);
  }, [props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e) setTextValue(e.target.value);
  };

  const AutoCompleteSpan = (autoCompleteRowProps: { part: { highlight: boolean; text: string }; isEmail: boolean }) => {
    const { part, isEmail } = autoCompleteRowProps;
    return (
      <span
        className={classes.optionValue}
        style={{
          color: part.highlight ? colors.blue[800] : '',
          fontWeight: isEmail ? 'normal' : 700,
        }}
        data-spec={`${part.text}-contact`}
      >
        {part.text}
      </span>
    );
  };

  const produceAutoCompleteRows = (parts, prefix, isEmail) => {
    return parts.map((part, i) => <AutoCompleteSpan part={part} key={`${prefix}${i}`} isEmail={isEmail} />);
  };
  const inputRoot = props.roundInput ? classes.inputRootRounded : classes.autocompleteInputRoot;

  return (
    <>
      {props.disabled ?
        <TextField
          classes={{ root: classes.input }}
          autoFocus={props.autoFocus}
          variant="filled"
          label={props.label}
          helperText={props.helperText}
          placeholder={props.placeholder}
          onChange={(e) => props.handleOnChange(e)}
          error={props.error}
          data-spec={props.dataSpec}
          required
          value={textValue}
          name={props.name}
          disabled
        /> : (
        <Autocomplete
          openOnFocus
          classes={{
            inputRoot,
            noOptions: classes.noOptions,
            option: classes.option,
            clearIndicator: classes.clearIndicator,
          }}
          options={props.disabled ? [] : filteredResults}
          filterOptions={(options, state) => {
            return filteredResults;
          }}
          onInputChange={(e: any) => {
            handleChange(e);
          }}
          onChange={(e: any, newValue: any) => {
            const value = newValue === 'NO RESULT' ? {
                firstName: '',
                lastName: '',
                email: ''
              } : newValue;

            if (props.clearOnSelect) setTextValue('');
            props.handleContactSelect(value, queryType);
          }}
          inputValue={textValue}
          getOptionLabel={props.getOptionLabel}
          renderOption={(option, { inputValue }) => {
            if (props.emptyResultsNode && option === 'NO RESULT') {
              return props.emptyResultsNode;
            }
            if (queryType) {
              const parts = getPartsForAutocomplete(option[convertFieldTypeToQueryType(props.label)], inputValue);
              const autoCompleteRow = produceAutoCompleteRows(parts, '', queryType === 'email');
              return (
                <div>
                  {queryType === 'firstName' ? (
                    autoCompleteRow
                  ) : (
                    <span className={classes.optionValue}>{`${option.firstName} `}</span>
                  )}
                  {queryType === 'lastName' ? (
                    autoCompleteRow
                  ) : (
                    <span className={classes.optionValue}>{` ${option.lastName}`}</span>
                  )}
                  <br />
                  {queryType === 'email' ? (
                    autoCompleteRow
                  ) : (
                    <span className={classes.optionValue} style={{ fontWeight: 'normal' }}>
                      {option.email}
                    </span>
                  )}
                </div>
              );
            }
            const firstNameParts = getPartsForAutocomplete(option.firstName, inputValue);
            const lastNameParts = getPartsForAutocomplete(option.lastName, inputValue);
            const emailParts = getPartsForAutocomplete(option.email, inputValue);
            return (
              <div>
                {produceAutoCompleteRows(firstNameParts, '1', false)}
                &nbsp;
                {produceAutoCompleteRows(lastNameParts, '2', false)}
                <br />
                {produceAutoCompleteRows(emailParts, '3', true)}
              </div>
            );
          }}
          autoHighlight
          renderInput={(params) => (
            <TextField
              {...params}
              classes={{ root: classes.input }}
              autoFocus={props.autoFocus}
              variant="filled"
              label={props.label}
              helperText={props.helperText}
              placeholder={props.placeholder}
              onChange={(e) => props.handleOnChange(e)}
              error={props.error}
              data-spec={props.dataSpec}
              required
              name={props.name}
              disabled={props.disabled}
              InputProps={{...params.InputProps, endAdornment: props.popupIcon ? (
              <div className={classes.adornment}>
                { props.popupIcon }
              </div>
              ) : ''}}
            />
          )}
        />
      )}
    </> 
  );
};

export default RecipientAutoComplete;
