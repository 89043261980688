/**
 * TODO
 * THIS IS COPIED FROM SKYSLOPE MACHE AND IDEALLY WE COULD JUST UPDATE
 * TO THE LATEST VERSION OF MACHE BUT THIS CODEBASE IS CURRENTLY WAYYYY
 * BEHIND.
 */
import { useRef } from 'react';

/**
 * A ref that is constantly up to date with the latest passed value
 */
export function useUpdatedRef<T>(value: T) {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
}
