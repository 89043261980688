import { IFile } from '../../../dtm/types';

export interface IFileDocument {
  id: string;
  name: string;
  fileName: string;
  extension: string;
  fileSize: string;
  pages: number;
  url: string;
}

export interface IDocument {
  fileName: string;
  url?: string;
  documentId: string;
  numberOfPages?: number;
  isUploading?: boolean;
  uploadStatus: DocumentStatus;
}

export enum DocumentStatus {
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Failed = 'Failed',
}

export interface IDocumentsState {
  envelopeId: string;
  file?: IFile;
  fileDocuments: IFileDocument[];
  envelopeDocuments: IDocument[];
  fetchError?: boolean;
  isFetching?: boolean;
  allDocumentsUploaded: boolean;
  envelopeDocumentsFetchComplete: boolean;
}
