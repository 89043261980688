import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { colors } from '@skyslope/mache/dist/src/theme';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import { IRootState } from '../store';
import * as actions from '../store/applyTemplate/actions';
import { setLoading } from '../store/pageFrame/actions';
import Documents from '../components/applyTemplate/Documents';
import DocumentPreview from '../components/applyTemplate/DocumentPreview';
import TemplatesList from '../components/applyTemplate/TemplatesList';
import Footer from '../components/applyTemplate/Footer';
import { chameleonElId, preferencesUrl } from '../lib/constants';
import { HeaderContext } from '../context/header-context';
import { IAppliedTemplates } from '../store/applyTemplate/types';
import { IDocument, IEnvelope } from '../store/senderBuild/types';
import { getUsersGroups } from '../lib/api/account/accountApi';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';
import NavHeader from '../common/navigation';
import { UserOrigins } from '../lib/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: -24,
    marginBottom: -24,
    justifyContent: 'space-between',
  },
  columnWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    padding: '24px 0',
    overflow: 'auto',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  column: {
    maxHeight: 'calc(100vh - 170px)',
    overflowY: 'auto',
    position: 'relative',
  },
  whiteBg: {
    backgroundColor: 'white',
    maxWidth: '70%',
    flexBasis: '70%',
  },
  documents: {
    width: '100%',
    maxWidth: '367px',
    marginTop: -24,
    borderRight: `1px solid ${colors.grey[400]}`,
  },
  templates: {
    maxWidth: '30%',
    flexBasis: '30%',
    borderLeft: `1px solid ${colors.grey[400]}`,
  },
}));

interface IState {
  envelopeFetched: boolean;
  templatesFetched: boolean;
  appliedTemplates: IAppliedTemplates;
  fetchedAppliedTemplates: boolean;
  showTemplatesSection: boolean;
  accountSettings: any;
  userOrigin: UserOrigins;
  documents: IDocument[];
  templateApplied: boolean;
  loading: boolean;
  envelope: IEnvelope;
}

const selector = (state: IRootState) => ({
  envelope: state.applyTemplate.envelope,
  envelopeFetched: !!state.applyTemplate.envelope,
  templatesFetched: !!state.applyTemplate.templates,
  appliedTemplates: state.applyTemplate.appliedTemplates,
  fetchedAppliedTemplates: state.applyTemplate.fetchedAppliedTemplates,
  showTemplatesSection: state.applyTemplate.showTemplatesSection,
  documents: state.applyTemplate.envelope?.documents,
  accountSettings: state.pageFrame.settings,
  userOrigin: state.pageFrame.settings?.account.userOrigin,
  templateApplied: state.applyTemplate.templateApplied,
  loading: state.pageFrame.loading,
});

export interface AppliedTemplate {
  template: string;
  data: {
    document: string;
  };
}

const ApplyTemplate = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const [templatesList, setTemplatesList] = React.useState<AppliedTemplate[]>([]);
  const headerContext = React.useContext(HeaderContext);
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const useMongoDBModel = flags && flags['enable-new-apply-templates-logic'];
  const templateSharing = flags && flags['enable-template-sharing'];

  const handleFetchTemplates = () => {
    if (!store.templatesFetched) {
      const urlParams = new URLSearchParams(window.location.search);
      const stateQueryParam = urlParams.get('state');
      const roleQueryParam = urlParams.get('role');
      const flashCodes = urlParams.get('flash');
      const flashCodesList = flashCodes ? flashCodes.split(',') : [];
      if (stateQueryParam && roleQueryParam) {
        dispatch(
          actions.fetchTemplates(
            { state: stateQueryParam, flashCodes: flashCodesList, role: roleQueryParam },
            templateSharing
          )
        );
      } else {
        dispatch(actions.fetchTemplates(undefined, templateSharing));
      }
    }
  };

  React.useEffect(() => {
    if (!store.envelopeFetched) {
      dispatch(actions.fetchEnvelope(params.id));
    }
    return () => {
      dispatch(actions.reset());
    };
  }, []);

  React.useEffect(() => {
    if (store.accountSettings) {
      handleFetchTemplates();
      const breadCrumb = hideNavItems ? undefined : { name: 'Envelopes', onClick: () => history.push('/envelopes') };
      headerContext.setContent(
        <NavHeader
          labelName="Apply templates"
          breadcrumb={breadCrumb}
          hideNavItems={hideNavItems}
          showBreezeApp={showBreezeApp}
          preferencesUrl={preferencesUrl}
          showPreferences={showPreferencesMenuItem}
          userOrigin={store.userOrigin}
          getUsersGroups={getUsersGroups}
          flags={flags}
        />
      );
    }
    return () => {
      headerContext.setContent(null);
    };
  }, [store.accountSettings]);

  React.useEffect(() => {
    if (store.templatesFetched && !store.fetchedAppliedTemplates) {
      dispatch(actions.fetchTemplatesApplied(params.id, useMongoDBModel));
    }
  });

  React.useEffect(() => {
    if (store.templateApplied) {
      if (store.envelope.config?.senderUi?.envelopePathPrefix === 'dtm/') {
        history.push(`/dtm/envelopes/${params.id}/recipients`);
      } else if (store.userOrigin === UserOrigins.DOP) {
        history.push(`/envelopes/${params.id}`);
      } else {
        history.push(`/envelopes/${params.id}/recipients`);
      }
    }
    dispatch(setLoading(false));
  }, [store.templateApplied]);

  React.useEffect(() => {
    if (store.fetchedAppliedTemplates) {
      for (const t in store.appliedTemplates) {
        templatesList.push({ data: { document: t }, template: store.appliedTemplates[t].metadata.id });
      }
    }
  }, [store.fetchedAppliedTemplates]);

  if (!store.envelopeFetched) {
    return null;
  }

  const handleApplyTemplate = async () => {
    dispatch(setLoading(true));
    if (!useMongoDBModel) {
      templatesList.forEach((a: AppliedTemplate) => {
        dispatch(actions.finalize(a.template, params.id, { documentId: a.data.document }));
      });
    } else {
      const bulkTemplates = templatesList.map((t) => {
        return { envelopeDocumentId: t.data.document, templateId: t.template };
      });
      const filteredTemplates = bulkTemplates.filter((t) => {
        return store.documents.some((d) => {
          return t.envelopeDocumentId === d.documentId;
        });
      });
      dispatch(actions.finalizeBulkTemplates(params.id, filteredTemplates));
    }
  };

  const handleAddTemplate = (template: AppliedTemplate, documentId?: string) => {
    const templateListCopy: Array<AppliedTemplate> = templatesList.filter((t) => {
      return t.data.document !== documentId;
    });
    templateListCopy.push(template);
    setTemplatesList(templateListCopy);
  };

  const handleRemoveTemplate = (documentId: string) => {
    const templateListCopy: Array<AppliedTemplate> = [...templatesList];
    templateListCopy.forEach((template, i) => {
      if (template.data.document === documentId) {
        templateListCopy.splice(i, 1);
      }
    });
    setTemplatesList(templateListCopy);
  };

  return (
    <>
      <div className={`${classes.documents} ${classes.column}`} id={chameleonElId(23)}>
        <Documents />
      </div>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={`${classes.whiteBg} ${classes.column}`} id={chameleonElId(24)}>
          <DocumentPreview />
        </Grid>
        <Grid item xs={12} md={4} className={`${classes.templates} ${classes.column}`} id={chameleonElId(25)}>
          {store.showTemplatesSection && (
            <TemplatesList handleAddTemplate={handleAddTemplate} handleRemoveTemplate={handleRemoveTemplate} />
          )}
        </Grid>
      </Grid>
      <Footer applyTemplate={handleApplyTemplate} flags={flags} loading={store.loading} />
    </>
  );
};

export default withLaunchDarkly(ApplyTemplate);
