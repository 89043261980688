import React from 'react';
import { useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import { IBlock, ISigner } from '../../store/senderBuild/types';
import { BLOCK_TYPES } from '../../lib/constants';
import BlockEditing from './BlockEditing';
import BlockStatic from './BlockStatic';

interface IProps {
  documentId: string;
  pageIndex: number;
  blockIndex: number;
  selected: boolean;
  selectedBlocks: number[];
  block: IBlock;
  selectBlocks: (
    documentId: string,
    pageIndex: number,
    blockIndices: number[],
    multiSelect?: boolean,
    selectThroughGroup?: boolean
  ) => void;
  zoom: number;
  pageHeight?: number;
  pageWidth?: number;
  currentSelectedBlocks?: number[];
  blocks?: IBlock[];
  convertToGroup?: (block: IBlock, blockIndex: number) => void;
}

interface IMouseCoords {
  clientX: number;
  clientY: number;
  screenX: number;
  screenY: number;
}

const selector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
});

interface IState {
  signers: ISigner[];
}

const Block = (props: IProps) => {
  const store: IState = useShallowSelector(selector);
  // This tracks whether a static block was mousedown'ed or not in order to know if it should re-render into an edit block
  const [staticBlockMouseDown, setStaticBlockMouseDown] = React.useState(false);
  // These coordinates are tracked inside of the static block so that it can be passed to the edit block
  const [mouseDownCoords, setMouseDownCoords] = React.useState<IMouseCoords>({
    clientX: NaN,
    clientY: NaN,
    screenX: NaN,
    screenY: NaN,
  });
  const { block } = props;

  const startEditing = (multiSelect: boolean = false, blockIndex: number, selectThroughGroup: boolean) => {
    props.selectBlocks(props.documentId, props.pageIndex, [props.blockIndex], multiSelect, selectThroughGroup);
  };

  const stopEditing = () => {
    if (!props.selectedBlocks.length) {
      props.selectBlocks(props.documentId, props.pageIndex, []);
    }
  };

  const mouseDownCoordinatesHandler = (e: MouseEvent) => {
    setMouseDownCoords({
      clientX: e.clientX,
      clientY: e.clientY,
      screenX: e.screenX,
      screenY: e.screenY,
    });
  };

  const staticMouseDownStateToggle = () => {
    setStaticBlockMouseDown(!staticBlockMouseDown);
  };

  const blockType = BLOCK_TYPES.find((b) => b.key === block.blockType);
  const signer = store.signers.find((s) => s.signerId === block.assignedTo);
  const pageDimensions = { height: props.pageHeight, width: props.pageWidth };

  return props.selected ? (
    <BlockEditing
      className={block.blockType}
      documentId={props.documentId}
      pageIndex={props.pageIndex}
      blockIndex={props.blockIndex}
      blockType={blockType}
      signer={signer}
      block={block}
      stopEditing={stopEditing}
      zoom={props.zoom}
      pageHeight={props.pageHeight}
      pageWidth={props.pageWidth}
      selectedBlocks={props.selectedBlocks}
      staticBlockMouseDown={staticBlockMouseDown}
      staticMouseDownStateToggle={staticMouseDownStateToggle}
      mouseDownCoordinates={mouseDownCoords}
      convertToGroup={props.convertToGroup}
    />
  ) : (
    <BlockStatic
      blockType={blockType}
      signer={signer!}
      block={props.block}
      blockIndex={props.blockIndex}
      startEditing={startEditing}
      zoom={props.zoom}
      staticMouseDownStateToggle={staticMouseDownStateToggle}
      mouseDownCoordinatesHandler={mouseDownCoordinatesHandler}
      currentSelectedBlockIndices={props.currentSelectedBlocks}
      blocks={props.blocks}
      pageDimensions={pageDimensions}
    />
  );
};

export default Block;
