import React from 'react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { colors } from '@skyslope/mache';

interface IProps {
  id: string;
  blockOptions?: any;
  children?: any;
  menuText?: string;
  styleProps?: {};
}

const BlockToolbar = (props: IProps) => {
  const [openOptions, setOpenOptions] = React.useState(false);

  return (
    <>
      <div
        className={
          openOptions
            ? 'bg-white py-2.5 px-3 rounded-t-lg flex items-center'
            : 'bg-white py-2.5 px-3 rounded-lg flex items-center'
        }
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          borderBottom: openOptions ? `1px solid ${colors.grey[300]}` : 'none',
        }}
      >
        <DragIndicatorIcon className={props.menuText === 'Strike Color:' ? 'text-grey-500 ml-2.5' : 'text-grey-500'} />
        {props.menuText && (
          <span className="my-0 mx-4 whitespace-nowrap" style={{ color: '#3F5B77' }}>
            {props.menuText}
          </span>
        )}
        {props.children}
        {props.blockOptions && (
          <IconButton data-spec="toolbarExpandIcon" className="p-1.5" onClick={() => setOpenOptions(!openOptions)}>
            {openOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </div>
      {openOptions && (
        <div className="rounded-b-lg bg-white flex" style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
          {props.blockOptions}
        </div>
      )}
    </>
  );
};

export default BlockToolbar;
