import React from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from "../../store/senderBuild/actions";
import RecipientManagement from '../../containers/RecipientManagement';
import { IRecipients } from '../../store/senderBuild/types';
import { IRootState } from '../../store';
import { isSkySlopeMobileApp, postWindowMessage } from '../../common/utils';
import useUserInfo from '../../auth/useUserInfo';

interface IProps {
  recipients: IRecipients;
  id: string;
}

interface IState {
  successShow: boolean;
  errorShow: boolean;
}

const selector = (state: IRootState) => ({
  successShow: state.pageFrame.successShow,
  errorShow: state.pageFrame.errorShow,
});

const MobileRecipientsOrder = (props: IProps) => {
  const dispatch = useDispatch();
  const state: IState = useShallowSelector(selector);
  const userInfo = useUserInfo();
  const isMobileApp = isSkySlopeMobileApp();

  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (state.errorShow || state.successShow) {
      dispatch(actions.hideMobileSigningOrder());
    }
  }, [state.errorShow, state.successShow]);

  const handleMobileRecipientsOrderClose = () => {
    dispatch(actions.hideMobileSigningOrder());
  };

  const handleSaveRecipients = () => {
    setDisabled(true);
    dispatch(actions.updateRecipients(props.recipients.recipients, props.id, userInfo!));
  };

  React.useEffect(() => {
    if (isMobileApp) {
      postWindowMessage({
        header_text: 'Edit Signing Order',
        back_button_type: '<',
        should_back_button_exit: false,
        button_text: 'Save',
      });
    }
    window.mobileApp.nextButtonTap = () => {};
    window.mobileApp.backButtonTap = () => {};
  }, []);

  window.mobileApp.backButtonTap = () => {
    dispatch(actions.hideMobileSigningOrder());
  };

  window.mobileApp.nextButtonTap = () => {
    setDisabled(true);
    dispatch(actions.updateRecipients(props.recipients.recipients, props.id, userInfo!));
  };

  return (
    <Dialog fullScreen open scroll="paper">
      <RecipientManagement modal disableRecipientList />
      {!isMobileApp && (
        <DialogActions
          className="w-full fixed bottom-0 bg-white"
          style={{ borderTop: '1px solid #CAD5E0', padding: 0 }}
        >
          <div className="flex justify-evenly items-center w-full" style={{ padding: '15px' }}>
            <Button onClick={handleMobileRecipientsOrderClose} variant="outlined" style={{ width: '140px' }}>
              Back
            </Button>
            <Button
              onClick={handleSaveRecipients}
              className="w-[140]"
              variant="contained"
              color="primary"
              style={{ width: '140px' }}
              disabled={disabled}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default MobileRecipientsOrder;
