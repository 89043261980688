import { IRecipientBody, IDocumentBody } from '../envelopeHistory/types';

export interface IGetEnvelopesResponse {
  envelopes: IEnvelopeMetadata[];
  totalEnvelopeCount: number;
  endReached: boolean;
}

export interface IEnvelopeMetadata {
  id: string;
  name: string;
  subject: string;
  status: string;
  recipientCount: number;
  signerCount: number;
  completedSignerCount: number;
  updatedDate: string;
  sentDate: string;
  completedDate: string;
  createdDate: string;
  senderCanSign: boolean;
  recipients: IRecipientBody[];
  documents: IDocumentBody[];
  config?: {
    senderUi?: {
      envelopePathPrefix?: string | null;
    };
  };
}

export interface IEnvelopeManagementState {
  init: boolean;
  envelopes: IEnvelopeMetadata[];
  totalEnvelopeCount: number;
  search: string;
  status: string;
  orderBy: string;
  orderByDesc: boolean;
  envelopeRedirect?: IEnvelopeMetadata;
  endReached: boolean;
  pdfPreview?: IEnvelopeMetadata;
}

export enum EnvelopeStatus {
  DRAFT = 'draft',
  CORRECTING = 'correcting',
  SENT = 'sent',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  INPROGRESS = 'inprogress',
}

export interface ITokenBody {
  prime_user_guid: string;
}
