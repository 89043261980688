import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import { colors } from '@skyslope/mache';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ITagFilters } from '../../containers/TemplateManagement';
import { tagIcons } from '../../lib/constants';
import { useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import { ISettings } from '../../store/pageFrame/types';
import Pill from '../common/Pill';
import { StyledMenu, StyledMenuItem } from '../common/StyledMenu';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${colors.grey[400]}`,
  },
  filterText: {
    marginRight: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: colors.grey[800],
  },
  buttonIcon: {
    color: colors.grey[500],
    marginRight: theme.spacing(1),
  },
  pills: {
    display: 'flex',
  },
  menu: {
    minWidth: 120,
    maxHeight: 360,
  },
}));

interface IProps {
  tagFilters: ITagFilters;
  setTagFilters: (tagFilters: ITagFilters) => void;
}

interface IState {
  settings?: ISettings;
}
const selector = (state: IRootState) => ({
  settings: state.pageFrame.settings,
});

const TaggingFilters = (props: IProps) => {
  const { settings }: IState = useShallowSelector(selector);
  const { tagFilters } = props;
  const classes = useStyles();
  const [openTagId, setOpenTagId] = React.useState('');
  const buttonRef = React.useRef(null);

  const openMenu = (event: any, tagId: string) => {
    setOpenTagId(tagId);
    buttonRef.current = event.currentTarget;
  };

  const closeMenu = () => {
    setOpenTagId('');
    buttonRef.current = null;
  };

  const changeFilter = (tagId: string, tagValue?: string) => {
    const newFilters = { ...props.tagFilters };
    if (tagValue) {
      newFilters[tagId] = tagValue;
      closeMenu();
    } else {
      delete newFilters[tagId];
    }
    props.setTagFilters(newFilters);
  };

  const tagTypes = settings?.account.template?.tagging?.tagTypes || [];
  return (
    <div className={classes.wrapper}>
      <div className={classes.buttons}>
        <Typography variant="body1" className={classes.filterText}>
          Filter results by:
        </Typography>
        {tagTypes.map(tt => (
          <Button key={tt.id} onClick={e => openMenu(e, tt.id)}>
            <div className={classes.button} data-spec="tag-filter">
              {tt.iconName && <div className={classes.buttonIcon}>{tagIcons[tt.iconName]}</div>}
              {tt.name}
              <ArrowIcon />
            </div>
          </Button>
        ))}
      </div>
      <StyledMenu
        anchorEl={buttonRef.current}
        keepMounted
        open={Boolean(openTagId)}
        onClose={closeMenu}
        classes={{ paper: classes.menu }}
        autoFocus={false}
      >
        {openTagId
          ? tagTypes
              .find(tt => tt.id === openTagId)!
              .options.map(o => (
                <StyledMenuItem key={o} onClick={() => changeFilter(openTagId, o)}>
                  {o}
                </StyledMenuItem>
              ))
          : ''}
      </StyledMenu>
      <div className={classes.pills}>
        {Object.keys(tagFilters).map((tagId: string) => (
          <Pill
            key={tagId}
            text={tagFilters[tagId]}
            onDelete={() => changeFilter(tagId)}
            icon={tagIcons[tagTypes.find(tt => tt.id === tagId)!.iconName || '']}
          />
        ))}
      </div>
    </div>
  );
};

export default TaggingFilters;
