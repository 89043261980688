import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Label from '@material-ui/icons/Label';
import EditIcon from '@material-ui/icons/Edit';
import { colors } from '@skyslope/mache';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { IRootState } from '../../store';
import { IBlocks, ISelectedBlocks, ISigner } from '../../store/senderBuild/types';
import { SIGNER_COLORS } from '../../lib/constants';
import { IRecipient, RecipientRole } from '../../common/recipients/types';
import { isSmallScreenMediaQuery } from '../../lib/isSmallScreen';

interface IState {
  signers: ISigner[];
  recipients: IRecipient[];
  activeSignerId: string;
  blocks: IBlocks;
  selectedBlocks: ISelectedBlocks;
}

const selector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
  recipients: state.senderBuild.recipientsModal.recipients.filter((r) => r.role === RecipientRole.Signer),
  activeSignerId: state.senderBuild.activeSigner,
  blocks: state.senderBuild.blocks,
  selectedBlocks: state.senderBuild.selectedBlocks,
});

const MobileRecipientsHeader = () => {
  const { signers, recipients, activeSignerId, blocks, selectedBlocks }: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentSigners, setCurrentSigners] = React.useState<IRecipient[]>([]);
  const [currentSigner, setCurrentSigner] = React.useState<IRecipient>();
  const [currentSignerName, setCurrentSignerName] = React.useState('');
  const isSmallScreen = isSmallScreenMediaQuery();
  React.useEffect(() => {
    const cs = recipients.find((s) => s.id === (activeSignerId || currentSigner?.id));
    setCurrentSignerName(`${cs?.firstName} ${cs?.lastName}`);
    setCurrentSigners(recipients);
    setCurrentSigner(cs);
  }, [signers, recipients, activeSignerId, currentSigner, currentSignerName, selectedBlocks]);

  const handleSignerSelect = (event: React.ChangeEvent<{ value: string }>) => {
    const signer = currentSigners.find((s) => s.id === event.target.value)!;
    setCurrentSigner(signer);
    setCurrentSignerName(`${signer?.firstName} ${signer?.lastName}`);
    dispatch(actions.changeActiveSigner(signer?.id));
    if (selectedBlocks.blockIndices.length) {
      const block = blocks[selectedBlocks.documentId!][selectedBlocks.pageIndex!][selectedBlocks.blockIndices[0]];
      if (block.readOnly) return;
      dispatch(
        actions.editBlock(selectedBlocks.documentId!, selectedBlocks.pageIndex!, selectedBlocks.blockIndices[0], {
          assignedTo: signer.id,
        })
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditSigningOrderClick = () => {
    dispatch(actions.showMobileSigningOrder());
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isSmallScreen) {
    return <></>;
  }

  const mapColor = (signer: IRecipient, i: number) => {
    if (signer.role === RecipientRole.CarbonCopy) {
      return SIGNER_COLORS[SIGNER_COLORS.length - i];
    } 
      return signers.find((s) => s.signerId === signer.id)?.color;
    
  };

  return (
    <div
      className="w-full bg-white flex justify-center px-4 py-2 fixed"
      style={{ borderBottom: `2px solid ${colors.grey[400]}`, zIndex: 999 }}
    >
      <Typography className="flex justify-center items-center pr-2">Signer:</Typography>
      <FormControl variant="filled" className="pr-2 rounded">
        <Select
          value={currentSigner?.id || ''}
          onChange={(e) => handleSignerSelect(e)}
          style={{ zIndex: 9999, minWidth: '250px', borderRadius: '4px' }}
          classes={{ root: 'bg-grey-200 border-none flex', selectMenu: 'rounded' }}
          IconComponent={KeyboardArrowDownIcon}
        >
          {recipients.map((cs: IRecipient, i: number) => {
            return (
              <MenuItem key={cs.id} value={cs.id} className="flex">
                <Label style={{ color: mapColor(cs, i) }} className="pr-2" />
                {cs.firstName} {cs.lastName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <IconButton onClick={handleClick} className=" p-0 pr-4 " style={{ zIndex: 9999 }}>
        <EditIcon className="flex justify-center ml-2 h-full" />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} onClose={handleClose} keepMounted open={Boolean(anchorEl)}>
        <MenuItem onClick={handleEditSigningOrderClick}>
          <EditIcon className="pr-2" />
          Edit Signing Order
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MobileRecipientsHeader;
