import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { IEnvelopeMetadata, IGetEnvelopesResponse, ITokenBody } from '../../../store/envelopeManagement/types';
import { AutoBlocksRes, IBlock, IEnvelope, IGroup } from '../../../store/senderBuild/types';
import { API_URL, AUDIT_URL } from '../../constants';
import { updateProgressPercentageForDocument } from '../common/progressPercentage';
import makePagedGraphQLRequest, { IExtraArgs } from '../common/pagedGraphQLRequest';
import { ICreateEmailBody, IDocumentBody, IEnvelopeHistoryBody } from '../../../store/envelopeHistory/types';
import { getAccessToken } from '../../../auth/oktaConfig';
import { headers } from '../common/helper';
import Cache from './cache';
import { IRecipient } from '../../../common/recipients/types';
import { IFile } from '../../../dtm/types';
import { getFileType } from '../../utils';
import { SmartEmailConfig } from '../../../components/senderBuild/EditEmailModal';
import { STATUSES } from '@digisign3-ui/sender/src/components/envelopeManagement/utils';

const envelopeStatusCache = new Cache(4000, Date);

export interface IResponse<T> {
  status: number;
}

export interface IEnvelopeDownloadResponse<T> {
  data: any;
  status: number;
}

export const getEnvelopesForUser = async (
  searchSubject: string,
  status: string,
  orderBy: string,
  orderByDesc: boolean
): Promise<IGetEnvelopesResponse> => {
  if (window.location.search.includes('?simulateEmpty')) {
    return {
      envelopes: [],
      totalEnvelopeCount: 0,
      endReached: true,
    };
  }
  const extraArgs: IExtraArgs = {
    orderBy,
    orderByDesc,
  };
  if (searchSubject) {
    extraArgs.searchSubject = `"${searchSubject}"`;
  }
  if (status) {
    const statusValues = STATUSES[status].statuses;
    if (statusValues?.length) {
      extraArgs.statuses = `[${statusValues.join(',')}]`;
    }
  }
  const envelopeDefinition = `{
    id
    name
    subject
    status
    recipientCount
    signerCount
    completedSignerCount
    updatedDate
    sentDate
    completedDate
    createdDate
    senderCanSign
    config {
      senderUi {
        envelopePathPrefix
      }
    }
  }`;
  const accessToken = await getAccessToken();
  const res = await makePagedGraphQLRequest<IEnvelopeMetadata>(
    accessToken,
    `${API_URL}/envelopesgraphql`,
    'envelopeSearch',
    envelopeDefinition,
    20,
    extraArgs
  );

  return {
    envelopes: res.nodes.map(e => ({ ...e, ...envelopeStatusCache.getSet(e.id, e) })),
    totalEnvelopeCount: res.totalCount,
    endReached: res.endReached,
  };
};

export const getEnvelopeCounts = async () => {
  const envelopeDefinition = `{
    id
  }`;
  const accessToken = await getAccessToken();
  const totalEnvelopesResult = await makePagedGraphQLRequest<IEnvelopeMetadata>(
    accessToken,
    `${API_URL}/envelopesgraphql`,
    'envelopeSearch',
    envelopeDefinition,
    1,
    {}
  );

  const totalSentEnvelopesResult = await makePagedGraphQLRequest<IEnvelopeMetadata>(
    accessToken,
    `${API_URL}/envelopesgraphql`,
    'envelopeSearch',
    envelopeDefinition,
    1,
    { statuses: '[Completed,Sent,InProgress,Canceled,Correcting]' }
  );

  const totalCompletedEnvelopesResult = await makePagedGraphQLRequest<IEnvelopeMetadata>(
    accessToken,
    `${API_URL}/envelopesgraphql`,
    'envelopeSearch',
    envelopeDefinition,
    1,
    { statuses: '[Completed]' }
  );

  return {
    envelopesCreated: totalEnvelopesResult.totalCount,
    envelopesSent: totalSentEnvelopesResult.totalCount,
    envelopesCompleted: totalCompletedEnvelopesResult.totalCount,
  };
};

export const getEnvelopeById = async (envelopeId: string): Promise<IResponse<IEnvelope>> => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(`${API_URL}/envelopes/${envelopeId}`, headers(accessToken));
  return data;
};

export const graphQlRequest = async (query: string) => {
  const accessToken = await getAccessToken();
  return await axios.post(`${API_URL}/envelopesGraphql`, { query }, headers(accessToken));
};

export const getEnvelopeDocuments = async (envelopeId: string): Promise<IDocumentBody[]> => {
  const { data } = await graphQlRequest(
    `{
    envelope(id: "${envelopeId}") {
      documents {
        id
        numberOfPages
        fileName
        presignedURL
        pages {
          height
          width
        }
      }
    }
  }`
  );
  return data.data.envelope.documents;
};

export const getEnvelopeRecipients = async (envelopeId: string): Promise<IRecipient[]> => {
  const { data } = await graphQlRequest(
    `{
    envelope(id: "${envelopeId}") {
      recipients {
        id,
        firstName,
        lastName,
        email,
        role,
        status,
      },
    },
  }`
  );
  return data.data.envelope.recipients;
};

export const getEnvelopeHistory = async (envelopeId: string): Promise<IEnvelopeHistoryBody> => {
  const { data } = await graphQlRequest(
    `{
    envelope(id: "${envelopeId}") {
      id,
      status,
      senderId,
      senderFirstName,
      senderMiddleName,
      senderLastName,
      senderEmail,
      sentDate,
      completedDate,
      recipients {
        id,
        role,
        firstName,
        middleName,
        lastName,
        email,
        status,
        title,
        isActive,
        isPlaceholder
      },
      documents {
        id,
        numberOfPages,
        fileName,
        presignedURL,
      },
      signerDetails {
        details {
          key
          value {
            signerId
            signingGroupId
            signingStatus
          }
        }
        activeGroup,
      },
      signingGroups {
        id,
        name,
        order,
      },
    },
  }`
  );
  return data;
};

export const sendEmail = async (envelopeId: string, createEmailConfig: ICreateEmailBody): Promise<void> => {
  const accessToken = await getAccessToken();
  const { status } = await axios.post(
    `${API_URL}/envelopes/${envelopeId}/emails`,
    createEmailConfig,
    headers(accessToken)
  );
  if (status !== 201) {
    throw new Error('An error has occured in the call to sendEmail');
  }
};

export const downloadEnvelope = async (envelopeId: string): Promise<IEnvelopeDownloadResponse<null>> => {
  const accessToken = await getAccessToken();
  const response = await axios.get(`${API_URL}/envelopes/${envelopeId}/pdf?api-version=2.0`, {
    responseType: 'blob',
    ...headers(accessToken),
  });
  const { data, status } = response;
  return {
    data,
    status,
  };
};

export const getPdfBlobFromUrl = async (downloadUrl: string): Promise<ArrayBuffer> => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(downloadUrl, { responseType: 'arraybuffer', ...headers(accessToken) });
  return data;
};

export const updateEmailConfig = async (envelopeId: string, emailConfig: { subject: string; body: string }) => {
  const accessToken = await getAccessToken();
  return await axios.patch(`${API_URL}/envelopes/${envelopeId}/emailConfig`, emailConfig, headers(accessToken));
};

export const getSmartEmailConfig = async (smartConfig: SmartEmailConfig): Promise<string> => {
  const accessToken = await getAccessToken();
  const res = await axios.put(
    `${API_URL}/envelopes/${smartConfig.envelopeId}/smartEmailConfig`,
    smartConfig,
    headers(accessToken)
  );
  return res.data.result.body;
};

export const reorderDocumentsInEnvelope = async (envelopeId: string, envelopeConfig: { documentOrder: string[] }) => {
  const accessToken = await getAccessToken();
  return await axios.patch(`${API_URL}/envelopes/${envelopeId}?api-version=2.0`, envelopeConfig, headers(accessToken));
};

export const getAuditHistory = async (envelopeId: string) => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(`${AUDIT_URL}/envelopes/${envelopeId}/history`, headers(accessToken));
  return data;
};

export const uploadDocumentToEnvelope = async (envelopeId: string, documentId: string, file: File) => {
  const accessToken = await getAccessToken();
  const formData = new FormData();
  formData.append('fileName', file.name);
  formData.append('pdf', file);

  const { data, status } = await axios.post(
    `${API_URL}/envelopes/${envelopeId}/documents?api-version=2.0&enableAutoBlocks=true`,
    formData,
    {
      onUploadProgress: e => {
        updateProgressPercentageForDocument(e, documentId);
      },
      ...headers(accessToken),
    }
  );
  return { result: data, status };
};

export const sendEnvelope = async (envelopeId: string) => {
  const accessToken = await getAccessToken();
  const { status } = await axios.put(
    `${API_URL}/envelopes/${envelopeId}/status?api-version=2.0`,
    { status: 'Sent' },
    headers(accessToken)
  );
  if (status === 201) {
    const currentDate = new Date().toISOString();
    envelopeStatusCache.set(envelopeId, {
      updatedDate: currentDate,
      sentDate: currentDate,
      id: envelopeId,
      status: 'Sent',
    });
  }
  return status;
};

export const deleteDocument = async (envelopeId: string, documentId: string) => {
  const accessToken = await getAccessToken();
  const { status } = await axios.delete(
    `${API_URL}/envelopes/${envelopeId}/documents/${documentId}?api-version=2.0`,
    headers(accessToken)
  );
  return {
    result: null,
    status,
  };
};

export const createEmptyEnvelope = async (impersonatedPrimeUserGuid?: string) => {
  const accessToken = await getAccessToken();
  const d = new Date();
  d.setFullYear(d.getFullYear() + 1);
  const expiration = d.toISOString().split('T')[0];
  const claims: ITokenBody = jwt_decode(accessToken);
  const body: { expiration: string; metadata: { primeUserId?: string } } = {
    expiration,
    metadata: {},
  };
  if (impersonatedPrimeUserGuid || claims.prime_user_guid) {
    body.metadata.primeUserId = impersonatedPrimeUserGuid || claims.prime_user_guid;
  }

  const { data, status } = await axios.post(`${API_URL}/envelopes?api-version=2.0`, body, headers(accessToken));
  return {
    result: data,
    status,
  };
};

export const renameEnvelope = async (payload: { envelopeId: string, updatedName: string }) => {
  const { envelopeId, updatedName } = payload;
  const accessToken = await getAccessToken();
  const { status } = await axios.put(`${API_URL}/envelopes/${envelopeId}/name?api-version=2.0`, { name: updatedName }, headers(accessToken));
  if (status !== 200) {
    throw new Error('Failed to rename envelope.');
  }
};

export const deleteEnvelope = async (envelopeId: string): Promise<void> => {
  const accessToken = await getAccessToken();
  const { status } = await axios.delete(`${API_URL}/envelopes/${envelopeId}`, headers(accessToken));
  if (status !== 204) {
    throw new Error('');
  }
};

export const cancelEnvelope = async (envelopeId: string, cancelBody: { canceledReason: string }): Promise<void> => {
  const accessToken = await getAccessToken();
  const { status } = await axios.patch(`${API_URL}/envelopes/${envelopeId}/cancel`, cancelBody, headers(accessToken));
  if (status !== 200) {
    throw new Error('An error has occured in the call to cancelEnvelope.');
  }
};

export const correctEnvelope = async (envelopeId: string): Promise<void> => {
  const accessToken = await getAccessToken();
  const correctingStatus = 'Correcting';
  const { status } = await axios.put(
    `${API_URL}/envelopes/${envelopeId}/status?api-version=2.0`,
    { status: correctingStatus },
    headers(accessToken)
  );
  if (status !== 201) {
    throw new Error('An error has occured in the call to change the envelope status.');
  }
  envelopeStatusCache.set(envelopeId, {
    updatedDate: new Date().toISOString(),
    id: envelopeId,
    status: correctingStatus,
  });
};

const cleanBlockForAdd = (block: IBlock) => {
  const strings = ['Signature', 'Initials', 'FullName', 'DateSigned', 'Strike', 'Rectangle'];
  if (strings.includes(block.blockType)) {
    const { value, ...blockWithoutValue } = block;
    return blockWithoutValue;
  }
  if (['Checkbox', 'ReadOnlyCheckbox'].includes(block.blockType)) {
    // @ts-ignore
    return { ...block, value: block.value === true };
  }
  return { ...block, value: block.value || '' };
};

export const addBlock = async (applicationId: string, documentId: string, block: IBlock) => {
  // TODO https://skyslope.atlassian.net/browse/DIGI-1378
  const accessToken = await getAccessToken();
  const blockWithValueCleanedUp = cleanBlockForAdd(block);
  const { data, status } = await axios.post(
    `${API_URL}/envelopes/${applicationId}/documents/${documentId}/blocks?api-version=2.0`,
    blockWithValueCleanedUp,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const editBlock = async (applicationId: string, documentId: string, block: IBlock) => {
  const accessToken = await getAccessToken();
  const { blockId } = block;
  const { status } = await axios.put(
    `${API_URL}/envelopes/${applicationId}/documents/${documentId}/blocks/${blockId}?api-version=2.0`,
    block,
    headers(accessToken)
  );
  return status;
};

export const deleteBlock = async (envelopeId: string, documentId: string, blockId: string) => {
  const accessToken = await getAccessToken();
  const { status } = await axios
    .delete(
      `${API_URL}/envelopes/${envelopeId}/documents/${documentId}/blocks/${blockId}?api-version=2.0`,
      headers(accessToken)
    )
    .catch(e => {
      return e;
    });
  if (status) {
    return status;
  }
  return new Error('404');
};

export const createNewGroup = async (envelopeId: string, group: IGroup) => {
  const accessToken = await getAccessToken();
  const postBody = {
    blockGroupType: 'CheckboxGroup',
    pageNumber: group.pageNumber,
    blockIds: group.blockIds,
    validation: group.validation,
  };
  const { data, status } = await axios.post(
    `${API_URL}/envelopes/${envelopeId}/documents/${group.documentId}/blockGroups?api-version=2.0`,
    postBody,
    headers(accessToken)
  );
  return {
    result: data.result.blockGroupId,
    status,
  };
};

export const editBlockGroup = async (envelopeId: string, group: IGroup) => {
  const accessToken = await getAccessToken();
  const postBody = {
    blockGroupType: 'CheckboxGroup',
    pageNumber: group.pageNumber,
    blockIds: group.blockIds,
    validation: group.validation,
  };
  const { status } = await axios.put(
    `${API_URL}/envelopes/${envelopeId}/documents/${group.documentId}/blockGroups/${group.id}?api-version=2.0`,
    postBody,
    headers(accessToken)
  );
  return {
    status,
  };
};

export const deleteBlockGroup = async (envelopeId: string, documentId: string, groupId: string, blockId?: string) => {
  const accessToken = await getAccessToken();
  const queryParam = blockId ? `blockId=${blockId}` : '';
  const { data, status } = await axios.delete(
    `${API_URL}/envelopes/${envelopeId}/documents/${documentId}/blockGroups/${groupId}?api-version=2.0&${queryParam}`,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const createEmptyEnvelopeDTM = async (file: IFile): Promise<string> => {
  const accessToken = await getAccessToken();
  const d = new Date();
  d.setFullYear(d.getFullYear() + 1);
  const expiration = d.toISOString().split('T')[0];
  const { data } = await axios.post(
    `${API_URL}/envelopes?api-version=2.0`,
    {
      expiration,
      config: {
        senderUi: {
          envelopePathPrefix: 'dtm/',
        },
      },
      metadata: {
        primeUserId: file.agent.id,
        listingGuid: file.objectType === 'listing' ? file.id : null,
        saleGuid: file.objectType === 'listing' ? null : file.id,
      },
    },
    headers(accessToken)
  );
  return data.id;
};

export const updateEnvelopeProperty = async (envelopeId: string, file: IFile): Promise<string> => {
  const accessToken = await getAccessToken();
  const { data } = await axios.put(
    `${API_URL}/envelopes/${envelopeId}/metadata?api-version=2.0`,
    {
      primeUserId: file.agent.id,
      listingGuid: file.objectType === 'listing' ? file.id : null,
      saleGuid: file.objectType === 'listing' ? null : file.id,
    },
    headers(accessToken)
  );
  return data;
};

export const createEmptyEnvelopeWithNoProperty = async (): Promise<string> => {
  const accessToken = await getAccessToken();
  const d = new Date();
  d.setFullYear(d.getFullYear() + 1);
  const expiration = d.toISOString().split('T')[0];
  const { data } = await axios.post(
    `${API_URL}/envelopes?api-version=2.0`,
    {
      expiration,
    },
    headers(accessToken)
  );
  return data.id;
};

export const updateEnvelopeEmail = async (envelopeId: string, subject: string, body?: string) => {
  const accessToken = await getAccessToken();
  const data: { subject: string; body?: string } = { subject };
  if (typeof body === 'string') {
    data.body = body;
  }
  await axios.patch(`${API_URL}/envelopes/${envelopeId}/emailConfig`, data, headers(accessToken));
};

export const uploadPrimeDocumentToEnvelope = async (
  envelopeId: string,
  documentId: string,
  fileName: string,
  url: string
) => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.post(
    `${API_URL}/envelopes/${envelopeId}/documents?api-version=2.0`,
    { fileName, url },
    {
      onUploadProgress: e => {
        updateProgressPercentageForDocument(e, documentId);
      },
      ...headers(accessToken),
    }
  );
  return { result: data, status };
};

export const getEnvelopeDocumentsDTM = async (envelopeId: string) => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(`${API_URL}/envelopes/${envelopeId}`, headers(accessToken));
  return data.result.documents;
};

export const getEnvelopeMetadata = async (envelopeId: string): Promise<IEnvelopeMetadata> => {
  const { data } = await graphQlRequest(
    `{
      envelope(id: "${envelopeId}") {
        recipientColorCounter
        metadata {
          primeUserId,
          listingGuid,
          saleGuid,
        },
        documents {
          fileName,
          id,
          numberOfPages,
          uploadStatus
        }
      },
    }`
  );
  const { metadata, recipientColorCounter } = data.data.envelope;
  const documents = data.data.envelope.documents.map(document => {
    return { ...document, documentId: document.id, uploadStatus: document.uploadStatus };
  });
  return { ...metadata, documents, fileType: getFileType(metadata), recipientColorCounter };
};

export const setDocumentBlocks = async (envelopeId: string, documentId: string, blocks: IBlock[]) => {
  const accessToken = await getAccessToken();
  const { status } = await axios.put(
    `${API_URL}/envelopes/${envelopeId}/documents/${documentId}/blocks?api-version=2.0`,
    blocks,
    headers(accessToken)
  );
  return status;
};

export const getAutoBlocks = async (envelopeId: string): Promise<AutoBlocksRes> => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(
    `${API_URL}/envelopes/${envelopeId}/auto-blocks?api-version=2.0`,
    headers(accessToken)
  );
  return data.result;
};

export const addEnvelopeBlocks = async (envelopeId: string, blocks: IBlock[]) => {
  const accessToken = await getAccessToken();
  const { data } = await axios.post(
    `${API_URL}/envelopes/${envelopeId}/blocks?api-version=2.0`,
    { blocks },
    headers(accessToken)
  );
  return data;
};
