import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginTop: '128px',
    maxWidth: '625px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  subtext: {
    marginTop: theme.spacing(2),
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h3">Sorry, page not found.</Typography>
        <Typography variant="body2" className={classes.subtext}>
          The page you're looking for has been removed, renamed, or unavailable.
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;
