/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useRef, useEffect } from 'react';
import { colors } from '@skyslope/mache';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { calculateFontSize } from '../../lib/useAutomaticFontSizing';
import {
  checkForInvalidBlockValuesAndLogToSentry,
  determineStrikeType,
  getValueBasedOnBlockType,
} from '../../lib/utils';
import {
  IBlock,
  IBlockSizingCache,
  IPageDimensions,
  ISelectedBlocks,
  ISigner,
  IStrike,
  StrikeType,
} from '../../store/senderBuild/types';
import { createRandomId } from '../../lib/randomId';
import { BLOCK_TYPE_KEYS, BLOCK_TYPES, requiredKey, STRIKE_LINE_WIDTH } from '../../lib/constants';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import * as actions from '../../store/senderBuild/actions';
import { LaunchDarklyFlags } from '../../common/launchDarkly';
import { isMediumScreenMediaQuery } from '../../lib/isSmallScreen';
import { useUpdatedRef } from '../../lib/useUpdatedRef';

const useStyles = makeStyles((theme: Theme) => ({
  drawingLayer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  drawingCrosshair: {
    '&:hover': {
      cursor: 'crosshair',
    },
  },
}));

interface IProps {
  pageIndex: number;
  createNewBlock: Function;
  documentId: string;
  deselectBlocks: Function;
  pageBlocks: IBlock[];
  flags?: LaunchDarklyFlags;
}

interface IBlockPos {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface IState {
  id: string;
  activeBlockType: string;
  pageDimensions: IPageDimensions;
  signers: ISigner[];
  activeSigner: string;
  selectedBlocks: ISelectedBlocks;
  zoom: number;
  isTextBlockEditingEnabled: boolean;
  keepBlocksSelected: boolean;
  settingsFetched: boolean;
  autoBlockPreference: string;
  blockSizingCache: IBlockSizingCache;
  dateFormat: string;
}

const selector = (state: IRootState) => ({
  id: state.senderBuild.id,
  activeBlockType: state.senderBuild.activeBlockType,
  pageDimensions: state.senderBuild.pageDimensions,
  signers: state.senderBuild.signers,
  activeSigner: state.senderBuild.activeSigner,
  selectedBlocks: state.senderBuild.selectedBlocks,
  zoom: state.senderBuild.zoom,
  isTextBlockEditingEnabled: state.senderBuild.isTextBlockEditingEnabled,
  keepBlocksSelected: state.pageFrame.settings?.user.keepBlocksSelected,
  settingsFetched: state.pageFrame.settingsFetched,
  autoBlockPreference: state.pageFrame.settings?.user.autoStampDateTime.preference,
  blockSizingCache: state.senderBuild.blockSizingCache,
  dateFormat: state.pageFrame.settings?.user.dateFormat,
});

export const getYForStrike: (startY: number, mouseY: number) => number = (startY, mouseY) => {
  const yDelta = Math.abs(mouseY - startY);
  const y = yDelta < 10 ? startY : mouseY;
  return y;
};

const DrawingLayer = (props: IProps) => {
  const store: IState = useShallowSelector(selector);
  const classes = useStyles();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const startX = useRef<number>(NaN);
  const startY = useRef<number>(NaN);
  const mouseX = useRef<number>(NaN);
  const mouseY = useRef<number>(NaN);
  const block = useRef<IBlockPos>({ x: NaN, y: NaN, width: NaN, height: NaN });
  const strikeType = useRef<StrikeType | undefined>();
  const canvasContext = useRef<CanvasRenderingContext2D | null>(null);
  const dispatch = useDispatch();
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const isDrawingRef = useUpdatedRef(isDrawing);
  const isDragging = useRef<boolean>(false);
  const isClickToPlace = useRef<boolean>(false);
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState<boolean>(false);
  const [autoBlockPreference, setAutoBlockPreference] = useState('');
  const selectMode = !store.activeBlockType;
  const { width: pageWidth, height: pageHeight } = store.pageDimensions[props.documentId][props.pageIndex];
  const signer: ISigner = store.signers.find(s => s.signerId === store.activeSigner)!;
  const blocksAreSelected: boolean = store.selectedBlocks.blockIndices.length > 0;
  const [keepBlocksSelected, setKeepBlocksSelected] = useState(false);
  const isMdScreen = isMediumScreenMediaQuery();
  useEffect(() => {
    canvasContext.current = canvasRef.current!.getContext('2d');
    return () => {
      canvasContext.current = null;
    };
  }, []);

  useEffect(() => {
    if (isDrawing) {
      window.requestAnimationFrame(draw);
    }
  }, [isDrawing]);

  useEffect(() => {
    if (store.settingsFetched) {
      setAutoBlockPreference(store.autoBlockPreference);
      setKeepBlocksSelected(store.keepBlocksSelected);
    }
  }, [store.settingsFetched]);

  const handleDragSelect = (x: number, width: number, y: number, height: number) => {
    const selectedBlocks: number[] = [];
    props.pageBlocks.forEach((block, i) => {
      if (block.x && block.y && block.width && block.height) {
        if (
          block.x >= x &&
          block.y >= y &&
          block.x + block.width <= x + width &&
          block.y + block.height <= y + height &&
          !block.isEditingDisabled
        ) {
          selectedBlocks.push(i);
        }
      }
    });
    if (isShiftKeyPressed) {
      dispatch(
        actions.selectBlocks(
          props.documentId,
          props.pageIndex,
          store.selectedBlocks.blockIndices.concat(selectedBlocks)
        )
      );
    } else {
      dispatch(actions.selectBlocks(props.documentId, props.pageIndex, selectedBlocks));
    }
  };

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    // Wrap this logic in a timout so we don't start drawing on random clicks, they have to hold down the mouse
    // for at least a few milliseconds to start drawing.
    const { clientX, clientY } = e;
    const drawingStarter = setTimeout(startDrawingInner.bind(null, clientX, clientY, e.shiftKey), 150);
    // This will get overwritten by startDrawingInner if there is no immediate mouseup event
    window.onmouseup = () => {
      clearInterval(drawingStarter);
      if (store.activeBlockType && !isShiftKeyPressed) {
        createBlockWithoutDrawing(clientX, clientY, e.shiftKey);
      } else {
        stopDrawing(e.shiftKey);
      }
    };
  };

  const createBlockWithoutDrawing = (clientX: number, clientY: number, shift: any) => {
    isClickToPlace.current = true;
    const blockType = BLOCK_TYPES.find(bt => bt.key === store.activeBlockType);
    const canvas = canvasRef.current;

    prepCanvas();
    const bounds = canvas!.getBoundingClientRect();
    const x = clientX - bounds.left;
    const y = clientY - bounds.top;
    startX.current = x;
    startY.current = y;
    let height;
    let width;
    if (
      store.blockSizingCache[blockType!.key] &&
      ![BLOCK_TYPE_KEYS.STRIKE, BLOCK_TYPE_KEYS.RECTANGLE].includes(blockType!.key)
    ) {
      height = store.blockSizingCache[blockType!.key].height;
      width = store.blockSizingCache[blockType!.key].width;
    } else {
      height = blockType!.size!.default.height;
      width = blockType!.size!.default.width;
    }
    if (x + width * store.zoom >= pageWidth * store.zoom) {
      mouseX.current = x - width * store.zoom;
    } else {
      mouseX.current = x + width * store.zoom;
    }
    if (y - height * store.zoom < 0) {
      mouseY.current = y + height * store.zoom;
    } else {
      mouseY.current = y - height * store.zoom;
    }
    draw();
    stopDrawing(shift);
  };

  const startDrawingInner = (clientX: number, clientY: number, shiftKey: boolean) => {
    window.onmouseup = stopDrawing;
    prepCanvas();
    const canvas = canvasRef.current;
    const bounds = canvas!.getBoundingClientRect();
    const x = clientX - bounds.left;
    const y = clientY - bounds.top;
    startX.current = x;
    startY.current = y;
    mouseX.current = x;
    mouseY.current = y;
    if (shiftKey) {
      setIsShiftKeyPressed(true);
    }
    setIsDrawing(true);
  };

  const prepCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      if (canvas!.width !== canvas!.clientWidth || canvas!.height !== canvas!.clientHeight) {
        canvas!.width = canvas!.clientWidth;
        canvas!.height = canvas!.clientHeight;
      }
    }
  };

  const updateMousePosition = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    const bounds = canvas!.getBoundingClientRect();
    mouseX.current = e.clientX - bounds.left;
    mouseY.current = e.clientY - bounds.top;
  };

  const stopDrawing = (shiftKey?: any) => {
    const isShiftKeyActive = shiftKey && shiftKey.shiftKey;
    if (!selectMode && !isShiftKeyActive) {
      submitBlock();
    } else {
      const zoomX = block.current.x / store.zoom;
      const zoomY = block.current.y / store.zoom;
      const zoomWidth = block.current.width / store.zoom;
      const zoomHeight = block.current.height / store.zoom;
      if (store.activeBlockType) {
        checkForInvalidBlockValuesAndLogToSentry(
          store.id,
          zoomX,
          zoomY,
          'Block had x/y null values in stopDrawing',
          true
        );
        checkForInvalidBlockValuesAndLogToSentry(
          store.id,
          zoomWidth,
          zoomHeight,
          'Block had height/width null values in stopDrawing',
          false
        );
      }
      handleDragSelect(zoomX, zoomWidth, zoomY, zoomHeight);
      reset();
    }
    window.onmouseup = null;
    setIsShiftKeyPressed(false);
    if (!keepBlocksSelected) {
      dispatch(actions.changeActiveBlockType(''));
    }
    if (isMdScreen) {
      dispatch(actions.changeActiveBlockType(''));
      dispatch(actions.selectBlocks(props.documentId, props.pageIndex, []));
    }
  };
  const assignIfNotReadOnly = (blockType: any) => {
    return !blockType?.options?.readonly;
  };

  const drawLine = (ctx: CanvasRenderingContext2D, style: any = {}) => {
    const { color = '#000', lineWidth = STRIKE_LINE_WIDTH } = style;
    ctx.beginPath();
    const y = getYForStrike(startY.current, mouseY.current);
    ctx.moveTo(startX.current, startY.current);
    ctx.lineTo(mouseX.current, y);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
  };

  // This handles the actual canvas updates
  const draw = () => {
    if (!canvasRef.current) return;

    const blockType = BLOCK_TYPES.find(bt => bt.key === store.activeBlockType);
    const context = canvasContext.current!;
    // Clear the canvas
    context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    context.setLineDash([]);
    context.lineWidth = 0.5;
    buildOriginAndSize();
    if (selectMode || isShiftKeyPressed) {
      context.fillStyle = colors.blue[100];
      context.strokeStyle = colors.blue[100];
      context.lineWidth = 3;
      // Draw dashed border for select box
      context.setLineDash([5, 3]);
    } else if (blockType?.name === BLOCK_TYPE_KEYS.STRIKE && !isClickToPlace.current) {
      drawLine(context);
    } else {
      context.fillStyle =
        blockType!.options!.assignRequired && signer && assignIfNotReadOnly(blockType) ? signer.color! : '#000000';
      context.strokeStyle =
        blockType!.options!.assignRequired && signer && assignIfNotReadOnly(blockType) ? signer.color! : '#000000';
    }

    if (blockType?.name !== BLOCK_TYPE_KEYS.STRIKE) {
      context.globalAlpha = 0.2;
      context.fillRect(block.current.x, block.current.y, block.current.width, block.current.height);
      context.globalAlpha = 1;
      context.strokeRect(block.current.x, block.current.y, block.current.width, block.current.height);
    }

    if (isDrawingRef.current) {
      window.requestAnimationFrame(draw);
    }
  };

  // Based on the starting x/y, and the current mouse x/y, we determine the top left and bottom right
  // points of the rectangle. The top left will serve as the origin, and the bottom right will let us
  // calculate the width and height of the rectangle.
  const buildOriginAndSize = () => {
    const blockType = BLOCK_TYPES.find(bt => bt.key === store.activeBlockType);

    let topLeftX: number;
    let topLeftY: number;
    let bottomRightX: number;
    let bottomRightY: number;

    if (startX.current <= mouseX.current) {
      topLeftX = startX.current;
      bottomRightX = mouseX.current;
    } else {
      topLeftX = mouseX.current;
      bottomRightX = startX.current;
    }
    if (startY.current <= mouseY.current) {
      topLeftY = startY.current!;
      bottomRightY = mouseY.current;
    } else {
      topLeftY = mouseY.current;
      bottomRightY = startY.current;
    }

    const blockWidth = bottomRightX - topLeftX;
    const blockHeight = bottomRightY - topLeftY;
    const isInStartingPosition = mouseX.current === startX.current && mouseY.current === startY.current;
    isDragging.current = !isInStartingPosition;

    // We enter this if block if the user is drawing a block
    if (blockType?.size && isDrawing && !isShiftKeyPressed) {
      const defaultWidth = blockType.size.default.width * store.zoom;
      const defaultHeight = blockType.size.default.height * store.zoom;
      const maxWidth = blockType.size.max.width * store.zoom;
      const maxHeight = blockType.size.max.height * store.zoom;

      if (blockWidth <= maxWidth) {
        // If drawing to the left, ensure x does not go below zero
        block.current.x = topLeftX < 0 ? 1 : topLeftX;
        block.current.width = blockWidth;
      } else {
        // If block width exceeds max width, set the width to the max width
        // This block indicates that the user is dragging to the left
        if (startX.current > mouseX.current) {
          block.current.x = startX.current! - maxWidth;
        } else if (startX.current < mouseX.current) {
          // This block indicates that the user is dragging to the right
          block.current.x = startX.current;
        }
        block.current.width = Number.isNaN(blockWidth) ? defaultWidth : maxWidth;
      }

      if (blockHeight <= maxHeight) {
        // If drawing towards the top, ensure it does not go below zero
        block.current.y = topLeftY < 0 ? 1 : topLeftY;
        block.current.height = blockHeight;
      } else {
        // If block height exceeds max height, set the height to the max height
        // This block indicates that the user is dragging up
        if (startY.current > mouseY.current) {
          block.current.y = startY.current - maxHeight;
        } else {
          // This block indicates that the user is dragging down
          block.current.y = startY.current;
        }
        block.current.height = Number.isNaN(blockHeight) ? defaultHeight : maxHeight;
      }
    } else {
      block.current.x = topLeftX;
      block.current.width = blockWidth;

      block.current.y = topLeftY;
      block.current.height = blockHeight;
    }

    if (blockType?.key === BLOCK_TYPE_KEYS.STRIKE) {
      const y = getYForStrike(startY.current, mouseY.current);
      strikeType.current = determineStrikeType(y, startY.current, startX.current, mouseY.current, mouseX.current);
    }
  };

  const reset = () => {
    const context = canvasContext.current!;
    if (canvasRef.current) {
      context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    }
    startX.current = NaN;
    startY.current = NaN;
    mouseX.current = NaN;
    mouseY.current = NaN;
    block.current = { x: NaN, y: NaN, width: NaN, height: NaN };
    strikeType.current = undefined;
    isClickToPlace.current = false;
    isDragging.current = false;
    setIsDrawing(false);
  };

  const getAutoPlacedBlock = (newBlock: IBlock, autoPlacedBlockTypeKey: string) => {
    const autoPlacedBlockType = BLOCK_TYPES.find(bt => bt.key === autoPlacedBlockTypeKey);
    const autoPlacedBlockWidth = autoPlacedBlockType?.size?.default.width;
    const autoPlacedBlockHeight = autoPlacedBlockType?.size?.default.height;
    let autoPlacedBlockX = newBlock.x + newBlock.width + 4;
    let autoPlacedBlockY = newBlock.y + newBlock.height - autoPlacedBlockHeight;
    if (autoPlacedBlockX + autoPlacedBlockWidth >= pageWidth) {
      autoPlacedBlockX -= autoPlacedBlockWidth + 4;
      newBlock.x -= autoPlacedBlockWidth + 4;
    }
    if (autoPlacedBlockY + autoPlacedBlockHeight >= pageHeight) {
      autoPlacedBlockY -= autoPlacedBlockHeight;
      newBlock.y -= autoPlacedBlockHeight;
    } else if (autoPlacedBlockY - autoPlacedBlockHeight < 0) {
      autoPlacedBlockY += autoPlacedBlockHeight;
      newBlock.y += autoPlacedBlockHeight;
    }

    const fontSize = autoPlacedBlockType?.key === BLOCK_TYPE_KEYS.TIME ? 8 : 12;

    return {
      blockId: createRandomId(),
      pageNumber: props.pageIndex,
      blockType: autoPlacedBlockTypeKey,
      x: autoPlacedBlockX,
      y: autoPlacedBlockY,
      width: autoPlacedBlockWidth,
      height: autoPlacedBlockHeight,
      required:
        (autoPlacedBlockType!.properties || []).includes(requiredKey) &&
        !autoPlacedBlockType!.options!.optionalByDefault,
      assignedTo:
        autoPlacedBlockType!.options!.assignRequired && assignIfNotReadOnly(autoPlacedBlockType)
          ? signer.signerId
          : null,
      isEditingDisabled: false,
      fontSize,
    };
  };

  const createNewBlock = (x: number, y: number, blockHeight: number, blockWidth: number, strikeType?: StrikeType) => {
    const currentBlockType = BLOCK_TYPES.find(bt => bt.key === store.activeBlockType);
    const newBlock: IBlock | IStrike = {
      blockId: createRandomId(),
      pageNumber: props.pageIndex,
      blockType: store.activeBlockType,
      x,
      y,
      width: blockWidth,
      height: blockHeight,
      required:
        (currentBlockType!.properties || []).includes(requiredKey) && !currentBlockType!.options!.optionalByDefault,
      assignedTo:
        currentBlockType!.options!.assignRequired && assignIfNotReadOnly(currentBlockType) ? signer.signerId : null,
      isEditingDisabled: false,
    };

    if (currentBlockType?.options?.readonly !== undefined) {
      newBlock.readOnly = currentBlockType?.options?.readonly;
    }

    if (currentBlockType?.key === BLOCK_TYPE_KEYS.STRIKE && strikeType) {
      (newBlock as IStrike).strikeType = strikeType;
    }

    return newBlock;
  };

  const submitBlock = () => {
    const currentBlockType = BLOCK_TYPES.find(bt => bt.key === store.activeBlockType);
    let blockWidth = block.current.width / store.zoom;
    let blockHeight = block.current.height / store.zoom;
    let blockX = block.current.x / store.zoom;
    let blockY = block.current.y / store.zoom;
    if (currentBlockType?.key === BLOCK_TYPE_KEYS.CHECKBOX && blockWidth === 0 && blockHeight === 0) {
      blockHeight = currentBlockType!.size!.default.height;
      blockWidth = currentBlockType!.size!.default.width;
    } else {
      // Adjust the block width and height if they are 0 or less than the min width and height
      if (blockWidth === 0 && !isDragging.current) {
        blockWidth = store.blockSizingCache[currentBlockType?.key]
          ? store.blockSizingCache[currentBlockType?.key].width
          : currentBlockType!.size!.default!.width;
      } else if (blockWidth < currentBlockType!.size!.min!.width || isNaN(blockWidth)) {
        // Only set the width to the one in cache when there is value, so the width is more similar to the one before error
        blockWidth =
          isNaN(blockWidth) && store.blockSizingCache[currentBlockType?.key]
            ? store.blockSizingCache[currentBlockType?.key].width
            : currentBlockType!.size!.min!.width;
      }
      if (blockHeight === 0 && !isDragging.current) {
        const originalHeight = blockHeight;
        blockHeight = store.blockSizingCache[currentBlockType?.key]
          ? store.blockSizingCache[currentBlockType?.key].height
          : currentBlockType!.size!.default!.height;
        blockY = blockY + originalHeight - blockHeight;
      } else if (blockHeight < currentBlockType!.size!.min!.height || isNaN(blockHeight)) {
        const originalHeight = blockHeight;
        // Only set the height to the one in cache when there is value, so the width is more similar to the one before error
        blockHeight =
          isNaN(blockHeight) && store.blockSizingCache[currentBlockType?.key]
            ? store.blockSizingCache[currentBlockType?.key].height
            : currentBlockType!.size!.min!.height;
        blockY = blockY + originalHeight - blockHeight;
      }
    }
    // Verify that the block is within the page dimensions
    if (blockX + blockWidth >= pageWidth) {
      blockX = pageWidth - blockWidth;
    }
    if (blockY + blockHeight >= pageHeight) {
      blockY = pageHeight - blockHeight;
    }

    if (store.activeBlockType) {
      checkForInvalidBlockValuesAndLogToSentry(
        store.id,
        blockX,
        blockY,
        'Block had x/y null values in submitBlock',
        true,
        { startX: startX.current, startY: startY.current, mouseX: mouseX.current, mouseY: mouseY.current }
      );
      checkForInvalidBlockValuesAndLogToSentry(
        store.id,
        blockWidth,
        blockHeight,
        'Block had height/width null values in submitBlock',
        false,
        { startX: startX.current, startY: startY.current, mouseX: mouseX.current, mouseY: mouseY.current }
      );
    }

    const invalidNum = (num: number) => isNaN(num) || num === null || num < 0;
    // If block has invalid values, set them to default values
    if (invalidNum(blockX) || invalidNum(blockY)) {
      blockX = mouseX.current;
      blockY = mouseY.current;
    }
    if (invalidNum(blockWidth)) blockWidth = currentBlockType!.size!.min!.width;
    if (invalidNum(blockHeight)) blockHeight = currentBlockType!.size!.min!.height;

    const rightX = blockX + blockWidth;
    // If block is out of page width
    if (rightX > pageWidth) {
      // reduce width if at least the min width
      // else set width to min width and move x to the left
      const newWidth = pageWidth - blockX;
      blockWidth = Math.max(newWidth, currentBlockType!.size!.min!.width);
      blockX -= blockWidth - newWidth;
    }

    const bottomY = blockY + blockHeight;
    // If block is out of page height
    if (bottomY > pageHeight) {
      // reduce height if at least the min height
      // else set height to min height and move y to the top
      const newHeight = pageHeight - blockY;
      blockHeight = Math.max(newHeight, currentBlockType!.size!.min!.height);
      blockY -= blockHeight - newHeight;
    }
    let autoPlacedBlock: IBlock;
    // Add attributes to the block to be created
    const newBlock = createNewBlock(blockX, blockY, blockHeight, blockWidth, strikeType.current);

    if (![BLOCK_TYPE_KEYS.STRIKE, BLOCK_TYPE_KEYS.RECTANGLE].includes(store.activeBlockType)) {
      const value = getValueBasedOnBlockType(store.activeBlockType, signer, store.dateFormat);
      newBlock.fontSize = calculateFontSize(value as string, 22, blockHeight, blockWidth, 1);
      if (newBlock.fontSize >= newBlock.height) {
        newBlock.fontSize = newBlock.height - 1;
      }
    }
    if (store.activeBlockType === BLOCK_TYPE_KEYS.TEXT) {
      newBlock.value = '';
      newBlock.isCreating = true;
    }
    if (store.activeBlockType === BLOCK_TYPE_KEYS.SIGNATURE) {
      if (autoBlockPreference && autoBlockPreference !== 'None') {
        if (autoBlockPreference === 'Date') autoPlacedBlock = getAutoPlacedBlock(newBlock, BLOCK_TYPE_KEYS.DATE);
        else if (autoBlockPreference === 'DateTime')
          autoPlacedBlock = getAutoPlacedBlock(newBlock, BLOCK_TYPE_KEYS.TIME);
      }
    }
    dispatch(actions.setMouseCoords(mouseX.current, mouseY.current));
    autoPlacedBlock && props.createNewBlock(autoPlacedBlock);
    props.createNewBlock(newBlock);
    reset();
  };

  const onMouseDown = (e: any) => {
    startDrawing(e);
  };

  return (
    <canvas
      ref={canvasRef}
      className={`${classes.drawingLayer} ${!blocksAreSelected && classes.drawingCrosshair}`}
      style={{
        zIndex: isDrawing ? 10 : 2,
      }}
      onMouseDown={onMouseDown}
      onMouseMove={isDrawing ? updateMousePosition : undefined}
    />
  );
};

export default DrawingLayer;
