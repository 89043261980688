import { IContact, IRecipient } from '../../common/recipients/types';
import { ResourceNames } from '../../lib/constants';

export interface ISigner {
  signerId: string;
  email: string;
  firstName: string;
  lastName?: string;
  signingStatus: string;
  color?: string;
  signingGroup?: string;
  role?: string;
  title?: string;
  recipientColorCounter: number;
}

export interface ICarbonCopyRecipient {
  id: string;
  envelopeId: string;
  role: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  color: string;
}

export interface IBlock {
  blockId?: string;
  pageNumber: number;
  blockType: string;
  value?: string | boolean;
  width?: number;
  height?: number;
  required?: boolean;
  x?: number;
  y?: number;
  assignedTo?: string | null;
  readOnly?: boolean;
  fontSize?: number;
  groupId?: string;
  isEditingDisabled: boolean;
  isCreating?: boolean;
  color?: string;
  bgColor?: string;
  opacity?: number;
  strikeType?: string;
  previouslyAssignedTo?: string;
  documentId: string;
}

export enum StrikeType {
  Up = 'UP', // this represents a diagonal line going from bottom left to top right, /
  Down = 'DOWN', // this represents a diagonal line going from top left to bottom right, \
  /* 
    strikeType value of `null` represents a horizontal line
  */
}

export interface IStrike extends IBlock {
  strikeType: StrikeType;
}

export interface IDocument {
  fileName: string;
  url?: string;
  documentId: string;
  numberOfPages?: number;
  blocks?: IBlock[];
  blockGroups?: IGroup[];
  isUploading?: boolean;
  isDownloaded: boolean;
  uploadStatus: DocumentStatus;
  presignedURL?: string;
  // Template Document Properties
  order?: number;
  links?: { downloadPDF?: { href?: string } };
}

export enum DocumentStatus {
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Failed = 'Failed',
}

export interface IConfigs {
  senderUi?: {
    sendRedirectUrl?: string;
    envelopePathPrefix?: string;
    isBackButtonVisible: boolean;
    infoBox?: {
      title?: string;
      body?: string;
    };
  };
  signerUi?: {
    eSignAgreementUrl?: string;
  };
  areLegalLinksVisible: boolean;
}

export interface IEmailConfig {
  subject: string | null;
  body: string | null;
  loading: boolean;
}

export interface IEnvelope {
  envelopeId: string;
  emailConfig: IEmailConfig;
  documents: IDocument[];
  expiration: Date;
  carbonCopy: ICarbonCopyRecipient[];
  signers: ISigner[];
  status: string;
  signingGroups?: ISigningGroup[];
  config?: IConfigs;
  recipientColorCounter?: number;
}

export interface ICopyCache {
  blocks: IBlock[];
  pasteCounter: number;
}

export interface IBlocks {
  [documentId: string]: IBlocksByDocument;
}
export interface IBlocksByDocument {
  [pageIndex: number]: IBlock[];
}

export interface ISelectedBlocks {
  documentId?: string;
  pageIndex?: number;
  blockIndices: number[];
  blockTypes?: string[];
  x?: number;
  y?: number;
}

export interface ICopiedBlocks {
  blocks?: IBlock[];
  startX?: number;
  startY?: number;
}

export interface IGroup {
  blockGroupType: string;
  id: string;
  documentId: string;
  envelopeId: string;
  pageNumber: number;
  blockIds: string[];
  validation: {
    rule: 'gte' | 'lte' | 'eq' | 'bt';
    value: number;
    valueB?: number;
  };
}
export interface ISigningGroup {
  name: string;
  order: number;
  id: string;
  links?: any[];
}

export interface IPageDimensions {
  [documentId: string]: {
    [pageIndex: number]: {
      height: number;
      width: number;
    };
  };
}

export interface IGroups {
  [documentId: string]: {
    [pageNumber: number]: {
      [groupId: string]: IGroup;
    };
  };
}

export interface IBlockSizingCache {
  [blockType: string]: {
    height: number;
    width: number;
  };
}

export interface ISenderBuildState {
  resource?: ResourceNames;
  id?: string;
  documents: IDocument[];
  zoom: number;
  blocks: IBlocks;
  groups: IGroups;
  activeBlockType: string;
  activeSigner: string;
  selectedBlocks: ISelectedBlocks;
  copiedBlocks: ICopiedBlocks;
  saving: boolean;
  done: boolean;
  isEditingField: boolean;
  signers: ISigner[];
  carbonCopy: ICarbonCopyRecipient[];
  signingGroups: ISigningGroup[];
  pageDimensions: IPageDimensions;
  emailConfig: IEmailConfig;
  fetchError: boolean;
  config: any;
  isAutoSaving: undefined | boolean;
  copyCache: ICopyCache;
  inputFocused: boolean;
  currentPage: number | undefined;
  currentDocument: string | undefined;
  recipientsModal: IRecipients;
  recipientsFetched: boolean;
  lastPlacedBlock: string;
  isTextBlockEditingEnabled: boolean;
  status: string;
  signerToken: string;
  isDeletingBlocks?: boolean;
  isCreatingBlock?: boolean;
  mobileSigningOrderOpen: boolean;
  blockSizingCache: IBlockSizingCache;
  readyToSendModalOpen: boolean;
  recipientColorCounter?: number;
  mouseCoords?: { x: number | null; y: number | null };
  blockMenuPos: { x: number | null; y: number | null };
  autoBlocksLoading: boolean;
}

export interface IFetchDone {
  documents: IDocument[];
  blocks: IBlocks;
  signers: ISigner[];
  carbonCopy: ICarbonCopyRecipient[];
  signingGroups: ISigningGroup[];
  groups: IGroups;
  emailConfig: IEmailConfig;
  config: IConfigs;
}

export interface IRecipients {
  recipients: IRecipient[];
  signingGroups: ISigningGroup[];
  loading: boolean;
  contacts: IContact[];
  saveSuccess?: boolean;
}

export interface ITemplateQueryParams {
  state: string;
  role: string;
  flashCodes: string[];
}

export enum FormsBackPage {
  Envelopes = 'envelopes',
  Fill = 'fill',
}

export type AutoBlock = IBlock & {
  transactionRole?: string;
};

export type AutoBlocksRes = {
  transactionRoles: string[];
  documents: Array<{
    documentId: string;
    status: 'pending' | 'done' | 'error';
    blocks: AutoBlock[];
  }>;
};
