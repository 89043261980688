import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setLoading, errorToast } from '../pageFrame/actions';
import * as actions from './actions';
import { IGetEnvelopesResponse } from './types';
import { envelopeApi } from '../../lib/api/envelope';

const ActionTypes = actions.ActionTypes;

export function* handleFetchEnvelopes(action: ReturnType<typeof actions.fetchEnvelopes>) {
  try {
    const { search, status, orderBy, orderByDesc } = action.payload;
    const res: IGetEnvelopesResponse = yield call(
      envelopeApi.getEnvelopesForUser,
      search,
      status,
      orderBy,
      orderByDesc
    );
    const { envelopes, totalEnvelopeCount, endReached } = res;
    yield put(
      actions.fetchEnvelopesDone(search, status, orderBy, orderByDesc, envelopes, totalEnvelopeCount, endReached)
    );
    yield put(setLoading(false)); // sometimes we set loading from the page depending on when we're fetching
  } catch (err) {
    console.error(err);
    yield put(errorToast('Failed to fetch envelopes, please refresh and try again!'));
  }
}

export function* handleCreateNewEnvelope(action: ReturnType<typeof actions.createNewEnvelope>) {
  try {
    yield put(setLoading(true));
    const { result } = yield call(envelopeApi.createEmptyEnvelope, action.payload);
    yield put(actions.createNewEnvelopeDone(result));
  } catch (err) {
    yield put(errorToast('Failed to create a new envelope, please try again'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleRenameEnvelope(action: ReturnType<typeof actions.renameEnvelope>) {
  try {
    yield put(setLoading(true));
    yield call(envelopeApi.renameEnvelope, action.payload);
    yield put(actions.renameEnvelopeDone(action.payload.envelopeId, action.payload.updatedName));
  } catch (err) {
    yield put(errorToast('Failed to rename envelope, please try again'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleDeleteEnvelope(action: ReturnType<typeof actions.deleteEnvelope>) {
  try {
    yield call(envelopeApi.deleteEnvelope, action.payload);
    yield put(actions.envelopeDeleted(action.payload));
  } catch (err) {
    yield put(errorToast('Failed to delete envelope'));
  }
}

export function* handleCancelEnvelope(action: ReturnType<typeof actions.cancelEnvelope>) {
  try {
    yield put(setLoading(true));
    yield call(envelopeApi.cancelEnvelope, action.payload.envelopeId, action.payload.cancelBody);
    yield put(actions.envelopeCanceled(action.payload.envelopeId));
  } catch (err) {
    yield put(errorToast('Failed to cancel envelope, please try again'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleCorrectEnvelope(action: ReturnType<typeof actions.correctEnvelope>) {
  try {
    yield call(envelopeApi.correctEnvelope, action.payload);
    yield put(actions.correctEnvelopeDone(action.payload));
  } catch (err) {
    yield put(errorToast('Failed to change envelope status to correcting'));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH_ENVELOPES, handleFetchEnvelopes),
    takeEvery(ActionTypes.CREATE_NEW_ENVELOPE, handleCreateNewEnvelope),
    takeEvery(ActionTypes.DELETE_ENVELOPE, handleDeleteEnvelope),
    takeEvery(ActionTypes.CANCEL_ENVELOPE, handleCancelEnvelope),
    takeEvery(ActionTypes.CORRECT_ENVELOPE, handleCorrectEnvelope),
    takeEvery(ActionTypes.RENAME_ENVELOPE, handleRenameEnvelope),
  ]);
}
