import axios from 'axios';
import { headers } from './helper';

export interface IExtraArgs {
  [argName: string]: any;
}

export interface IPagedResponse<T> {
  totalCount: number;
  nodes: T[];
  endReached: boolean;
}

interface IPageInfo {
  lastCursor: string | null;
  previousArgs: IExtraArgs;
}

let pageInfo: { [queryName: string]: IPageInfo } = {};

export const resetPageInfo = () => {
  pageInfo = {};
};

const makePagedGraphQLRequest = async <T>(
  accessToken: string,
  url: string,
  queryName: string,
  nodeDefinition: string,
  pageSize = 20,
  extraArgs: IExtraArgs = {}
): Promise<IPagedResponse<T>> => {
  if (!(queryName in pageInfo)) {
    pageInfo[queryName] = {
      lastCursor: null,
      previousArgs: {},
    };
  }
  const queryPageInfo = pageInfo[queryName];

  if (!shallowEquals(queryPageInfo.previousArgs, extraArgs)) {
    queryPageInfo.lastCursor = null;
  }
  queryPageInfo.previousArgs = extraArgs;

  const queryArgs = [];
  queryArgs.push(`first: ${pageSize}`);
  if (queryPageInfo.lastCursor) {
    queryArgs.push(`cursor: "${queryPageInfo.lastCursor}"`);
  }
  for (const argKey in extraArgs) {
    if (typeof extraArgs[argKey] !== 'undefined') {
      queryArgs.push(`${argKey}: ${extraArgs[argKey]}`);
    }
  }
  const query = `{
    ${queryName}(${queryArgs.join(', ')}) {
      edges {
        node ${nodeDefinition} 
        cursor
      }
      totalCount
      pageInfo {
        lastCursor
      }
    }
  }`;

  const { data } = await axios.post(url, { query }, headers(accessToken));
  const res = data.data[queryName];

  queryPageInfo.lastCursor = res.pageInfo.lastCursor;
  return {
    totalCount: res.totalCount,
    nodes: res.edges.map((edge: any) => edge.node),
    endReached: !res.pageInfo.lastCursor,
  };
};

const shallowEquals = (obj1: object, obj2: object) =>
  Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(key => obj1[key] === obj2[key]);

export default makePagedGraphQLRequest;
