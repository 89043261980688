import { BLOCK_PROPERTIES, requiredKey, readOnlyKey, BLOCK_TYPE_KEYS } from '../../../lib/constants';
import { Typography } from '@material-ui/core';
import Checkbox from '../../common/Checkbox';
import React from 'react';
import { IBlock } from '../../../store/senderBuild/types';

interface IProps {
  changeProperty: (key: string, flipValue: boolean) => void;
  blockTypeProperties: string[];
  firstBlock?: IBlock;
  isBlockToolbar?: boolean;
  selectedBlocks?: IBlock[];
}

const BlockProperties = (props: IProps) => {
  let someBlocksHavePropertyApplied = false;
  let allBlocksHaveEqualPropertyValue = false;

  const getPropertyCheckedValue = (blockProperty: any) => {
    if (props.selectedBlocks?.length) {
      const firstBlockProperty = props.selectedBlocks![0][blockProperty.key];
      allBlocksHaveEqualPropertyValue = props.selectedBlocks.every(
        (block) => block[blockProperty.key] === firstBlockProperty
      );
      someBlocksHavePropertyApplied = props.selectedBlocks.some((block) => block[blockProperty.key]);

      return blockProperty.flipValue && allBlocksHaveEqualPropertyValue
        ? !someBlocksHavePropertyApplied
        : someBlocksHavePropertyApplied;
    } else {
      return blockProperty.flipValue ? !props.firstBlock![blockProperty.key] : props.firstBlock![blockProperty.key];
    }
  };

  return (
    <section
      style={
        props.isBlockToolbar
          ? {
              justifyContent: props.blockTypeProperties!.length > 1 ? 'space-around' : 'flex-start',
              marginLeft: props.blockTypeProperties!.length === 1 ? '14px' : 'none',
            }
          : {}
      }
      className={props.isBlockToolbar ? 'flex pt-1.5 px-4 pb-4' : 'py-4 px-7'}
      id="propertiesSection"
    >
      {!props.isBlockToolbar && <Typography variant="body2">Properties</Typography>}
      {props.blockTypeProperties!.map((property) => {
        const blockProperty = BLOCK_PROPERTIES[property];

        return (
          <Checkbox
            isBlockToolbarCheckbox={props.isBlockToolbar}
            key={blockProperty.key}
            className={props.isBlockToolbar ? 'text-blue-800 my-2 mx-0' : 'text-blue-800 w-full my-3 mx-0'}
            label={props.isBlockToolbar && blockProperty.key === requiredKey ? 'Optional' : blockProperty.label}
            checked={getPropertyCheckedValue(blockProperty)}
            intermediate={
              props.selectedBlocks?.length! > 1 && someBlocksHavePropertyApplied && !allBlocksHaveEqualPropertyValue
            }
            onClick={(checked: boolean) =>
              props.changeProperty(blockProperty.key, blockProperty.flipValue ? !checked : checked)
            }
            disabled={
              (props.firstBlock?.readOnly && blockProperty.key === requiredKey) ||
              (blockProperty.key === readOnlyKey &&
                (!!props.firstBlock?.groupId || !!props.selectedBlocks?.[0].groupId)) ||
              (props.selectedBlocks?.every((block) => block.readOnly && block.blockType === BLOCK_TYPE_KEYS.TEXT) &&
                blockProperty.key === requiredKey)
            }
            data-spec={'toggle-optional'}
          />
        );
      })}
    </section>
  );
};

export default BlockProperties;
