import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { MAX_ZOOM, MIN_ZOOM } from '../../lib/constants';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { colors } from '@skyslope/mache';

const useStyles = makeStyles({
  zoomPercent: (props: IProps) => ({
    color: props.darkMode ? '#FFFFFF' : colors.grey[800],
    margin: '0 7px 0 10px',
  }),
  zoomWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  zoomBtn: (props: IProps) => ({
    display: 'flex',
    height: '24px',
    width: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${colors.grey[400]}`,
    borderRadius: '20px',
    padding: 0,
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:disabled': {
      opacity: 0.4,
    },
    '&:hover, &:focus': {
      backgroundColor: props.darkMode ? 'rgba(255,255,255,0.15)' : undefined,
    },
  }),
  zoomIcon: (props: IProps) => ({
    fontSize: '20px',
    color: props.darkMode ? '#FFFFFF' : colors.grey[800],
  }),
});

interface IProps {
  zoom: number;
  zoomIn: () => void;
  zoomOut: () => void;
  darkMode?: boolean;
}

const ZoomButtons = (props: IProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.zoomWrapper} id="zoomControls">
      <button className={classes.zoomBtn} disabled={props.zoom <= MIN_ZOOM} onClick={props.zoomOut} id="zoomOut">
        <RemoveIcon className={classes.zoomIcon} />
      </button>
      <span className={classes.zoomPercent}>{`${props.zoom * 100}%`}</span>
      <button className={classes.zoomBtn} disabled={props.zoom >= MAX_ZOOM} onClick={props.zoomIn} id="zoomIn">
        <AddIcon className={classes.zoomIcon} />
      </button>
    </div>
  );
};

export default ZoomButtons;
