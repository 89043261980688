import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { ISettings, IUserSettings, IUserTrial } from '../../../store/pageFrame/types';
import { IGroup, IDocument } from '../../../store/senderBuild/types';
import { ITemplateRecipient, ITemplate, ITemplateTag, IStripeCheckout, ICheckoutBody } from '../../../store/templateCreate/types';
import { ITemplateMetadata } from '../../../store/templateManagement/types';
import {
  ITemplateAppliedFromDynamoDB,
  ITemplatesAppliedFromMongoDB,
  ITemplateMetadata as ITemplateApplyMetadata,
} from '../../../store/applyTemplate/types';
import { API_URL, TEMPLATES_URL } from '../../constants';
import { updateProgressPercentageForDocument } from '../common/progressPercentage';
import { headers, primeAuthHeaders } from '../common/helper';
import { getAccessToken, getGlobalToken } from '../../../auth/oktaConfig';
import { ITemplateUser } from '../../../store/templateSharing/types';
import { allOfficeGuid } from '../../utils';
import useUserInfo from '@digisign3-ui/sender/src/auth/useUserInfo';

export * from './blocks';

export const getPdfBlobFromUrl = async (downloadUrl: string): Promise<ArrayBuffer> => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(downloadUrl, { responseType: 'arraybuffer', ...headers(accessToken) });
  return data;
};

export interface ITemplateResponse<T> {
  result: T;
  status: number;
}

export const graphQlRequest = async (query: string) => {
  const accessToken = await getGlobalToken() || await getAccessToken();
  return await axios.post(`${TEMPLATES_URL}/templatesgraphql`, { query }, headers(accessToken));
};

export const getTemplates = async (
  fetchCreatedDate: boolean = true
): Promise<ITemplateResponse<ITemplateMetadata[]>> => {
  if (window.location.search.includes('?simulateEmpty')) {
    return {
      result: [],
      status: 200,
    };
  }
  const { data, status } = await graphQlRequest(
    `{
      templates(fetchShares: true) {
      id
      name
      tags {
        tagTypeId
        value
      }
      createdByName
      createdBy
      ${fetchCreatedDate ? 'createdDate' : ''}
    }
  }`
  );
  return {
    result: data.data.templates,
    status,
  };
};

export const getTemplate = async (templateId: string, fetchShares?: boolean): Promise<ITemplateResponse<ITemplate>> => {
  const { data, status } = await graphQlRequest(
    `{
    template(templateId: "${templateId}") {
      id
      name
      documents {
        id
        order
        name
        numPages
        pageDimensions {
          height
          width
        }
        links {
          downloadPDF {
            href
          }
        }
        blockGroups {
          id
          documentId
          pageNumber
          blockIds
          validation {
            rule
            value
            valueB
          }
        }
        blocks {
          blockId: id
          pageNumber
          blockType
          value
          width
          height
          x
          y
          required
          assignedTo
          fontSize
          color
          strikeType
          groupId: blockGroupId
          documentId
        }
      }
      ${fetchShares
      ? `shares {
        sharedTo {
          id
          shareType
        }
        userExclusions
      }`
      : ''
    }
      recipients {
        id
        role
        recipientTitle
      }
    }
  }`
  );
  return {
    result: data.data.template,
    status,
  };
};

export const createTemplate = async (): Promise<ITemplateResponse<ITemplateMetadata>> => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.post(TEMPLATES_URL + '/templates', {}, headers(accessToken));
  return {
    result: data,
    status,
  };
};

export const createEnvelopeFromTemplate = async (templateId: string, impersonatedPrimeUserGuid?: string): Promise<ITemplateResponse<{ id: string }>> => {
  const accessToken = await getGlobalToken() || await getAccessToken();
  const claims = jwt_decode(accessToken);
  const body: { primeUserId?: string } = {};
  if (impersonatedPrimeUserGuid || claims.prime_user_guid) {
    body.primeUserId = impersonatedPrimeUserGuid || claims.prime_user_guid;
  }

  const { data, status } = await axios.post(TEMPLATES_URL + `/templates/${templateId}/envelope`, body, headers(accessToken));
  return {
    result: data,
    status,
  };
};

export const applyTemplate = async (
  templateId: string,
  envelopeId: string,
  data: { documentId: string }
): Promise<ITemplateResponse<ITemplateMetadata>> => {
  const accessToken = await getAccessToken();
  const { resData, status } = await axios.post(
    `${API_URL}/templateApplications`,
    {
      templateId,
      envelopeId,
      data,
    },
    headers(accessToken)
  );
  return {
    result: resData,
    status,
  };
};

export const applyTemplatesInBulk = async (
  envelopeId: string,
  body: any
): Promise<ITemplateResponse<ITemplatesAppliedFromMongoDB>> => {
  const accessToken = await getGlobalToken() || await getAccessToken();
  const { data, status } = await axios.put(
    `${TEMPLATES_URL}/templateApplications/${envelopeId}`,
    {
      templateApplications: body,
    },
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const updateTemplate = async (
  templateId: string,
  patchBody: Partial<ITemplate>
): Promise<ITemplateResponse<ITemplate>> => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.patch(
    `${TEMPLATES_URL}/templates/${templateId}`,
    patchBody,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const reorderDocumentsInTemplate = async (
  templateId: string,
  orderedDocumentIds: string[]
): Promise<ITemplateResponse<ITemplate>> => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.patch(
    `${TEMPLATES_URL}/templates/${templateId}`,
    { orderedDocumentIds },
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const uploadDocumentToTemplate = async (
  templateId: string,
  file: File,
  temporaryDocumentId: string
): Promise<ITemplateResponse<IDocument>> => {
  const accessToken = await getAccessToken();
  const formData = new FormData();
  formData.append('name', file.name);
  formData.append('pdf', file);
  const { data, status } = await axios.post(`${TEMPLATES_URL}/templates/${templateId}/documents`, formData, {
    onUploadProgress: (e) => {
      updateProgressPercentageForDocument(e, temporaryDocumentId);
    },
    ...headers(accessToken),
  });
  return {
    result: data,
    status,
  };
};

export const addRecipientToTemplate = async (
  templateId: string,
  recipient: ITemplateRecipient
): Promise<ITemplateResponse<ITemplateRecipient>> => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.post(
    `${TEMPLATES_URL}/templates/${templateId}/recipients`,
    recipient,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const updateTemplateRecipient = async (
  templateId: string,
  recipientId: string,
  patchBody: Partial<ITemplateRecipient>
): Promise<ITemplateResponse<ITemplateRecipient>> => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.patch(
    `${TEMPLATES_URL}/templates/${templateId}/recipients/${recipientId}`,
    patchBody,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const deleteDocument = async (templateId: string, documentId: string): Promise<ITemplateResponse<null>> => {
  const accessToken = await getAccessToken();
  const { status } = await axios.delete(
    `${TEMPLATES_URL}/templates/${templateId}/documents/${documentId}`,
    headers(accessToken)
  );
  return {
    result: null,
    status,
  };
};

export const deleteTemplate = async (templateId: string): Promise<ITemplateResponse<null>> => {
  const accessToken = await getAccessToken();
  const { status } = await axios.delete(`${TEMPLATES_URL}/templates/${templateId}`, headers(accessToken));
  return {
    result: null,
    status,
  };
};

export const fetchTemplatesForApply = async (
  fetchShares: boolean
): Promise<ITemplateResponse<ITemplateApplyMetadata[]>> => {
  const queryString = fetchShares ? `templates(fetchShares: ${true})` : `templates`;
  const { data, status } = await graphQlRequest(
    `{
      ${queryString} {
      id
      name
      metadata {
        numberOfSigners
      },
      tags {
        tagTypeId,
        value
      }
    }
  }`
  );

  return {
    result: data.data.templates.map((t: any) => ({
      id: t.id,
      name: t.name,
      numberOfSigners: t.metadata.numberOfSigners,
      tags: t.tags,
    })),
    status,
  };
};

export const getSettings = async (accountId: string, userId: string): Promise<ITemplateResponse<ISettings>> => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.post(
    `${TEMPLATES_URL}/templatesgraphql`,
    {
      query: `{
    accountSettings(accountId: "${accountId}") {
      template {
        recipientTitles
        blockTypes {
          disabled
        }
        tagging {
          enabled
          tagTypes {
            id
            name
            displayType
            placeholderText
            helperText
            options
            iconName
          }
        }
      }
      envelope {
        blockTypes {
          disabled
        }
      }
      userOrigin
    }
    userSettings(userId: "${userId}") {
      autoStampDateTime {
        preference
      }
      dateFormat
      keepBlocksSelected
      emailDocsAsAttachment
      dateFormat
      automatedReminders {
        enabled
      }
    }
  }`,
    },
    headers(accessToken)
  );
  return {
    result: {
      account: data.data.accountSettings,
      user: data.data.userSettings,
    },
    status,
  };
};

export const updateUserSettings = async (
  settings: Partial<IUserSettings>
): Promise<ITemplateResponse<IUserSettings>> => {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const { data, status } = await axios.patch(
    `${TEMPLATES_URL}/users/${claims.delegated_user_id || claims.uid || claims.user_id || claims.UserId}/settings`,
    settings,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const getTemplateTags = async (templateId: string): Promise<ITemplateResponse<ITemplateTag[]>> => {
  const { data, status } = await graphQlRequest(
    `{
    template(templateId: "${templateId}") {
      name
      tags {
        tagTypeId
        value
      }
    }
  }`
  );
  return {
    result: data.data.template,
    status,
  };
};

export const getTemplatePreview = async (templateId: string): Promise<ITemplateResponse<Partial<ITemplate>>> => {
  const { data, status } = await graphQlRequest(
    `{
    template(templateId: "${templateId}") {
      documents {
        blocks {
          blockId: id
          pageNumber
          blockType
          value
          width
          height
          x
          y
          required
          assignedTo
          fontSize
          color
          strikeType
        }
        numPages
      }
      recipients {
        id
        role
      }
    }
  }`
  );
  return {
    result: data.data.template,
    status,
  };
};

export const createNewGroup = async (templateId: string, group: IGroup) => {
  const accessToken = await getAccessToken();
  const postBody = {
    blockType: 'Checkbox',
    pageNumber: group.pageNumber,
    validation: group.validation,
    blockIds: group.blockIds,
  };
  const { data, status } = await axios.post(
    `${TEMPLATES_URL}/templates/${templateId}/documents/${group.documentId}/blockGroups`,
    postBody,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const editBlockGroup = async (templateId: string, group: IGroup) => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.put(
    `${TEMPLATES_URL}/templates/${templateId}/documents/${group.documentId}/blockGroups/${group.id}`,
    group,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const deleteBlockGroup = async (templateId: string, documentId: string, groupId: string) => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.delete(
    `${TEMPLATES_URL}/templates/${templateId}/documents/${documentId}/blockGroups/${groupId}`,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const deleteRecipient = async (templateId: string, recipientId: string) => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.delete(
    `${TEMPLATES_URL}/templates/${templateId}/recipients/${recipientId}`,
    headers(accessToken)
  );
  return {
    result: data,
    status,
  };
};

export const getTemplatesApplied = async (
  envelopeId: string
): Promise<ITemplateResponse<ITemplatesAppliedFromMongoDB | ITemplateAppliedFromDynamoDB[]>> => {
  const accessToken = await getAccessToken();
  const { data, status } = await axios.get(`${TEMPLATES_URL}/templateApplications/${envelopeId}`, headers(accessToken));
  return {
    result: data,
    status,
  };
};

export const toggleShareTemplateToUser = async (
  user: ITemplateUser,
  templateId: string,
  sharedToAllParentGroups: boolean,
  sharedToASubGroup: boolean,
  sharedToAnOffice: boolean
) => {
  const accessToken = await getGlobalToken() || await getAccessToken();
  let requestBody: any = {};
  if (sharedToAllParentGroups || sharedToASubGroup || sharedToAnOffice) {
    if (user.isTemplateShared) {
      // the user was previously excluded from the template and now we are sharing to them
      requestBody.remove = {
        userExclusions: [`${user.oktaUserId}`],
      };
    } else {
      // the template was previously shared to user via their parent groups or via an office and now we are excluding them
      requestBody.add = {
        userExclusions: [`${user.oktaUserId}`],
      };
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (user.isTemplateShared) {
      requestBody = {
        add: {
          sharedTo: [
            {
              id: user.oktaUserId,
              shareType: 'User',
            },
          ],
        },
        remove: {
          userExclusions: [`${user.oktaUserId}`],
        },
      };
    } else {
      requestBody.remove = {
        sharedTo: [
          {
            id: user.oktaUserId,
            shareType: 'User',
          },
        ],
      };
    }
  }
  // if the template is shared to all users parent groups, we only manage userExclusions in the request
  // else we just manage the individual user shares
  await axios.put(`${TEMPLATES_URL}/templates/${templateId}/shares`, requestBody, headers(accessToken));
};

export const toggleShareTemplateToASubGroupOrOffice = async (
  templateId: string,
  id: string,
  alreadyShared: boolean,
  shareType?: string,
) => {
  const accessToken = await getGlobalToken() || await getAccessToken();
  let requestBody: any = {};
  if (alreadyShared) {
    requestBody.remove = {
      sharedTo: [
        {
          id,
          shareType,
        },
      ],
    };
  } else {
    requestBody.add = {
      sharedTo: [
        {
          id,
          shareType,
        },
      ],
    };
  }
  await axios.put(`${TEMPLATES_URL}/templates/${templateId}/shares`, requestBody, primeAuthHeaders(accessToken));
};

export const toggleShareTemplateToAllUsersParentGroups = async (alreadyShared: boolean, templateId: string, officeGuids = new Array<string>()) => {
  const accessToken = await getGlobalToken() || await getAccessToken();
  let requestBody: any = {};
  if (alreadyShared) {
    requestBody.remove = {
      sharedTo: [
        {
          shareType: 'AllGroups',
        },
        {
          shareType: 'AllUsers',
        },
      ],
    };
  } else {
    requestBody.add = {
      sharedTo: [
        {
          shareType: 'AllGroups',
        },
      ],
    };
    if (officeGuids && officeGuids.length) {
      requestBody.remove = { sharedTo: [] };
      officeGuids.forEach((o) => {
        requestBody.remove.sharedTo.push({
          id: o,
          shareType: o === allOfficeGuid ? 'AllOffice' : 'Office',
        });
      });
    }
  }
  await axios.put(`${TEMPLATES_URL}/templates/${templateId}/shares`, requestBody, headers(accessToken));
};

export const getUserTrialInfo = async (): Promise<ITemplateResponse<IUserTrial>> => {
  const { data, status } = await graphQlRequest(
    `{
    trial {
      endDate
      daysLeftInTrial
    }
  }`
  );
  return {
    result: data.data.trial,
    status,
  };
};