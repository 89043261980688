import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import { typography, elevation } from '@skyslope/mache';

// @ts-ignore
const StyledTooltip = withStyles({
  // @ts-ignore
  tooltip: {
    ...typography.body1,
    backgroundColor: 'white',
    padding: 16,
    boxShadow: elevation.normal[12],
    position: 'relative',
    marginTop: 8,
  },
  arrow: {
    color: 'white',
  },
})(Tooltip);

export default StyledTooltip;
