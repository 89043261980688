import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache/dist/src/theme';
import { ReactComponent as Mailbox } from '../images/ds3-mailbox.svg';
import FooterLinks from '../components/common/FooterLinks';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import { IRootState } from '../store';
import * as actions from '../store/senderBuild/actions';
import { signerUiUrl } from '../lib/constants';
import { checkIfRecipientIsSender, isSkySlopeMobileApp } from '../common/utils';
import { logoutCleanup } from '../auth';
import { ISigner } from '../common/recipients/types';
import { LaunchDarklyFlags } from '../common/launchDarkly';
import useUserInfo from '../auth/useUserInfo';
import { bold } from '../lib/utils';
import { isSmallScreenMediaQuery } from '../lib/isSmallScreen';

interface IState {
  signers: ISigner[];
  envelopeId: string;
  signerToken: string;
  config: any;
  automaticEmailRemindersEnabled: boolean;
}

interface IProps {
  flags?: LaunchDarklyFlags;
}

const mobilePortrait = '(max-width: 599.95px)';
const mobileLandscape = '(min-width: 600px) and (max-height: 599.95px) ';
const desktop = '(min-width: 600px) and  (min-height: 600px)';

const useStyles = makeStyles(() => ({
  container: {
    [`@media ${mobilePortrait}, ${mobileLandscape}`]: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 180px)',
    },
    [`@media ${desktop}`]: {
      display: 'flex',
      flexFlow: 'column',
      alignSelf: 'center',
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media ${mobilePortrait}, ${mobileLandscape}`]: {
      flex: 1,
      gap: '8px',
      paddingBottom: '100px',
      justifyContent: 'center',
    },
  },
  header: {
    [`@media ${mobilePortrait}`]: {
      fontFamily: 'proxima-nova, sans-serif',
      letterSpacing: '-0.28px',
      textAlign: 'center',
      width: '75%',
      marginBottom: '24px',
      marginTop: '24px',
    },
    [`@media ${mobileLandscape}`]: {
      fontFamily: 'proxima-nova, sans-serif',
      letterSpacing: '-0.28px',
      textAlign: 'center',
      marginBottom: '24px',
      marginTop: '-24px',
    },
  },
  svgContainer: {
    [`@media ${mobilePortrait}, ${mobileLandscape}`]: {
      display: 'inline-block',
      position: 'relative',
      width: '70%',
      verticalAlign: 'top',
      overflow: 'hidden',
    },
    [`@media ${mobileLandscape}`]: {
      display: 'inline-block',
      position: 'relative',
      width: '50%',
      verticalAlign: 'top',
      overflow: 'hidden',
    },
    [`@media ${desktop}`]: {
      width: '494px',
      height: '394px',
    },
  },
  description: {
    textAlign: 'center',
    [`@media ${mobilePortrait}, ${mobileLandscape}`]: {
      paddingLeft: '16px',
      paddingRight: '16px',
      textAlign: 'center',
    },
    [`@media ${desktop}`]: {
      marginTop: '20 px',
    },
  },
  buttonContainer: {
    display: 'flex',
    zIndex: 100,
    width: '100%',
    [`@media ${mobilePortrait}, ${mobileLandscape}`]: {
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      justifyContent: 'space-evenly',
      paddingTop: '24px',
      paddingBottom: '24px',
      background: '#F4F8FC',
      borderTop: `1px solid ${colors.grey[400]}`,
    },
    [`@media ${desktop}`]: {
      justifyContent: 'center',
      columnGap: '20px',
      marginTop: '40px',
    },
  },
  footer: {
    width: 'calc(80vw)',
    bottom: '40px',
    left: '100px',
    right: '100px',
    [`@media ${mobilePortrait}, ${mobileLandscape}`]: {
      display: 'none',
    },
  },
}));

export const envelopeSentSelector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
  envelopeId: state.senderBuild.id,
  signerToken: state.senderBuild.signerToken,
  config: state.senderBuild.config,
  automaticEmailRemindersEnabled: state.pageFrame.settings?.user.automatedReminders?.enabled,
});

export const EnvelopeSent = (props: IProps) => {
  const { flags } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const state: IState = useShallowSelector(envelopeSentSelector);
  const { signers, envelopeId, signerToken, config, automaticEmailRemindersEnabled } = state;
  const userInfo = useUserInfo();
  const [signNow, setSignNow] = useState(false);
  const [signNowSigner, setSignNowSigner] = useState<undefined | ISigner>();
  const isMobileApp = isSkySlopeMobileApp();
  const isSmallScreen = isSmallScreenMediaQuery();
  const [init, setInit] = useState(false);
  const showAutomatedRemindersFlag = flags && flags['automated-reminders'];

  useEffect(() => {
    if (signerToken.length) {
      window.location.href = `${signerUiUrl}?signNow=true#t=${signerToken}`;
    }
  }, [signerToken]);

  useEffect(() => {
    if (init || !userInfo) return;
    let isSigner = false;
    let signer: undefined | ISigner;
    signers.forEach((s) => {
      if (checkIfRecipientIsSender(s) && userInfo.senderEmail === s.email && s.signingGroup === 'default') {
        isSigner = true;
        signer = s;
      }
    });
    if (isSigner && flags && flags['sign-now']) {
      setSignNow(true);
      setSignNowSigner(signer);
    }
    window.scrollTo(0, 0);
    setInit(true);
  }, [userInfo, init]);

  const handleSignNow = () => {
    dispatch(
      actions.fetchSignerToken({ envelopeId, signerId: signNowSigner!.signerId, signerEmail: userInfo!.senderEmail })
    );
  };

  const handleImDone = () => {
    if (config.senderUi.sendRedirectUrl) {
      logoutCleanup();
      window.location.href = config.senderUi.sendRedirectUrl;
    } else {
      history.push('/envelopes');
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <Typography variant="h3" className={classes.header}>
          Your envelope has been sent!
        </Typography>
        <div className={classes.svgContainer}>
          <Mailbox />
        </div>
        <Typography variant="body1" className={classes.description}>
        {showAutomatedRemindersFlag && automaticEmailRemindersEnabled ? <>
          Your recipients receive an email when it's their turn to sign.{isMobileApp || isSmallScreen ? ' ' : <br />}
          If they don't sign within {bold('24 hours')}, we'll automatically{isMobileApp || isSmallScreen ? ' ' : <br />}send a reminder on your behalf.
        </> : 
        <>
          Recipients receive an email when it's their turn to sign. 
        </>}  
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button color="primary" variant="outlined" onClick={handleImDone} data-spec="im-done-button" tabIndex={0}>
          I'm done
        </Button>
        {signNow && !isMobileApp ? (
          <Button color="primary" variant="contained" tabIndex={0} onClick={handleSignNow} data-spec="sign-now-button">
            Sign now
          </Button>
        ) : (
          ''
        )}
      </div>
      <div className={classes.footer}>
        <FooterLinks showSocialIcons />
      </div>
    </div>
  );
};
export default EnvelopeSent;
