import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { setLoading } from '../pageFrame/actions';
import { ITemplate, ITemplateTag } from '../templateCreate/types';
import * as actions from './actions';
import { errorToast } from '../pageFrame/actions';
import { envelopeApi } from '../../lib/api/envelope/';
import * as templateApi from '../../lib/api/template';
import { IEnvelope, IPageDimensions } from '../senderBuild/types';
import pdfCache from '../../lib/pdfCache';
import {
  IBlockWithSignerNumber,
  ITemplateAppliedFromDynamoDB,
  ITemplatesAppliedFromMongoDB,
  ITemplateMetadata,
} from './types';
import { IRootState } from '..';
import { ITagType } from '../pageFrame/types';
import { RecipientRole } from '../../common/recipients/types';

const ActionTypes = actions.ActionTypes;

function* fetchTemplateBlocks(templateId: string) {
  const res: templateApi.ITemplateResponse<Partial<ITemplate>> = yield call(templateApi.getTemplatePreview, templateId);
  const templatePreview = res.result!;
  if (res.status !== 200) {
    throw Error(`Non-200 Status Code of Template GET: ${res.status}`);
  }

  const recipientIds = templatePreview.recipients!.filter((r) => r.role === RecipientRole.Signer).map((r) => r.id);
  const blocks: IBlockWithSignerNumber[] = [];
  let fullPage = 0;
  templatePreview.documents!.forEach((document) => {
    document.blocks?.forEach((block) => {
      const modifiedBlock: IBlockWithSignerNumber = {
        ...block,
        signerNumber: recipientIds.indexOf(block.assignedTo!),
        pageNumber: block.pageNumber + fullPage,
      };
      blocks.push(modifiedBlock);
    });
    fullPage += document.numberOfPages!;
  });
  return blocks;
}

const getUserTemplateSettings = (state: IRootState) => state.pageFrame.settings?.account.template;
const validateFlashCodes = (flashCodes: ITemplateTag[], queryFlashCodes: string[]): boolean => {
  if (!queryFlashCodes.length) {
    return true;
  }
  let valid = false;
  flashCodes.forEach((tag) => {
    if (queryFlashCodes.includes(tag.value)) {
      valid = true;
    }
  });
  return valid;
};

export function* handleFetchEnvelope(action: ReturnType<typeof actions.fetchEnvelope>) {
  try {
    yield put(setLoading(true));
    const res: envelopeApi.IResponse<IEnvelope> = yield call(envelopeApi.getEnvelopeById, action.payload);
    const envelope = res.result!;
    if (res.statusCode !== 200) {
      throw Error(`Non-200 Status Code of Envelope GET: ${res.statusCode}`);
    } else if (envelope.status !== 'Draft' && envelope.status !== 'Correcting') {
      throw Error('Envelope already sent');
    }
    const docBlobs: ArrayBuffer[] = yield all(envelope.documents.map((doc) => doc.presignedURL));
    const pageDimensions: IPageDimensions = {};
    envelope.documents.forEach((doc, i) => {
      pdfCache[doc.documentId] = docBlobs[i];
      pageDimensions[doc.documentId] = doc.pages;
    });
    yield put(actions.fetchEnvelopeDone(envelope, pageDimensions));
  } catch (err) {
    yield put(errorToast('Failed to fetch the envelope information, please refresh!'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleFetchTemplates(action: ReturnType<typeof actions.fetchTemplates>) {
  try {
    const res: templateApi.ITemplateResponse<ITemplateMetadata[]> = yield call(
      templateApi.fetchTemplatesForApply,
      action.payload.fetchShares
    );
    const templates = res.result!;
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Templates GET: ${res.status}`);
    }
    if (action.payload.templateQueryParams) {
      const settings = yield select(getUserTemplateSettings);
      const stateCodeSetting = settings.tagging.tagTypes.find((tag: ITagType) => tag.name === 'State');
      const flashCodeSetting = settings.tagging.tagTypes.find((tag: ITagType) => tag.name === 'Flash Code(s)');
      const roleSetting = settings.tagging.tagTypes.find((tag: ITagType) => tag.name === 'Role');
      const filteredTemplates: ITemplateMetadata[] = templates.filter((template) => {
        const flashTag = template.tags.filter((tag) => tag.tagTypeId === flashCodeSetting.id);
        const stateTag = template.tags.find((tag) => tag.tagTypeId === stateCodeSetting.id);
        if (!stateTag || stateTag.value !== action.payload.templateQueryParams.state) {
          return false;
        }
        if (!validateFlashCodes(flashTag, action.payload.templateQueryParams.flashCodes)) {
          return false;
        }
        const roleTag = template.tags.find((tag) => tag.tagTypeId === roleSetting.id);
        if (
          !roleTag ||
          roleTag.value.toLocaleLowerCase() !== action.payload.templateQueryParams.role.toLocaleLowerCase()
        ) {
          return false;
        }
        return true;
      });
      yield put(actions.fetchTemplatesDone(filteredTemplates));
    } else {
      yield put(actions.fetchTemplatesDone(templates));
    }
  } catch (err) {
    yield put(errorToast('Failed to fetch the template information, please refresh!'));
  }
}

export function* handlePreviewTemplate(action: ReturnType<typeof actions.previewTemplate>) {
  if (!action.payload) {
    return;
  }
  try {
    const blocks = yield fetchTemplateBlocks(action.payload);
    yield put(actions.previewTemplateDone(blocks));
  } catch (err) {
    yield put(errorToast('Failed to fetch the template information, please refresh!'));
  }
}

export function* handleFinalize(action: ReturnType<typeof actions.finalize>) {
  try {
    const res: templateApi.ITemplateResponse<ITemplateMetadata[]> = yield call(
      templateApi.applyTemplate,
      action.payload.templateId,
      action.payload.envelopeId,
      action.payload.data
    );
    if (res.status !== 201) {
      throw Error(`Non-201 Status Code of Template Apply POST: ${res.status}`);
    }
    yield put(actions.finalizeDone());
  } catch (err) {
    yield put(errorToast('Error updating templates applied'));
  }
}

export function* handleBulkTemplates(action: ReturnType<typeof actions.finalizeBulkTemplates>) {
  try {
    const { envelopeId, templatesList } = action.payload;
    const res: templateApi.ITemplateResponse<ITemplatesAppliedFromMongoDB> = yield call(
      templateApi.applyTemplatesInBulk,
      envelopeId,
      templatesList
    );
    if (res.status !== 200) {
      throw Error(`Non-200 Status Code of Bulk Template Apply PUT: ${res.status}`);
    }
    yield put(actions.finalizeBulkTemplatesDone());
  } catch (err) {
    yield put(errorToast('Error updating templates applied'));
  }
}

export function* handleFetchTemplatesApplied(action: ReturnType<typeof actions.fetchTemplatesApplied>) {
  try {
    const res: templateApi.ITemplateResponse<
      ITemplatesAppliedFromMongoDB | ITemplateAppliedFromDynamoDB[]
    > = yield call(templateApi.getTemplatesApplied, action.payload.envelopeId);
    const useMongoDBModel = action.payload.useMongoDBModel;
    const responseCodes = [200, 204];
    if (!responseCodes.includes(res.status)) {
      throw Error(`Non-Successful Status Code of Fetching Applied Templates POST: ${res.status}`);
    }
    if (!useMongoDBModel) {
      for (let i = 0; i < res.result.length; i++) {
        const blocks = yield fetchTemplateBlocks(res.result[i].templateId);
        res.result[i].blocks = blocks;
      }
    } else if (res.result.templatesApplied?.length > 0) {
      res.result.blockMapping = [];
      const allBlocksForAppliedTemplates = yield all(
        res.result.templatesApplied.map((template: string) => fetchTemplateBlocks(template))
      );
      res.result.templatesApplied.forEach((template, i) => {
        res.result.blockMapping.push({ templateId: template, blocks: allBlocksForAppliedTemplates[i] });
      });
    }
    yield put(actions.fetchTemplatesAppliedDone(res.result, useMongoDBModel));
  } catch (err) {
    yield put(errorToast('Error fetching templates applied.'));
  }
}

export function* handleFetchTemplatesAppliedForDocuments(
  action: ReturnType<typeof actions.fetchTemplatesAppliedForDocuments>
) {
  try {
    const res: templateApi.ITemplateResponse<
      ITemplatesAppliedFromMongoDB | ITemplateAppliedFromDynamoDB[]
    > = yield call(templateApi.getTemplatesApplied, action.payload);
    const responseCodes = [200, 204];
    if (!responseCodes.includes(res.status)) {
      throw Error(`Non-Successful Status Code of Fetching Applied Templates POST: ${res.status}`);
    }
    yield put(actions.fetchTemplatesAppliedForDocumentsDone(res.result));
  } catch (err) {
    yield put(errorToast('Error fetching templates applied.'));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH_ENVELOPE, handleFetchEnvelope),
    takeEvery(ActionTypes.FETCH_TEMPLATES, handleFetchTemplates),
    takeEvery(ActionTypes.PREVIEW_TEMPLATE, handlePreviewTemplate),
    takeEvery(ActionTypes.FINALIZE, handleFinalize),
    takeEvery(ActionTypes.FINALIZE_BULK_TEMPLATES, handleBulkTemplates),
    takeEvery(ActionTypes.FETCH_TEMPLATES_APPLIED, handleFetchTemplatesApplied),
    takeEvery(ActionTypes.FETCH_TEMPLATES_APPLIED_FOR_DOCUMENTS, handleFetchTemplatesAppliedForDocuments),
  ]);
}
