import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { EnvelopeStatus } from '../../store/envelopeManagement/types';
import { STATUSES } from './utils';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const useStyles = makeStyles((theme: Theme) => ({
  progressBar: {
    height: '8px',
    borderRadius: '4px',
  },
  progressBarWrapper: {
    background: colors.grey[300],
    width: '128px',
  },
  progressBarInner: (props: any) => ({
    width: props.width! || '0%',
    background: props.color! || '#47B676',
  }),
}));

interface IProps {
  status: string;
  sentDate: string;
  signerCount: number;
  completedSignerCount: number;
}

const StatusProgress = (props: IProps) => {
  const { status, sentDate, signerCount, completedSignerCount } = props;
  const statusMap = {
    [EnvelopeStatus.DRAFT]: {
      color: '',
      width: '0%',
    },
    [EnvelopeStatus.CORRECTING]: {
      color: '#F78812',
      width: '50%',
    },
    [EnvelopeStatus.SENT]: {
      color: '#00ACC1',
      width: '5%',
    },
    [EnvelopeStatus.CANCELED]: {
      color: '#D20004',
      width: '50%',
    },
    [EnvelopeStatus.COMPLETED]: {
      color: '#47B676',
      width: '100%',
    },
    [EnvelopeStatus.INPROGRESS]: {
      color: '#00ACC1',
      width: `${10 + (signerCount > 1 ? (completedSignerCount * 90) / signerCount : 0)}%`,
    },
  };
  const progressBarInner = statusMap[status.toLowerCase()];
  const styleProps = progressBarInner ? { color: progressBarInner.color, width: progressBarInner.width } : {};
  const classes = useStyles(styleProps);
  let displayStatus = status;

  if (sentDate && status.toLowerCase() === EnvelopeStatus.SENT) {
    const now = dayjs();
    const sentEnvelopeDate = dayjs(sentDate);
    displayStatus = `Sent ${now.from(sentEnvelopeDate, true)} ago`;
  } else if (signerCount > 1 && status.toLowerCase() === EnvelopeStatus.INPROGRESS) {
    displayStatus = `Signed by ${completedSignerCount} of ${signerCount}`;
  } else {
    displayStatus = STATUSES[status].label;
  }

  return (
    <div>
      <Typography data-spec="display-status">{displayStatus}</Typography>
      <div className={`${classes.progressBarWrapper} ${classes.progressBar}`}>
        <div className={`${classes.progressBarInner} ${classes.progressBar}`} />
      </div>
    </div>
  );
};

export default StatusProgress;
