import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { env } from './constants';

// if the application isn't running locally then we track errors with sentry
export default function initSentry() {
  if (
    env !== '__REPLACE_ME_FROM_ENTRYPOINT__DS3_ENV__' ||
    process.env.env !== 'local' ||
    process.env.SENTRY_ENABLED === 'true'
  ) {
    Sentry.init({
      dsn: 'https://4ee4ac569a0042d69568b119dc26e412@o460680.ingest.sentry.io/5566190',
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing(), new Sentry.Replay({ maskAllText: true, maskAllInputs: true })],
      environment: env,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.2,
      beforeSend(event) {
        if (event.request?.url) {
          event.request.url = event.request.url.split('#')[0];
        }
        return event;
      },
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }
}
