import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/applyTemplate/actions';
import { IRootState } from '../../store';
import { IAppliedTemplates } from '../../store/applyTemplate/types';
import { IDocument } from '../../store/senderBuild/types';
import { colors } from '@skyslope/mache';
import Description from '@material-ui/icons/Description';
import CheckIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  header: {
    height: 68,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(4),
    borderBottom: `1px solid ${colors.grey[400]}`,
  },
  icon: {
    marginRight: theme.spacing(2),
    color: colors.grey[500],
    width: 28,
    height: 28,
  },
  document: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderBottom: `1px solid ${colors.grey[400]}`,
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedDocument: {
    backgroundColor: '#FFFFFF',
  },
  overflowContainer: {
    overflow: 'hidden',
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: 1,
  },
  iconWrapper: {
    position: 'relative',
    display: 'flex',
  },
  check: {
    color: '#02AF90',
    fontSize: 18,
    position: 'absolute',
    right: 14,
    top: -4,
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
  },
}));

interface IState {
  documents: IDocument[];
  selectedDocumentId: string;
  appliedTemplates: IAppliedTemplates;
}

const selector = (state: IRootState) => ({
  documents: state.applyTemplate.envelope!.documents,
  selectedDocumentId: state.applyTemplate.selectedDocumentId!,
  appliedTemplates: state.applyTemplate.appliedTemplates,
});

const Documents = () => {
  const { documents, selectedDocumentId, appliedTemplates }: IState = useShallowSelector(selector);
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectDocument = (documentId: string) => {
    if (documentId === selectedDocumentId) {
      return;
    }
    dispatch(actions.selectDocument(documentId));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="subtitle1">YOUR UPLOADED DOCUMENTS</Typography>
      </div>
      {documents.map(document => (
        <div
          data-spec="apply-template-document"
          key={document.documentId}
          className={`${classes.document} ${
            document.documentId === selectedDocumentId ? classes.selectedDocument : ''
          }`}
          onClick={() => {
            selectDocument(document.documentId);
          }}
        >
          <div className={classes.iconWrapper}>
            <Description className={classes.icon} />
            {appliedTemplates[document.documentId] && <CheckIcon className={classes.check} />}
          </div>
          <div className={classes.overflowContainer}>
            <Typography variant="body1" className={classes.fileName}>
              {document.fileName}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Documents;
