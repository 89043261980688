import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import { colors } from '@skyslope/mache';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Table from '../components/common/Table';
import { fetchProperties, resetEndOfPropertiesReached, resetProperties } from '../../store/dtm/properties/actions';
import { IFile } from '../types';
import Empty from '../components/common/EmptyState';
import {
  createEmptyEnvelopeDTM,
  createEmptyEnvelopeWithNoProperty,
  updateEnvelopeEmail,
  updateEnvelopeProperty,
} from '../../lib/api/envelope/envelope';
import selectPropertyEmpty from '../../images/selectPropertyEmpty.svg';
import { IRootState } from '../../store';
import PropertyStatus from '../components/common/PropertyStatus';
import { preferencesUrl } from '../../lib/constants';
import { HeaderContext } from '../../context/header-context';
import NavHeader from '../../common/navigation';
import useBottomScrollListener from '../../hooks/useBottomScrollListener';
import SkeletonTable from '../../common/loading/SkeletonTable';
import { withLaunchDarkly, LaunchDarklyFlags } from '../../common/launchDarkly';
import { getTimezoneCode } from '../../lib/utils';
import useDebounce from '../../lib/useDebounce';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import Search from '../../components/common/Search';
import Footer from '../../components/common/Footer';
import FooterLinks from '../../components/common/FooterLinks';
import { getUsersGroups } from '../../lib/api/account/accountApi';
import LoadingTable from '../components/loading/LoadingTable';

dayjs.extend(advancedFormat);

const useStyles = makeStyles((theme: Theme) => ({
  properties: {
    maxWidth: 1200,
    width: '100%',
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px 70px`,
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  tableControls: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    height: 1,
    width: '100%',
    marginTop: 40,
    marginBottom: 32,
    backgroundColor: colors.grey[400],
  },
  count: {
    display: 'flex',
    alignItems: 'center',
  },
  countIcon: {
    marginRight: theme.spacing(1),
    color: colors.grey[500],
  },
  date: {
    fontWeight: 'normal',
  },
  templateCount: {
    display: 'flex',
    alignItems: 'center',
  },
  templateCountIcon: {
    marginRight: theme.spacing(1),
    color: colors.grey[500],
  },
  foundSkeleton: {
    width: 200,
  },
}));

interface IState {
  totalCount: number;
  properties: IFile[];
  salesEnd: boolean;
  listingEnd: boolean;
}

const selector = (state: IRootState) => ({
  totalCount: state.dtm.properties.totalCount,
  properties: state.dtm.properties.properties,
  salesEnd: state.dtm.properties.salesEnd,
  listingEnd: state.dtm.properties.listingEnd,
});

const Properties = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const { properties, totalCount } = store;
  const params = useParams<{ id?: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [files, setFiles] = useState<IFile[]>([]);
  const [search, setSearch] = useState('');
  const [selectedFile, setSelectedFile] = React.useState<IFile | null>(null);
  const hasInit = React.useRef(false);
  const debouncedSearch = useDebounce(search, 200);
  const headerContext = React.useContext(HeaderContext);
  const [showBottomLoader, setShowBottomLoader] = useState(false);
  const [alreadySearched, setAlreadySearched] = useState(false);
  const [sortModifiedAsc, setSortModifiedAsc] = useState(false);
  const [activeSearchType, setActiveSearchType] = useState('');
  const [fetching, setFetching] = useState(false);
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const existingEnvelopeId = params.id;
  const [loadingTable, setLoadingTable] = useState(false);

  const tableColumns = [
    {
      key: 'propertyStr',
      label: 'Address',
      boldText: true,
    },
    {
      key: 'modifiedOn',
      label: 'Last Modified',
      sortKey: 'LASTMODIFIED',
      cell: (file: IFile) => (
        <Typography classes={{ root: classes.date }} variant="body2">
          {dayjs(file.modifiedOn).format('MM/DD/YY hh:mm A').concat(' ', getTimezoneCode())}
        </Typography>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      cell: (file: IFile) => <PropertyStatus file={file} />,
    },
  ];

  useBottomScrollListener(() => {
    if (!(store.salesEnd && store.listingEnd)) {
      setShowBottomLoader(true);
      setActiveSearchType('scroll');
      dispatch(
        fetchProperties({
          searchValue: search,
          sortAsc: sortModifiedAsc,
          searchType: 'scroll',
        })
      );
    }
  });

  useEffect(() => {
    if (showBottomLoader) {
      setShowBottomLoader(false);
    }
    hasInit.current = true;
    setFiles(properties);
    setLoadingTable(false);
    setActiveSearchType('');
    setFetching(false);
  }, [properties]);

  React.useEffect(() => {
    setLoadingTable(true);
    setActiveSearchType('init');
    dispatch(fetchProperties({ searchValue: '', sortAsc: sortModifiedAsc, searchType: 'init' }));
    headerContext.setContent(
      <NavHeader
        labelName="Select property"
        breadcrumb={{ name: 'Envelope Management', onClick: () => history.push('/envelopes') }}
        progressBarPercent={20}
        hideNavItems={hideNavItems}
        showBreezeApp={showBreezeApp}
        preferencesUrl={preferencesUrl}
        showPreferences={showPreferencesMenuItem}
        getUsersGroups={getUsersGroups}
        flags={flags}
      />
    );
    return () => {
      dispatch(resetProperties());
      headerContext.setContent(null);
    };
  }, []);

  React.useEffect(() => {
    if (activeSearchType !== 'search') return;
    dispatch(fetchProperties({ searchValue: search, sortAsc: sortModifiedAsc, searchType: 'search' }));
  }, [debouncedSearch]);

  const onClickNext = async () => {
    if (!selectedFile) return;
    dispatch(resetEndOfPropertiesReached());
    let envelopeId: string;
    if (existingEnvelopeId) {
      await updateEnvelopeProperty(existingEnvelopeId, selectedFile);
      envelopeId = existingEnvelopeId;
    } else {
      envelopeId = await createEmptyEnvelopeDTM(selectedFile);
    }
    await updateEnvelopeEmail(envelopeId, selectedFile.propertyStr);
    history.push(`/dtm/envelopes/${envelopeId}/documents`);
  };

  if (!hasInit.current) {
    return null;
  }

  const handleRowClick = (file: IFile) => {
    setSelectedFile(selectedFile?.id === file.id ? null : file);
  };

  const handleTableSort = (sortKey: string) => {
    const modifiedAsc = !sortModifiedAsc;
    setSortModifiedAsc(modifiedAsc);
    setLoadingTable(true);
    setActiveSearchType('sort');
    setFetching(true);
    dispatch(resetEndOfPropertiesReached());
    dispatch(fetchProperties({ searchValue: search, sortAsc: modifiedAsc, searchType: 'sort' }));
  };

  const returnTable = () => {
    if (activeSearchType === 'sort' || activeSearchType === 'search') {
      return (
        <>
          <div data-spec="skeleton-loading-table">
            <div className={classes.divider} />
            <div className={classes.tableControls}>
              <Typography variant="overline" className={classes.templateCount} data-spec="template-count">
                <DescriptionIcon className={classes.templateCountIcon} />
                <span className={classes.foundSkeleton}>
                  <Skeleton animation="wave" />
                </span>
              </Typography>
            </div>
            <SkeletonTable columns={tableColumns} />
          </div>
        </>
      );
    }
    return (
      <>
        <Table
          onClickRow={handleRowClick}
          columns={tableColumns}
          rows={properties}
          idKey="id"
          showBottomLoader={showBottomLoader}
          onClickHeader={handleTableSort}
          currentSortDesc={!sortModifiedAsc}
          currentSortKey="LASTMODIFIED"
          rowIsSelected={(file: IFile) => file.id === selectedFile?.id}
        />
      </>
    );
  };

  const handleEmptyClick = async () => {
    let envelopeId = '';
    if (!existingEnvelopeId) {
      envelopeId = await createEmptyEnvelopeWithNoProperty();
    }
    history.push(`/envelopes/${existingEnvelopeId || envelopeId}/documents`);
  };

  const emptyStateText = {
    heading: 'No results found',
    subtitle: 'Try changing your search terms or filters to find what you’re looking for.',
  };

  const noPropertiesText = {
    heading: 'There are no properties linked to this account.',
    subtitle: 'Continue without a property or create a transaction or listing in SkySlope to continue.',
    button: 'Continue Without Property',
  };

  const handleSearch = (value: string) => {
    if (!alreadySearched && value !== '') {
      setAlreadySearched(true);
    }
    setSearch(value.toLocaleLowerCase());
    setActiveSearchType('search');
    dispatch(resetEndOfPropertiesReached());
    setFetching(true);
  };

  const emptyState = () => {
    const emptyStateContent = search ? emptyStateText : noPropertiesText;
    return (
      <Empty
        icon={selectPropertyEmpty}
        heading={emptyStateContent.heading}
        headAria={emptyStateContent.heading}
        subtitle={emptyStateContent.subtitle}
        subAria={emptyStateContent.subtitle}
        buttonText={totalCount ? null : emptyStateContent.button}
        buttAria={totalCount ? null : emptyStateContent.button}
        actionButton={() => handleEmptyClick()}
      />
    );
  };

  const getPropertyCountString = () => {
    const properties = totalCount === 1 ? 'PROPERTY' : 'PROPERTIES';
    const filtered = search ? '(FILTERED)' : '';

    return `${totalCount.toLocaleString()} ${properties} FOUND ${filtered}`;
  };

  const counter = () => {
    if (!fetching && !loadingTable) {
      return (
        <>
          <div className={classes.divider} />
          <div className={classes.tableControls}>
            <Typography variant="overline" className={classes.count} data-spec="count">
              <DescriptionIcon className={classes.countIcon} />
              {getPropertyCountString()}
            </Typography>
          </div>
        </>
      );
    }
    return '';
  };

  const contentToRender = () => {
    if (loadingTable) {
      return <LoadingTable />;
    }
    if (files.length < 1 && activeSearchType !== 'search') {
      return emptyState();
    }
    return returnTable();
  };

  const headerContent = () => {
    if (!loadingTable) {
      return (
        <>
          <Typography variant="h3" className={classes.header}>
            Select a property
          </Typography>
          <Search value={search} onChange={(value) => handleSearch(value)} />
        </>
      );
    }
  };

  const footerContent = () => {
    if (!loadingTable) {
      return (
        <>
          <Footer>
            {hideNavItems ? (
              ''
            ) : (
              <Button color="primary" onClick={() => history.push('/envelopes')}>
                Exit
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              disabled={!selectedFile}
              onClick={onClickNext}
              data-spec="next-button"
            >
              Next
            </Button>
          </Footer>
          <FooterLinks />
        </>
      );
    }
  };

  return (
    <div className={classes.properties}>
      {headerContent()}
      {counter()}
      {contentToRender()}
      {footerContent()}
    </div>
  );
};

export default withLaunchDarkly(Properties);
